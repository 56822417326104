import { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import PopupModalForDelete from './delete-company-popup'
import {
  UPDATE_TRADE_CONTRACTOR,
  GET_INVITATION_BY_TRADE_CONTRACTOR_ID,
  CREATE_BROKER,
  LIST_TRADE_CONTRACTOR_BROKERS,
  DELETE_ALL_BROKER_FOR_A_TRADE_CONTRACTOR,
  GET_USER_BY_EMAIL_AND_ROLE,
} from '../graphql/graphql-queries'
import CustomClickableButton from './custom-components/custom-clickable-button'
import ValidationError from './validation-error'
import { maskEin } from '../helper-functions'
import { TrashIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { useLocation } from 'react-router-dom'
import { IBrokerDetails } from '../interface'
import { useValidationPermissionsSettings } from './settings-component/use-validation-permissions-settings'
import { useDebounce } from './custom-hooks/use-debounce'
import CusotmOverlayTooltip from './custom_overlay_tooltip'
export interface TradeContractorFormProps extends React.ComponentProps<any> {
  className?: string
}

const TradeContractorForm: React.FC<TradeContractorFormProps> = ({
  className,
}) => {
  const navigate = useNavigate()
  const location: any = useLocation()
  let tradeContractorData = location?.state?.tradeContractor
  const [tradeContractorDetails, setTradeContractorDetails] =
    useState<any>(null)
  const [alternateEmails, setAlternateEmails] = useState<any>([])

  const [
    isDelegatePolicySubmissionChecked,
    setIsDelegatePolicySubmissionChecked,
  ] = useState<boolean>(false)
  const url = new URL(window.location.href)
  const endpoint = url.pathname
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isDeletedSuccessfully, setIsDeletedSuccessfully] = useState(false)
  const [selectedBrokerForDeletion, setSelectedBrokerForDeletion] = useState({
    brokerId: '',
    tradeContractorId:
      tradeContractorData?.contractedCompanyUser?.id ||
      tradeContractorDetails?.user?.id,
  })
  const initialTradeContractorDetails = {
    companyName: '',
    companyAddress: '',
    ein: '',
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    alternateEmail1: '',
    alternateEmail2: '',
    alternateEmail3: '',
    alternateEmail4: '',
    brokerList: [
      {
        firstName: '',
        lastName: '',
        email: '',
        isActive: false,
      },
    ],
  }

  const [tradeContractorInput, setTradeContractorInput] = useState<any>(
    initialTradeContractorDetails
  )
  const [isSearching, setIsSearching] = useState(false)
  const [searchingIndex, setSearchingIndex] = useState(-1)

  const [email, setEmail] = useState('')
  const debouncedSearchTerm = useDebounce(email, 400)

  let {
    validateBrokersDetailsInputForm,
    checkBrokersDetailsValid,
    brokersDetailslnputError,
    setBrokersDetailsInputError,
    resetBrokersDetailslInputErrors,
    checkIfBrokersDetailsFormEdited,
  } = useValidationPermissionsSettings(
    tradeContractorInput.brokerList,
    tradeContractorInput.brokerList
  )

  const initialBrokerDetails = {
    firstName: '',
    lastName: '',
    email: '',
  }
  useEffect(() => {
    updateListOfBrokers(
      tradeContractorData?.contractedCompanyUser?.id ||
        tradeContractorDetails?.user?.id
    )
    if (
      isDeletedSuccessfully &&
      tradeContractorInput?.brokerList?.length === 1 &&
      tradeContractorInput?.brokerList[0]?.firstName === ''
    ) {
      setIsDelegatePolicySubmissionChecked(false)
    }
  }, [isDeletedSuccessfully])
  useEffect(() => {
    setTradeContractorInput({
      ...tradeContractorInput,
      companyName: tradeContractorDetails?.user?.companies?.[0]?.name,
      email: tradeContractorDetails?.user?.email,
      alternateEmail1: tradeContractorDetails?.user?.companies?.alternateEmail1,
      alternateEmail2: tradeContractorDetails?.user?.companies?.alternateEmail2,
      alternateEmail3: tradeContractorDetails?.user?.companies?.alternateEmail3,
      alternateEmail4: tradeContractorDetails?.user?.companies?.alternateEmail4,
    })

    updateListOfBrokers(tradeContractorDetails?.user?.id)
  }, [tradeContractorDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  const [getTradeContractorByLoggedUserId] = useLazyQuery(
    GET_INVITATION_BY_TRADE_CONTRACTOR_ID,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setTradeContractorDetails(data.getInvitationByTradeContractorId)
      },
    }
  )

  useEffect(() => {
    if (tradeContractorData) {
      let dataOfTrade = tradeContractorData
      let obj: any = {
        companyName: dataOfTrade?.contractedCompany?.name,
        companyAddress: dataOfTrade?.contractedCompany?.address,
        ein: dataOfTrade?.contractedCompany?.ein,
        firstName: dataOfTrade?.contractedCompanyUser?.firstName,
        lastName: dataOfTrade?.contractedCompanyUser?.lastName,
        email: dataOfTrade?.contractedCompanyUser?.email,
        title: dataOfTrade?.contractedCompanyUser?.title,
        alternateEmail1: dataOfTrade?.contractedCompany?.alternateEmail1,
        alternateEmail2: dataOfTrade?.contractedCompany?.alternateEmail2,
        alternateEmail3: dataOfTrade?.contractedCompany?.alternateEmail3,
        alternateEmail4: dataOfTrade?.contractedCompany?.alternateEmail4,
      }
      setTradeContractorInput({ ...tradeContractorInput, ...obj })

      updateListOfBrokers(dataOfTrade?.contractedCompanyUser?.id)
    } else {
      getTradeContractorByLoggedUserId()
    }
  }, [tradeContractorData])
  const [listTradeContractorBrokers] = useLazyQuery(
    LIST_TRADE_CONTRACTOR_BROKERS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        let brokersLists: IBrokerDetails[] =
          data?.listTradeContractorBrokers?.map((el: any) => ({
            ...el?.broker,
            isActive: el?.isActive,
          }))

        if (brokersLists.length) {
          setIsDelegatePolicySubmissionChecked(true)
          setTradeContractorInput({
            ...tradeContractorInput,
            brokerList: [...brokersLists],
          })
        } else {
          setTradeContractorInput({
            ...tradeContractorInput,
            brokerList: [
              {
                firstName: '',
                lastName: '',
                email: '',
                isActive: false,
              },
            ],
          })
        }
      },
      onError(err) {
        //  setErrorMessage('Some error encountered. Please try again!')
      },
    }
  )
  const handleTradeContractorInputOnChange = (e: any) => {
    setTradeContractorInput({
      ...tradeContractorInput,
      [e.target.name]: e.target.value,
    })
  }
  const updateListOfBrokers = (userId: string) => {
    listTradeContractorBrokers({
      variables: {
        tradeContractorId: userId,
      },
    })
  }
  const handleDelegatePolicySubmissionCheckboxClick = (e: any) => {
    setIsDelegatePolicySubmissionChecked(e.target.checked)
    if (e.target.checked) {
      updateListOfBrokers(
        tradeContractorData?.contractedCompanyUser?.id ||
          tradeContractorDetails?.user?.id
      )
    }
    if (
      e.target.checked === false &&
      tradeContractorInput.brokerList.length >= 1 &&
      tradeContractorInput.brokerList[0].email !== ''
    ) {
      deleteAllBrokerForATradeContractor({
        variables: {
          tradeContractorId:
            tradeContractorData?.contractedCompanyUser?.id ||
            tradeContractorDetails?.user?.id,
        },
      })
    }
  }

  const [deleteAllBrokerForATradeContractor] = useMutation(
    DELETE_ALL_BROKER_FOR_A_TRADE_CONTRACTOR,
    {
      onCompleted: (data) => {},
    }
  )
  const handleOnChangeEin = (event: any) => {
    const maskedEin = maskEin(event.target.value)
    setTradeContractorInput({
      ...tradeContractorInput,
      ein: maskedEin!,
    })
  }

  const [updateTradeContractor] = useMutation(UPDATE_TRADE_CONTRACTOR, {
    onCompleted: (data) => {
      if (tradeContractorData) {
        navigate(-1)
      } else {
        window.location.href = '/jobs'
      }
    },
  })

  const handleTradeContractorSubmitClick = (e: any) => {
    let errorss = validateForm()
    let errors1 = validateBrokersDetailsInputForm()
    if (isValid(errorss)) {
      if (
        isDelegatePolicySubmissionChecked &&
        checkBrokersDetailsValid(errors1)
      ) {
        updateTradeContractorDetails()
        resetBrokersDetailslInputErrors()
      } else if (
        isDelegatePolicySubmissionChecked &&
        checkBrokersDetailsValid(errors1) === false
      ) {
        setBrokersDetailsInputError(errors1)
      } else {
        updateTradeContractorDetails()
      }
    } else {
      setError(errorss)
    }
  }
  const updateTradeContractorDetails = () => {
    updateTradeContractor({
      variables: {
        data: {
          ...tradeContractorInput,
          tradeContractorCompanyId: !tradeContractorData
            ? tradeContractorDetails?.user?.companies?.[0]?.id
            : tradeContractorData?.contractedCompanyId,
          tradeContractorUserId: !tradeContractorData
            ? tradeContractorDetails?.user?.id
            : tradeContractorData?.contractedCompanyUserId,
        },
      },
    })
    resetErrors()
  }
  const [error, setError] = useState({
    companyAddress: '',
    ein: '',
    firstName: '',
    lastName: '',
    title: '',
  })

  const validateForm = () => {
    let errors = {
      companyAddress: '',
      ein: '',
      firstName: '',
      lastName: '',
      title: '',
    }

    errors.companyAddress = validateCompanyAddress(
      tradeContractorInput?.companyAddress
    )!
    errors.ein = validateEin(tradeContractorInput?.ein)!
    errors.firstName = validateFirstName(tradeContractorInput?.firstName)!
    errors.lastName = validateLastName(tradeContractorInput?.lastName)!
    errors.title = validateTitle(tradeContractorInput?.title)!

    return errors
  }

  const isValid = (errors: any) => {
    let keys = Object.keys(errors)
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0)
    return count === 0
  }

  const validateCompanyAddress = (companyAddress: string) => {
    if (companyAddress === '') {
      return 'Company Address is required'
    }
    return ''
  }

  const validateEin = (ein: string) => {
    if (ein?.length > 0 && ein?.length < 11) {
      return 'EIN must be 9 digits long'
    }
    return ''
  }

  const validateFirstName = (firstName: string) => {
    if (firstName === '') {
      return 'First Name is required'
    }
    return ''
  }

  const validateLastName = (lastName: string) => {
    if (lastName === '') {
      return 'Last Name is required'
    }
    return ''
  }

  const validateTitle = (title: string) => {
    if (title === '') {
      return 'Title is required'
    }
    return ''
  }

  const resetErrors = () => {
    setError(initialTradeContractorDetails)
  }
  const addAlternateEmails = () => {
    if (alternateEmails.length !== 4) {
      let newObj = { alternateEmail: '' }
      let newAlternateArray = []
      newAlternateArray.push(newObj)
      setAlternateEmails([...alternateEmails, newObj])
    }
  }
  const removeAlternateEmail = (index: number, key: string) => {
    const alternateEmailArray = [...alternateEmails]
    let duplicateTradeInput = { ...tradeContractorInput }
    duplicateTradeInput[key as keyof typeof tradeContractorInput] = ''
    alternateEmailArray.splice(index, 1)
    setTradeContractorInput(duplicateTradeInput)
    setAlternateEmails(alternateEmailArray)
  }
  const handleOnChangeForAlternateEmail = (index: number, e: any) => {
    let duplicateAlternateEmailArray = [...alternateEmails]
    duplicateAlternateEmailArray[index].alternateEmail = e.target.value
    setTradeContractorInput({
      ...tradeContractorInput,
      [e.target.name]: e.target.value,
    })
    setAlternateEmails([...duplicateAlternateEmailArray])
  }
  const handleToggleSwitch = (e: any, index: number) => {
    let { checked } = e.target
    let duplicateBrokerDetailsArray = [...tradeContractorInput.brokerList]
    let updatedBrokerDetails = duplicateBrokerDetailsArray.map(
      (brokerDetail, i) => {
        if (i === index && checked) {
          return { ...brokerDetail, isActive: checked }
        }
        return { ...brokerDetail, isActive: false }
      }
    )
    setTradeContractorInput({
      ...tradeContractorInput,
      brokerList: updatedBrokerDetails,
    })
  }
  const removeBrokerDetails = (index: number, brokerId: string) => {
    if (brokerId) {
      let brokerForDeletion = {
        ...selectedBrokerForDeletion,
        brokerId,
        tradeContractorId:
          tradeContractorData?.contractedCompanyUser?.id ||
          tradeContractorDetails?.user?.id,
      }
      setSelectedBrokerForDeletion(brokerForDeletion)
      setShowDeleteModal(true)
    } else {
      const brokerDetailRows = [...tradeContractorInput.brokerList]
      brokerDetailRows.splice(index, 1)
      setTradeContractorInput({
        ...tradeContractorInput,
        brokerList: brokerDetailRows,
      })

      setIsDelegatePolicySubmissionChecked(false)
    }
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      checkEmailExistsAndRetrieveDetails(email)
    }
  }, [debouncedSearchTerm])
  const [getBrokerByEmail] = useLazyQuery(GET_USER_BY_EMAIL_AND_ROLE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setIsSearching(false)
      let matchedBrokerDetails = data?.getUserByEmailAndRole
      if (matchedBrokerDetails) {
        let updatedBrokerDetails = tradeContractorInput.brokerList?.map(
          (broker: any) => {
            if (broker.email !== matchedBrokerDetails.email) {
              return broker
            } else {
              return {
                ...broker,
                id: matchedBrokerDetails?.id,
                firstName: matchedBrokerDetails?.firstName,
                lastName: matchedBrokerDetails?.lastName,
              }
            }
          }
        )
        setTradeContractorInput({
          ...tradeContractorInput,
          brokerList: updatedBrokerDetails,
        })
      } else {
        let updatedBrokerDetails = [...tradeContractorInput.brokerList]
        updatedBrokerDetails = updatedBrokerDetails?.map((broker) => {
          if (broker.email === email) {
            return { ...broker, id: '' }
          } else {
            return broker
          }
        })
        setTradeContractorInput({
          ...tradeContractorInput,
          brokerList: updatedBrokerDetails,
        })
      }
    },
  })
  const checkEmailExistsAndRetrieveDetails = (email: string) => {
    getBrokerByEmail({
      variables: {
        email,
        role: 'BROKER',
      },
    })
  }
  const handleOnChangeForBrokerDetails = (index: number, e: any) => {
    const { name, value, checked } = e.target
    if (name === 'email') {
      setSearchingIndex(index)

      setIsSearching(true)
      setEmail(value)
    }
    let duplicateBrokerDetailsArray = tradeContractorInput.brokerList
    duplicateBrokerDetailsArray[index][name] = value
    setTradeContractorInput({
      ...tradeContractorInput,
      brokerList: duplicateBrokerDetailsArray,
    })
  }
  console.log('trade contract input', tradeContractorInput)
  return (
    <div
      className={`${className} text-start border text-start client-list shadow-none p-5 m-4 bg-white rounded`}
    >
      <div className="w-50">
        {!tradeContractorData ? (
          <div className="mb-4">
            <p className="fs-4 fw-bold mb-0">{`Hey, ${tradeContractorDetails?.user?.firstName}!`}</p>
            <p className="text-muted fw-bold">
              Let's get some details about your company
            </p>
          </div>
        ) : (
          <div className="mb-4">
            <p className="fs-4 fw-bold mb-0">{`Hey, ${tradeContractorInput?.firstName}!`}</p>
            <p className="text-muted fw-bold">
              Here's the details about your company
            </p>
          </div>
        )}

        <Form>
          <div className="mb-5">
            <p className="fs-5 fw-bold">Company Details</p>
            <Form.Group className="mb-3" controlId="companyName">
              <Form.Label className="fw-bold">Company Name</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                value={tradeContractorInput.companyName}
                onChange={handleTradeContractorInputOnChange}
                readOnly={!tradeContractorData}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="companyAddress">
              <Form.Label className="fw-bold">Company Address</Form.Label>
              <Form.Control
                type="text"
                name="companyAddress"
                value={tradeContractorInput.companyAddress}
                onChange={handleTradeContractorInputOnChange}
                autoComplete="off"
              />
              {error.companyAddress && (
                <ValidationError errorMessage={error.companyAddress} />
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="ein">
              <Form.Label className="fw-bold">EIN</Form.Label>
              <Form.Control
                type="text"
                name="ein"
                value={tradeContractorInput.ein}
                onChange={handleOnChangeEin}
                autoComplete="off"
              />
              {error.ein && <ValidationError errorMessage={error.ein} />}
            </Form.Group>
          </div>

          <div>
            <p className="fs-5 fw-bold">Responsible Party</p>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label className="fw-bold">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={tradeContractorInput.firstName}
                    onChange={handleTradeContractorInputOnChange}
                    autoComplete="off"
                  />
                  {error.firstName && (
                    <ValidationError errorMessage={error.firstName} />
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="lastName">
                  <Form.Label className="fw-bold">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={tradeContractorInput.lastName}
                    onChange={handleTradeContractorInputOnChange}
                    autoComplete="off"
                  />
                  {error.lastName && (
                    <ValidationError errorMessage={error.lastName} />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="title">
                <Form.Label className="fw-bold">Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={tradeContractorInput.title}
                  onChange={handleTradeContractorInputOnChange}
                  autoComplete="off"
                />
                {error.title && <ValidationError errorMessage={error.title} />}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label className="fw-bold">Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={tradeContractorInput.email}
                  readOnly
                />
              </Form.Group>
            </Row>
            {alternateEmails.length !== 0 &&
              alternateEmails.map((element: any, index: number) => {
                return (
                  <Row>
                    <Col sm={11}>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Control
                          type="email"
                          name={`alternateEmail${index + 1}`}
                          value={element.alternateEmail}
                          placeholder="Add another contact"
                          onChange={(e) =>
                            handleOnChangeForAlternateEmail(index, e)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={1}>
                      <TrashIcon
                        className="react-icon"
                        style={{
                          marginTop: '0px',
                        }}
                        onClick={() =>
                          removeAlternateEmail(
                            index,
                            `alternateEmail${index + 1}`
                          )
                        }
                      />
                    </Col>
                  </Row>
                )
              })}
          </div>
          {alternateEmails.length !== 4 && (
            <div>
              <span
                className="button add text-primary-medium fw-bold add-trade-contractor-contact-cursor"
                onClick={addAlternateEmails}
              >
                <span className="plus-icon">+</span>
                <span>Add another contact</span>
              </span>
            </div>
          )}

          <div className="mt-5">
            <Form.Group className="mb-3" controlId="delegatePolicySubmission">
              <Form.Check
                type="checkbox"
                label={
                  <p className="fs-5 fw-bold">
                    Delegate Policy Submission
                    <CusotmOverlayTooltip tooltipContent="Allow your broker to submit/upload your policy on your behalf">
                      <QuestionMarkCircleIcon className="react-icon question-mark-circle-icon ms-3" />
                    </CusotmOverlayTooltip>
                  </p>
                }
                name="delegatePolicySubmission"
                key="delegatePolicySubmission"
                checked={isDelegatePolicySubmissionChecked}
                onChange={(e: any) =>
                  handleDelegatePolicySubmissionCheckboxClick(e)
                }
              ></Form.Check>
            </Form.Group>

            {isDelegatePolicySubmissionChecked && (
              <>
                {tradeContractorInput?.brokerList?.length !== 0 &&
                  tradeContractorInput?.brokerList?.map(
                    (brokerDetail: any, index: number) => {
                      return (
                        <Row key={`broker${index}`}>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="fw-bold">
                                Email Address
                              </Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                value={brokerDetail?.email}
                                onChange={(e) =>
                                  handleOnChangeForBrokerDetails(index, e)
                                }
                                autoComplete="off"
                                // disabled={!!brokerDetail?.id}
                              />
                              {isSearching && searchingIndex === index && (
                                <div className="pt-2 text-primary fw-bold">
                                  Searching...
                                </div>
                              )}
                              {brokersDetailslnputError[index]?.email && (
                                <ValidationError
                                  errorMessage={
                                    brokersDetailslnputError[index]?.email
                                  }
                                />
                              )}
                              {brokersDetailslnputError[index]?.email && (
                                <ValidationError
                                  errorMessage={
                                    brokersDetailslnputError[index]?.email
                                  }
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicFirstName"
                            >
                              <Form.Label className="fw-bold">
                                First Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="firstName"
                                value={brokerDetail?.firstName}
                                onChange={(e) =>
                                  handleOnChangeForBrokerDetails(index, e)
                                }
                                autoComplete="off"
                                disabled={!!brokerDetail?.id}
                              />
                              {brokersDetailslnputError[index]?.firstName && (
                                <ValidationError
                                  errorMessage={
                                    brokersDetailslnputError[index]?.firstName
                                  }
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicLastName"
                            >
                              <Form.Label className="fw-bold">
                                Last Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="lastName"
                                value={brokerDetail?.lastName}
                                onChange={(e) =>
                                  handleOnChangeForBrokerDetails(index, e)
                                }
                                autoComplete="off"
                                disabled={!!brokerDetail?.id}
                              />
                              {brokersDetailslnputError[index]?.lastName && (
                                <ValidationError
                                  errorMessage={
                                    brokersDetailslnputError[index]?.lastName
                                  }
                                />
                              )}
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicActiveInactive"
                            >
                              <Form.Label className="fw-bold">
                                Status
                              </Form.Label>
                              <Form.Check
                                type="switch"
                                name="isActive"
                                id={`$broker{index}`}
                                label={
                                  brokerDetail?.isActive ? 'Active' : 'Inactive'
                                }
                                checked={brokerDetail?.isActive}
                                onChange={(e) => handleToggleSwitch(e, index)}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <div>
                              <p className="fw-bold">Action</p>
                              <TrashIcon
                                className="react-icon trash-icon"
                                onClick={() =>
                                  removeBrokerDetails(index, brokerDetail?.id)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      )
                    }
                  )}
              </>
            )}
          </div>

          <Row className="text-end">
            <Col className="my-4">
              <CustomClickableButton
                buttonContent="Submit"
                disable={false}
                handleOnClick={handleTradeContractorSubmitClick}
              />
            </Col>
          </Row>
        </Form>
      </div>
      {showDeleteModal && (
        <PopupModalForDelete
          show={showDeleteModal}
          brokerDetailForDeletion={selectedBrokerForDeletion}
          onHide={() => setShowDeleteModal(false)}
          setshowdeletemodal={setShowDeleteModal}
          modalTitle="Delete Broker"
          setIsDeletedSuccessfully={setIsDeletedSuccessfully}
        />
      )}
    </div>
  )
}

export default styled(TradeContractorForm)`
  .add-trade-contractor-contact-cursor {
    cursor: pointer;
  }
  .remove-policyholder-cursor {
    cursor: pointer;
  }
  .plus-icon {
    font-size: 20px;
    padding-right: 5px;
  }
  .template-input {
    background: #fff;
  }
  .copy-checkbox {
    margin-top: 5px;
    margin-left: 55px;
  }
`
