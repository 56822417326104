import React from 'react'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { useQuery, useMutation } from '@apollo/client'
import { FaRegBell } from 'react-icons/fa'
import styled from 'styled-components'

import {
  NOTIFICATIONS_LIST,
  UPDATE_NOTIFICATIONS,
} from '../graphql/graphql-queries'

export interface NavigationBarTwoProps extends React.ComponentProps<any> {
  className?: string
}

const NavigationBarTwo: React.FC<NavigationBarTwoProps> = ({ className }) => {
  const { data } = useQuery(NOTIFICATIONS_LIST)

  const [updateNotifications] = useMutation(UPDATE_NOTIFICATIONS)

  const markIsReadTrue = () => {
    updateNotifications()
    window.location.href = '/start-labelling'
  }

  return (
    <div className={className}>
      <Navbar bg="light" variant="light" className="d-flex">
        <Container>
          <Navbar.Brand className="text-primary fw-bold" href="/">
            <img
              src="/resolvedrisk_logo.png"
              width="45"
              height="30"
              className="d-inline-block align-top pe-1"
              alt="React Bootstrap logo"
            />
            {'    '}
            RESOLVED RISK
          </Navbar.Brand>

          <Navbar.Collapse className="justify-content-end scrollable">
            <Nav.Link href="#" className="fw-bold nav-item">
              Templates
            </Nav.Link>
            <Nav.Link href="#" className="fw-bold nav-item">
              Policyholders
            </Nav.Link>
            <Nav className="position-relative" id="notification-nav-dropdown">
              {!!data?.listUnreadNotifications?.length && (
                <span
                  id="new-notification-alert"
                  className="bg-danger border border-light rounded-circle"
                >
                  <span className="visually-hidden">New alerts</span>
                </span>
              )}
              <NavDropdown
                title={<FaRegBell size={20} />}
                className="justify-content-start scrollable"
                pre-scrollable="true"
              >
                <NavDropdown.Item
                  href="/start-labelling"
                  onClick={markIsReadTrue}
                >
                  A new Master Form needs your review.
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default styled(NavigationBarTwo)`
  .dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
    left: auto;
    right: 0;
  }

  .bg-danger {
    --bs-bg-opacity: 0.5;
  }
  #new-notification-alert {
    position: absolute;
    padding: 5px;
    margin-top: 5px;
    margin-left: -6px;
    left: 50%;
  }
  .navbar {
    padding: 0 !important;
  }
  .nav-item {
    color: #0b0c0c;
  }
  .nav-item:hover {
    color: #13948b;
    text-decoration: underline;
    text-underline-offset: 12px;
    text-decoration-thickness: 3px;
  }
`
