import { Container, Row, Col, Badge, Spinner, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { DocumentTextIcon } from '@heroicons/react/outline'
import SignDocumentsImage from '../assets/images/sign-documents.png'
import SignedDocumentsImage from '../assets/images/signed-documents.png'
import CustomClickableButton from './custom-components/custom-clickable-button'
import { appConfig } from '../core'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_SIGNED_CONTRACT_BY_TRADE_CONTRACT_ID } from '../graphql/graphql-queries'
import { convertTimezoneToESTFromUTC } from '../helper-functions'

export interface SignAddendumProps extends React.ComponentProps<any> {
  className?: string
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  tradeContractForCertifyInsurance: any
  hasTradeContractorSignedContractAndConfirmationAwaited: boolean
}

const SignAddendum: React.FC<SignAddendumProps> = ({
  className,
  setCurrentStep,
  currentStepIndex,
  setCurrentStepIndex,
  tradeContractForCertifyInsurance,
  hasTradeContractorSignedContractAndConfirmationAwaited,
}) => {
  const [signedContract, setSignedContract] = useState<any>(null)

  const [
    isSignDocumentsNextButtonDisabled,
    setIsSignDocumentsNextButtonDisabled,
  ] = useState(true)

  const [signDocumentProcessing, setSignDocumentProcessing] = useState(false)

  useQuery(GET_SIGNED_CONTRACT_BY_TRADE_CONTRACT_ID, {
    variables: { tradeContractId: tradeContractForCertifyInsurance.id },
    onCompleted: (data) => {
      setSignedContract(data.getSignedContractByTradeContractId)
    },
  })

  useEffect(() => {
    if (
      signedContract ||
      hasTradeContractorSignedContractAndConfirmationAwaited
    ) {
      setIsSignDocumentsNextButtonDisabled(false)
    }
  }, [signedContract]) // eslint-disable-line react-hooks/exhaustive-deps

  let additionalInsureds: any =
    tradeContractForCertifyInsurance?.job?.additionalInsureds
      ?.map((additionalInsured: any) => additionalInsured.name)
      .join(', ')

  let indemnitees: any =
    tradeContractForCertifyInsurance?.job?.indemnifiedParties
      ?.map((indemnitee: any) => indemnitee?.name)
      .join(', ')

  const handleSignDocumentClick = async () => {
    try {
      setSignDocumentProcessing(true)
      const result = await axios({
        method: 'POST',
        url: `${appConfig.apiUrl}/rest/v1/send-envelope`,
        data: {
          signerName: `${tradeContractForCertifyInsurance?.contractedCompanyUser?.firstName} ${tradeContractForCertifyInsurance?.contractedCompanyUser?.lastName}`,
          signerEmail:
            tradeContractForCertifyInsurance?.contractedCompanyUser?.email,
          signerId: tradeContractForCertifyInsurance.id,
          tradeContractCompanyName:
            tradeContractForCertifyInsurance?.contractedCompany?.name,
          firstNamedInsured:
            tradeContractForCertifyInsurance?.job?.nonProcessingPolicy?.company
              ?.name,
          additionalInsureds,
          indemnitees,
          jobLocation: tradeContractForCertifyInsurance?.job?.streetAddress,
          tradeContractId: tradeContractForCertifyInsurance?.id,
        },
        // headers: {
        //   'Content-Type': 'application/json',
        //   Authorization: `Bearer ${token}`,
        // },
      })
      if (result) {
        window.location.href = result.data.redirectUrl
      } else {
        setSignDocumentProcessing(false)
        console.error('Some error encountered')
      }
    } catch (err) {
      console.log('Error: ', err)
      setSignDocumentProcessing(false)
    }
  }

  const handleSignDocumentsNextClick = () => {
    setCurrentStep('Upload Policy')
    setCurrentStepIndex(currentStepIndex + 1)
  }

  const handleSignDocumentsBackClick = () => {
    // uncomment it when need for Accept/Sign Addendum
    // setCurrentStep('Accept Trade Contract')
    // setCurrentStepIndex(currentStepIndex - 1)
  }

  let signedDate = convertTimezoneToESTFromUTC(
    signedContract?.signedDate
  ).format('MM-DD-YYYY')
  let signedTime = convertTimezoneToESTFromUTC(
    signedContract?.signedDate
  ).format('hh:mm A')

  return (
    <Container className={`${className} text-start m-5`}>
      <Row>
        <h6 className="text-primary fw-bold">Job Details</h6>
        <h5 className="fw-bold">
          {tradeContractForCertifyInsurance?.job.streetAddress}
        </h5>
        <p className="text-secondary fw-bold">
          <span>
            {tradeContractForCertifyInsurance?.contractedCompany?.name}
          </span>
          <span className="p-2">|</span>
          <span>{`Job ${tradeContractForCertifyInsurance?.job.slug}`}</span>
          <span className="p-2">|</span>
          <span>{tradeContractForCertifyInsurance?.jobName}</span>
        </p>
        <hr className="ms-3 small text-secondary" />
      </Row>
      <div className="text-start mt-4">
        <p className="fs-4 fw-bold">Documents</p>
        <Row className="w-50 border documents-container d-flex align-items-center justify-content-center ms-0">
          <Col sm={1}>
            <DocumentTextIcon className="document-text-icon" />
          </Col>
          <Col sm={6}>
            <div className="fw-bold">Insurance and Indemnity Addendum</div>
            <div className="text-muted">
              {signedContract
                ? `Signed on ${signedDate} at ${signedTime}`
                : hasTradeContractorSignedContractAndConfirmationAwaited
                ? null
                : 'No signature yet'}
            </div>
          </Col>
          <Col sm={4}>
            {hasTradeContractorSignedContractAndConfirmationAwaited ? (
              <Badge
                pill
                bg="warning-dark"
                className="text-white badge-sign-document badge-non-clickable"
              >
                Waiting for the result
              </Badge>
            ) : !signedContract ? (
              <Badge
                pill
                bg="warning-dark"
                className="text-white badge-sign-document"
                onClick={handleSignDocumentClick}
              >
                {signDocumentProcessing ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="p-1">Processing...</span>
                  </>
                ) : (
                  'Sign Addendum'
                )}
              </Badge>
            ) : null}
          </Col>
        </Row>
      </div>
      <Row className="text-end me-4">
        <Col>
          {
            <img
              className="policy-verification-failed"
              src={signedContract ? SignedDocumentsImage : SignDocumentsImage}
              height={180}
              width={210}
              alt="Sign Document"
            />
          }
        </Col>
      </Row>
      <div className="footer">
        <div className="left-side">
          <Button
            className={`btn btn-primary-medium rounded-pill fw-bold back-button`}
            variant="outline-success"
            onClick={handleSignDocumentsBackClick}
          >
            Back
          </Button>
        </div>
        <div className="right-side">
          <CustomClickableButton
            buttonContent="Next"
            handleOnClick={handleSignDocumentsNextClick}
            // disable={isSignDocumentsNextButtonDisabled}
          />
        </div>
      </div>
    </Container>
  )
}

export default styled(SignAddendum)`
  .documents-container {
    border-radius: 12px;
    height: 80px;
  }
  .document-text-icon {
    color: #8b9192;
    height: 35px;
  }

  .badge-sign-document {
    padding: 10px 12px;
    font-size: 14px;
    cursor: pointer;
  }

  .badge-non-clickable {
    cursor: default;
  }

  .back-button {
    border-radius: 100px !important;
    color: black;
    background-color: #fff;
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100vw;
    border-top: 1px solid #e6e6e6;
    height: 60px;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  .left-side {
    margin-left: 40px;
  }
  .right-side {
    margin-right: 40px;
  }
`
