import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { ITemplate } from '../interface'

export interface IncDecCounterProps extends React.ComponentProps<any> {
  className?: string
  template: ITemplate
  // setTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
  duplicateTemplate: ITemplate
  setDuplicateTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
}

const IncDecCounter: React.FC<IncDecCounterProps> = ({
  className,
  template,
  setTemplate,
  duplicateTemplate,
  setDuplicateTemplate
}) => {
  let incNum = () => {
    if (duplicateTemplate.duration < 7) {
      setDuplicateTemplate({ ...duplicateTemplate, duration: Number(duplicateTemplate.duration + 1) })
    }
  }
  let decNum = () => {
    if (duplicateTemplate.duration > 1) {
      setDuplicateTemplate({ ...duplicateTemplate, duration: Number(duplicateTemplate.duration - 1) })
    }
  }
  let handleInputChange = (e: any) => {
    setDuplicateTemplate({ ...duplicateTemplate, duration: Number(e.target.value) })
  }

  return (
    <div className={className}>
      <Row className="ms-4 my-2">
        <Col sm={2} className="mt-2">
          <p>Duration</p>
        </Col>
        <Col sm={4} className="d-flex">
          <span
            className="d-flex justify-content-center align-items-center border duration-counter"
            onClick={decNum}
          >
            -
          </span>
          <span>
            <input
              type="text"
              className="form-control duration-counter"
              value={duplicateTemplate.duration}
              onChange={handleInputChange}
            />
          </span>
          <span
            className="d-flex justify-content-center align-items-center border duration-counter"
            onClick={incNum}
          >
            +
          </span>
        </Col>
        <Col sm={1} className="mt-2 ps-0 ms-0">
          years
        </Col>
      </Row>
    </div>
  )
}

export default styled(IncDecCounter)`
  .duration-counter {
    border-radius: 4px;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
`
