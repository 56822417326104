import { gql } from '@apollo/client'
const data_for_template = `{
  templates
  {
    id
    createdAt
    updatedAt
    templateName
    eachOccurrenceLimit
    aggregateLimit
    personalAdvertisingLimit
    projectCompletedOperationLimit
    deductible
    aggregateType
    scope
    durationType
    duration
    contractualLiability
    priorityOfCoverage
    noticeOfChange
    waiverOfSubrogation
    reviewerEmailAddress
    reviewerMailingAddress
    isPublished
    containsSpecialRequirements
    containsSpecialProhibitions
    templateLabels{
      templateId
      labellingPath
      isSpecialRequirement
      isSpecialProhibition
    }
  }
  count
}
`
const data_for_master_form = `{
      id
      createdAt
      updatedAt
      type
      formNumber
      formTitle
      scheduledText
      numberOfPages
      filePath
      formStartPageNumber
      formEndPageNumber
      isLabelled
      containsParserError
      containsUncapturedScheduledTexts
      isCompliant
      isInvalidInput
      labellingPaths {
        id
        masterFormId
        labellingPath
      }
    }`

const data_for_template_create_or_edit = `{
    id
    createdAt
    updatedAt
    templateName
    eachOccurrenceLimit
    aggregateLimit
    personalAdvertisingLimit
    projectCompletedOperationLimit
    deductible
    aggregateType
    scope
    durationType
    duration
    contractualLiability
    priorityOfCoverage
    noticeOfChange
    waiverOfSubrogation
    reviewerEmailAddress
    reviewerMailingAddress
    isPublished
    containsSpecialRequirements
    containsSpecialProhibitions
}
`

export const DOCUMENT_LIST = gql`
  query getDocuments {
    listDocuments {
      id
      createdAt
      updatedAt
      name
      totalPages
      parserId
      type
      policyId
      fileUpload {
        id
        path
        assignedName
        jobId
        tradeContractId
      }
    }
  }
`

export const DOCUMENT_BY_DOC_ID = gql`
  query getDocumentByDocId($docId: String!) {
    getDocumentByDocId(docId: $docId) {
      id
      createdAt
      updatedAt
      name
      totalPages
      parserId
      type
      policyId
      fileUpload {
        id
        documentId
        path
        originalName
        assignedName
        status
      }
    }
  }
`

export const POLICY_DETAILS = gql`
  query fetchPolicy($docId: String!) {
    getPolicy(docId: $docId) {
      id
      createdAt
      updatedAt
      claimNotificationAddress
      claimNotificationPersonName
      claimNotificationPersonDesignation
      claimNotificationPhone
      claimNotificationFax
      claimNotificationEmail
      insurerCompany
      insuredName
      policyPeriod
      policyNumber
      carrierName
      limitEachOccurance
      generalAggregateLimit
      deductible
      selfInsuredRetention
      minimumPremium
      projectCompletedOperationLimit
      personalAdvertisingLimit
      policyAggregateLimit
    }
  }
`

export const POLICY_DETAILS_BY_TRADE_CONTRACT_ID = gql`
  query fetchPolicyByTradeContractId($tradeContractId: String!) {
    getPolicyByTradeContractId(tradeContractId: $tradeContractId) {
      id
      documentId
      createdAt
      updatedAt
      claimNotificationAddress
      claimNotificationPersonName
      claimNotificationPersonDesignation
      claimNotificationPhone
      claimNotificationFax
      claimNotificationEmail
      insurerCompany
      insuredName
      policyPeriod
      policyNumber
      carrierName
      limitEachOccurance
      generalAggregateLimit
      projectCompletedOperationLimit
      personalAdvertisingLimit
      deductible
      selfInsuredRetention
      minimumPremium
      projectCompletedOperationLimit
      policyAggregateLimit
      policyPeriodStartDate
      policyPeriodEndDate
    }
  }
`
export const FORM_DETAILS = gql`
  query fetchForms($docId: String!) {
    listForms(docId: $docId) {
      id
      createdAt
      updatedAt
      documentId
      pageNumber
      endorsementNumber
      isDeleted
      deletedBy
      normalizedFormTitle
      type
      formNumber
      formTitle
      scheduledTexts {
        id
        createdAt
        updatedAt
        textName
        textValue
      }
    }
  }
`

export const LABELLING_LIST = gql`
  query getForms($data: PaginationInputData!) {
    labels(data: $data) {
      masterData {
        id
        createdAt
        updatedAt
        type
        formNumber
        formTitle
        scheduledText
        numberOfPages
        filePath
        formStartPageNumber
        formEndPageNumber
        isLabelled
        containsParserError
        containsUncapturedScheduledTexts
        isCompliant
        isInvalidInput
      }
      count
    }
  }
`
export const LABELLING_FORM = gql`
  mutation updateForms($id: String!, $formData: LabellingInput!) {
    labelForm(formData: $formData, id: $id) ${data_for_master_form}
  }
`

export const MASTER_FORM_DATA = gql`
  query getFormDataUsingFormId($formId: String!) {
    getFormDataUsingFormId(formId: $formId) ${data_for_master_form}
  }
`

export const NOTIFICATIONS_LIST = gql`
  query listUnreadNotifications {
    listUnreadNotifications {
      id
      createdAt
      updatedAt
      notificationTitle
      notificationText
      isRead
      notificationType
      fileUploadId
    }
  }
`

export const UPDATE_NOTIFICATIONS = gql`
  mutation updateNotifications($notificationType: String!, $id: String) {
    updateNotifications(notificationType: $notificationType, id: $id) {
      id
      createdAt
      updatedAt
      notificationTitle
      notificationText
      isRead
      notificationType
    }
  }
`

export const POLICYHOLDER_DETAILS = gql`
  query getPolicyholders {
    listPolicyholders {
      id
      createdAt
      updatedAt
      email
      avatarURL
      role
      enabled
      firstName
      lastName
      title
      fullName
      isSuperAdmin
      isPolicyHolder
      isTradeContractor
      isClaimsSpecialist
      company {
        id
        createdAt
        updatedAt
        name
        address
        ein
      }
      companyId
    }
  }
`

export const NON_PROCESSING_POLICIES_BY_COMPANY_ID = gql`
  query getNonProcessingPolicyServiceByCompanyId {
    listNonProcessingPoliciesByCompanyId {
      id
      createdAt
      updatedAt
      policyNumber
      isExpired
      startDate
      endDate
      companyId
      templateId
    }
  }
`

export const NON_PROCESSING_POLICIES_LIST = gql`
  query getNonProcessingPolicies {
    listNonProcessingPolicies {
      id
      createdAt
      updatedAt
      policyNumber
      isExpired
      startDate
      endDate
      companyId
      templateId
    }
  }
`

export const COMPANIES_LIST = gql`
  query getCompanies {
    listCompanies {
      id
      createdAt
      updatedAt
      name
      address
      ein
      users {
        id
        createdAt
        updatedAt
        email
        avatarURL
        role
        enabled
        firstName
        lastName
        title
        fullName
        isSuperAdmin
        isPolicyHolder
        isTradeContractor
        isClaimsSpecialist
      }
      nonProcessingPolicies {
        id
        createdAt
        updatedAt
        policyNumber
        isExpired
        startDate
        endDate
        companyId
        templateId
      }
    }
  }
`

export const TEMPLATES_LIST = gql`
  query getTemplatesList($data: PaginationTemplateData!) {
    listTemplates(data: $data) ${data_for_template}
  }
`

export const CLAIMS_SPECIALISTS_LIST = gql`
  query getClaimsSpecialists {
    listClaimsSpecialists {
      id
      createdAt
      updatedAt
      claimsSpecialistFirstName
      claimsSpecialistLasttName
      claimsSpecialistTitle
      claimsSpecialistEmailAddress
      claimsSpecialistPhoneNumber
    }
  }
`

export const CREATE_POLICYHOLDER = gql`
  mutation createPolicyholder($data: PolicyHolderInput!) {
    createPolicyHolder(data: $data) {
      id
      createdAt
      updatedAt
      email
      avatarURL
      role
      enabled
      firstName
      lastName
      title
      fullName
      isSuperAdmin
      isPolicyHolder
      isTradeContractor
      isClaimsSpecialist
    }
  }
`

export const CREATE_TEMPLATE = gql`
  mutation createTemplate($data: TemplateInput!) {
    createTemplate(data: $data) ${data_for_template_create_or_edit}
  }
`
export const UPDATE_TEMPLATE = gql`
  mutation updateIsPublished($id: String!) {
    updateIsPublished(id: $id) ${data_for_template_create_or_edit}
  }
`

export const EDIT_TEMPLATE = gql`
  mutation updateTemplate($data: TemplateInput!) {
    updateTemplate(data: $data) ${data_for_template_create_or_edit}
  }
`

export const CREATE_JOB = gql`
  mutation createJob($data: JobInput!) {
    createJob(data: $data) {
      id
      createdAt
      updatedAt
      userId
      startDate
      streetAddress
      nonProcessingPolicyId
    }
  }
`
export const UPDATE_JOB = gql`
  mutation updateJob($data: JobInput!) {
    updateJob(data: $data) {
      id
      createdAt
      updatedAt
      userId
      startDate
      streetAddress
      nonProcessingPolicyId
    }
  }
`
export const DELETE_COMPANY = gql`
  mutation deleteCompany($id: String!) {
    deleteCompany(id: $id)
  }
`
export const DELETE_CLIENT = gql`
  mutation deleteClient($id: String!) {
    deleteClient(id: $id)
  }
`
export const NON_PROCESSING_POLICIES_LIST_FOR_DROPDOWN = gql`
  query listNonProcessingPoliciesForDropdown($user: UserInput!) {
    listNonProcessingPoliciesForDropdown(user: $user) {
      id
      policyNumber
      companyId
      template {
        id
        templateName
      }
      company {
        id
        createdAt
        updatedAt
        name
        user {
          id
          firstName
          email
        }
      }
    }
  }
`

export const LISTING_TEMPLATES = gql`
  query getTemplatesListing {
    listTemplates ${data_for_template}
  }
`

export const PROHIBITED_ENDORSEMENTS = gql`
  query getProhibitedEndorsements($templateId: String!) {
    listProhibitedEndorsements(templateId: $templateId) {
      masterForm {
        id
        createdAt
        updatedAt
        type
        formNumber
        formTitle
        scheduledText
        numberOfPages
        filePath
        formStartPageNumber
        formEndPageNumber
        isLabelled
        containsParserError
        containsUncapturedScheduledTexts
        isCompliant
        isInvalidInput
      }
    }
  }
`

export const GET_JOB = gql`
  query getJob {
    getJob {
      id
      userId
      startDate
      streetAddress
      nonProcessingPolicyId
    }
  }
`
export const GET_CLIENTS = gql`
  query getClients($data: PaginationClientInputData!) {
    getClients(data: $data) {
      clientData {
        name
        firstName
        lastName
        id
        email
        phoneNumber
        firstNameInsuredCount
        numberOfPolicies
        numberOfActivePolicies
      }
      count
    }
  }
`

export const GET_CLIENT_DETAIL = gql`
  query getUserDetail($id: String!) {
    getUserDetail(id: $id) {
      id
      email
      fullName
      phoneNumber
    }
  }
`
export const GET_CLIENT_COMPANIES = gql`
  query listCompanies(
    $clientId: String!
    $data: PaginationClientCompanyInputData!
  ) {
    listCompanies(clientId: $clientId, data: $data) {
      companyData {
        name
        id
        numberOfPolicies
        numberOfActivePolicies
        numberOfNonActivePolicies
      }
      count
    }
  }
`
export const CREATE_CLIENT_COMPANY = gql`
  mutation createCompany($data: CompanyInput!, $userId: String!) {
    createCompany(data: $data, userId: $userId) {
      name
    }
  }
`
export const MAKE_INACTIVE_POLICY = gql`
  mutation makeNonProcessingPolicyInactive($id: String!) {
    makeNonProcessingPolicyInactive(id: $id) {
      id
    }
  }
`
export const CREATE_OR_UPDATE_JOB_TYPE = gql`
  mutation createOrUpdateJobType($data: JobTypeInput!) {
    createOrUpdateJobType(data: $data) {
      id
      createdAt
      updatedAt
      jobCode
      name
    }
  }
`
export const GET_JOB_TYPES_SELECTED_ENDORSEMENTS = gql`
  query listJobTypeEndorsment($jobTypeId: String!) {
    listJobTypeEndorsment(jobTypeId: $jobTypeId) {
      id
      jobTypeId
      masterFormId
    }
  }
`
export const CREATE_CLIENT = gql`
  mutation createClient($data: ClientInput!) {
    createClient(data: $data) {
      id
      email
      enabled
      firstName
      lastName
    }
  }
`
export const CREATE_POLICY = gql`
  mutation createPolicy($data: NonProcessingPolicyInput!) {
    createPolicy(data: $data) {
      id
      createdAt
      updatedAt
      policyNumber
      isExpired
      startDate
      endDate
      companyId
      templateId
      claimsSpecialistId
    }
  }
`
export const UPDATE_NPP = gql`
  mutation updateNonProcessingPolicy($data: NonProcessingPolicyInput!) {
    updateNonProcessingPolicy(data: $data) {
      id
    }
  }
`
export const GET_NON_PROCESSING_POLICIES = gql`
  query listNonProcessingPolicies(
    $firstNameInsuredId: String!
    $data: PaginationNonProcessingPolicyInput!
  ) {
    listNonProcessingPolicies(
      firstNameInsuredId: $firstNameInsuredId
      data: $data
    ) {
      policyOutput {
        policyNumber
        id
        policyStatus
        templateName
        templateId
        claimsSpecialistId
        startDate
        endDate
        numberOfJobs
        totalClaims
        tradeContracts {
          id
          nonProcessingPolicyId
          contractAcceptanceDateTime
          contractedCompanyId
          contractedCompanyUserId
          contractedCompany {
            id
            name
            ein
            claims {
              id
            }
          }
          contractedCompanyUser {
            id
            firstName
            lastName
          }
          jobId
          job {
            id
            slug
            streetAddress
            nonProcessingPolicy {
              template {
                id
                templateName
              }
            }
            claims {
              id
            }
          }
          fileUploads {
            id
            createdAt
            status
            originalName
            documentId
          }
          policies {
            id
            createdAt
            documentId
            matchingStatus
            matchingResponse
            certificationStatus
            policyNumber
            tradeContractId
            insuredName
          }
          jobName
        }
      }
      count
    }
  }
`
export const GET_TRADE_CONTRACTS_BY_NON_PROCESSING_POLICY_ID = gql`
  query listTradeContractsByNonProcessingPolicyId(
    $data: PaginationDataForTradesByNonProcessingId!
  ) {
    listTradeContractsByNonProcessingPolicyId(data: $data) {
      TradeContracts {
        id
        contractAcceptanceDateTime
        contractedCompanyId
        contractedCompanyUserId
        contractedCompanyUser {
          id
          email
          firstName
          lastName
          title
        }
        contractedCompany {
          id
          name
          ein
          address
          claims {
            id
          }
        }
        jobId
        job {
          id
          slug
          streetAddress
          nonProcessingPolicy {
            template {
              id
              templateName
              aggregateLimit
              aggregateType
              contractualLiability
              deductible
              duration
              durationType
              eachOccurrenceLimit
              noticeOfChange
              personalAdvertisingLimit
              priorityOfCoverage
              waiverOfSubrogation
              projectCompletedOperationLimit
              scope
            }
          }
        }
        fileUploads {
          id
          createdAt
          status
          originalName
          documentId
        }
        policies {
          id
          createdAt
          documentId
          matchingStatus
          matchingResponse
          certificationStatus
          policyNumber
          tradeContractId
          insuredName
          deductible
          generalAggregateLimit
          limitEachOccurance
          minimumPremium
          policyAggregateLimit
          personalAdvertisingLimit
          projectCompletedOperationLimit
          selfInsuredRetention
        }
        jobName
      }
      count
    }
  }
`
export const GET_NON_PROCESSING_POLICY_DETAILS = gql`
  query listDetailsOfNonProcessingPolicies($policyId: String!) {
    listDetailsOfNonProcessingPolicies(policyId: $policyId) {
      policyNumber
      id
      templateName
      numberOfJobs
      totalClaims
      eachOccurrenceLimit
      aggregateLimit
      durationType
      duration
      scope
      contractualLiability
      template {
        id
        updatedAt
        templateName
        eachOccurrenceLimit
        aggregateLimit
        aggregateType
        scope
        durationType
        duration
        contractualLiability
        priorityOfCoverage
        noticeOfChange
        waiverOfSubrogation
        reviewerEmailAddress
        reviewerMailingAddress
        personalAdvertisingLimit
        projectCompletedOperationLimit
        deductible
        templateLabels {
          id
          templateId
          labellingPath
          isSpecialRequirement
          isSpecialProhibition
        }
      }
      tradeContracts {
        id
        contractAcceptanceDateTime
        contractedCompanyId
        contractedCompanyUserId
        contractedCompany {
          id
          name
          ein
          claims {
            id
          }
        }
        jobId
        fileUploads {
          id
          createdAt
          status
          originalName
          documentId
        }
        policies {
          id
          createdAt
          documentId
          matchingStatus
          matchingResponse
          certificationStatus
          policyNumber
          tradeContractId
          insuredName
        }
        jobName
      }
    }
  }
`

export const UPDATE_CLIENT = gql`
  mutation updateClient($data: ClientInput!, $id: String!) {
    updateClient(data: $data, id: $id) {
      id
      email
      enabled
      firstName
      lastName
    }
  }
`
export const UPDATE_FIRST_NAMED_INSURED = gql`
  mutation updateFirstNamedInsured($data: CompanyInput!, $id: String!) {
    updateFirstNamedInsured(data: $data, id: $id) {
      id
      name
    }
  }
`
export const GET_FILE_UPLOAD_BY_JOB_ID = gql`
  query getFileUploadByJobId($jobId: String!) {
    getFileUploadByJobId(jobId: $jobId) {
      id
      originalName
      assignedName
      status
      matchingStatus
      matchingResponse
    }
  }
`
export const GET_LABELLING_PATH_FOR_PROHIBITED_ENDORSMENT_OF_TRADECONTRACTOR_JOB = gql`
  mutation getLabellingPathsForProhibitedEndorsmentsOfTradeContractorJob(
    $id: String!
  ) {
    getLabellingPathsForProhibitedEndorsmentsOfTradeContractorJob(id: $id) {
      id
      labellingPath
      masterFormId
    }
  }
`
export const LIST_JOBS_BY_USER_ID_AND_ROLE = gql`
  query getJobsByUserIdAndRole($user: UserInput!) {
    getJobsByUserIdAndRole(user: $user) {
      jobByUserIdData {
        id
        startDate
        streetAddress
        slug
        additionalInsureds {
          id
          name
          email
          createdAt
        }
        indemnifiedParties {
          id
          name
          email
        }
        nonProcessingPolicy {
          id
          company {
            name
          }
          template {
            id
            templateName
          }
        }
        claims {
          id
        }
        tradeContracts {
          id
        }
      }
      count
    }
  }
`

export const JOB_TYPES_LIST_FOR_DROPDOWN = gql`
  query listJobTypesDropdown {
    listJobTypesDropdown {
      id
      jobCode
      name
    }
  }
`
export const GET_ATTACHMENT_DATA = gql`
  query getAttachments($claimId: String!) {
    getAttachments(claimId: $claimId) {
      id
      createdAt
      updatedAt
      originalName
      assignedName
      path
      claimNoticeId
    }
  }
`
export const CREATE_TRADE = gql`
  mutation createTrade($data: TradeCreateInput!) {
    createTrade(data: $data) {
      id
      createdAt
      updatedAt
    }
  }
`

export const CREATE_NOTICE_FOR_CLAIM = gql`
  mutation createClaimNotice($data: ClaimNoticeInput!) {
    createClaimNotice(data: $data) {
      id
      createdAt
      updatedAt
      claimNoticeFiledByUserId
      dateOfIncident
      jobId
      address
      descriptionOfIncident
      injuredPartyEmployerId
    }
  }
`
export const GET_INVITATION_BY_TOKEN = gql`
  query getInvitationByToken($token: String!) {
    getInvitationByToken(token: $token) {
      id
      createdAt
      updatedAt
      sentDate
      token
      invitationType
      sesUniqueId
      expired
      userId
      user {
        id
        createdAt
        updatedAt
        email
        password
        avatarURL
        role
        enabled
        firstName
        lastName
        phoneNumber
        title
        fullName
        isSuperAdmin
        isPolicyHolder
        isTradeContractor
        isClaimsSpecialist
        companies {
          id
          name
        }
      }
      emailSenderId
      emailSender {
        id
        createdAt
        updatedAt
        email
        avatarURL
        role
        enabled
        firstName
        lastName
        phoneNumber
        title
        fullName
        isSuperAdmin
        isPolicyHolder
        isTradeContractor
        isClaimsSpecialist
      }
    }
  }
`

export const SIGN_UP_USER = gql`
  mutation signUpUser($data: SignUpInput!) {
    signUpUser(data: $data) {
      id
      email
      avatarURL
      role
      enabled
      firstName
      lastName
      phoneNumber
      title
      fullName
      isSuperAdmin
      isPolicyHolder
      isTradeContractor
      isClaimsSpecialist
    }
  }
`
export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($data: RecoverPasswordEmailInput!) {
    recoverPassword(data: $data) {
      id
      createdAt
      updatedAt
      email
      avatarURL
      role
      enabled
      firstName
      lastName
      phoneNumber
      title
      password
      fullName
      isSuperAdmin
      isPolicyHolder
      isTradeContractor
      isClaimsSpecialist
    }
  }
`

export const GET_TRADE_CONTRACTS_BY_TRADE_CONTRACTOR_ID = gql`
  query listTradeContractsByTradeContractorId {
    listTradeContractsByTradeContractorId {
      id
      contractAcceptanceDateTime
      contractFilePath
      contractedCompanyId
      contractedCompanyUserId
      contractedCompanyUser {
        id
        email
        firstName
        lastName
      }
      contractedCompany {
        id
        name
        claims {
          id
        }
      }
      jobId
      fileUploads {
        id
        createdAt
        status
        originalName
        documentId
      }
      policies {
        id
        createdAt
        documentId
        matchingStatus
        matchingResponse
        certificationStatus
        policyNumber
        tradeContractId
        insuredName
      }
      job {
        id
        userId
        startDate
        streetAddress
        slug
        additionalInsureds {
          id
          name
        }
        nonProcessingPolicy {
          id
          policyNumber
          company {
            name
          }
          template {
            templateName
            eachOccurrenceLimit
            aggregateLimit
            scope
            durationType
            duration
            contractualLiability
            priorityOfCoverage
            noticeOfChange
            waiverOfSubrogation
          }
        }
      }
      jobName
      jobCode
    }
  }
`

export const GET_JOB_TYPE = gql`
  query listJobTypes($data: paginationJobData!) {
    listJobTypes(data: $data) {
      jobTypesData {
        jobCode
        name
        id
        prohibitedEndorsementCount
      }
      count
    }
  }
`
export const GET_ENDORSMENT = gql`
  query getEndorsement($jobTypeId: String!) {
    getEndorsments(jobTypeId: $jobTypeId) {
      id
      formTitle
      formNumber
      isCompliant
      isChecked
    }
  }
`

export const UPDATE_TRADE_CONTRACTOR = gql`
  mutation updateTradeContractor($data: TradeContractorUpdateInput!) {
    updateTradeContractor(data: $data) {
      id
    }
  }
`

export const LIST_FILE_UPLOADS_BY_TRADE_CONTRACT_ID = gql`
  query listFileUploadsByTradeContractId($tradeContractId: String!) {
    listFileUploadsByTradeContractId(tradeContractId: $tradeContractId) {
      id
      status
      matchingStatus
      matchingResponse
      jobId
      tradeContractId
    }
  }
`

export const JOBS_AND_FILE_UPLOADS_QUERIES = gql`
  query getJobsByUserIdAndRole($user: UserInput!) {
    getJobsByUserIdAndRole(user: $user) {
      id
      userId
      startDate
      streetAddress
      nonProcessingPolicy {
        id
        policyNumber
        company {
          name
        }
        template {
          templateName
          eachOccurrenceLimit
          aggregateLimit
          scope
          durationType
          duration
          contractualLiability
          priorityOfCoverage
          noticeOfChange
          reviewerEmailAddress
          reviewerMailingAddress
          waiverOfSubrogation
        }
      }
      additionalInsureds {
        id
        name
      }
      indemnifiedParties {
        id
        name
      }
      user {
        id
        role
        fullName
      }
      tradeContracts {
        id
        contractedCompanyId
        contractedCompanyUserId
        jobId
        jobName
        jobCode
        certifiedToStartDate
        contractedCompany {
          id
          name
          ein
        }
        contractedCompanyUser {
          id
          firstName
          lastName
        }
        certifiedActivePolicyId
        policies {
          id
        }
        fileUploads {
          id
          tradeContractId
        }
      }
    }
  }
`

export const GET_INVITATION_BY_TRADE_CONTRACTOR_ID = gql`
  query getInvitationByTradeContractorId {
    getInvitationByTradeContractorId {
      id
      sentDate
      token
      invitationType
      sesUniqueId
      expired
      user {
        id
        email
        role
        enabled
        firstName
        lastName
        phoneNumber
        title
        fullName
        isClaimsSpecialist
        companies {
          id
          name
          alternateEmail1
          alternateEmail2
          alternateEmail3
          alternateEmail4
        }
      }
    }
  }
`

export const GET_TRADE_CONTRACTOR_BY_EMAIL = gql`
  query getTradeContractorByEmail($email: String!) {
    getTradeContractorByEmail(email: $email) {
      id
      email
      fullName
      companies {
        id
        name
        address
        ein
      }
    }
  }
`

export const GET_USER_BY_EMAIL_AND_ROLE = gql`
  query getUserByEmailAndRole($email: String!, $role: String!) {
    getUserByEmailAndRole(email: $email, role: $role) {
      id
      email
      firstName
      lastName
    }
  }
`

export const GET_SIGNED_CONTRACT_BY_TRADE_CONTRACT_ID = gql`
  query getSignedContractByTradeContractId($tradeContractId: String!) {
    getSignedContractByTradeContractId(tradeContractId: $tradeContractId) {
      id
      tradeContractId
      envelopeId
      documentId
      signedDate
      signerEmail
      signerUserId
      signatureName
    }
  }
`

export const UPDATE_CONTRACT_ACCEPTANCE_DATE_TIME_IN_TRADE_CONTRACT = gql`
  mutation setContractAcceptanceDateTimeInTradeContract(
    $tradeContractId: String!
  ) {
    setContractAcceptanceDateTimeInTradeContract(
      tradeContractId: $tradeContractId
    ) {
      id
      contractAcceptanceDateTime
    }
  }
`

export const GET_TRADE_CONTRACT_BY_TRADE_CONTRACT_ID = gql`
  query getTradeContractByTradeContractId($tradeContractId: String!) {
    getTradeContractByTradeContractId(tradeContractId: $tradeContractId) {
      id
      contractAcceptanceDateTime
      contractFilePath
      contractedCompanyId
      contractedCompanyUserId
      contractedCompanyUser {
        id
        email
        firstName
        lastName
      }
      contractedCompany {
        id
        name
      }
      jobId
      fileUploads {
        id
        createdAt
        status
        originalName
        documentId
      }
      policies {
        id
        createdAt
        documentId
        matchingStatus
        matchingResponse
        certificationStatus
        policyNumber
        tradeContractId
        insuredName
      }
      job {
        id
        userId
        startDate
        streetAddress
        slug
        additionalInsureds {
          id
          name
        }
        indemnifiedParties {
          id
          name
        }
        nonProcessingPolicy {
          id
          policyNumber
          company {
            id
            name
          }
          template {
            templateName
            eachOccurrenceLimit
            aggregateLimit
            aggregateType
            scope
            durationType
            duration
            contractualLiability
            priorityOfCoverage
            waiverOfSubrogation
            noticeOfChange
            reviewerEmailAddress
            reviewerMailingAddress
            personalAdvertisingLimit
            projectCompletedOperationLimit
            deductible
            templateLabels {
              id
              templateId
              labellingPath
              isSpecialRequirement
              isSpecialProhibition
            }
          }
        }
      }
      jobName
      jobCode
      signingStatus
      documentViewedOrSigningDate
      prohibitedEndorsementsByJobType {
        id
        labellingPath
      }
    }
  }
`

export const GET_JOB_BY_ID = gql`
  query getJobById($jobId: String!) {
    getJobById(jobId: $jobId) {
      id
      userId
      startDate
      streetAddress
      slug
      nonProcessingPolicy {
        id
        policyNumber
        company {
          name
        }
        template {
          templateName
          eachOccurrenceLimit
          aggregateLimit
          scope
          durationType
          duration
          contractualLiability
          priorityOfCoverage
          waiverOfSubrogation
          noticeOfChange
          reviewerEmailAddress
          reviewerMailingAddress
        }
      }
      additionalInsureds {
        id
        name
      }
      indemnifiedParties {
        id
        name
      }
      user {
        id
        role
        fullName
      }
      tradeContracts {
        id
        contractedCompanyId
        contractedCompanyUserId
        jobId
        jobName
        jobCode
        certifiedToStartDate
        contractedCompany {
          id
          name
          ein
        }
        contractedCompanyUser {
          id
          firstName
          lastName
        }
        fileUploads {
          id
          createdAt
          status
          originalName
          documentId
        }
        policies {
          id
          createdAt
          documentId
          matchingStatus
          matchingResponse
          certificationStatus
          policyNumber
          tradeContractId
          insuredName
        }
      }
    }
  }
`

export const GET_USER_BY_ID = gql`
  query getUserById($userId: String!) {
    getUserById(userId: $userId) {
      id
      email
      avatarURL
      role
      enabled
      firstName
      lastName
      phoneNumber
      title
      password
      companies {
        id
        createdAt
        updatedAt
        name
        address
        ein
      }
    }
  }
`

// export const LIST_TRADE_CONTRACTOR_COMPANY_BROKERS =  gql`
// query listTradeContractorCompanyBrokers($tradeContractorCompanyId: String!) {
//   listTradeContractorCompanyBrokers(tradeContractorCompanyId: $tradeContractorCompanyId) {
//     id
//     tradeContractorCompanyId
//     brokerId
//     isActive
//     broker {
//       id
//       firstName
//       lastName
//       email
//     }
//   }
// }
// `

export const LIST_TRADE_CONTRACTOR_BROKERS = gql`
  query listTradeContractorBrokers($tradeContractorId: String!) {
    listTradeContractorBrokers(tradeContractorId: $tradeContractorId) {
      id
      tradeContractorId
      tradeContractorCompanyId
      brokerId
      isActive
      tradeContractor {
        id
        firstName
        lastName
        email
      }
      broker {
        id
        firstName
        lastName
        email
      }
    }
  }
`

export const UPDATE_USER_DETAILS = gql`
  mutation updateUserDetails($userId: String!, $data: UpdateUserInput!) {
    updateUserDetails(userId: $userId, data: $data) {
      id
      email
      avatarURL
      role
      enabled
      firstName
      lastName
      phoneNumber
      title
      password
      companies {
        id
        createdAt
        updatedAt
        name
        address
        ein
      }
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword(
    $userId: String!
    $data: UpdateUserPasswordInput!
  ) {
    updateUserPassword(userId: $userId, data: $data) {
      id
      password
    }
  }
`

export const UPDATE_COMPANY_DETAILS = gql`
  mutation updateCompanyDetails(
    $companyId: String!
    $data: UpdateCompanyInput!
  ) {
    updateCompanyDetails(companyId: $companyId, data: $data) {
      id
      name
      address
      ein
      userId
    }
  }
`

export const CREATE_BROKER = gql`
  mutation createBroker($data: TradeContractorBrokerInput!) {
    createBroker(data: $data) {
      id
      email
      firstName
      lastName
    }
  }
`

export const UPDATE_BROKER = gql`
  mutation updateBroker($data: TradeContractorBrokerInput!) {
    updateBroker(data: $data) {
      id
      email
      firstName
      lastName
    }
  }
`

export const GET_LATEST_SIGNED_TRADE_CONTRACT = gql`
  query getLatestSignedTradeContract {
    getLatestSignedTradeContract {
      id
      contractAcceptanceDateTime
      contractFilePath
      contractedCompanyId
      contractedCompanyUserId
      contractedCompanyUser {
        id
        email
        firstName
        lastName
      }
      contractedCompany {
        id
        name
      }
      jobId
      fileUploads {
        id
        createdAt
        status
        originalName
        documentId
      }
      policies {
        id
        createdAt
        documentId
        matchingStatus
        matchingResponse
        certificationStatus
        policyNumber
        tradeContractId
        insuredName
      }
      job {
        id
        userId
        startDate
        streetAddress
        slug
        additionalInsureds {
          id
          name
        }
        nonProcessingPolicy {
          id
          policyNumber
          template {
            templateName
            eachOccurrenceLimit
            aggregateLimit
            scope
            durationType
            duration
            contractualLiability
            priorityOfCoverage
            waiverOfSubrogation
            noticeOfChange
            reviewerEmailAddress
            reviewerMailingAddress
          }
          company {
            id
            name
          }
        }
      }
      jobName
      jobCode
      signingStatus
      documentViewedOrSigningDate
    }
  }
`

export const GET_TRADE_CONTRACTS_BY_JOB_ID = gql`
  query listTradeContractsByJobId($data: PaginationDataForTradeContracts!) {
    listTradeContractsByJobId(data: $data) {
      TradeContracts {
        id
        contractAcceptanceDateTime
        contractedCompanyId
        contractedCompanyUserId
        contractedCompanyUser {
          id
          email
          firstName
          lastName
          title
        }
        contractedCompany {
          id
          name
          ein
          address
          alternateEmail1
          alternateEmail2
          alternateEmail3
          alternateEmail4
          claims {
            id
          }
        }
        jobId
        job {
          id
          slug
          startDate
          streetAddress
          additionalInsureds {
            id
            name
          }
          nonProcessingPolicy {
            template {
              id
              templateName
              eachOccurrenceLimit
              aggregateLimit
              aggregateType
              scope
              durationType
              duration
              contractualLiability
              priorityOfCoverage
              noticeOfChange
              waiverOfSubrogation
              personalAdvertisingLimit
              projectCompletedOperationLimit
              deductible
              templateLabels {
                id
                templateId
                labellingPath
                isSpecialRequirement
                isSpecialProhibition
              }
            }
          }
        }
        fileUploads {
          id
          createdAt
          status
          originalName
          documentId
          path
        }
        policies {
          id
          createdAt
          documentId
          matchingStatus
          matchingResponse
          certificationStatus
          policyNumber
          tradeContractId
          insuredName
          limitEachOccurance
          projectCompletedOperationLimit
          generalAggregateLimit
          personalAdvertisingLimit
          deductible
          selfInsuredRetention
          minimumPremium
          policyAggregateLimit
        }
        jobName
        prohibitedEndorsementsByJobType {
          id
          labellingPath
        }
      }
      count
    }
  }
`

export const GET_TRADES_CONTRACTS_LIST_TO_GET_JOBS_LIST_FOR_DROPDOWN = gql`
  query listTradeContractsWithJobsByTradeContractorId {
    listTradeContractsWithJobsByTradeContractorId {
      id
      job {
        id
        streetAddress
      }
    }
  }
`
export const GET_COMPANY_FEATURE_FLAGS = gql`
  query getCompanyFeatureFlags($companyId: String!) {
    getCompanyFeatureFlags(companyId: $companyId) {
      id
      companyId
      isDocusignEnabled
    }
  }
`
export const LIST_TRADE_CONTRACTS_FOR_SELECTED_JOB_FOR_A_TRADE_CONTRACTOR = gql`
  query listTradeContractsByTradeContractorIdAndJobId(
    $data: PaginationDataForTradeContracts!
  ) {
    listTradeContractsByTradeContractorIdAndJobId(data: $data) {
      TradeContracts {
        id
        contractAcceptanceDateTime
        contractFilePath
        contractedCompanyId
        contractedCompanyUserId
        contractedCompanyUser {
          id
          email
          firstName
          lastName
        }
        contractedCompany {
          id
          name
          claims {
            id
          }
        }
        jobId
        fileUploads {
          id
          createdAt
          status
          originalName
          documentId
        }
        policies {
          id
          createdAt
          documentId
          matchingStatus
          matchingResponse
          certificationStatus
          policyNumber
          tradeContractId
          insuredName
          limitEachOccurance
          projectCompletedOperationLimit
          generalAggregateLimit
          personalAdvertisingLimit
          deductible
          selfInsuredRetention
          minimumPremium
          policyAggregateLimit
        }
        job {
          id
          userId
          startDate
          streetAddress
          slug
          additionalInsureds {
            id
            name
          }
          nonProcessingPolicy {
            id
            policyNumber
            company {
              id
              name
            }
            template {
              templateName
              eachOccurrenceLimit
              aggregateLimit
              aggregateType
              scope
              durationType
              duration
              contractualLiability
              priorityOfCoverage
              waiverOfSubrogation
              noticeOfChange
              personalAdvertisingLimit
              projectCompletedOperationLimit
              deductible
              templateLabels {
                id
                templateId
                labellingPath
                isSpecialRequirement
                isSpecialProhibition
              }
            }
          }
        }
        jobName
        jobCode
        prohibitedEndorsementsByJobType {
          id
          labellingPath
        }
      }
      count
    }
  }
`
export const CREATE_POLICY_FROM_JSON = gql`
  mutation create($data: PolicyCreateInput!) {
    create(data: $data) {
      id
    }
  }
`

export const UPDATE_POLICY_FROM_JSON = gql`
  mutation updatePolicy($data: PolicyCreateInput!) {
    updatePolicy(data: $data) {
      id
    }
  }
`

export const GET_FILE_UPLOAD_BY_ID = gql`
  query getFileUploadById($id: String!) {
    getFileUploadById(id: $id) {
      id
      originalName
      assignedName
      path
      status
      jobId
      tradeContractId
    }
  }
`

export const LIST_FILE_UPLOADS_BY_STATUS_COMPLETED_OR_FAILED = gql`
  query listFileUploadsByStatusCompleteOrFailed(
    $data: PaginationDocumentsData!
  ) {
    listFileUploadsByStatusCompleteOrFailed(data: $data) {
      fileUploads {
        id
        path
        originalName
        assignedName
        status
        documentId
        jobId
        tradeContractId
        documentId
        createdAt
      }
      count
    }
  }
`
export const GET_LIST_OF_CLAIMS = gql`
  mutation getListOfClaimsFiled($data: PaginationDataForClientAndSuperAdmin!) {
    getListOfClaimsFiled(data: $data) {
      claimNoticeForClientAdmin {
        claimNoticeFiledByUser
        injuredPartyEmployer
        createdAt
        id
        jobId
        contactName
        jobName
        attachments {
          id
          createdAt
          updatedAt
          originalName
          assignedName
          path
          claimNoticeId
        }
      }
      count
    }
  }
`

export const GET_LIST_OF_CLAIMS_FOR_TRADE_CONTRACTOR = gql`
  mutation getListOfClaimsForTradeContractor(
    $data: PaginationDataForTradeContractor!
  ) {
    getListOfClaimsForTradeContractor(data: $data) {
      claimNoticeForTradeContractors {
        claimNoticeFiledByUser
        injuredPartyEmployer
        createdAt
        id
        jobId
        contactName
        jobName
        attachments {
          id
          createdAt
          updatedAt
          originalName
          assignedName
          path
          claimNoticeId
        }
      }
      count
    }
  }
`

export const GET_LIST_OF_CLAIMS_BY_NONPROCESSINGID = gql`
  mutation getListOfClaimsByNonprocessingId(
    $data: PaginationDataForNonprocessingId!
  ) {
    getListOfClaimsByNonprocessingId(data: $data) {
      claimNoticeForNonProcessingPolicy {
        claimNoticeFiledByUser
        injuredPartyEmployer
        createdAt
        id
        jobId
        contactName
        jobName
        attachments {
          id
          createdAt
          updatedAt
          originalName
          assignedName
          path
          claimNoticeId
        }
      }
      count
    }
  }
`

export const GET_ENDORSEMENTS = gql`
  query getEndorsements {
    getEndorsements {
      id
      type
      formTitle
      formNumber
      isChecked
    }
  }
`

export const RECERTIFY_POLICY = gql`
  mutation reCertifyPolicy($policyId: String!) {
    reCertifyPolicy(policyId: $policyId) {
      id
    }
  }
`

export const LIST_MASTER_FORM_LABELLING_PATHS_BY_MASTER_FORM_ID = gql`
  query listMasterFormLabellingPathsByMasterFormId($masterFormId: String!) {
    listMasterFormLabellingPathsByMasterFormId(masterFormId: $masterFormId) {
      id
      masterFormId
      labellingPath
    }
  }
`

export const CREATE_OR_UPDATE_JOB_TYPE_PROHIBITIONS_LABELS = gql`
  mutation createOrUpdateJobTypeProhibitionsLabels(
    $data: JobTypeProhibitionLabelInput!
  ) {
    createOrUpdateJobTypeProhibitionsLabels(data: $data) {
      id
    }
  }
`

export const LIST_JOB_TYPE_PROHIBITIONS_LABELS = gql`
  query listJobTypeProhibitionsLabels($jobTypeId: String!) {
    listJobTypeProhibitionsLabels(jobTypeId: $jobTypeId) {
      id
      jobTypeId
      labellingPath
    }
  }
`

export const DELETE_MASTER_FORM = gql`
  mutation deleteMasterForm($id: String!) {
    deleteMasterForm(id: $id)
  }
`

export const DELETE_TRADE_CONTRACT_BROKER_RELATIONSHIP = gql`
  mutation deleteTradeContractBrokerRelationship(
    $tradeContractorId: String!
    $brokerId: String!
  ) {
    deleteTradeContractBrokerRelationship(
      tradeContractorId: $tradeContractorId
      brokerId: $brokerId
    )
  }
`
export const DELETE_ALL_BROKER_FOR_A_TRADE_CONTRACTOR = gql`
  mutation deleteAllBrokerForATradeContractor($tradeContractorId: String!) {
    deleteAllBrokerForATradeContractor(tradeContractorId: $tradeContractorId)
  }
`

export const GET_JOBS_LIST_FOR_DROPDOWN_FOR_BROKER = gql`
  query listJobsByBrokerId($brokerId: String!) {
    listJobsByBrokerId(brokerId: $brokerId) {
      id
      streetAddress
    }
  }
`

export const LIST_TRADE_CONTRACTS_ADDED_AGAINST_A_JOB_FOR_SPECIFIED_BROKER = gql`
  query listTradeContractsForABroker($data: PaginationDataForTradeContracts!) {
    listTradeContractsForABroker(data: $data) {
      TradeContracts {
        id
        contractAcceptanceDateTime
        contractFilePath
        contractedCompanyId
        contractedCompanyUserId
        contractedCompanyUser {
          id
          email
          firstName
          lastName
        }
        contractedCompany {
          id
          name
          claims {
            id
          }
        }
        jobId
        fileUploads {
          id
          createdAt
          status
          originalName
          documentId
        }
        policies {
          id
          createdAt
          documentId
          matchingStatus
          matchingResponse
          certificationStatus
          policyNumber
          tradeContractId
          insuredName
          limitEachOccurance
          projectCompletedOperationLimit
          generalAggregateLimit
          personalAdvertisingLimit
          deductible
          selfInsuredRetention
          minimumPremium
          policyAggregateLimit
        }
        job {
          id
          userId
          startDate
          streetAddress
          slug
          additionalInsureds {
            id
            name
          }
          nonProcessingPolicy {
            id
            policyNumber
            company {
              id
              name
            }
            template {
              templateName
              eachOccurrenceLimit
              aggregateLimit
              aggregateType
              scope
              durationType
              duration
              contractualLiability
              priorityOfCoverage
              waiverOfSubrogation
              noticeOfChange
              personalAdvertisingLimit
              projectCompletedOperationLimit
              deductible
              templateLabels {
                id
                templateId
                labellingPath
                isSpecialRequirement
                isSpecialProhibition
              }
            }
          }
        }
        jobName
        jobCode
        prohibitedEndorsementsByJobType {
          id
          labellingPath
        }
      }
      count
    }
  }
`

export const LIST_TRADE_CONTRACTORS_ASSIGNED_TO_BROKER = gql`
  query listTradeContractorsAssignedToBroker($brokerId: String!) {
    listTradeContractorsAssignedToBroker(brokerId: $brokerId) {
      id
      tradeContractorId
      tradeContractor {
        id
        email
        role
        firstName
        lastName
        fullName
      }
      isActive
    }
  }
`
