import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { GET_NON_PROCESSING_POLICIES } from '../graphql/graphql-queries'
import CustomClickableButton from './custom-components/custom-clickable-button'
import PopupModal from './popup-modal'
import { PencilIcon, StopIcon } from '@heroicons/react/solid'
import styled from 'styled-components'
import CusotmOverlayTooltip from './custom_overlay_tooltip'
import Pagination from './Pagination Component/pagination'
import config from '../core/config'

import PopupModalForInactivePolicy from './show-inactive-policy-modal'
import CustomAlert from './custom-components/custom-alert'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
import { IEditNonProcessingPolicyData } from '../interface'
type Props = {
  className?: string
}

const ClientCompanyPolicyDetail: React.FC<Props> = (props: Props) => {
  const location: any = useLocation()
  const {
    state: {
      firstNameInsuredId,
      firstNameInsured,
      clientName,
      clientEmail,
      clientPhoneNumber,
    },
  }: any = useLocation()

  const navigate = useNavigate()
  const [policyDetail, setPolicyDetail] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage] = useState(config.itemPerPage)
  const [showModal, setShowModal] = useState(false)
  const [showModalForPolicy, setShowModalForPolicy] = useState(false)
  const [policyIdToMakeInactive, setPolicyidToMakeInctive] = useState('')
  const [success, setSuccess] = useState('For Notification')
  const [successMessage, setSuccessMessage] = useState('Message')
  const [count, setCount] = useState(1)
  const [isEditNPP, setIsEdit] = useState(false)

  const [
    nonProcessingPolicyDataTOBeEdited,
    setNonProcessingPolicyDataTOBeEdited,
  ] = useState<IEditNonProcessingPolicyData>({
    id: '',
    policyNumber: '',
    startDate: null,
    endDate: null,
    templateId: '',
    claimsSpecialistId: '',
    policyStatus: true,
  })
  let PaginationNonProcessingInput = {
    offset: currentPage === 1 ? 0 : (currentPage - 1) * 25,
    limit: dataPerPage,
  }
  const { refetch, loading, error } = useQuery(GET_NON_PROCESSING_POLICIES, {
    variables: {
      firstNameInsuredId: firstNameInsuredId,
      data: PaginationNonProcessingInput,
    },
    onCompleted: (data) => {
      setPolicyDetail(data.listNonProcessingPolicies?.policyOutput)
      setCount(data.listNonProcessingPolicies?.count)
    },
  })
  useEffect(() => {
    refetch()
  }, [showModal, showModalForPolicy]) // eslint-disable-line react-hooks/exhaustive-deps

  const lastIndexOfPage = currentPage * dataPerPage
  const firstIndexOfPage = lastIndexOfPage - dataPerPage
  const policyDetailArray = policyDetail?.slice(
    firstIndexOfPage,
    lastIndexOfPage
  )
  const perPage = Math.ceil(count / dataPerPage)
  const handleClose = () => setSuccess('For Notification')

  const nonProcessingPolicyInfo = {
    firstNameInsured,
    clientName,
    clientEmail,
    clientPhoneNumber,
  }

  const handleTradesClick = (it: any) => {
    navigate('/trades-list-for-all-jobs', {
      state: {
        policyId: it.id,
        policyStatus: it.policyStatus,
        nonProcessingPolicyInfo,
      },
    })
  }
  const handleEditClick = (it: any) => {
    setNonProcessingPolicyDataTOBeEdited({
      id: it.id,
      policyNumber: it.policyNumber,
      startDate: it.startDate,
      endDate: it.endDate,
      templateId: it.templateId,
      claimsSpecialistId: it.claimsSpecialistId,
      policyStatus: it.policyStatus === 'Active' ? true : false,
    })
    setIsEdit(true)
    setShowModal(true)
  }
  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />
  return (
    <Container className={props.className}>
      {success === 'success' && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleClose}
            alertType="success"
            alertMessage={successMessage}
          />
        </Row>
      )}
      {success === 'failed' && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleClose}
            alertType="error"
            alertMessage={successMessage}
          />
        </Row>
      )}
      <Row className="my-4 text-start">
        <Col sm={2} className="fw-bold">
          <div>Client Name</div>
          <div>First Named Insured</div>
        </Col>
        <Col sm={4}>
          <div>{location?.state?.clientName}</div>
          <div>{location?.state?.firstNameInsured}</div>
        </Col>
        <Col sm={6} className="text-primary text-end">
          <CustomClickableButton
            buttonContent="Add Policy"
            handleOnClick={() => {
              setShowModal(true)
              setIsEdit(false)
            }}
            disable={false}
          />
        </Col>
      </Row>
      <div className="client-list shadow-none p-3 mb-5 bg-white rounded">
        <Row className=" fw-bold border-bottom border-light pb-2">
          <Col sm={2} className="text-start">
            Policy Details
          </Col>
          <Col sm={2}>Policy Status</Col>
          <Col sm={2}>Jobs</Col>
          <Col sm={2}>Claims</Col>
          <Col sm={2}>Trades</Col>
        </Row>

        {policyDetailArray?.map((it: any, index: number) => {
          return (
            <Row
              className="border-bottom border-light p-2"
              key={it.policyNumber + index}
            >
              <Col className="text-start" sm={2}>
                <div className="d-flex justify-content-between align-items-center">
                  <span
                    onClick={() => {
                      navigate('/client-policy-details', {
                        state: {
                          policyId: it.id,
                          policyStatus: it.policyStatus,
                          firstNameInsured: location.state.firstNameInsured,
                          clientName: location.state.clientName,
                          clientEmail: location.state.clientEmail,
                          clientPhoneNumber: location.state.clientPhoneNumber,
                          firstNameInsuredId: location.state.firstNameInsuredId,
                          nonProcessingPolicyDetailPassed: it,
                          nonProcessingPolicyInfo,
                          tradeContracts: it.tradeContracts,
                        },
                      })
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {it.policyNumber}
                    <br />
                    {it.templateName}
                  </span>
                  <span className="ms-4">
                    <CusotmOverlayTooltip tooltipContent="Edit">
                      <PencilIcon
                        className="react-icon"
                        onClick={() => handleEditClick(it)}
                      />
                    </CusotmOverlayTooltip>
                  </span>
                </div>
              </Col>
              <Col sm={2}>{it.policyStatus}</Col>
              <Col sm={2}>
                <CusotmOverlayTooltip tooltipContent="Click to see jobs">
                  <span
                    onClick={() => {
                      navigate(`/jobs/${it.id}`)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {it.numberOfJobs}
                  </span>
                </CusotmOverlayTooltip>
              </Col>
              <Col sm={2}>
                <CusotmOverlayTooltip tooltipContent="Click to see claims">
                  <span
                    onClick={() => {
                      navigate(`/claims`, {
                        state: {
                          nonProcessingPolicy: {
                            ...it,
                            firstNameInsuredId:
                              location?.state?.firstNameInsuredId,
                          },
                          job: '',
                          contractedCompanyId: '',
                        },
                      })
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {it.totalClaims}
                  </span>
                </CusotmOverlayTooltip>
              </Col>

              <Col sm={2}>
                <CusotmOverlayTooltip tooltipContent="Click to see trades">
                  <span
                    className="clickable-text"
                    onClick={() => handleTradesClick(it)}
                  >
                    {it.tradeContracts.length}
                  </span>
                </CusotmOverlayTooltip>
              </Col>
              <Col>
                {it.policyStatus === 'Active' && (
                  <CusotmOverlayTooltip tooltipContent="Make Policy Inactive">
                    <StopIcon
                      className="stop-icon"
                      onClick={() => {
                        setPolicyidToMakeInctive(it.id)
                        setShowModalForPolicy(true)
                      }}
                    />
                  </CusotmOverlayTooltip>
                )}
              </Col>
            </Row>
          )
        })}
      </div>
      {policyDetailArray?.length > 0 && (
        <div>
          {' '}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={perPage}
          />
        </div>
      )}

      {showModal && (
        <PopupModal
          show={showModal}
          onHide={() => setShowModal(false)}
          email={location.state.clientEmail}
          message={
            !isEditNPP
              ? 'Create Non Processing Policy'
              : 'Update Non Processing Policy'
          }
          policynumber="Policy Number"
          policystartdate="Policy Start Date"
          policyenddate="Policy End Date"
          claimspecialist="Claim Specialist"
          requirementstemplate="Requirements Template"
          companyid={location.state.firstNameInsuredId}
          page="Client_Policy_List"
          size="lg"
          setshowmodal={setShowModal}
          setsuccess={setSuccess}
          setsuccessmessage={setSuccessMessage}
          nonprocessingpolicydatatObeedited={nonProcessingPolicyDataTOBeEdited}
          iseditpolicy={isEditNPP}
          setIsEdit={setIsEdit}
        />
      )}
      <div>
        {showModalForPolicy && (
          <PopupModalForInactivePolicy
            show={showModalForPolicy}
            onHide={() => setShowModalForPolicy(false)}
            policyid={policyIdToMakeInactive}
          />
        )}
      </div>
    </Container>
  )
}
export default styled(ClientCompanyPolicyDetail)`
  .stop-icon {
    height: 25px;
    color: #d22b2b;
    cursor: pointer;
  }
`
