import { InformationCircleIcon } from '@heroicons/react/solid'
import moment from 'moment'
import { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import PopupModal from './popup-modal'
import {
  LimitsForReviewRequirements,
  OtherTemplateDetails,
  ScopeAndDuration,
  TemplateLabelsList,
} from './template-details'

export interface ReviewOrCertifyRequirementsProps
  extends React.ComponentProps<any> {
  className?: string
  tradeContractForCertifyInsurance: any
}

const ReviewOrCertifyRequirements: React.FC<
  ReviewOrCertifyRequirementsProps
> = ({ tradeContractForCertifyInsurance, className }) => {
  const [showModal, setShowModal] = useState(false)

  const { jobName, contractedCompany, job } =
    tradeContractForCertifyInsurance || {}

  return (
    <Container className={`text-start mx-4 reviewRequirements ${className}`}>
      <div className="my-5">
        <h6 className="text-primary fw-bold">Job Details</h6>
        <h5 className="fw-bold">{job?.streetAddress}</h5>
        <p className="text-secondary fw-bold">
          <span>{contractedCompany?.name}</span>
          <span className="p-2">|</span>
          <span>{`Job ${job?.slug}`}</span>
          <span className="p-2">|</span>
          <span>{jobName}</span>
        </p>
        <hr />
      </div>
      <div className="my-5">
        <h5 className="fw-bold">
          Insurance Requirements
          <span className="px-1">
            <InformationCircleIcon
              style={{ height: '20px', color: '#8B9192' }}
            />
          </span>
        </h5>
        <p>
          {`These requirements must be met to work at ${job?.streetAddress}.`}
        </p>
      </div>
      <span
        className="text-primary fw-bold text-decoration-underline prohibitedEndorsmentLink"
        onClick={() => {
          setShowModal(true)
        }}
      >
        Prohibited Endorsements
      </span>
      <Row className="my-5">
        <LimitsForReviewRequirements
          template={job?.nonProcessingPolicy?.template}
        />
        <ScopeAndDuration template={job?.nonProcessingPolicy?.template} />
      </Row>

      <Row className="my-5">
        <Col>
          <div className="fw-bold mb-2">
            Named Insured(s) and Additional Insured(s)
          </div>
          <div>
            <span className="text-underline-dashed">Named Insured(s)</span>
            <span className="fw-bold p-3 mx-2">{contractedCompany?.name}</span>
          </div>
          <div className=" d-flex justify-content-flex-start">
            <div className="text-underline-dashed">Additional Insured(s)</div>
            <div className="fw-bold p-1 ">
              {job?.additionalInsureds && job?.additionalInsureds.length ? (
                <span className="additonal-insureds-list ">
                  {job?.additionalInsureds.map((additionalInsured: any) => (
                    <p className="my-0" key={additionalInsured.id}>
                      {additionalInsured.name}
                    </p>
                  ))}
                </span>
              ) : (
                'NA'
              )}
            </div>
          </div>
        </Col>
        <Col>
          <div className="fw-bold mb-2">Project Start Date and Address</div>
          <div>
            <span className="text-underline-dashed">Project Start Date</span>
            <span className="fw-bold p-3">
              {moment(job?.startDate).format('MM/DD/YYYY')}
            </span>
          </div>
          <div>
            <span className="text-underline-dashed">Project Address</span>
            <span className="fw-bold p-3">{job?.streetAddress}</span>
          </div>
        </Col>
      </Row>

      <OtherTemplateDetails template={job?.nonProcessingPolicy?.template} />
      <Row className="my-3">
        <TemplateLabelsList template={job?.nonProcessingPolicy?.template} />
      </Row>

      <div className="my-0">
        <h6 className="fw-bold my-1">Things to know</h6>
        <div>
          If your policy is changed or cancelled for any reason, you must notify
          us immediately by sending an email to{' '}
          <a href="mailto:changes@resolvedrisk.com" className="email-link">
            changes@resolvedrisk.com
          </a>
          .
        </div>
      </div>

      {showModal && (
        <PopupModal
          show={showModal}
          onHide={() => setShowModal(false)}
          message="Prohibited Endorsements for this job"
          page="Prohibited_Endorsements"
          size="lg"
          setshowmodal={setShowModal}
          tradecontracrtorid={tradeContractForCertifyInsurance?.id}
        />
      )}
    </Container>
  )
}

export default styled(ReviewOrCertifyRequirements)`
  .prohibitedEndorsmentLink {
    cursor: pointer;
  }
  .reviewRequirements {
    margin-bottom: 60px !important;
  }
`
