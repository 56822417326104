import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  DOCUMENT_BY_DOC_ID,
  FORM_DETAILS,
  POLICY_DETAILS_BY_TRADE_CONTRACT_ID,
} from '../graphql/graphql-queries'
import PolicyDetailsForm from './policy-details-form/policy-details-form'
import {
  IFormInputs,
  IFormsAndScheduledTextInputs,
  IScheduledTextInputs,
} from '../interface'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
export interface ReviewPolicyDetailProps extends React.ComponentProps<any> {
  className?: string
}

const ReviewPolicyDetail: React.FC<ReviewPolicyDetailProps> = ({
  className,
}) => {
  const tradeContracrtorId = useParams()
  const [policyId, setPolicyId] = useState('')
  const [policyDetails, setPolicyDetails] = useState<any>()
  const [docId, setDocId] = useState<any>()
  const { refetch } = useQuery(POLICY_DETAILS_BY_TRADE_CONTRACT_ID, {
    variables: { tradeContractId: tradeContracrtorId.id },
    onCompleted: (data) => {
      let updatedPolicyDetail = {
        ...data.getPolicyByTradeContractId,
        minimumPremium: data.getPolicyByTradeContractId.minimumPremium
          ? String(data.getPolicyByTradeContractId.minimumPremium)
          : null,
      }
      setPolicyDetails({ ...updatedPolicyDetail })

      setPolicyId(data.getPolicyByTradeContractId.id)
      setDocId(data?.getPolicyByTradeContractId?.documentId)
    },
  })
  useEffect(() => {
    refetch()
  }, [tradeContracrtorId])
  const [documentDetails, setDocumentDetails] = useState<any>()
  useQuery(DOCUMENT_BY_DOC_ID, {
    variables: { docId },
    onCompleted: (data) => {
      setDocumentDetails(data?.getDocumentByDocId)
    },
    skip: !docId,
  })
  const [formData, setFormData] = useState<any>()
  const {
    loading: getFormsAndScheduledTextsLoading,
    error: getFormsAndScheduledTextsError,
  } = useQuery(FORM_DETAILS, {
    variables: { docId: docId },
    onCompleted: (data) => {
      setFormData(data?.listForms)
    },
    skip: !docId,
  })
  const formsArray: IFormInputs[] = []
  const scheduledTextArray: IScheduledTextInputs[] = []
  formData &&
    formData?.map((form: any) => {
      const formFields: IFormInputs = {
        id: form?.id,
        documentId: form?.documentId,
        pageNumber: form?.pageNumber,
        type: form?.type,
        formNumber: form?.formNumber,
        endorsementNumber: form?.endorsementNumber,
        isDeleted: form?.isDeleted,
        deletedBy: form?.deletedBy,
        formTitle: form?.formTitle,
        normalizedFormTitle: form?.normalizedFormTitle,
      }
      formsArray.push(formFields)
      if (form?.scheduledTexts?.length) {
        for (let i = 0; i < form?.scheduledTexts.length; i++) {
          const scheduledTextData = form?.scheduledTexts[i]
          const scheduledTextFields: IScheduledTextInputs = {
            documentId: form?.documentId,
            formId: form?.id,
            textName: scheduledTextData.textName,
            textValue: scheduledTextData.textValue,
            id: scheduledTextData.id,
          }
          scheduledTextArray.push(scheduledTextFields)
        }
      }
    })
  const formsAndScheduledTextsForMannualEdit: IFormsAndScheduledTextInputs = {
    forms: formsArray,
    scheduledTexts: scheduledTextArray,
  }
  if (getFormsAndScheduledTextsLoading) return <SpinnerLoading />
  if (getFormsAndScheduledTextsError) return <ErrorFetchingData />

  return (
    <>
      {policyDetails && tradeContracrtorId && (
        <PolicyDetailsForm
          policyDetails={policyDetails}
          documentDetails={documentDetails}
          formsAndScheduledTextsForMannualEdit={
            formsAndScheduledTextsForMannualEdit
          }
          tradeContracrtorId={tradeContracrtorId}
        />
      )}
    </>
  )
}
export default styled(ReviewPolicyDetail)``
