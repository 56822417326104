import { Form, Row, Col } from 'react-bootstrap'

interface FilterDropdownDocumentsListingProps {
  filterByStatus: string
  setFilterByStatus: React.Dispatch<React.SetStateAction<string>>
  filteringStatusList: string[]
  statusText: string
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
  setPageNumber?: React.Dispatch<React.SetStateAction<number>>
}

const FilterDropdownDocumentsListing: React.FC<
  FilterDropdownDocumentsListingProps
> = ({
  setPageNumber,

  filterByStatus,
  setFilterByStatus,
  filteringStatusList,
  statusText,
  setSearchTerm,
}) => {
  const filterByType = (e: any) => {
    if (setSearchTerm) setSearchTerm('')
    setFilterByStatus(e.target.value)
    if (setPageNumber) setPageNumber(1)
  }

  return (
    <Form.Group as={Row} className="mb-4 g-0" controlId="formHorizontalEmail">
      <Form.Label column sm={`${statusText === 'Parsing Status' ? 3 : 4}`}>
        {statusText}
      </Form.Label>
      <Col sm={4}>
        <Form.Select onChange={filterByType} value={filterByStatus}>
          {filteringStatusList.map((it: string, index) => (
            <option key={`${it}_${index}`} value={it}>
              {it}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Form.Group>
  )
}

export default FilterDropdownDocumentsListing
