import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { FieldValues, FormState, UseFormRegister } from 'react-hook-form'
import {
  IFormInputs,
  IFormsAndScheduledTextInputs,
  IPolicyDetailsValidationEror,
  IScheduledTextInputs,
} from '../../interface'
import FormInputs from './form-inputs'

export interface FormsAndScheduledTextsInputsProps
  extends React.ComponentProps<any> {
  className?: string
  formAndScheduledTextInputs: IFormsAndScheduledTextInputs
  setFormAndScheduledTextInputs: React.Dispatch<
    React.SetStateAction<IFormsAndScheduledTextInputs>
  >
  formFields: IFormInputs
  scheduledTextFields: IScheduledTextInputs
  error: IPolicyDetailsValidationEror
  tradeContracrtorId?: string
}

const FormsAndScheduledTextsInputs: React.FC<
  FormsAndScheduledTextsInputsProps
> = ({
  formAndScheduledTextInputs,
  setFormAndScheduledTextInputs,
  formFields,
  scheduledTextFields,
  error,
  tradeContracrtorId,
}) => {
  const [formInputValues, setFormInputValues] = useState([formFields])

  let [formId, setFormId] = useState('1')

  const handleAddFormInputFields = () => {
    let formCount = Number(formId) + 1
    setFormId((formId) => `${formCount}`)
    setFormInputValues([
      ...formInputValues,
      { ...formFields, id: `${formCount}` },
    ])
    setFormAndScheduledTextInputs({
      ...formAndScheduledTextInputs,
      forms: [
        ...formAndScheduledTextInputs?.forms,
        { ...formFields, id: `${formCount}` },
      ],
    })
  }

  return (
    <Container className="text-start mb-5 border p-5 my-4">
      <p className="fs-5 fw-bold">Form & Scheduled Text Details</p>
      <FormInputs
        formAndScheduledTextInputs={formAndScheduledTextInputs}
        setFormAndScheduledTextInputs={setFormAndScheduledTextInputs}
        formInputValues={formInputValues}
        setFormInputValues={setFormInputValues}
        scheduledTextFields={scheduledTextFields}
        error={error}
        tradeContracrtorId={tradeContracrtorId}
      />

      <div className="pt-4">
        {!tradeContracrtorId && (
          <span
            className="button add text-primary-medium fw-bold add-cursor"
            onClick={handleAddFormInputFields}
          >
            <>
              <span className="pe-1">+</span>
              <span>
                {formInputValues.length && !tradeContracrtorId
                  ? 'Add another form'
                  : 'Add form'}
              </span>
            </>
          </span>
        )}
      </div>
    </Container>
  )
}

export default FormsAndScheduledTextsInputs
