import { InformationCircleIcon } from '@heroicons/react/outline'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { checkArrayIncludesString } from '../helper-functions'

export interface ScrollableListProps extends React.ComponentProps<any> {
  className?: string
  labelsDefinedInRequirement: string[]
  typeOfLabels?: string
  labelsInMatchingResponseJson?: string[]
  title?: string
  width?: string
  commonwidth?: boolean
}

const ScrollableList: React.FC<ScrollableListProps> = ({
  className,
  labelsDefinedInRequirement,
  typeOfLabels,
  labelsInMatchingResponseJson,
  title,
  width,
  commonwidth,
}) => {
  return (
    <div className={`${className}`}>
      <p className="fw-bold mb-2  ">{title}</p>
      <Container
        className={`${
          width ? width : 'w-75'
        } border scrollable-container p-2 d-inline-flex flex-column ${
          commonwidth ? 'make-common-width' : ''
        }`}
      >
        {labelsDefinedInRequirement?.length
          ? labelsDefinedInRequirement?.map((it: any, index: number) => {
              return (
                <ul key={`${index}`} className="my-1">
                  <div className="d-flex align-items-baseline">
                    <span className="pe-2">
                      {labelsInMatchingResponseJson &&
                        checkArrayIncludesString(
                          labelsInMatchingResponseJson,
                          it
                        ) && (
                          <InformationCircleIcon className="info-circle-error template-labels-info-circle-error" />
                        )}
                    </span>
                    <div className="d-flex flex-column">
                      <li className="text-uppercase list-content">{it}</li>
                      {typeOfLabels &&
                        index !== labelsDefinedInRequirement.length - 1 && (
                          <span className="text-uppercase text-center or-text">
                            OR
                          </span>
                        )}
                    </div>
                  </div>
                </ul>
              )
            })
          : !typeOfLabels && !labelsDefinedInRequirement?.length
          ? `${title} Not Specified`
          : null}
      </Container>
    </div>
  )
}

export default styled(ScrollableList)`
  .scrollable-container {
    height: auto;
    max-height: 15vh;
    min-width: 25vw;
    max-width: 40vw;
    overflow-y: auto;
    border-radius: 12px;
  }

  .template-labels-info-circle-error {
    height: 14px;
  }

  .list-content {
    line-height: 1.1;
    white-space: nowrap;
  }
  .make-common-width {
    max-width: 400px !important;
    min-width: 300px !important;
  }

  .or-text {
    width: 450px;
    margin-bottom: -8px;
  }
`
