import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import CustomRadioContainer from './custom-components/custom-radio-container'
import CustomRadioDuration from './custom-components/custom-radio-button-duration'
import { ITemplate } from '../interface'
import CustomClickableButton from './custom-components/custom-clickable-button'
import { useState } from 'react'

export interface ScopeAndDurationContainerProps
  extends React.ComponentProps<any> {
  className?: string
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  template: ITemplate
  setTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
  duplicateTemplate: ITemplate
  setDuplicateTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
}

const ScopeAndDurationContainer: React.FC<ScopeAndDurationContainerProps> = ({
  className,
  setCurrentStep,
  currentStepIndex,
  setCurrentStepIndex,
  duplicateTemplate,
  setDuplicateTemplate
}) => {
  const [
    isDurationOngoingAndCompletedSelected,
    setIsDurationOngoingAndCompletedSelected,
  ] = useState(false)
  let scope = [
    'Arising out of the work',
    'Caused, in whole or in part by, the work',
    'Resulting from the negligence',
  ]
  let duration = [
    'Ongoing operations',
    'Ongoing operations and completed operations',
  ]

  const handleOnChangeScopeAndDuration = (e: any) => {
    if (e.target.value === 'Ongoing operations and completed operations') {
      setIsDurationOngoingAndCompletedSelected(true)
    }
    if (e.target.value === 'Ongoing operations') {
      setIsDurationOngoingAndCompletedSelected(false)
    }
    setDuplicateTemplate({
      ...duplicateTemplate,
      [e.target.name]: e.target.value,
    })
  }

  const handleScopeAndDurationNextClick = () => {
    setCurrentStep('Additional Requirements')
    setCurrentStepIndex(currentStepIndex + 1)
  }

  return (
    <div className={`${className} text-start m-4`}>
      <Container>
        <Row>
          <Col>
            <Container className="mx-0">
              <h3 className="m-4 mb-5 fw-bold">Scope and Duration</h3>

              <CustomRadioContainer
                btnType="radio"
                list={scope}
                title="Scope"
                caption="Relationship between work and occurence that triggers coverage for additional insured"
                name="scope"
                handleOnChangeScopeAndDuration={handleOnChangeScopeAndDuration}
                selectedValue={duplicateTemplate.scope}
              />
              {isDurationOngoingAndCompletedSelected === true ||
              duplicateTemplate.durationType ===
                'Ongoing operations and completed operations' ? (
                <CustomRadioDuration
                  btnType="radio"
                  list={duration}
                  title="Duration"
                  caption="Required duration of coverage"
                  name="durationType"
                  duplicateTemplate={duplicateTemplate}
                  setDuplicateTemplate={setDuplicateTemplate}
                  handleOnChangeScopeAndDuration={
                    handleOnChangeScopeAndDuration
                  }
                  selectedValue={duplicateTemplate.durationType}
                />
              ) : (
                <CustomRadioContainer
                  btnType="radio"
                  list={duration}
                  title="Duration"
                  caption="Required duration of coverage"
                  name="durationType"
                  handleOnChangeScopeAndDuration={
                    handleOnChangeScopeAndDuration
                  }
                  selectedValue={duplicateTemplate.durationType}
                />
              )}
            </Container>
          </Col>
        </Row>
        <Row className="text-end">
          <Col sm={10}>
            <CustomClickableButton
              buttonContent="Next"
              handleOnClick={handleScopeAndDurationNextClick}
              disable={false}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default styled(ScopeAndDurationContainer)``
