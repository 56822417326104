import { Container, Row, Col, Badge } from 'react-bootstrap'
import moment from 'moment'
import styled from 'styled-components'
import authentication from '../core/authentication'
import { useNavigate } from 'react-router-dom'
import { DocumentTextIcon, PencilIcon } from '@heroicons/react/solid'
import Pagination from './Pagination Component/pagination'
import {
  formatByInsertingHyphens,
  getLatestUploadedFileFromList,
  getLatestUploadedPolicyCertificationStatusFromList,
  getLatestUploadedPolicyFromList,
  handleViewDocumentClick,
} from '../helper-functions'
import CustomClickableNavigateButton from './custom-components/custom-clickable-navigate-button'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_TRADE_CONTRACTS_BY_JOB_ID,
  POLICY_DETAILS_BY_TRADE_CONTRACT_ID,
  RECERTIFY_POLICY,
} from '../graphql/graphql-queries'
import CustomAlert from './custom-components/custom-alert'
import { useState } from 'react'
import config from '../core/config'
import ShowBadgeForCertificationStatus from './show-badge-for-certification-status'
import CusotmOverlayTooltip from './custom_overlay_tooltip'

export interface TradesListProps extends React.ComponentProps<any> {
  className?: string
  tradeContracts: any
  job: any
  handleAddTradeClick: () => void
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  perPage: number
}

const TradesList: React.FC<TradesListProps> = ({
  className,
  tradeContracts,
  job,
  handleAddTradeClick,
  currentPage,
  setCurrentPage,
  perPage,
}) => {
  const tradeContractArray = tradeContracts
  let navigate = useNavigate()
  const [recertifyPolicySuccess, setRecertifyPolicySuccess] = useState(false)
  const [recertifyPolicyError, setRecertifyPolicyError] = useState(false)

  const offset = 0
  const dataPerPage = config.itemPerPage

  let paginationDataForTradeContracts = {
    jobId: job?.id,
    offset: currentPage === 1 ? offset : (currentPage - 1) * 25,
    limit: dataPerPage,
  }

  const [reCertifyPolicy] = useMutation(RECERTIFY_POLICY, {
    onCompleted: () => {
      setRecertifyPolicySuccess(true)
    },
    onError: () => {
      setRecertifyPolicyError(true)
    },
    refetchQueries: [
      {
        query: GET_TRADE_CONTRACTS_BY_JOB_ID,
        variables: { data: paginationDataForTradeContracts },
      },
    ],
  })

  const [getPolicyByTradeContractId] = useLazyQuery(
    POLICY_DETAILS_BY_TRADE_CONTRACT_ID,
    {
      fetchPolicy: 'cache-and-network',

      onCompleted: (data) => {
        reCertifyPolicy({
          variables: {
            policyId: data?.getPolicyByTradeContractId?.id,
          },
        })
      },
    }
  )

  let getCertifiedPolicyCertificationDate = (tradeContract: any) => {
    let latestUploadedFile = getLatestUploadedFileFromList(tradeContract)
    let latestUploadedPolicy = getLatestUploadedPolicyFromList(
      tradeContract,
      latestUploadedFile
    )
    return latestUploadedPolicy?.createdAt
  }

  const recertifyPolicyHandler = async (tradeContractId: string) => {
    getPolicyByTradeContractId({
      variables: {
        tradeContractId,
      },
    })
  }

  const isAdmin = authentication.isSuperAdmin()
  const handleEditClick = (trade: any) => {
    navigate('/update-trade-contractor', {
      state: {
        tradeContractor: trade,
      },
    })
  }
  return (
    <div className={className}>
      {recertifyPolicySuccess && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setRecertifyPolicySuccess(false)}
            alertType="success"
            alertMessage='Policy certification process completed. Please check "Trade Status" for certification status.'
          />
        </Row>
      )}
      {recertifyPolicyError && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setRecertifyPolicyError(false)}
            alertType="error"
            alertMessage={'Some error encountered. Please try again!'}
          />
        </Row>
      )}

      <Row className="my-3">
        <Col className="text-start">
          <p className="fw-bold fs-5 ms-1">Trades Contracted</p>
        </Col>
        {(authentication.isSuperAdmin() || authentication.isClient()) && (
          <Col className="text-primary text-end">
            <CustomClickableNavigateButton
              buttonContent="+ Add Trade"
              handleOnClick={handleAddTradeClick}
              disable={false}
            />
          </Col>
        )}
      </Row>
      <Container className="border text-start bg-white">
        <Row className="fw-bold py-3">
          <Col sm={3}>Company</Col>
          <Col sm={1}>EIN</Col>
          <Col sm={2}>Contact Name</Col>
          <Col sm={1}>Date Certified</Col>
          <Col sm={1}>Claims</Col>
          <Col sm={1}>Trades Status</Col>
        </Row>
        {!!tradeContracts?.length ? (
          tradeContractArray?.map((trade: any) => (
            <Row
              className="border-top d-flex align-items-center py-2"
              key={trade.id}
            >
              <Col sm={3}>
                <div className="d-flex justify-content-between me-5">
                  <span>{trade.contractedCompany.name}</span>
                  <span>
                    <CusotmOverlayTooltip tooltipContent="Edit">
                      <PencilIcon
                        className="react-icon"
                        onClick={() => handleEditClick(trade)}
                      />
                    </CusotmOverlayTooltip>
                  </span>
                </div>
                <div className="text-muted pt-1">
                  <span className="fw-bold">Job Type: </span>
                  {trade?.jobName}
                </div>
              </Col>
              <Col sm={1}>
                <div>
                  {trade.contractedCompany.ein
                    ? formatByInsertingHyphens(trade.contractedCompany?.ein)
                    : 'Pending'}
                </div>
              </Col>
              <Col sm={2}>
                <div>{`${trade.contractedCompanyUser.firstName} ${trade.contractedCompanyUser.lastName}`}</div>
              </Col>
              <Col sm={1}>
                <div>
                  {getLatestUploadedPolicyCertificationStatusFromList(trade) ===
                  'CERTIFIED'
                    ? moment(getCertifiedPolicyCertificationDate(trade)).format(
                        'MM/DD/YYYY'
                      )
                    : 'Pending'}
                </div>
              </Col>
              <Col sm={1}>
                <span
                  className="span-cursor"
                  onClick={() => {
                    navigate('/claims', {
                      state: {
                        nonProcessingPolicy: '',
                        job: job,
                        contractedCompanyId: trade.contractedCompanyId,
                      },
                    })
                  }}
                >
                  {trade?.contractedCompany?.claims?.length
                    ? trade?.contractedCompany?.claims?.length
                    : 0}
                </span>
              </Col>
              <Col sm={1}>
                <ShowBadgeForCertificationStatus tradeContract={trade} />
              </Col>
              <Col className="text-center ps-5">
                {isAdmin &&
                  trade?.policies?.length > 0 &&
                  (getLatestUploadedPolicyCertificationStatusFromList(trade) ===
                    'NOT CERTIFIED' ||
                    getLatestUploadedPolicyCertificationStatusFromList(
                      trade
                    ) === 'CERTIFIED') && (
                    <Badge
                      pill
                      bg="primary-light"
                      className="text-dark badge-document badge-view-policy-detail"
                      onClick={() => {
                        navigate(`/review-policy/${trade.id}`, {
                          state: {
                            clickedFile: '',
                          },
                        })
                      }}
                    >
                      <DocumentTextIcon className="check-icon hero-icon-primary" />
                      View Policy Detail
                    </Badge>
                  )}
                {authentication.isClient() && trade?.fileUploads?.length ? (
                  <Badge
                    pill
                    bg="primary-light"
                    className="text-dark badge-document badge-view-policy-detail"
                    onClick={() =>
                      handleViewDocumentClick(
                        getLatestUploadedFileFromList(trade)?.path
                      )
                    }
                  >
                    <DocumentTextIcon className="check-icon hero-icon-primary" />
                    View Policy
                  </Badge>
                ) : (
                  ''
                )}
              </Col>
              {isAdmin && !!trade?.policies?.length && (
                <Col className="text-center">
                  <Badge
                    pill
                    bg="primary-light"
                    className="text-dark badge-document badge-recertify"
                    onClick={() => recertifyPolicyHandler(trade.id)}
                  >
                    Re-certify Policy
                  </Badge>
                </Col>
              )}
            </Row>
          ))
        ) : (
          <Row className="border-top">
            <p className="text-center m-3">No trades have been added yet.</p>
          </Row>
        )}
      </Container>
      {tradeContractArray?.length > 0 && (
        <div style={{ marginTop: '50px' }}>
          {' '}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={perPage}
          />
        </div>
      )}
    </div>
  )
}

export default styled(TradesList)`
  .span-cursor {
    // height: 25px;
    color: #13948b;
    cursor: pointer;
  }

  .badge-view-policy-detail {
    padding: 8px 12px;
  }

  .badge-recertify {
    padding: 12px 12px;
  }
`
