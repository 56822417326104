import { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import { UploadIcon } from '@heroicons/react/outline'
import { v4 as uuidv4 } from 'uuid'
import { TrashIcon } from '@heroicons/react/solid'

import { appConfig } from '../core'
import axios from 'axios'
import authentication from '../core/authentication'

export interface DropzoneComponentProps extends React.ComponentProps<any> {
  className: string
  selectedFile: any
  setSelectedFile: React.Dispatch<any>
  selectedFileUpdatedDetails: string
  setSelectedFileUpdatedDetails: React.Dispatch<any>
  handleFileUploadClick?: any
  type: string
  isFileUploadedinS3?: boolean
  setIsFileUploadedinS3?: React.Dispatch<any>
  setIsUploadButtonDisabled?: React.Dispatch<any>
  isFileUploading: boolean
  docName?: string
  dropzoneContent?: string
  setDocumentName?: React.Dispatch<React.SetStateAction<boolean>>
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>
}

const DropzoneComponent: React.FC<DropzoneComponentProps> = ({
  className,
  selectedFile,
  setSelectedFile,
  selectedFileUpdatedDetails,
  setSelectedFileUpdatedDetails,
  isFileUploadedinS3,
  setIsUploadButtonDisabled,
  type,
  setIsFileUploadedinS3,
  isFileUploading,
  docName,
  dropzoneContent,
  setDocumentName,
  setErrorMessage,
}) => {
  const [isFileSelected, setIsFileSelected] = useState(false)
  const [originalFileName, setOriginalFileName] = useState<string>('')
  const [fileType, setFileType] = useState('')

  const uploadDataToS3bucket = async (
    fileToBeUpload: any,
    originalFile: any
  ) => {
    try {
      const token = authentication.getAccessToken()
      let res = await axios({
        method: 'POST',
        url: `${appConfig.apiUrl}/rest/signed-put-url?type=${type}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          fileType,
        },
      })

      let signedUrl = new URL(res.data.url)

      let assignedName = signedUrl.pathname.slice(1)

      let uploadedFileLocation = res.data.url.substring(
        0,
        res.data.url.indexOf('?')
      )

      var options = {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      }

      await axios.put(res.data.url, fileToBeUpload, options)

      setSelectedFileUpdatedDetails({
        ...fileToBeUpload,
        originalFileName: originalFile,
        assignedFileName: assignedName,
        path: uploadedFileLocation,
      })
    } catch (err) {
      console.error('Error: ', err)
      if (setErrorMessage)
        setErrorMessage(
          'Some error encountered while uploading file to S3 bucket. Please try again!'
        )
    }
  }

  const onDrop = (files: any) => {
    if (files.length > 0) {
      let fileExtension = files[0]?.type.split('/')[1]
      setFileType(`.${fileExtension}`)
      setSelectedFile(files[0])
      setIsFileSelected(true)
      const originalFile = files[0]
      let fileName = originalFile.name
      setOriginalFileName(fileName)
      let assignedName = appConfig.s3DirName + uuidv4().toString() + '.pdf'
      let newFile = new File([originalFile], assignedName)
      setSelectedFile(newFile)
      if (setIsUploadButtonDisabled) setIsUploadButtonDisabled(false)
      if (setDocumentName) setDocumentName(fileName)
    }
  }

  const removeFile = () => {
    setSelectedFile(null)
    setIsFileSelected(false)
    if (setIsUploadButtonDisabled) setIsUploadButtonDisabled(true)
  }

  useEffect(() => {
    if (isFileUploading && selectedFile && originalFileName) {
      ;(async () => {
        try {
          await uploadDataToS3bucket(selectedFile, originalFileName)
        } catch (err) {
          console.error('Error: ', err)
        }
      })()
    }
  }, [isFileUploading, selectedFile, originalFileName]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedFileUpdatedDetails) {
      if (setIsFileUploadedinS3) setIsFileUploadedinS3(true)
    }
  }, [selectedFileUpdatedDetails, setIsFileUploadedinS3])

  return (
    <div className={className}>
      <Dropzone onDrop={onDrop} multiple={false}>
        {({
          getRootProps,
          getInputProps,
        }: {
          getRootProps: any
          getInputProps: any
        }) => (
          <Row>
            <Col sm={10}>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />

                <div className="d-flex justify-content-center align-items-center">
                  {!isFileSelected && (
                    <div>
                      <Button className="btn-primary-medium rounded-pill text-white fw-bold">
                        <span>
                          <UploadIcon className="upload-icon" />
                        </span>
                        Upload
                      </Button>
                    </div>
                  )}

                  {selectedFile && selectedFile?.name ? (
                    <div className="selected-file">
                      {selectedFile && originalFileName}
                    </div>
                  ) : (
                    <span className="p-2 text-black">
                      {dropzoneContent
                        ? dropzoneContent
                        : `or drag and drop your ${
                            docName ? docName : 'file'
                          } here`}
                    </span>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              {selectedFile && (
                <TrashIcon className="trash-icon" onClick={removeFile} />
              )}
            </Col>
          </Row>
        )}
      </Dropzone>
    </div>
  )
}

export default styled(DropzoneComponent)`
  .dropzone {
    text-align: center;
    padding: 20px;
    border: 2px dashed #eeeeee;
    color: #bdbdbd;
    cursor: pointer;
    // width: 95%;
    margin-top: 5px;
    border-radius: 10px;
  }

  .selected-file {
    color: #000;
    font-weight: bold;
  }

  .upload-icon {
    height: 25px;
    color: #fff;
  }

  .trash-icon {
    height: 25px;
    color: #8b9192;
    margin-top: 22px;
    cursor: pointer;

    &:hover {
      color: #13948b;
    }
  }
`
