import { useLocation } from 'react-router-dom'
import PolicyInReview from './policy-in-review'
import PolicyVerificationSuccessful from './policy-verification-successful'
import PolicyVerificationFailed from './policy-verification-failed'
import PolicyNotRead from './policy-not-read'

export interface CertifyPolicyProps extends React.ComponentProps<any> {}

const CertifyPolicy: React.FC<CertifyPolicyProps> = () => {
  const {
    state: {
      clickedTradeContract,
      latestUploadPolicyCertificationStatus,
      latestUploadedPolicy,
    },
  }: any = useLocation()

  const checkIsPolicyMatched = () => {
    if (
      latestUploadedPolicy?.matchingResponse != null &&
      (latestUploadedPolicy?.matchingResponse?.unmatchedInputs
        ?.unmatchedAggregateLimit ||
        latestUploadedPolicy?.matchingResponse?.unmatchedInputs
          ?.unmatchedOccurrenceLimit ||
        latestUploadedPolicy?.matchingResponse?.unmatchedInputs
          ?.unmatchedInsuredNames?.length ||
        latestUploadedPolicy?.matchingResponse?.unmatchedInputs
          ?.unmatchedAdditionalInsureds?.length ||
        latestUploadedPolicy?.matchingResponse?.unmatchedInputs
          ?.unmatchedProjectAddresses?.length ||
        latestUploadedPolicy?.matchingResponse?.unmatchedInputs
          ?.unmatchedPolicyPeriod)
    ) {
      return false
    } else if (
      latestUploadedPolicy?.matchingResponse != null &&
      latestUploadedPolicy?.matchingResponse?.success === false
    ) {
      return false
    } else return true
  }

  return (
    <div>
      {latestUploadPolicyCertificationStatus === 'REVIEW PENDING' ? (
        <PolicyInReview />
      ) : latestUploadPolicyCertificationStatus ===
        "POLICY COULDN'T BE READ" ? (
        <PolicyNotRead
          tradeContractClickedForPolicyCertificationStatus={
            clickedTradeContract
          }
          latestUploadedPolicy={latestUploadedPolicy}
        />
      ) : latestUploadPolicyCertificationStatus === 'NOT CERTIFIED' ? (
        <PolicyVerificationFailed
          tradeContractClickedForPolicyCertificationStatus={
            clickedTradeContract
          }
          latestUploadedPolicy={latestUploadedPolicy}
        />
      ) : latestUploadPolicyCertificationStatus === 'CERTIFIED' ? (
        <PolicyVerificationSuccessful
          tradeContractClickedForPolicyCertificationStatus={
            clickedTradeContract
          }
          latestUploadedPolicy={latestUploadedPolicy}
        />
      ) : latestUploadedPolicy?.matchingStatus === 'COMPARED' &&
        !checkIsPolicyMatched() &&
        latestUploadedPolicy?.matchingResponse?.data ===
          'Some error occurred while matching' ? (
        <p>Some error encountered while matching the policy.</p>
      ) : null}
    </div>
  )
}

export default CertifyPolicy
