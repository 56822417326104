import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { UPDATE_COMPANY_DETAILS } from '../../graphql/graphql-queries'
import CustomClickableButton from '../custom-components/custom-clickable-button'
import ValidationError from '../validation-error'
import { maskEin } from '../../helper-functions'

export interface CompanyDetailsSettingsProps extends React.ComponentProps<any> {
  className?: string
  user: any
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  cancelClicked: boolean
  setCancelClicked: React.Dispatch<React.SetStateAction<boolean>>
}

const CompanyDetailsSettings: React.FC<CompanyDetailsSettingsProps> = ({
  className,
  user,
  setSuccessMessage,
  setErrorMessage,
  cancelClicked,
  setCancelClicked,
}) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState('')
  const [selectedCompanyDetails, setSelectedCompanyDetails] =
    useState<any>(null)
  const [companyDetails, setCompanyDetails] = useState({
    ein: '',
    address: '',
  })
  const [savedCompanyDetails, setSavedCompanyDetails] = useState({
    ein: '',
    address: '',
  })

  const handleOnSelectForCompany = (e: any) => {
    setSelectedCompanyId(e.target.value)
    let filteredCompanyDetails = user?.companies?.filter(
      (company: any) => company.id === e.target.value
    )[0]
    setSavedCompanyDetails({
      ein: filteredCompanyDetails?.ein || '',
      address: filteredCompanyDetails?.address || '',
    })
    setSelectedCompanyDetails(filteredCompanyDetails)
    setCompanyDetails({
      ein: filteredCompanyDetails?.ein || '',
      address: filteredCompanyDetails?.address || '',
    })
  }

  const handleOnChangeCompanyDetails = (e: any) => {
    setCompanyDetails({
      ...companyDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleSaveChangesClick = (e: any) => {
    let errorss = validateForm()
    if (isValid(errorss)) {
      if (checkIfFormEdited()) {
        updateCompanyDetails({
          variables: {
            companyId: selectedCompanyId,
            data: companyDetails,
          },
        })
        resetErrors()
      } else {
        setErrorMessage('There are no changes detected in the user profile.')
      }
    } else {
      setError(errorss)
    }
  }

  const [updateCompanyDetails] = useMutation(UPDATE_COMPANY_DETAILS, {
    onCompleted: (data) => {
      setSuccessMessage('Company details successfully updated')
      setSavedCompanyDetails({
        ein: companyDetails?.ein || '',
        address: companyDetails?.address || '',
      })
    },
    onError(err) {
      setErrorMessage('Some error encountered. Please try again!')
    },
  })

  useEffect(() => {
    setCompanyDetails({
      ein: selectedCompanyDetails?.ein || '',
      address: selectedCompanyDetails?.address || '',
    })
    setCancelClicked(false)
  }, [cancelClicked]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChangeEin = (event: any) => {
    const maskedEin = maskEin(event.target.value)
    setCompanyDetails({
      ...companyDetails,
      ein: maskedEin!,
    })
  }

  const [error, setError] = useState({
    ein: '',
    selectedCompanyId: '',
  })

  const resetErrors = () => {
    setError({
      ein: '',
      selectedCompanyId: '',
    })
  }

  const validateForm = () => {
    let errors = {
      ein: '',
      selectedCompanyId: '',
    }

    errors.selectedCompanyId = validateSelectedCompanyId(selectedCompanyId)!
    errors.ein = validateEin(companyDetails?.ein)!
    return errors
  }

  const isValid = (errors: any) => {
    let keys = Object.keys(errors)
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0)
    return count === 0
  }

  const validateEin = (ein: string) => {
    if (ein?.length > 0 && ein?.length < 11) {
      return 'EIN must be 9 digits long'
    }
    return ''
  }

  const validateSelectedCompanyId = (selectedCompanyId: string) => {
    if (selectedCompanyId === '') {
      return 'Select a Company to move forward'
    }
    return ''
  }

  const checkIfFormEdited = () => {
    return !(
      savedCompanyDetails.ein === companyDetails.ein &&
      savedCompanyDetails.address === companyDetails.address
    )
  }

  return (
    <Container className={`${className} p-5`}>
      <Form className="text-start">
        <Row>
          <Form.Group className="mb-3 w-50" controlId="formBasicFirstName">
            <Form.Label>Select Company</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="selectedCompanyId"
              value={selectedCompanyId}
              onChange={handleOnSelectForCompany}
            >
              <option value="" disabled>
                Select Company
              </option>
              {user?.companies?.map((company: any) => (
                <option key={company?.id} value={company?.id}>
                  {company.name}
                </option>
              ))}
            </Form.Select>
            {error.selectedCompanyId && (
              <ValidationError errorMessage={error.selectedCompanyId} />
            )}
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEin">
              <Form.Label>EIN</Form.Label>
              <Form.Control
                name="ein"
                type="text"
                value={companyDetails?.ein}
                onChange={handleOnChangeEin}
                autoComplete="off"
              />
            </Form.Group>
            {error.ein && <ValidationError errorMessage={error.ein} />}
          </Col>

          <Col>
            <Form.Group className="mb-3" controlId="formBasiCompanyAddress">
              <Form.Label>Company Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={companyDetails?.address ? companyDetails?.address : ''}
                onChange={handleOnChangeCompanyDetails}
                autoComplete="off"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4 d-flex d-flex justify-content-center">
          <Col sm={2} className="text-primary me-1">
            <CustomClickableButton
              buttonContent="Save Changes"
              handleOnClick={handleSaveChangesClick}
              disable={false}
            />
          </Col>
          <Col sm={2} className="text-primary">
            <Button
              variant="outline-primary-medium rounded-pill fw-bold"
              className="outline-button"
              onClick={() => setCancelClicked(true)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default styled(CompanyDetailsSettings)`
`
