import { useEffect, useRef, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import { redirectDelayInMilliseconds } from '../data'
import ClaimNoticeComponent from './claim-notice'
import {
  GET_TRADES_CONTRACTS_LIST_TO_GET_JOBS_LIST_FOR_DROPDOWN,
  LIST_TRADE_CONTRACTS_FOR_SELECTED_JOB_FOR_A_TRADE_CONTRACTOR,
} from '../graphql/graphql-queries'
import { getLatestUploadedPolicyCertificationStatus } from '../helper-functions'
import CertificationStatusBadge from './certification-status-badge'
import CustomAlert from './custom-components/custom-alert'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
import Pagination from './Pagination Component/pagination'
import config from '../core/config'
import ShowBadgeForCertificationStatus from './show-badge-for-certification-status'

export interface TradeContractorJobsListProps
  extends React.ComponentProps<any> {
  className?: string
}

interface IJob {
  id: string
  streetAddress: string
}

interface IInjuredPartyArray {
  job: IJob
  tradeContracts: any
}

const TradeContractorJobsList: React.FC<TradeContractorJobsListProps> = ({
  className,
}) => {
  const params = new URLSearchParams(window.location.search)
  let ref = useRef()
  let navigate = useNavigate()
  const [tradeContracts, setTradeContracts] = useState<any>([])
  const [
    filteredTradeContractsWithUniqueJobs,
    setFilteredTradeContractssWithUniqueJobs,
  ] = useState<any>([])
  const [selectedJobLocation, setSelectedJobLocation] = useState('')
  const [contractSignedSuccess, setContractSignedSuccess] = useState(false)
  const [showModel, setShowModel] = useState(false)
  const [showSuccessNotification, setShowSuccessNotification] = useState('')
  const [errorNotification, setErrorNotification] = useState('')
  const [tradeContractsForSelectedJob, setTradeContractsForSelectedJob] =
    useState<any>([])
  const [count, setCount] = useState(1)
  const dataPerPage = config.itemPerPage
  const [pageNumber, setPageNumber] = useState(1)
  const perPage = Math.ceil(count / dataPerPage)
  const offset = 0
  const [injuredPartyArray, setInjuredPartyArray] =
    useState<IInjuredPartyArray>({
      job: {
        id: '',
        streetAddress: '',
      },
      tradeContracts: [],
    })

  const {
    loading: getJobsListForDropdownLoading,
    error: getJobsListForDropdownError,
    refetch: queryRefetch,
  } = useQuery(GET_TRADES_CONTRACTS_LIST_TO_GET_JOBS_LIST_FOR_DROPDOWN, {
    onCompleted: (data) => {
      setTradeContracts(data.listTradeContractsWithJobsByTradeContractorId)
      const jobsIds = data.listTradeContractsWithJobsByTradeContractorId.map(
        (tradeContract: any) => tradeContract.job.id
      )
      const filteredTradeContracts =
        data.listTradeContractsWithJobsByTradeContractorId?.filter(
          (tradeContract: any, index: number) =>
            !jobsIds.includes(tradeContract.job.id, index + 1)
        )
      setFilteredTradeContractssWithUniqueJobs(filteredTradeContracts)
    },
  })

  let paginationDataForTradeContracts = {
    jobId: selectedJobLocation,
    offset: pageNumber === 1 ? offset : (pageNumber - 1) * 25,
    limit: dataPerPage,
  }
  const [
    getTradeContractsForSelectedJob,
    {
      loading: getTradeContractsForSelectedJobLoading,
      error: getTradeContractsForSelectedJobError,
      refetch,
    },
  ] = useLazyQuery(
    LIST_TRADE_CONTRACTS_FOR_SELECTED_JOB_FOR_A_TRADE_CONTRACTOR,
    {
      fetchPolicy: 'cache-and-network',

      onCompleted: (data) => {
        setTradeContractsForSelectedJob(
          data.listTradeContractsByTradeContractorIdAndJobId.TradeContracts
        )
        ref.current =
          data.listTradeContractsByTradeContractorIdAndJobId.TradeContracts

        setCount(data.listTradeContractsByTradeContractorIdAndJobId.count)
      },
    }
  )

  useEffect(() => {
    setInjuredPartyArray({
      ...injuredPartyArray,
      job: {
        ...injuredPartyArray?.job,
        id: selectedJobLocation,
        streetAddress: tradeContractsForSelectedJob[0]?.job?.streetAddress,
      },
      tradeContracts: [...tradeContractsForSelectedJob],
    })
  }, [tradeContractsForSelectedJob])

  const handleCertifyInsuranceClick = (clickedTradeContractId: any) => {
    navigate('/certify-insurance', { state: clickedTradeContractId })
  }

  let setCertifyInsuranceAction = (tradeContract: any) => {
    if (
      getLatestUploadedPolicyCertificationStatus(tradeContract) === 'CERTIFIED'
    ) {
      return 'Update Policy'
    } else if (
      getLatestUploadedPolicyCertificationStatus(tradeContract) ===
      'REVIEW PENDING'
    ) {
      return null
    } else {
      return 'Start'
    }
  }

  const handleJobChooseJobLocationClick = (e: any) => {
    setSelectedJobLocation(e.target.value)
  }

  useEffect(() => {
    if (params.get('signed')) {
      setContractSignedSuccess(true)
      setTimeout(() => {
        navigate('/jobs')
      }, redirectDelayInMilliseconds)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleNoticeClaimClick = () => {
    setShowModel(true)
  }
  const handleCloseClaimNotification = () => {
    setErrorNotification('')
  }
  useEffect(() => {
    if (selectedJobLocation) {
      getTradeContractsForSelectedJob({
        variables: { data: paginationDataForTradeContracts },
      })
      queryRefetch()
    }
  }, [selectedJobLocation, pageNumber])

  if (getJobsListForDropdownLoading || getTradeContractsForSelectedJobLoading)
    return <SpinnerLoading />
  if (getJobsListForDropdownError || getTradeContractsForSelectedJobError)
    return <ErrorFetchingData />
  const arrayOfTradeContractor = ref.current || []
  return (
    <div className={className}>
      {contractSignedSuccess && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setContractSignedSuccess(false)}
            alertType="success"
            alertMessage="You have completed the signing process. Please wait for some time and then check the status."
          />
        </Row>
      )}
      {errorNotification && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setErrorNotification('')}
            alertType="error"
            alertMessage={errorNotification}
          />
        </Row>
      )}
      {showSuccessNotification && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setShowSuccessNotification('')}
            alertType="success"
            alertMessage={showSuccessNotification}
          />
        </Row>
      )}
      <Row className="text-start">
        <Col sm={4}>
          <Form>
            <Form.Group>
              <Form.Select
                aria-label="Default select example"
                name="jobTypeForTrade"
                onChange={handleJobChooseJobLocationClick}
                value={selectedJobLocation}
              >
                <option value="">Select a Job</option>
                {filteredTradeContractsWithUniqueJobs?.map(
                  (tradeContract: any) => (
                    <option
                      key={tradeContract?.job?.id}
                      value={tradeContract.job.id}
                    >
                      {tradeContract?.job?.streetAddress}
                    </option>
                  )
                )}
              </Form.Select>
            </Form.Group>
          </Form>
        </Col>
        {selectedJobLocation && (
          <Col className="text-end">
            <Button
              className="btn btn-primary-medium rounded-pill claim-button fw-bold p-2"
              variant="outline-success"
              onClick={handleNoticeClaimClick}
            >
              Give Notice of Claim
            </Button>
          </Col>
        )}
      </Row>
      {selectedJobLocation && arrayOfTradeContractor?.length > 0 && (
        <Container className="border text-start bg-white my-4">
          <Row className="border-bottom fw-bold py-2">
            <Col sm={2}>Job</Col>
            <Col sm={3}>Company</Col>
            <Col sm={1}>Start Date</Col>
            <Col sm={1}>Claims</Col>
            <Col sm={2}>Status</Col>
            <Col sm={3}>Certify Insurance</Col>
          </Row>
          {!!arrayOfTradeContractor?.length &&
            arrayOfTradeContractor?.map((tradeContractForSelectedJob: any) => {
              return (
                <Row
                  className="border-top d-flex align-items-center py-2"
                  key={tradeContractForSelectedJob?.id}
                >
                  <Col sm={2}>
                    <div>{tradeContractForSelectedJob?.job.streetAddress}</div>
                  </Col>
                  <Col sm={3}>
                    <div>
                      {tradeContractForSelectedJob?.contractedCompany?.name}
                      <div className="text-muted pt-1">
                        <span className="fw-bold">Job Type: </span>
                        {tradeContractForSelectedJob?.jobName}
                      </div>
                    </div>
                    <div>
                      {tradeContractForSelectedJob?.job?.user?.fullName}
                    </div>
                  </Col>
                  <Col sm={1}>
                    <div>
                      {moment(
                        tradeContractForSelectedJob?.job?.startDate
                      ).format('MM/DD/YYYY')}
                    </div>
                  </Col>
                  <Col sm={1}>
                    <span
                      className="span-cursor"
                      onClick={() => {
                        navigate('/claims', {
                          state: {
                            nonProcessingPolicy: '',
                            job: tradeContractForSelectedJob?.job,
                            contractedCompanyId:
                              tradeContractForSelectedJob?.contractedCompany
                                ?.id,
                          },
                        })
                      }}
                    >
                      {tradeContractForSelectedJob?.contractedCompany?.claims
                        ?.length
                        ? tradeContractForSelectedJob?.contractedCompany?.claims
                            ?.length
                        : 0}
                    </span>
                  </Col>
                  <Col>
                    <ShowBadgeForCertificationStatus
                      tradeContract={tradeContractForSelectedJob}
                    />
                  </Col>
                  {setCertifyInsuranceAction(tradeContractForSelectedJob) !==
                    null && (
                    <Col sm={3}>
                      <CertificationStatusBadge
                        certificationStatus={setCertifyInsuranceAction(
                          tradeContractForSelectedJob
                        )}
                        bg="warning-light"
                        className="text-primary2-med"
                        clickCertificationStatusFunction={() =>
                          handleCertifyInsuranceClick(
                            tradeContractForSelectedJob.id
                          )
                        }
                      />
                    </Col>
                  )}
                </Row>
              )
            })}
        </Container>
      )}
      {selectedJobLocation && tradeContractsForSelectedJob?.length > 0 && (
        <div style={{ marginTop: '45px' }}>
          {' '}
          <Pagination
            currentPage={pageNumber}
            setCurrentPage={setPageNumber}
            perPage={perPage}
          />
        </div>
      )}
      {showModel && (
        <ClaimNoticeComponent
          show={showModel}
          className="-notice-popup"
          onHide={() => setShowModel(false)}
          setShowSuccessNotification={setShowSuccessNotification}
          setErrorNotification={setErrorNotification}
          job={injuredPartyArray?.job}
          tradeContracts={injuredPartyArray?.tradeContracts}
          refetch={refetch}
        />
      )}
    </div>
  )
}

export default styled(TradeContractorJobsList)`
  .span-cursor {
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: #0e6c66 !important;
    }
  }

  .claim-button {
    border-radius: 100px !important;
    border: 5px solid #13948Bb !important;
    color: black;
    background-color: #fff;
  }
`
