import { useContext, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import SignInImage from '../assets/images/sign-in.png'
import { AppContext } from '../contexts/app-context'
import authentication from '../core/authentication'
import CustomClickableButton from './custom-components/custom-clickable-button'
import '../../src/index.css'
import { RECOVER_PASSWORD } from '../graphql/graphql-queries'
import { useMutation } from '@apollo/client'
import CustomAlert from './custom-components/custom-alert'
import ValidationError from './validation-error'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'

interface SignInProps extends React.ComponentProps<any> {
  className?: string
}

const SignIn: React.FC<SignInProps> = ({ className }) => {
  const { dispatch, state } = useContext(AppContext)
  const { isAuthenticated } = state
  const [loading, setLoading] = useState(false)
  const [loginUser, setLoginUser] = useState({
    email: '',
    password: '',
  })
  const [showSignInAfterSignUpMessage, setShowSignInAfterSignUpMessage] =
    useState(false)
  const [showSignInIfAlreadySignedUp, setShowSignInIfAlreadySignedUp] =
    useState(false)

  const navigate = useNavigate()
  const location: any = useLocation()
  const [loggedInUser, setLoggedInUser] = useState<any>(null)
  const [reGeneratePassword, setRecoverPassword] = useState(false)
  const [recoverPasswordNotification, setRecoverPasswordNotification] =
    useState('')
  const [errorNotification, setErrorNotification] = useState('')
  let user = window.sessionStorage.getItem('user')
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    ;(async () => {
      if (loggedInUser) {
        if (loggedInUser.role === 'TRADE CONTRACTOR' && !loggedInUser.title) {
          window.location.href = '/trade-contractor-details'
        } else if (location?.state?.from?.pathname) {
          navigate(location?.state.from.pathname)
        } else {
          window.location.href = '/'
        }
      }
    })()
  }, [isAuthenticated, loggedInUser, location, navigate])

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSignInClick = async () => {
    let errorss = validationForm()
    if (!isValid(errorss)) {
      setError(errorss)
      return
    }

    if (loading) {
      return
    }

    try {
      setLoading(true)
      await authentication.login({
        username: loginUser.email,
        password: loginUser.password,
      })
      const data = await authentication.getUserData()

      setLoggedInUser(data)

      dispatch({
        type: 'set',
        value: {
          accessToken: authentication.getAccessToken(),
          isAuthenticated: true,
          ...data,
        },
      })

      state.isAuthenticated = true
      state.accessToken = authentication.getAccessToken()!
      state.ready = true
      setLoading(false)
    } catch (e: any) {
      //  Notification.toast(
      //    'Please check your email and password.',
      //    'Login Failed'
      //  )
      if (e.name === 'Error') {
        setErrorNotification('Invalid email and/or password')
      }
      console.log('USER NAME AND PASSWRD DO NOT MATCH')
      setLoading(false)
    }
  }

  const [recoverYourPassword] = useMutation(RECOVER_PASSWORD, {
    onCompleted: async (data) => {
      //add notification variable
      setRecoverPasswordNotification(
        'Please check your email to recover the password'
      )
    },
    onError(err) {
      setErrorNotification(err.message)
    },
  })

  const recoverPassword = () => {
    setRecoverPassword(true)
  }
  const signInWithPassword = () => {
    setRecoverPassword(false)
  }
  const handlePasswordRecovery = () => {
    recoverYourPassword({
      variables: {
        data: { email: loginUser.email },
      },
    })
  }
  const handleCloseNotification = () => {
    setRecoverPasswordNotification('')
    setErrorNotification('')
  }
  const handleChangeForInput = (e: any) => {
    setLoginUser({
      ...loginUser,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handleSignInClick()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  })

  const [error, setError] = useState({
    email: '',
    password: '',
  })

  const validationForm = () => {
    let errors = {
      email: '',
      password: '',
    }

    errors.email = validateEmail(loginUser.email)!
    errors.password = validatePassword(loginUser.password)!
    return errors
  }

  const isValid = (errors: any) => {
    let keys = Object.keys(errors)
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0)
    return count === 0
  }

  const validateEmail = (email: string) => {
    if (email === '') {
      return 'Email is required'
    }
    return ''
  }

  const validatePassword = (password: string) => {
    if (password === '') {
      return 'Password is required'
    }
    return ''
  }

  useEffect(() => {
    if (location?.state?.signedUp) {
      setShowSignInAfterSignUpMessage(true)
      navigate(location.pathname, {})
    }
    if (location?.state?.alreadySignedUp) {
      setShowSignInIfAlreadySignedUp(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const toggle = (currentStatus: boolean) => {
    if (currentStatus === true) {
      return false
    } else {
      return true
    }
  }

  const [visibility, setVisibility] = useState(true)

  const visibilityStatus = () => {
    return setVisibility(toggle(visibility))
  }
  const inputType = visibility ? 'password' : 'text'

  const icon = visibility ? <EyeIcon /> : <EyeOffIcon />
  return (
    <div className={`${className} signup`}>
      {showSignInAfterSignUpMessage && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setShowSignInAfterSignUpMessage(false)}
            alertType="success"
            alertMessage="Signup is successful. Please login with your password to continue."
          />
        </Row>
      )}
      {showSignInIfAlreadySignedUp && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setShowSignInIfAlreadySignedUp(false)}
            alertType="info"
            alertMessage="You've already registered using this sign-up link. Please sign in to access your account."
          />
        </Row>
      )}
      {recoverPasswordNotification && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleCloseNotification}
            alertType="success"
            alertMessage={recoverPasswordNotification}
          />
        </Row>
      )}
      {errorNotification && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleCloseNotification}
            alertType="error"
            alertMessage={errorNotification}
          />
        </Row>
      )}
      <Row className="h-100">
        <Col sm={5} className=" text-start bg-white">
          <div className="left-panel">
            <p className="fs-4 fw-bold">
              {reGeneratePassword ? 'Recover Your Password' : 'Sign In'}
            </p>
            <Form autoComplete="off">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold">E-mail Address</Form.Label>

                <Form.Control
                  name="email"
                  className="login-input"
                  onChange={handleChangeForInput}
                  placeholder="Enter your email address"
                />
                {error.email && <ValidationError errorMessage={error.email} />}
              </Form.Group>
              {!reGeneratePassword && (
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="fw-bold">Password</Form.Label>
                  <Form.Control
                    className="login-input"
                    type={inputType}
                    name="password"
                    onChange={handleChangeForInput}
                    required
                    placeholder="Enter your password"
                    autoComplete="new-password"
                  />
                  <span className="password-toggle-icon">
                    <i onClick={visibilityStatus}>{icon}</i>
                  </span>
                  {error.password && (
                    <ValidationError errorMessage={error.password} />
                  )}
                </Form.Group>
              )}

              <Form.Label className="ml-1">
                {reGeneratePassword ? (
                  <>
                    Already have an account?{' '}
                    <span
                      className="text-primary recover_password"
                      onClick={signInWithPassword}
                    >
                      Sign In.
                    </span>
                  </>
                ) : (
                  <>
                    Forgot your password? Click here to{' '}
                    <span
                      className="text-primary recover_password"
                      onClick={recoverPassword}
                    >
                      Recover Password.
                    </span>
                  </>
                )}
              </Form.Label>
              {reGeneratePassword ? (
                <CustomClickableButton
                  buttonContent="Submit"
                  handleOnClick={handlePasswordRecovery}
                  disable={false}
                  classNaming="btn-round"
                />
              ) : (
                <CustomClickableButton
                  buttonContent="Sign In"
                  handleOnClick={handleSignInClick}
                  disable={false}
                  classNaming="btn-round"
                />
              )}
            </Form>
          </div>
        </Col>
        <Col sm={7}>
          <div className="right-panel">
            <img
              className="login-image"
              src={SignInImage}
              height={400}
              width={500}
              alt="sign-in"
            />
            <p className="fs-4 fw-bold text-center mt-4">Let's get to work!</p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default styled(SignIn)`
  .login-wrapper {
    min-height: 100vh;
  }

  .login-input {
    border-radius: 20px;
    width: 70%;
    max-width: 500px;
  }
  .left-panel {
    display: flex;
    width: 85%;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    top: 50%;
    transform: translate(19%, -50%);
  }
  .right-panel {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 10%;
    justify-content: center;
    align-items: center;
  }
  .recover_password:hover {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }
  .password-toggle-icon {
    position: absolute;
    top: 57%;
    right: 33%;
    z-index: 1000;
    cursor: pointer;
    width: 10px;
    margin: 0;
    padding: 0;
    scale: 1.5;
  }
  @media only screen and (max-width: 1000px) {
    .password-toggle-icon {
      position: absolute;
      top: 54%;
      right: 35%;
      z-index: 1000;
      cursor: pointer;
      width: 10px;
      margin: 0;
      padding: 0;
      scale: 1.5;
    }
  }
  @media only screen and (min-width: 403px) and (max-width: 575px) {
    .password-toggle-icon {
      position: absolute;
      top: 57%;
      right: 35%;
      z-index: 1000;
      cursor: pointer;
      width: 10px;
      margin: 0;
      padding: 0;
      scale: 1.5;
    }
  }
`
