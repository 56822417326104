import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'

import PolicyVerificationStatusContent from './policy-verification-status-content'

export interface PolicyNotReadProps extends React.ComponentProps<any> {
  tradeContractClickedForPolicyCertificationStatus?: any
  latestUploadedPolicy?: any
}

const PolicyNotRead: React.FC<PolicyNotReadProps> = ({
  tradeContractClickedForPolicyCertificationStatus,
  latestUploadedPolicy,
}) => {
  const [tradeContract, setTradeContract] = useState({
    contractedCompany: null,
    job: null,
    jobName: '',
    latestUploadedPolicyData: null,
  })

  useEffect(() => {
    if (
      tradeContractClickedForPolicyCertificationStatus &&
      latestUploadedPolicy
    ) {
      setTradeContract({
        contractedCompany:
          tradeContractClickedForPolicyCertificationStatus?.contractedCompany,
        job: tradeContractClickedForPolicyCertificationStatus?.job,
        jobName: tradeContractClickedForPolicyCertificationStatus?.jobName,
        latestUploadedPolicyData: latestUploadedPolicy,
      })
    }
  }, [tradeContractClickedForPolicyCertificationStatus, latestUploadedPolicy])

  return (
    <Container className="text-start m-4">
      <PolicyVerificationStatusContent
        tradeContractClickedForPolicyCertificationStatus={
          tradeContractClickedForPolicyCertificationStatus
        }
        latestUploadedPolicy={latestUploadedPolicy}
        alertType="danger"
        verificationStatus="notRead"
      />
    </Container>
  )
}

export default PolicyNotRead
