import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import styled from 'styled-components'

import MultiStepProgressBar from './custom-components/multi-step-progress-bar'
import UploadPolicy from './upload-policy'
import ReviewRequirements from './review-requirements'
import PolicyInReview from './policy-in-review'
import SignAddendum from './sign-addendum'
import AcceptTradeContract from './accept-trade-contract'
import {
  GET_COMPANY_FEATURE_FLAGS,
  GET_LATEST_SIGNED_TRADE_CONTRACT,
  GET_TRADE_CONTRACT_BY_TRADE_CONTRACT_ID,
} from '../graphql/graphql-queries'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'

export interface CertifyInsuranceProps extends React.ComponentProps<any> {
  className: string
}

const CertifyInsurance: React.FC<CertifyInsuranceProps> = ({ className }) => {
  const params = new URLSearchParams(window.location.search)

  const { state: tradeContractIdForCertifyInsurance }: { state: any } =
    useLocation()
  const [certifyInsuranceSteps, setCertifyInsuranceSteps] = useState([
    'Review Requirements',
    //uncomment when need for Accept/Sign Addendum
    // 'Accept Trade Contract',
    // 'Sign Addendum',
    'Upload Policy',
    'Certify',
  ])
  const [certifyInsuranceStepIndexes, setCertifyInsuranceStepIndexes] =
    useState([
      0, 1, 2,
      //uncomment when need for Accept/Sign Addendum
      // 3
    ])

  const [certifyInsuranceCurrentPage, setCertifyInsuranceCurrentPage] =
    useState('Review Requirements')

  const [
    certifyInsuranceCurrentStepIndex,
    setCertifyInsuranceCurrentStepIndex,
  ] = useState(certifyInsuranceStepIndexes[0])
  const [companyFeatureFlags, setCompanyFeatureFlags] = useState({})
  const [
    tradeContractForCertifyInsurance,
    setTradeContractForCertifyInsurance,
  ] = useState<any>(null)

  const [
    hasTradeContractorSignedContractAndConfirmationAwaited,
    setHasTradeContractorSignedContractAndConfirmationAwaited,
  ] = useState(false)

  const [
    getTradeContractByTradeContractId,
    {
      loading: getTradeContractByTradeContractIdLoading,
      error: getTradeContractByTradeContractIdError,
    },
  ] = useLazyQuery(GET_TRADE_CONTRACT_BY_TRADE_CONTRACT_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setTradeContractForCertifyInsurance(
        data.getTradeContractByTradeContractId
      )
    },
  })

  const [getLatesSignedTradeContract] = useLazyQuery(
    GET_LATEST_SIGNED_TRADE_CONTRACT,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setTradeContractForCertifyInsurance(data.getLatestSignedTradeContract)
        setCertifyInsuranceCurrentStepIndex(3)
        setCertifyInsuranceCurrentPage('Upload Policy')
      },
    }
  )
  const [getCompanyFeatureFlag] = useLazyQuery(GET_COMPANY_FEATURE_FLAGS, {
    fetchPolicy: 'cache-and-network',

    onCompleted: (data) => {
      setCompanyFeatureFlags(data.getCompanyFeatureFlags)
      if (
        data?.getCompanyFeatureFlags &&
        data?.getCompanyFeatureFlags?.isDocusignEnabled
      ) {
        setCertifyInsuranceSteps([
          'Review Requirements',
          //uncomment when need for Accept/Sign Addendum
          // 'Accept Trade Contract',
          'Upload Policy',
          'Certify',
        ])
        setCertifyInsuranceStepIndexes([0, 1, 3])
      }
    },
  })
  useEffect(() => {
    ;(async () => {
      if (params.get('signed')) {
        setHasTradeContractorSignedContractAndConfirmationAwaited(true)
        getLatesSignedTradeContract()
      } else if (params.get('upload-new-policy')) {
        await getTradeContractByTradeContractId({
          variables: { tradeContractId: tradeContractIdForCertifyInsurance },
        })
        setCertifyInsuranceCurrentStepIndex(3)
        setCertifyInsuranceCurrentPage('Upload Policy')
      } else {
        getTradeContractByTradeContractId({
          variables: { tradeContractId: tradeContractIdForCertifyInsurance },
        })
      }
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (tradeContractForCertifyInsurance) {
      ;(async () => {
        await getCompanyFeatureFlag({
          variables: {
            companyId:
              tradeContractForCertifyInsurance?.job?.nonProcessingPolicy
                ?.company?.id,
          },
        })
      })()
    }
  }, [tradeContractForCertifyInsurance])

  if (getTradeContractByTradeContractIdLoading) return <SpinnerLoading />
  if (getTradeContractByTradeContractIdError) return <ErrorFetchingData />

  return (
    <div className={`${className} mt-5`}>
      <Container className="certify-insurance-container border-top">
        <Row>
          <Col
            sm={2}
            className="min-vh-100 py-5 d-flex justify-content-center progress-bar-wrapper "
          >
            <MultiStepProgressBar
              progressBarHeading="Sign and Certify"
              steps={certifyInsuranceSteps}
              currentPage={certifyInsuranceCurrentPage}
              stepIndex={certifyInsuranceStepIndexes}
              currentStepIndex={certifyInsuranceCurrentStepIndex}
            />
          </Col>
          <Col className="bg-white">
            {certifyInsuranceCurrentPage === 'Review Requirements' ? (
              <ReviewRequirements
                setCurrentStep={setCertifyInsuranceCurrentPage}
                currentStepIndex={certifyInsuranceCurrentStepIndex}
                setCurrentStepIndex={setCertifyInsuranceCurrentStepIndex}
                tradeContractForCertifyInsurance={
                  tradeContractForCertifyInsurance
                }
              />
            ) : certifyInsuranceCurrentPage === 'Accept Trade Contract' ? (
              <AcceptTradeContract
                setCurrentStep={setCertifyInsuranceCurrentPage}
                currentStepIndex={certifyInsuranceCurrentStepIndex}
                setCurrentStepIndex={setCertifyInsuranceCurrentStepIndex}
                tradeContractForCertifyInsurance={
                  tradeContractForCertifyInsurance
                }
                companyFeatureFlags={companyFeatureFlags}
              />
            ) : certifyInsuranceCurrentPage === 'Sign Addendum' ? (
              <SignAddendum
                setCurrentStep={setCertifyInsuranceCurrentPage}
                currentStepIndex={certifyInsuranceCurrentStepIndex}
                setCurrentStepIndex={setCertifyInsuranceCurrentStepIndex}
                tradeContractForCertifyInsurance={
                  tradeContractForCertifyInsurance
                }
                hasTradeContractorSignedContractAndConfirmationAwaited={
                  hasTradeContractorSignedContractAndConfirmationAwaited
                }
              />
            ) : certifyInsuranceCurrentPage === 'Upload Policy' ? (
              <UploadPolicy
                setCurrentStep={setCertifyInsuranceCurrentPage}
                currentStepIndex={certifyInsuranceCurrentStepIndex}
                setCurrentStepIndex={setCertifyInsuranceCurrentStepIndex}
                tradeContractForCertifyInsurance={
                  tradeContractForCertifyInsurance
                }
                companyFeatureFlags={companyFeatureFlags}
              />
            ) : certifyInsuranceCurrentPage === 'Certify' ? (
              <PolicyInReview
                currentStepIndex={certifyInsuranceCurrentStepIndex}
                setCurrentStepIndex={setCertifyInsuranceCurrentStepIndex}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default styled(CertifyInsurance)`
  .text-underline-dashed {
    text-decoration: underline dashed #13948b;
    text-underline-offset: 4px;
  }

  .progress-bar-wrapper {
    width: 280px;
    background: #f7f5f4;
  }

  .certify-insurance-container {
    margin-top: -42px !important;
  }
`
