import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { FaRegBell, FaUserCircle } from 'react-icons/fa'
import styled from 'styled-components'
import { getUniqueListBy } from '../helper-functions'

import {
  GET_FILE_UPLOAD_BY_ID,
  NOTIFICATIONS_LIST,
  UPDATE_NOTIFICATIONS,
} from '../graphql/graphql-queries'
import authentication from '../core/authentication'
import { IDatum } from '../interface'

export interface NavigationBarProps extends React.ComponentProps<any> {
  className?: string
}

const NavigationBar: React.FC<NavigationBarProps> = ({ className }) => {
  let navigate = useNavigate()

  const userData = authentication.getUserData()

  const [policyNotReadNotifications, setPolicyNotReadNotifications] = useState<
    any[]
  >([])

  const [
    masterFormNotLabelledNotifications,
    setMasterFormNotLabelledNotifications,
  ] = useState<any[]>([])

  const { data, refetch } = useQuery(NOTIFICATIONS_LIST, {
    onCompleted: (data) => {
      let filteredPolicyNotReadNotifications =
        data?.listUnreadNotifications?.filter(
          (notification: any) =>
            notification?.notificationType === 'Policy Upload'
        )
      let uniquePolicyNotReadNotificationsList: IDatum[] = getUniqueListBy(
        filteredPolicyNotReadNotifications,
        'fileUploadId'
      )
      let filteredMasterFormNotLabelledNotifications =
        data?.listUnreadNotifications?.filter(
          (notification: any) =>
            notification?.notificationType === 'Master Form'
        )
      setPolicyNotReadNotifications(uniquePolicyNotReadNotificationsList)
      setMasterFormNotLabelledNotifications(
        filteredMasterFormNotLabelledNotifications
      )
    },
  })

  const [getFileUploadById] = useLazyQuery(GET_FILE_UPLOAD_BY_ID, {
    fetchPolicy: 'cache-and-network',

    onCompleted: (data) => {
      navigate('/policy-details-form', {
        state: {
          clickedFile: data?.getFileUploadById,
        },
      })
    },
  })

  const [updateNotifications] = useMutation(UPDATE_NOTIFICATIONS, {
    refetchQueries: [{ query: NOTIFICATIONS_LIST }],
    onError: (err) => {
      console.error('Error: ', err)
    },
  })

  const markIsReadTrueAndRedirect = async (
    notificationType: string,
    redirectPath: string,
    id?: string
  ) => {
    let queryVariables
    if (notificationType === 'Policy Upload') {
      queryVariables = {
        notificationType,
        id,
      }
    } else {
      queryVariables = {
        notificationType,
      }
    }

    await updateNotifications({
      variables: queryVariables,
    })
    if (notificationType === 'Policy Upload') {
      getFileUploadById({
        variables: { id },
      })
      return
    }
    navigate(redirectPath)
  }

  const handleOnClickLogout = () => {
    authentication.clear()
    window.location.href = '/login'
  }

  const handleOnClickSettings = () => {
    window.location.href = '/settings'
  }
  const pathName = window.location.pathname

  useEffect(() => {
    if (pathName === '/' && authentication.isSuperAdmin()) {
      navigate('/start-labelling')
    }
    if (
      pathName === '/' &&
      (authentication.isClient() || authentication.isTradeContractor())
    ) {
      navigate('/jobs')
    }
  }, [pathName])

  return (
    <div className={className}>
      <Navbar bg="light" variant="light">
        <div className="navbar-wrapper">
          <Navbar.Brand className="text-primary fw-bold" href="/">
            <img
              src="/resolvedrisk_logo.png"
              width="40"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            {'    '}
            Resolved Risk
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {authentication.isSuperAdmin() && (
              <Nav className="me-auto">
                <NavDropdown
                  title={
                    <span
                      className={
                        pathName?.startsWith('/document') ? 'active-link' : ''
                      }
                    >
                      Documents
                    </span>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item
                    href="/document/list"
                    className={
                      pathName === '/document/list' ? 'active-link' : ''
                    }
                  >
                    List Documents
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  href="/start-labelling"
                  className={
                    pathName?.startsWith('/start-labelling')
                      ? 'active-link'
                      : ''
                  }
                >
                  Master Data
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>

          <Navbar.Collapse className="justify-content-end scrollable">
            <Nav className="position-relative" id="notification-nav-dropdown">
              {authentication.isSuperAdmin() &&
                !!data?.listUnreadNotifications?.length && (
                  <span
                    id="new-notification-alert"
                    className="bg-danger border border-light rounded-circle"
                  >
                    <span className="visually-hidden">New alerts</span>
                  </span>
                )}

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {authentication.isSuperAdmin() && (
                  <Nav className="me-auto">
                    <NavDropdown
                      title={
                        <span
                          className={
                            pathName?.startsWith('/file-upload')
                              ? 'active-link'
                              : ''
                          }
                        >
                          File Uploads
                        </span>
                      }
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        href="/file-upload"
                        className={
                          pathName === '/file-upload' ? 'active-link' : ''
                        }
                      >
                        Upload File
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                )}
              </Navbar.Collapse>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {authentication.isSuperAdmin() && (
                  <Nav className="me-auto">
                    <NavDropdown
                      title={
                        <span
                          className={
                            pathName?.startsWith('/client') ? 'active-link' : ''
                          }
                        >
                          Clients
                        </span>
                      }
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        href="/client-listing"
                        className={
                          pathName === '/client-listing' ? 'active-link' : ''
                        }
                      >
                        Client Listing
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                )}
              </Navbar.Collapse>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {authentication.isSuperAdmin() && (
                  <Nav className="me-auto">
                    <NavDropdown
                      title={
                        <span
                          className={
                            pathName?.startsWith('/job-type')
                              ? 'active-link'
                              : ''
                          }
                        >
                          Job-Type
                        </span>
                      }
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        href="/job-type-listing"
                        className={
                          pathName === '/job-type-listing' ? 'active-link' : ''
                        }
                      >
                        Job Type
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                )}
              </Navbar.Collapse>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {authentication.isSuperAdmin() && (
                  <Nav className="me-auto">
                    <NavDropdown
                      title={
                        <span
                          className={
                            [
                              '/templates',
                              '/create-template',
                              '/edit-template',
                            ].includes(pathName)
                              ? 'active-link'
                              : ''
                          }
                        >
                          Templates
                        </span>
                      }
                      id="basic-nav-dropdown"
                      className="navbar-items"
                    >
                      <NavDropdown.Item
                        href="/templates"
                        className={
                          pathName === '/templates' ? 'active-link' : ''
                        }
                      >
                        Templates Listing
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                )}
              </Navbar.Collapse>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {authentication.isSuperAdmin() || authentication.isClient() ? (
                  <Nav className="me-auto">
                    <NavDropdown
                      title={
                        <span
                          className={
                            ['/jobs', '/create-job', '/update-job'].includes(
                              pathName
                            )
                              ? 'active-link'
                              : ''
                          }
                        >
                          Jobs
                        </span>
                      }
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        href="/jobs"
                        className={pathName === '/jobs' ? 'active-link' : ''}
                      >
                        Jobs Listing
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                ) : authentication.isTradeContractor() ? (
                  <Nav.Link href="/jobs">My Jobs </Nav.Link>
                ) : null}
              </Navbar.Collapse>

              {authentication.isSuperAdmin() && (
                <NavDropdown
                  title={<FaRegBell size={20} />}
                  className="justify-content-start scrollable"
                  pre-scrollable="true"
                >
                  {!!masterFormNotLabelledNotifications.length && (
                    <NavDropdown.Item
                      onClick={() =>
                        markIsReadTrueAndRedirect(
                          'Master Form',
                          '/start-labelling'
                        )
                      }
                    >
                      A new Master Form needs your review.
                    </NavDropdown.Item>
                  )}
                  {policyNotReadNotifications?.map(
                    (policyNotReadNotification: any) => (
                      <NavDropdown.Item
                        key={policyNotReadNotification?.id}
                        onClick={() =>
                          markIsReadTrueAndRedirect(
                            'Policy Upload',
                            '/policy-details-form',
                            policyNotReadNotification?.fileUploadId
                          )
                        }
                      >
                        {policyNotReadNotification?.notificationText}
                      </NavDropdown.Item>
                    )
                  )}
                  {!data?.listUnreadNotifications?.length && (
                    <NavDropdown.Item>No new notifications</NavDropdown.Item>
                  )}
                </NavDropdown>
              )}

              {(authentication.isSuperAdmin() ||
                authentication.isClient() ||
                authentication.isTradeContractor() || authentication.isBroker()) && (
                <NavDropdown
                  title={
                    <span
                      className={
                        pathName?.startsWith('/settings') ? 'active-link' : ''
                      }
                    >
                      <FaUserCircle size={20} />
                    </span>
                  }
                  className="justify-content-start scrollable"
                  pre-scrollable="true"
                >
                  <NavDropdown.Item
                    href="/settings"
                    disabled
                    className="fw-bold"
                  >
                    {userData?.fullName}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href="/settings"
                    onClick={handleOnClickSettings}
                    className={pathName === '/settings' ? 'active-link' : ''}
                  >
                    Settings
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/login" onClick={handleOnClickLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  )
}

export default styled(NavigationBar)`
  .dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
    left: auto;
    right: 0;
  }

  .bg-danger {
    --bs-bg-opacity: 0.5;
  }
  .navbar {
    padding-top: 0px;
    padding-bottom: 0;
    margin-bottom: 20px;
  }
  #new-notification-alert {
    position: absolute;
    padding: 5px;
    margin-top: 2px;
    margin-left: 170px;
    left: 50%;
  }

  .navbar-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: inherit;
    justify-content: space-between;
    width: 100%;
    padding-right: 2%;
    padding-left: 2%;
    margin-right: auto;
    margin-left: auto;
    background-color: #ffffff;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #000000;

    &:hover {
      color: #13948b;
    }
  }

  .active-link {
    color: #13948b !important;
    font-weight: bold;
  }
`
