import { Table } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { FORM_DETAILS } from '../../graphql/graphql-queries'

export interface FormDetailsProps extends React.ComponentProps<any> {
  docId: string | undefined
}

const FormDetails: React.FC<FormDetailsProps> = ({ docId }) => {
  const {
    data: formData,
    loading,
    error,
  } = useQuery(FORM_DETAILS, { variables: { docId } })

  return (
    <>
      {
        <Table hover className="table-light">
          <thead className="table-dark text-start">
            <tr>
              <th scope="col" className="col-sm-4">
                Form Detail
              </th>
              <th scope="col" className="col-sm-3">
                Form Type
              </th>
              <th scope="col" className="col-sm-1">
                Page Number
              </th>
              <th scope="col">Scheduled Text</th>
            </tr>
          </thead>
          <tbody className="text-start">
            {loading && (
              <td align="center" colSpan={6} className="p-2">
                Loading...
              </td>
            )}
            {error && (
              <td align="center" colSpan={6} className="p-2">
                Oops! Something went wrong while fetching form details. Please
                refresh the page and try again.
              </td>
            )}
            {!loading &&
              !error &&
              !!formData?.listForms?.length &&
              formData?.listForms.map((form: any, index: number) => (
                <tr key={form.id}>
                  {form?.type !== 'OTHERS' && (
                    <>
                      <td className="align-middle">
                        <div>{form?.formTitle?.toUpperCase()}</div>
                        <div className="small">
                          {form?.formNumber?.toUpperCase()}
                        </div>
                      </td>
                      <td className="align-middle">
                        {form?.type?.toUpperCase()}
                      </td>
                      <td className="align-middle">{form.pageNumber}</td>
                      <td className="align-middle" style={{ width: '30%' }}>
                        {form.scheduledTexts.length
                          ? form.scheduledTexts.map((scheduledText: any) => (
                              <div key={scheduledText.id}>
                                <b>{scheduledText.textName}: </b>
                                {scheduledText.textValue}
                              </div>
                            ))
                          : null}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            {!loading && !error && !formData?.listForms?.length && (
              <td align="center" colSpan={6} className="p-2">
                No data found
              </td>
            )}
          </tbody>
        </Table>
      }
    </>
  )
}

export default FormDetails
