import { Container, Row, Col } from 'react-bootstrap'
import CustomButton from './custom-components/custom-button';
import RequirementsCard from './requirements-card';

export interface TemplatesProps extends React.ComponentProps<any> {
  className?: string
}

const Templates: React.FC<TemplatesProps> = () => {

  return (
    <Container>
      <Row className="my-4">
        <Col className="text-primary text-start"><h4>Templates</h4></Col>
        <Col className="text-primary text-end"> <CustomButton buttonContent="+ Create Template" onClickPath='/create-template'/></Col>
      </Row>
      <RequirementsCard />
    </Container>
  )
}

export default Templates;
