import { Container, Form } from 'react-bootstrap'
import styled from 'styled-components'

export interface CustomOptionBadgeProps extends React.ComponentProps<any> {
    className?: string
    handleOnAdditionalRequirements:(e: any) => void
    id: string
    name: string []
    additionalRequirement: {
        name: string;
        value: string;
        key: string;
    }
    keyValue: string
    value: any 
}

const CustomOptionBadge: React.FC<CustomOptionBadgeProps> = ({ className, handleOnAdditionalRequirements, additionalRequirement, keyValue, value}) => {

    return (
        <Container className={className}>
            <Form>
                <Form.Check 
                    type="switch"
                    name={`${additionalRequirement.key}`}
                    id={`${additionalRequirement.name}${keyValue}`}
                    label={value?"Yes":"No"}
                    defaultChecked = {value}
                    onChange= {handleOnAdditionalRequirements}
                />
            </Form>
        </Container>
    )
}

export default styled(CustomOptionBadge)`
    .btn-option {
       height: 25px     
    }
    .search-icon {
        background: white;
        border-right: none;
        // position: absolute;
        // right: 5px;
        // top: 5px;
        z-index: 9999; /*this will keep the icon appearing all time, even when on input::focus*/
    }
`;


