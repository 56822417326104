import { Container, Row, Col } from 'react-bootstrap'
import MultiStepProgressBar from './custom-components/multi-step-progress-bar'
import { useEffect, useState } from 'react'
import NamesAndLimits from './names-and-limits'
import { useLocation } from 'react-router-dom'
import ScopeAndDurationContainer from './scope-and-duration-container'

import AdditionalRequirement from './additional-requrements'
import ReviewAndPublishTemplate from './review-and-publish-template'
import { ITemplate } from '../interface'

export interface CreateTemplateProps extends React.ComponentProps<any> {}

const CreateTemplate: React.FC<CreateTemplateProps> = () => {
  const location: any = useLocation()

  let [template, setTemplate] = useState<ITemplate>({
    templateName: '',
    eachOccurrenceLimit: 1000000,
    aggregateLimit: 1000000,
    personalAdvertisingLimit: 50000,
    projectCompletedOperationLimit: 1000000,
    deductible: 10000,
    scope: 'Arising out of the work',
    durationType: 'Ongoing operations',
    duration: 1,
    contractualLiability: false,
    priorityOfCoverage: false,
    noticeOfChange: false,
    waiverOfSubrogation: false,
    reviewerEmailAddress: '',
    reviewerMailingAddress: '',
    isPublished: false,
    id: '',
    aggregateType: 'Per Policy',
    containsSpecialRequirements: false,
    containsSpecialProhibitions: false,
    templateLabels: [],
    templateLabelsForSpecialRequirements: [],
    templateLabelsForSpecialProhibitions: [],
  })

  const obj = { ...template }

  let stepIndex = [0, 1, 2, 3]

  let [currentPage, setCurrentPage] = useState('Name and Limits')

  let [currentStepIndex, setCurrentStepIndex] = useState(stepIndex[0])
  const [duplicateTemplate, setDuplicateTemplate] = useState(obj)
  const [edit, setEdit] = useState(false)
  let steps = [
    'Name and Limits',
    'Scope and Duration',
    'Additional Requirements',
    'Review and Publish',
  ]

  useEffect(() => {
    if (location.state) {
      let editTemplate = location?.state?.template
      delete editTemplate.updatedAt
      delete editTemplate.createdAt
      delete editTemplate.__typename
      setTemplate(editTemplate)
      setDuplicateTemplate({
        ...editTemplate,
        templateLabelsForSpecialRequirements: editTemplate?.templateLabels
          ?.map((label: any) => {
            if (label?.isSpecialRequirement) {
              return label?.labellingPath
            }
          })
          ?.filter((e: string) => {
            return e !== undefined
          }),
        templateLabelsForSpecialProhibitions: editTemplate?.templateLabels
          ?.map((label: any) => {
            if (label?.isSpecialProhibition) {
              return label?.labellingPath
            }
          })
          ?.filter((e: string) => {
            return e !== undefined
          }),
      })
      setEdit(true)
    }
  }, [location.state])

  let [isDataSaved, setIsDataSaved] = useState(false)
  return (
    <Container>
      <Row>
        <Col
          sm={3}
          className="min-vh-100 py-5 d-flex justify-content-center progress-bar-wrapper text-start"
        >
          <MultiStepProgressBar
            progressBarHeading="Create Template"
            steps={steps}
            currentPage={currentPage}
            stepIndex={stepIndex}
            currentStepIndex={currentStepIndex}
            isDataSaved={isDataSaved}
          />
        </Col>
        <Col sm={9} className="m-0 text-start bg-white">
          <Container className="ms-0">
            {currentPage === 'Name and Limits' ? (
              <NamesAndLimits
                setCurrentStep={setCurrentPage}
                currentStepIndex={currentStepIndex}
                setCurrentStepIndex={setCurrentStepIndex}
                duplicateTemplate={duplicateTemplate}
                setDuplicateTemplate={setDuplicateTemplate}
              />
            ) : currentPage === 'Scope and Duration' ? (
              <ScopeAndDurationContainer
                setCurrentStep={setCurrentPage}
                currentStepIndex={currentStepIndex}
                setCurrentStepIndex={setCurrentStepIndex}
                duplicateTemplate={duplicateTemplate}
                setDuplicateTemplate={setDuplicateTemplate}
              />
            ) : currentPage === 'Additional Requirements' ? (
              <AdditionalRequirement
                setCurrentStep={setCurrentPage}
                currentStepIndex={currentStepIndex}
                setCurrentStepIndex={setCurrentStepIndex}
                duplicateTemplate={duplicateTemplate}
                setDuplicateTemplate={setDuplicateTemplate}
              />
            ) : (
              <ReviewAndPublishTemplate
                setCurrentStep={setCurrentPage}
                currentStepIndex={currentStepIndex}
                setCurrentStepIndex={setCurrentStepIndex}
                setTemplate={setTemplate}
                setIsDataSaved={setIsDataSaved}
                edit={edit}
                duplicateTemplate={duplicateTemplate}
                setDuplicateTemplate={setDuplicateTemplate}
              />
            )}
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default CreateTemplate
