import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import styled from 'styled-components'
import authentication from '../../core/authentication'
import { appConfig } from '../../core'
import axios from 'axios'

export interface JobTypeProhibitionsCheckboxTreeProps
  extends React.ComponentProps<any> {
  className: string
  state: any
  setState: React.Dispatch<any>
}
const JobTypeProhibitionsCheckboxTree: React.FC<
  JobTypeProhibitionsCheckboxTreeProps
> = ({ className, state, setState }) => {

  const [labellerHierarchyJson, setLabellerHierarchyJson] = useState<any[]>([])

  const handledOnCheck = (checked: any) => {
    setState({ ...state, checked })
  }

  const handledOnClick = (clicked: any) => {
    setState({ ...state, clicked })
  }

  const handledOnExpand = (expanded: any) => {
    setState({ ...state, expanded })
  }

  useEffect(() => {
    const token = authentication.getAccessToken()
    axios
      .get(`${appConfig.apiUrl}/rest/labels-for-checkbox-tree`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLabellerHierarchyJson(response.data)
      })
      .catch((err) => console.error('Error: ', err))
  }, [])

  return (
    <div className={className}>
      <Container>
        <Row>
          <Col sm={1}></Col>
        </Row>
        <Row>
          <Col sm={1}></Col>
          <Col sm={9}>
            <CheckboxTree
              nodes={labellerHierarchyJson}
              checked={state.checked}
              expanded={state.expanded}
              onCheck={(checked) => handledOnCheck(checked)}
              onExpand={(expanded) => handledOnExpand(expanded)}
              onClick={(targetNode) => handledOnClick(targetNode)}
              showNodeIcon={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default styled(JobTypeProhibitionsCheckboxTree)``
