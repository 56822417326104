export const formTypesForDropdown = [
  { id: 1, type: 'ENDORSEMENT' },
  { id: 2, type: 'COVERAGE_FORM' },
  { id: 3, type: 'COMMON POLICY CONDITIONS' },
]

export const scheduledTextNamesForDropdown = [
  {
    id: 1,
    textName: 'COVERAGE',
  },
  {
    id: 2,
    textName: 'SUBSEQUENT TO',
  },
  {
    id: 3,
    textName: 'EFFECTIVE DATE',
  },
  {
    id: 4,
    textName: 'VACANCY WARRANTY',
  },
  {
    id: 5,
    textName: 'PROJECT COVERAGE',
  },
  {
    id: 6,
    textName: 'EXTENDED TIME PERIOD',
  },
  {
    id: 7,
    textName: 'RETAINED_LIMIT_EACH_OCCURANCE',
  },
  {
    id: 8,
    textName: 'MINIMUM_PREMIUM',
  },
  {
    id: 9,
    textName: 'ADDITIONAL INSURED DETAILS',
  },
  {
    id: 10,
    textName: 'SCHEDULE',
  },
  {
    id: 11,
    textName: 'ADDITIONAL INSURED NAME',
  },
  {
    id: 12,
    textName: 'WITHIN DAYS OF INCEPTION',
  },
  {
    id: 13,
    textName: 'OTHER THAN',
  },
  {
    id: 14,
    textName: 'EFFECTIVE',
  },
  {
    id: 15,
    textName: 'DELETED ENDORSEMENT',
  },
  {
    id: 16,
    textName: 'WORK EXCLUSION',
  },
  {
    id: 17,
    textName: 'MODIFIED ENDORSEMENT',
  },
  {
    id: 18,
    textName: 'RETAINED_LIMIT_AGGREGATE',
  },
  {
    id: 19,
    textName: 'PREMIUM',
  },
  {
    id: 20,
    textName: 'HIRING WARRANTY',
  },
  {
    id: 21,
    textName: 'APPLICATION DATE RANGE',
  },
  {
    id: 22,
    textName: 'CARRIERS',
  },
  {
    id: 23,
    textName: 'INSURED ADDRESS AMENDMENT',
  },
  {
    id: 24,
    textName: 'SCHEDULED RAILROAD',
  },
]

export const labelNotMatched = 'The label or labels do not match'

export const redirectDelayInMilliseconds = 2000

export const regexEmail = "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"