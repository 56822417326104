import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import styled from 'styled-components'
import { UPDATE_USER_DETAILS } from '../../graphql/graphql-queries'
import CustomClickableButton from '../custom-components/custom-clickable-button'

export interface UserProfileSettingsProps extends React.ComponentProps<any> {
  className?: string
  user: any
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  cancelClicked: boolean
  setCancelClicked: React.Dispatch<React.SetStateAction<boolean>>
}

const UserProfileSettings: React.FC<UserProfileSettingsProps> = ({
  className,
  user,
  setSuccessMessage,
  setErrorMessage,
  cancelClicked,
  setCancelClicked,
}) => {
  let initialUserDetails = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
  }
  const [userDetails, setUserDetails] = useState<any>(initialUserDetails)
  const [savedUserDetails, setSavedUserDetails] = useState(initialUserDetails)

  const handleOnChangeUserProfileSettings = (e: any) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleSaveChangesClick = (e: any) => {
    if (checkIfFormEdited()) {
      updateUserDetails({
        variables: {
          userId: user?.id,
          data: userDetails,
        },
      })
    } else {
      setErrorMessage('There are no changes detected in the user profile.')
    }
  }

  const [updateUserDetails] = useMutation(UPDATE_USER_DETAILS, {
    onCompleted: (data) => {
      setSuccessMessage('User details successfully updated')
    },
    onError(err) {
      setErrorMessage('Some error encountered. Please try again!')
    },
  })

  useEffect(() => {
    setUserDetails({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
    })
    setSavedUserDetails({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
    })
    setCancelClicked(false)
  }, [user, cancelClicked]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkIfFormEdited = () => {
    return !(
      savedUserDetails.firstName === userDetails.firstName &&
      savedUserDetails.lastName === userDetails.lastName &&
      savedUserDetails.phoneNumber === userDetails.phoneNumber
    )
  }

  return (
    <Container className={`${className} p-5`}>
      <Form className="text-start">
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={userDetails?.firstName ? userDetails?.firstName : ''}
                onChange={handleOnChangeUserProfileSettings}
                autoComplete="off"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={userDetails?.lastName ? userDetails?.lastName : ''}
                onChange={handleOnChangeUserProfileSettings}
                autoComplete="off"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                readOnly
                disabled
                value={user?.email ? user?.email : ''}
                onChange={handleOnChangeUserProfileSettings}
                autoComplete="off"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicContactNumber">
              <Col>
                <Form.Label>Contact Number</Form.Label>
              </Col>
              <Col>
                <InputMask
                  className="contact-input"
                  type="text"
                  mask="(999) 999-9999"
                  name="phoneNumber"
                  onChange={handleOnChangeUserProfileSettings}
                  value={
                    userDetails?.phoneNumber ? userDetails?.phoneNumber : ''
                  }
                  autoComplete="off"
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-4 d-flex d-flex justify-content-center">
          <Col sm={2} className="text-primary me-1">
            <CustomClickableButton
              buttonContent="Save Changes"
              handleOnClick={handleSaveChangesClick}
              disable={false}
            />
          </Col>
          <Col sm={2} className="text-primary">
            <Button
              variant="outline-primary-medium rounded-pill fw-bold"
              className="outline-button"
              onClick={() => setCancelClicked(true)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default styled(UserProfileSettings)`
  div {
    position: relative;
    overflow: hidden;
  }

  .upload-file-input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .contact-input {
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 470px;
    padding: 5px;
  }
`
