import { Card, Row, Col } from 'react-bootstrap'
import ScrollableList from './scrollable-list'
import _ from 'lodash'
import { getLabellingPathsForSpecialRequirementsOrProhibitions } from '../helper-functions'
import { ITemplate, ITemplateLabel } from '../interface'

interface ITemplateDetailsProps extends React.ComponentProps<any> {
  template: ITemplate
  pageType?: string
}

const LimitsForTemplateCard: React.FC<ITemplateDetailsProps> = ({
  template,
}) => {
  return (
    <>
      <div className="fs-5 fw-bold mb-2">Limits</div>
      <Row>
        <Col sm={5}>
          <Row>
            <Col className="mb-1">Each Occurence</Col>
            <Col className="fw-bold text-start">
              ${template?.eachOccurrenceLimit.toLocaleString()}
            </Col>
          </Row>
          <Row>
            <Col className="mb-1">{`General Aggregate (${template?.aggregateType})`}</Col>
            <Col className="fw-bold text-start">
              ${template?.aggregateLimit.toLocaleString()}
            </Col>
          </Row>
          <Row>
            <Col className="mb-1">Deductible/Retention</Col>
            <Col className="fw-bold text-start">
              ${template?.deductible?.toLocaleString()}
            </Col>
          </Row>
        </Col>
        <Col sm={7} className="g-0">
          <Row>
            <Col sm={7} className="mb-1">
              Products-Completed Operations Limit
            </Col>
            <Col className="fw-bold text-start">
              ${template?.projectCompletedOperationLimit?.toLocaleString()}
            </Col>
          </Row>
          <Row>
            <Col sm={7} className="mb-1">
              Personal & Advertising Injury Limit
            </Col>
            <Col className="fw-bold text-start">
              ${template?.personalAdvertisingLimit?.toLocaleString()}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

const LimitsForReviewRequirements: React.FC<ITemplateDetailsProps> = ({
  className,
  template,
}) => {
  return (
    <Col>
      <div className="fw-bold mb-2">Limits</div>
      <div>
        <span className="text-underline-dashed">Each Occurrence</span>
        <span className="fw-bold p-3">
          ${template?.eachOccurrenceLimit.toLocaleString()}
        </span>
      </div>
      <div>
        <span className="text-underline-dashed">{`Aggregate (${template?.aggregateType})`}</span>
        <span className="fw-bold p-3">
          ${template?.aggregateLimit?.toLocaleString()}
        </span>
      </div>
      <div>
        <span className="text-underline-dashed">Deductible/Retention</span>
        <span className="fw-bold p-3">
          ${template?.deductible?.toLocaleString()}
        </span>
      </div>
      <div>
        <span className="text-underline-dashed">
          Products-Completed Operations Limit
        </span>
        <span className="fw-bold p-3">
          ${template?.projectCompletedOperationLimit?.toLocaleString()}
        </span>
      </div>
      <div>
        <span className="text-underline-dashed">
          Personal & Advertising Injury Limit
        </span>
        <span className="fw-bold p-3">
          ${template?.personalAdvertisingLimit?.toLocaleString()}
        </span>
      </div>
    </Col>
  )
}

const ScopeAndDuration: React.FC<ITemplateDetailsProps> = ({
  template,
  pageType,
}) => {
  return (
    <Col>
      <div className={`${pageType ? 'fs-5' : ''} fw-bold mb-2`}>
        Scope and Duration
      </div>
      <div>
        <span className="text-underline-dashed">Scope</span>
        <span className="fw-bold p-3">{template?.scope}</span>
      </div>
      <div>
        <span className="text-underline-dashed">Duration</span>
        <span className="fw-bold p-3">
          {template?.durationType !== 'ongoing operations'
            ? `${template?.durationType} for ${template?.duration} years`
            : template?.durationType}
        </span>
      </div>
    </Col>
  )
}

const TemplateLabelsList: React.FC<ITemplateDetailsProps> = ({
  template,
  pageType,
}) => {
  return (
    <Row className="mt-4">
      <Col>
        <div className={`${pageType ? 'fs-5' : ''} fw-bold mb-2`}>
          Special Requirements
        </div>
        {template?.templateLabels?.find(
          (templateLabel: ITemplateLabel) => templateLabel.isSpecialRequirement
        ) ? (
          <ScrollableList
            labelsDefinedInRequirement={getLabellingPathsForSpecialRequirementsOrProhibitions(
              template?.templateLabels,
              'Special Requirements'
            )}
            width="w-75"
          />
        ) : (
          'N/A'
        )}
      </Col>
      <Col>
        <div className={`${pageType ? 'fs-5' : ''} fw-bold mb-2`}>
          Special Prohibitions
        </div>
        {template?.templateLabels?.find(
          (templateLabel: ITemplateLabel) => templateLabel.isSpecialProhibition
        ) ? (
          <ScrollableList
            labelsDefinedInRequirement={getLabellingPathsForSpecialRequirementsOrProhibitions(
              template?.templateLabels,
              'Special Prohibitions'
            )}
            width="w-75"
          />
        ) : (
          'N/A'
        )}
      </Col>
    </Row>
  )
}

const OtherTemplateDetails: React.FC<ITemplateDetailsProps> = ({
  template,
  pageType,
}) => {
  return (
    <Row className="mt-4">
      <Col>
        <div>
          <span className={`${pageType ? 'fs-5' : ''} fw-bold`}>
            Contract Liability
          </span>
          <span className="ps-4">
            {template?.contractualLiability ? 'Yes' : 'No'}
          </span>
        </div>
        <div className="text-muted">
          You must have coverage for its liability arising out of an insured
          contract.
        </div>
      </Col>
      <Col>
        <div>
          <span className={`${pageType ? 'fs-5' : ''} fw-bold`}>
            Priority of Coverage
          </span>
          <span className="ps-4">
            {template?.priorityOfCoverage ? 'Yes' : 'No'}
          </span>
        </div>
        <div className="text-muted">
          Coverage afforded to an additional insured must be primary and
          non-contributory.
        </div>
      </Col>
      <Col>
        <div>
          <span className={`${pageType ? 'fs-5' : ''} fw-bold`}>
            Waiver of Subrogation
          </span>
          <span className="ps-4">
            {template?.waiverOfSubrogation ? 'Yes' : 'No'}
          </span>
        </div>
        <div className="text-muted">
          Carrier must agree to waive rights of subrogation against additional
          insureds.
        </div>
      </Col>
      <Col>
        <div>
          <span className={`${pageType ? 'fs-5' : ''} fw-bold`}>
            Notice of Change
          </span>
          <span className="ps-4">
            {template?.noticeOfChange ? 'Yes' : 'No'}
          </span>
        </div>
        <div className="text-muted">
          Carrier must provide notice of change or cancellation.
        </div>
      </Col>
    </Row>
  )
}

export {
  TemplateLabelsList,
  ScopeAndDuration,
  OtherTemplateDetails,
  LimitsForTemplateCard,
  LimitsForReviewRequirements,
}
