import { Button } from 'react-bootstrap'
import styled from 'styled-components'

export interface CustomClickableButtonProps extends React.ComponentProps<any> {
  buttonContent?: string
  handleOnClick: () => void
  disable: boolean
  classNaming?: string
  children?: any
}

const CustomClickableButton: React.FC<CustomClickableButtonProps> = ({
  buttonContent,
  handleOnClick,
  disable,
  children
}) => {
  return (
    <div>
      <Button
        variant="primary"
        className={`btn rounded-pill fw-bold ${disable ? "btn-gray-light2 text-gray-light1" : "btn-primary-medium text-white"}`}
        onClick={handleOnClick}
        disabled={disable}
        style={{ width: '' }}
      >
         {children} {buttonContent}
      </Button>
    </div>
  )
}

export default styled(CustomClickableButton)`
`
