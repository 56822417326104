import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import { COMPANIES_LIST } from '../graphql/graphql-queries'

export interface PolicyHoldersListProps extends React.ComponentProps<any> {
  className?: string
}
const PolicyHoldersList: React.FC<PolicyHoldersListProps> = ({ className }) => {
  const [companies, setCompanies] = useState([])

  useQuery(COMPANIES_LIST, {
    onCompleted: (data) => {
      setCompanies(data.listCompanies)
    },
  })

  return (
    <div className={className}>
      <Container className="border text-start className">
        <Row className="border-bottom fw-bold py-2">
          <Col>Policyholders</Col>
          <Col>Policy Details</Col>
        </Row>
        {!!companies.length &&
          companies.map((company: any) => (
            <Row
              className="border-bottom d-flex align-items-center py-2"
              key={company.id}
            >
              <Col>
                <div className="fw-bold">{company.name}</div>
                <div>{company.users[0].fullName}</div>
              </Col>

              <Col>
                {company.nonProcessingPolicies.map(
                  (nonProcessingPolicy: any) => (
                    <>
                      <div className="fw-bold">
                        {nonProcessingPolicy.policyNumber}
                      </div>
                      <div>
                        {nonProcessingPolicy.startDate}-
                        {nonProcessingPolicy.endDate}
                      </div>
                      <div>Gold Requirements</div>
                    </>
                  )
                )}
              </Col>
            </Row>
          ))}
      </Container>
    </div>
  )
}

export default styled(PolicyHoldersList)``
