import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import _ from 'lodash'
import JobTypeProhibitionsCheckboxTree from './job-type-prohibitions-checkbox-tree'
import {
  CREATE_OR_UPDATE_JOB_TYPE_PROHIBITIONS_LABELS,
  GET_JOB_TYPE,
} from '../../graphql/graphql-queries'
import { useMutation } from '@apollo/client'

export interface JobTypeProhibitionsPopupProps
  extends React.ComponentProps<any> {
  show: boolean
  onHide: () => void
  jobtypedatatobeedited?: any
  setShowProhibitedPopup?: React.Dispatch<React.SetStateAction<boolean>>
  jobTypeProhibitionsLabellingPaths: string[]
  paginationJobData: {
    offset: number
    limit: number
  }
}
const JobTypeProhibitionsPopup: React.FC<JobTypeProhibitionsPopupProps> = (
  props
) => {
  const [state, setState] = useState<any>({
    checked: [],
    expanded: [],
    clicked: {},
  })

  const [createOrUpdateJobTypeProhibitionsLabels] = useMutation(
    CREATE_OR_UPDATE_JOB_TYPE_PROHIBITIONS_LABELS,
    {
      refetchQueries: [
        { query: GET_JOB_TYPE, variables: { data: props.paginationJobData } },
      ],
    }
  )

  const handleMarkAsProhibited = async () => {
    if (props.setShowProhibitedPopup !== undefined) {
      let data = {
        jobTypeId: props.jobtypedatatobeedited?.id,
        jobTypeProhibitionsLabels: state.checked,
      }
      await createOrUpdateJobTypeProhibitionsLabels({
        variables: {
          data: data,
        },
      })
      props.setShowProhibitedPopup(false)
    }
  }

  useEffect(() => {
    setState({ ...state, checked: props.jobTypeProhibitionsLabellingPaths })
  }, [JSON.stringify(props.jobTypeProhibitionsLabellingPaths)])

  return (
    <div className="popup-modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Job Type Prohibitions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JobTypeProhibitionsCheckboxTree
            state={state}
            setState={setState}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end">
            <span className="m-4"></span>
            <span className="m-3">
              <Button
                onClick={handleMarkAsProhibited}
                style={{ border: 'none' }}
              >
                Done
              </Button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default styled(JobTypeProhibitionsPopup)`
  .modal-lg,
  .modal-xl {
    max-width: 75%;
    height: -webkit-fill-available;
  }
  .modal-content {
    height: -webkit-fill-available;
  }
  .modal-header {
    position: sticky;
  }
  .modal-body {
    height: fit-content;
    overflow-y: scroll;
  }
`
