import { Container, Row, Col, Form } from 'react-bootstrap'
import styled from 'styled-components'
import CustomOptionBadge from './custom-components/customOptionBage'
import ScrollableList from './scrollable-list'
import { PencilIcon } from '@heroicons/react/solid'
import { ITemplate } from '../interface'

export interface AdditionalRequirementContainerProps
  extends React.ComponentProps<any> {
  additionalRequirement: {
    name: string
    value: string
    key: string
  }
  handleOnAdditionalRequirements: any
  setShowSpecialRequirementsPopup: React.Dispatch<React.SetStateAction<boolean>>
  setShowSpecialProhibitionsPopup: React.Dispatch<React.SetStateAction<boolean>>
  duplicateTemplate: ITemplate
  className: string
}
const AdditionalRequirementContainer: React.FC<
  AdditionalRequirementContainerProps
> = ({
  additionalRequirement,
  handleOnAdditionalRequirements,
  keyValue,
  isNoticeOfChangeSelected,
  duplicateTemplate,
  setShowSpecialRequirementsPopup,
  setShowSpecialProhibitionsPopup,
  className,
}) => {
  const key: any = additionalRequirement.key
  const getKeyValue =
    <T extends object, U extends keyof T>(obj: T) =>
    (key: U) =>
      obj[key]
  let value: any = getKeyValue(duplicateTemplate)(key)

  if (Array.isArray(value)) {
    value = !!value.length
  }

  const handleEditSpecialRequirements = (it: any) => {
    setShowSpecialRequirementsPopup(true)
  }

  const handleEditSpecialProhibitions = (it: any) => {
    setShowSpecialProhibitionsPopup(true)
  }
  return (
    <Container className={`${className} border w-75 mx-4 mb-3 p-3`}>
      <Row>
        <Col sm={7}>
          <div>
            <h5 className="fw-bold text-start ">
              {additionalRequirement.name}
            </h5>
          </div>
        </Col>
        <Col sm={2}>
          <CustomOptionBadge
            handleOnAdditionalRequirements={handleOnAdditionalRequirements}
            additionalRequirement={additionalRequirement}
            keyValue={keyValue}
            value={value}
          />
        </Col>
        {duplicateTemplate?.containsSpecialRequirements &&
          additionalRequirement.name === 'Special Requirements' && (
            <Col className="text-end">
              <PencilIcon
                className="react-icon"
                onClick={() => handleEditSpecialRequirements(duplicateTemplate)}
              />
            </Col>
          )}
        {duplicateTemplate?.containsSpecialProhibitions &&
          additionalRequirement.name === 'Special Prohibitions' && (
            <Col className="text-end">
              <PencilIcon
                className="react-icon"
                onClick={() => handleEditSpecialProhibitions(duplicateTemplate)}
              />
            </Col>
          )}
      </Row>
      {Array.isArray(additionalRequirement.value) &&
      additionalRequirement.value.length > 0 ? (
        <Row>
          <ScrollableList
            labelsDefinedInRequirement={additionalRequirement.value}
          />
        </Row>
      ) : (
        <div className="text-start">{additionalRequirement.value}</div>
      )}
      {isNoticeOfChangeSelected &&
      additionalRequirement.key === 'noticeOfChange' ? (
        <Row>
          {' '}
          <hr className="m-1" />{' '}
          <Col>
            <Container className=" ">
              <Form className="text-start">
                <div>
                  <Row className=" mb-4">
                    <Col sm={12} className="email-column">
                      <Form.Label className="fw-bold">
                        Email Address For Notice
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="changes@resolvedrisk.com"
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            </Container>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default styled(AdditionalRequirementContainer)`
  .columns: 2 auto;
  .email-column {
    margin-left: -20px;
    margin-top: 10px;
  }
`
