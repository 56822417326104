import { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'

import SignInImage from '../assets/images/sign-in.png'
import CustomClickableButton from './custom-components/custom-clickable-button'
import ValidationError from './validation-error'
import {
  GET_INVITATION_BY_TOKEN,
  SIGN_UP_USER,
} from '../graphql/graphql-queries'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
import CustomAlert from './custom-components/custom-alert'

interface SignUpProps extends React.ComponentProps<any> {
  className?: string
}

const SignUp: React.FC<SignUpProps> = ({ className }) => {
  const { register, handleSubmit: signUpHandler, formState, watch } = useForm()

  const [invitationData, setInvitationData] = useState<any>({})
  const [resetLink, setResetLink] = useState(false)

  const [isLinkValid, setIsLinkValid] = useState<any>(true)

  const [signUpInput, setSignUpInput] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    role: '',
  })

  //   const { register, handleSubmit: loginHandler, formState } = useForm()
  //   const [validated, setValidated] = useState(false)
  const navigate = useNavigate()

  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')

  const [signUpUser] = useMutation(SIGN_UP_USER, {
    onCompleted: async (data) => {
      navigate('/login', {
        state: {
          signedUp: true,
        },
      })
    },
  })

  useEffect(() => {
    if (
      typeof invitationData === 'object' &&
      invitationData !== null &&
      Object.keys(invitationData).length !== 0
    ) {
      if (invitationData?.expired) {
        setIsLinkValid(false)
        navigate('/login', {
          state: {
            alreadySignedUp: true,
          },
        })
      } else {
        setSignUpInput({
          ...signUpInput,
          email: invitationData?.user?.email,
          role: invitationData?.user?.role,
        })
      }
    }
  }, [invitationData]) // eslint-disable-line react-hooks/exhaustive-deps

  const { refetch, loading, error } = useQuery(GET_INVITATION_BY_TOKEN, {
    variables: {
      token,
    },
    onCompleted: (data) => {
      setInvitationData(data.getInvitationByToken)
    },
  })

  useEffect(() => {
    refetch()
  }, [refetch, invitationData])
  const onError = (errors: any, e: any) => console.log(errors, e)

  const handleSignUpInputChange = (e: any) => {
    setSignUpInput({
      ...signUpInput,
      [e.target.name]: e.target.value,
    })
  }
  useEffect(() => {
    let URL = window.location.href
    if (URL.includes('reset-password')) {
      setResetLink(true)
    }
  }, [window.location.href]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSignUpClick = (e: any) => {
    setResetLink(false)

    setSignUpInput({
      ...signUpInput,
      role: invitationData?.user?.role,
    })

    signUpUser({
      variables: {
        data: signUpInput,
      },
    })
  }

  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />

  return (
    <div className={`${className}`}>
      {isLinkValid === true && (
        <Container className="text-start">
          <Row className="sign-up-wrapper d-flex align-items-center">
            <Col>
              <p className="fs-4 fw-bold  mb-2">
                {resetLink ? 'Recover Your Password' : 'Sign Up'}
              </p>
              <p>
                Already have an account?
                <a
                  className="ps-2 text-primary-medium fw-bold sign-up-link"
                  href="./login"
                >
                  Sign In
                </a>
              </p>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-bold">E-mail Address</Form.Label>
                  <Form.Control
                    className="sign-up-input"
                    type="email"
                    name="email"
                    value={
                      invitationData?.user?.email
                        ? invitationData.user.email
                        : ''
                    }
                    readOnly
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="fw-bold">Password</Form.Label>
                  <Form.Control
                    {...register('password', {
                      required: {
                        value: true,
                        message:
                          'You must specify a Password before moving forward.',
                      },
                      validate: (val: string) => {
                        if (watch('password').length < 8) {
                          return 'Password must be at least 8 characters long!'
                        }
                      },
                    })}
                    className="sign-up-input"
                    type="password"
                    name="password"
                    // ref={password}
                    required
                    onChange={handleSignUpInputChange}
                  />
                  {formState.errors.password && (
                    <ValidationError
                      errorMessage={formState.errors.password.message}
                    />
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="formBasicConfirmPassword"
                >
                  <Form.Label className="fw-bold">Confirm Password</Form.Label>
                  <Form.Control
                    {...register('confirmPassword', {
                      required: {
                        value: true,
                        message:
                          'You must confirm password before moving forward.',
                      },
                      validate: (val: string) => {
                        if (watch('password') !== val) {
                          return 'Your passwords do no match'
                        }
                      },
                    })}
                    className="sign-up-input"
                    type="password"
                    name="confirmPassword"
                    required
                    onChange={handleSignUpInputChange}
                  />
                  {formState.errors.confirmPassword && (
                    <ValidationError
                      errorMessage={formState.errors.confirmPassword.message}
                    />
                  )}
                </Form.Group>

                <CustomClickableButton
                  buttonContent="Sign Up"
                  handleOnClick={signUpHandler(handleSignUpClick, onError)}
                  disable={false}
                  classNaming="btn-round"
                />
              </Form>
            </Col>

            <Col sm={8}>
              <img
                className="login-image"
                src={SignInImage}
                height={300}
                width={300}
                alt="Login"
              />
              <p className="fs-5 fw-bold text-center mt-4">
                We’re here to help.
              </p>
              <p className="fs-6 fw-bold text-center my-0">
                We certify your general liability insurance to get
              </p>
              <p className="fs-6 fw-bold text-center">you to work quicker!</p>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default styled(SignUp)`
  .sign-up-wrapper {
    min-height: 100vh;
  }

  .login-image {
    margin-left: 270px;
  }

  .sign-up-input {
    border-radius: 20px;
  }

  .sign-up-link {
    text-underline-offset: 3px;
  }
`
