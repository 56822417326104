import { Card, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Moment from 'moment'
import _ from 'lodash'
import { ITemplate } from '../interface'
import { PencilIcon } from '@heroicons/react/outline'
import {
  LimitsForTemplateCard,
  OtherTemplateDetails,
  ScopeAndDuration,
  TemplateLabelsList,
} from '../components/template-details'

export interface TemplateCardProps extends React.ComponentProps<any> {
  className: string
  template: ITemplate
  hasEditOption: boolean
}

const TemplateCard: React.FC<TemplateCardProps> = ({
  className,
  template,
  hasEditOption,
}) => {
  const navigate = useNavigate()

  return (
    <div className={className}>
      <Card
        className="text-start my-4 container-border-radius"
        key={template?.id}
      >
        <Card.Header
          as="h5"
          className="bg-transparent d-flex align-items-center"
        >
          <Col>
            <Card.Title className="text-primary fw-bold">
              {template?.templateName}
            </Card.Title>
          </Col>
          {hasEditOption && (
            <Col className="text-end">
              <PencilIcon
                className="react-icon"
                onClick={() => {
                  navigate('/edit-template', {
                    state: {
                      template: template,
                    },
                  })
                }}
              />
            </Col>
          )}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <LimitsForTemplateCard template={template} />
            </Col>
            <Col sm={4}>
              <ScopeAndDuration
                template={template}
                pageType="Template Requirement"
              />
            </Col>
            <Col sm={2}>
              <div className="fs-5 fw-bold">Last Updated</div>
              <Card.Text>
                {Moment(template?.updatedAt).format('MM/DD/YYYY')}
              </Card.Text>
            </Col>
          </Row>
          <OtherTemplateDetails
            template={template}
            pageType="Template Requirement"
          />
          <TemplateLabelsList
            template={template}
            pageType="Template Requirement"
          />
        </Card.Body>
      </Card>
    </div>
  )
}

export default styled(TemplateCard)`
  .container-border-radius {
    border-radius: 12px;
  }
`
