import { Container, Row } from 'react-bootstrap'
import ReviewOrCertifyRequirements from './review-or-certify-requirements'
import CustomClickableButton from './custom-components/custom-clickable-button'
import styled from 'styled-components'

export interface ReviewRequirementsProps extends React.ComponentProps<any> {
  className?: string
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  tradeContractForCertifyInsurance: any
}

const ReviewRequirements: React.FC<ReviewRequirementsProps> = ({
  setCurrentStep,
  currentStepIndex,
  setCurrentStepIndex,
  tradeContractForCertifyInsurance,
  className,
}) => {
  const handleReviewRequirementsNextClick = () => {
    // setCurrentStep('Accept Trade Contract')
    //Remove this and uncomment above when need for Accept/Sign Addendum
    setCurrentStep('Upload Policy')
    setCurrentStepIndex(currentStepIndex + 1)
  }

  return (
    <div className={`${className} reviewRequirements`}>
      <Container className="mb-5">
        <Row>
          <ReviewOrCertifyRequirements
            tradeContractForCertifyInsurance={tradeContractForCertifyInsurance}
          />
        </Row>

        <div className="footer">
          <div className="left-side">{/*  */}</div>
          <div className="right-side">
            <CustomClickableButton
              buttonContent="Next"
              handleOnClick={handleReviewRequirementsNextClick}
              disable={false}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default styled(ReviewRequirements)`
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100vw;
    border-top: 1px solid #e6e6e6;
    height: 60px;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  .left-side {
    margin-left: 40px;
  }
  .right-side {
    margin-right: 40px;
  }
  .reviewRequirements {
    margin-bottom: 60px !important;
  }
`
