import { useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { LIST_TRADE_CONTRACTORS_ASSIGNED_TO_BROKER } from '../../graphql/graphql-queries'
import SpinnerLoading from '../spinner-loading'
import ErrorFetchingData from '../error-fetching-data'
import styled from 'styled-components'
import { BsFillCircleFill } from 'react-icons/bs'
export interface BrokerPermissionsSettingsProps
  extends React.ComponentProps<any> {
  className?: string
  user: any
}

const BrokerPermissionsSettings: React.FC<BrokerPermissionsSettingsProps> = ({
  className,
  user,
}) => {
  const [tradeContractorsDetails, setTradeContractorsDetails] = useState<any>(
    []
  )

  const {
    loading: listTradeContractorsWithActiveBrokerLoading,
    error: listTradeContractorsWithActiveBrokerError,
  } = useQuery(LIST_TRADE_CONTRACTORS_ASSIGNED_TO_BROKER, {
    variables: {
      brokerId: user?.id,
    },
    onCompleted: (data) => {
      setTradeContractorsDetails(data?.listTradeContractorsAssignedToBroker)
    },
  })

  if (listTradeContractorsWithActiveBrokerLoading) return <SpinnerLoading />
  if (listTradeContractorsWithActiveBrokerError) return <ErrorFetchingData />

  return (
    <div className={className}>
      <Container className="p-5">
        <p className="text-start text-primary fw-bold fs-5 ">Delegated Permissions:</p>
        <p className="text-start fw-bold">Policy Submission</p>

        <Form className="text-start">
          {tradeContractorsDetails?.length !== 0
            ? tradeContractorsDetails?.map(
                (tradeContractorDetail: any, index: number) => {
                  const { tradeContractor, isActive } = tradeContractorDetail
                  return (
                    <Row key={`broker${index}`}>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicFirstName"
                        >
                          <Form.Label className="fw-bold">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={tradeContractor?.firstName}
                            autoComplete="off"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicLastName"
                        >
                          <Form.Label className="fw-bold">Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={tradeContractor?.lastName}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="fw-bold">
                            Email Address
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={tradeContractor?.email}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicStatus"
                        >
                          <Form.Label className="fw-bold">Status</Form.Label>
                          <div className="py-1">
                            <BsFillCircleFill
                              style={
                                isActive
                                  ? { color: '#13948b' }
                                  : { color: '#808080' }
                              }
                            />
                            <span className="px-2">
                              {isActive ? 'Active' : 'Inactive'}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  )
                }
              )
            : 'No Records of Policy Submission Permissions.'}
        </Form>
      </Container>
    </div>
  )
}

export default styled(BrokerPermissionsSettings)``
