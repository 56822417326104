import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { GET_NON_PROCESSING_POLICY_DETAILS } from '../graphql/graphql-queries'
import CusotmOverlayTooltip from './custom_overlay_tooltip'
import ErrorFetchingData from './error-fetching-data'
import NonProcessingPolicyHeader from './non-processing-policy-header'
import SpinnerLoading from './spinner-loading'
import TemplateCard from './template-card'
type Props = {}
const ClientPolicyDetails: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate()

  const {
    state: {
      policyId,
      policyStatus,
      nonProcessingPolicyInfo,
      firstNameInsuredId,
      tradeContracts,
    },
  }: any = useLocation()

  const [nonProcessingPolicyDetail, setNonProcessingPolicyDetail] =
    useState<any>({})

  const {loading,error}=useQuery(GET_NON_PROCESSING_POLICY_DETAILS, {
    variables: { policyId: policyId },
    onCompleted: (data) => {
      setNonProcessingPolicyDetail(data.listDetailsOfNonProcessingPolicies[0])
    },
  })

  const handleTradesClick = (tradeContracts: any) => {
    navigate('/trades-list-for-all-jobs', {
      state: {
        tradeContracts,
        policyId,
        policyStatus,
        nonProcessingPolicyInfo,
      },
    })
  }

  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />
  return (
    <Container>
      <NonProcessingPolicyHeader
        policyStatus={policyStatus}
        nonProcessingPolicyInfo={nonProcessingPolicyInfo}
      />
      <div className="client-list shadow-none p-1  bg-white rounded">
        <Row className="my-4 px-2">
          <Col sm={2} className="fw-bold text-start">
            Policy Details
          </Col>
          <Col sm={3} className="fw-bold">
            Jobs
          </Col>
          <Col sm={4} className="fw-bold">
            Claims
          </Col>
          <Col sm={3} className="fw-bold">
            Trades
          </Col>
        </Row>
        <Row className="my-4 px-2">
          <Col sm={2} className="text-start">
            {nonProcessingPolicyDetail.policyNumber}
            <br />
            {nonProcessingPolicyDetail.templateName}
          </Col>
          <Col sm={3} className="">
            <CusotmOverlayTooltip tooltipContent="Click to see jobs">
              <span
                onClick={() => {
                  navigate(`/jobs/${nonProcessingPolicyDetail.id}`)
                }}
                style={{ cursor: 'pointer' }}
              >
                {nonProcessingPolicyDetail.numberOfJobs}
              </span>
            </CusotmOverlayTooltip>
          </Col>
          <Col sm={4} className="">
            <CusotmOverlayTooltip tooltipContent="Click to see claims">
              <span
                onClick={() => {
                  navigate(`/claims`, {
                    state: {
                      nonProcessingPolicy: {
                        ...nonProcessingPolicyDetail,
                        firstNameInsuredId: firstNameInsuredId,
                      },
                      job: '',
                      contractedCompanyId: '',
                    },
                  })
                }}
                style={{ cursor: 'pointer' }}
              >
                {nonProcessingPolicyDetail.totalClaims}
              </span>
            </CusotmOverlayTooltip>
          </Col>
          <Col sm={3}>
            <CusotmOverlayTooltip tooltipContent="Click to see trades">
              <span
                className="clickable-text"
                onClick={() => handleTradesClick(tradeContracts)}
              >
                {nonProcessingPolicyDetail?.tradeContracts?.length}
              </span>
            </CusotmOverlayTooltip>
          </Col>
        </Row>
      </div>
      <TemplateCard template={nonProcessingPolicyDetail.template} hasEditOption={false}/>
    </Container>
  )
}
export default ClientPolicyDetails
