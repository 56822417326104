import { useLazyQuery, useQuery } from '@apollo/client'
import { PencilIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import {
  GET_JOB_TYPE,
  LIST_JOB_TYPE_PROHIBITIONS_LABELS,
} from '../graphql/graphql-queries'
import CustomClickableButton from './custom-components/custom-clickable-button'
import PopupModal from './popup-modal'
import Pagination from './Pagination Component/pagination'
import config from '../core/config'
import CustomAlert from './custom-components/custom-alert'
import JobTypeProhibitionsPopup from './job-type-prohibitions/job-type-prohibitions-popup'
import { getJobTypeProhibitionsLabellingPaths } from '../helper-functions'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
type Props = {}
const JobTypeListing: React.FC<Props> = () => {
  const [jobTypes, setJobTypes] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const dataPerPage = config.itemPerPage
  const [isEdit, setIsEdit] = useState(false)
  const [success, setSuccess] = useState('For Notification')
  const [successMessage, setSuccessMessage] = useState('Message')
  const offset = 0
  const defaultDataSize = config.itemPerPage
  const [jobTypeDataToBeEdited, setJobTypeDataToBeEdited] = useState({
    id: '',
    jobCode: '',
    jobName: '',
  })

  let paginationJobData = {
    offset: currentPage === 1 ? offset : (currentPage - 1) * 25,
    limit: defaultDataSize,
  }
  const [showProhibitedPopup, setShowProhibitedPopup] = useState(false)

  const [
    jobTypeProhibitionsLabellingPaths,
    setJobTypeProhibitionsLabellingPaths,
  ] = useState<string[]>([])

  const { refetch, loading, error } = useQuery(GET_JOB_TYPE, {
    variables: { data: paginationJobData },
    onCompleted: (data) => {
      setCount(data?.listJobTypes?.count)
      setJobTypes(data?.listJobTypes?.jobTypesData)
    },
  })

  const [listJobTypeProhibitionsLabels] = useLazyQuery(
    LIST_JOB_TYPE_PROHIBITIONS_LABELS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        let jobTypeProhibitionsLabellingPaths =
          getJobTypeProhibitionsLabellingPaths(
            data.listJobTypeProhibitionsLabels
          )
        setJobTypeProhibitionsLabellingPaths(jobTypeProhibitionsLabellingPaths)
      },
    }
  )

  useEffect(() => {
    refetch()
  }, [showModal, showProhibitedPopup, refetch])

  const handleEditClick = (jobType: any) => {
    setJobTypeDataToBeEdited({
      id: jobType.id,
      jobCode: jobType.jobCode,
      jobName: jobType.name,
    })
    setShowModal(true)
    setIsEdit(true)
    setShowProhibitedPopup(false)
  }

  const handleJobTypeProhibitions = async (jobType: any) => {
    setJobTypeDataToBeEdited({
      id: jobType.id,
      jobCode: jobType.jobCode,
      jobName: jobType.name,
    })
    setShowProhibitedPopup(true)
    await listJobTypeProhibitionsLabels({
      variables: { jobTypeId: jobType.id },
    })
  }

  const jobTypeArray = jobTypes
  const perPage = Math.ceil(count / dataPerPage)
  const handleClose = () => setSuccess('For Notification')

  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />
  return (
    <Container className="mt-3 pb-3">
      {success === 'success' && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleClose}
            alertType="success"
            alertMessage={successMessage}
          />
        </Row>
      )}
      {success === 'failed' && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleClose}
            alertType="error"
            alertMessage={successMessage}
          />
        </Row>
      )}
      <Row className="my-4">
        <Col className="text-primary text-end">
          <CustomClickableButton
            buttonContent="Create Job Type"
            handleOnClick={() => {
              setShowModal(true)
              setIsEdit(false)
            }}
            disable={false}
          />
        </Col>
      </Row>
      <div className="client-list shadow-none p-3 mb-5 bg-white rounded">
        <Row className=" fw-bold border-bottom border-light pb-2">
          <Col sm={2} className="text-start">
            Job Code
          </Col>
          <Col className="text-start">Job Name</Col>
          <Col className="text-center">No. of prohibited Endorsements</Col>
          <Col></Col>
        </Row>

        {jobTypeArray.map((jobType: any, index: number) => {
          return (
            <Row
              className="border-bottom border-light p-2"
              key={jobType.jobCode + index}
            >
              <Col
                className="text-start"
                sm={2}
                onClick={() => {
                  handleJobTypeProhibitions(jobType)
                }}
              >
                <span onClick={() => {}} style={{ cursor: 'pointer' }}>
                  {jobType.jobCode}
                </span>
              </Col>
              <Col
                className="text-start"
                onClick={() => {
                  handleJobTypeProhibitions(jobType)
                }}
              >
                {jobType.name}
              </Col>
              <Col
                className="text-center"
                onClick={() => {
                  handleJobTypeProhibitions(jobType)
                }}
              >
                {jobType.prohibitedEndorsementCount}
              </Col>
              <Col>
                <PencilIcon
                  className="react-icon"
                  onClick={() => handleEditClick(jobType)}
                />
              </Col>
            </Row>
          )
        })}
      </div>
      {jobTypeArray?.length > 0 && (
        <div>
          {' '}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={perPage}
          />
        </div>
      )}

      {showModal && (
        <PopupModal
          show={showModal}
          onHide={() => setShowModal(false)}
          isedit={isEdit}
          jobcode="Job Code"
          jobname="Job Name"
          message={!isEdit ? 'Add New Job Type' : 'Update Job Type'}
          page="Job_Type"
          setshowmodal={setShowModal}
          jobtypedatatobeedited={jobTypeDataToBeEdited}
          setsuccess={setSuccess}
          setsuccessmessage={setSuccessMessage}
        />
      )}
      {showProhibitedPopup && (
        <JobTypeProhibitionsPopup
          show={showProhibitedPopup}
          onHide={() => setShowProhibitedPopup(false)}
          jobtypedatatobeedited={jobTypeDataToBeEdited}
          setShowProhibitedPopup={setShowProhibitedPopup}
          jobTypeProhibitionsLabellingPaths={jobTypeProhibitionsLabellingPaths}
          paginationJobData={paginationJobData}
        />
      )}
    </Container>
  )
}

export default styled(JobTypeListing)``
