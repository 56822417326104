import { Toast, ToastContainer } from 'react-bootstrap'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/solid'
import styled from 'styled-components'
import authentication from '../../core/authentication'
import { useEffect } from 'react'

export interface CustomAlertProps extends React.ComponentProps<any> {
  className?: string
}

const CustomErrorNotification: React.FC<CustomAlertProps> = ({ className }) => {
  useEffect(() => {
    let auth = authentication.getAccessToken()
    if (!auth) {
      setTimeout(() => {
        window.location.href = '/'
      }, 2000)
    }
  }, [])
  return (
    <ToastContainer
      className={`${className} w-50 p-3 mb-14 mx-auto d-flex justify-content-center`}
    >
      <Toast
        className={`w-auto fw-bold d-flex justify-content-center ${'text-error-dark'}`}
        bg={'error-light'}
        delay={2000}
        autohide
        animation
      >
        <div className="d-flex align-items-center">
          <ExclamationCircleIcon className="exclamation-circle-icon hero-icon-primary" />

          <Toast.Body className="toast-content">
            'Your session is expired! Please login again
          </Toast.Body>
        </div>
      </Toast>
    </ToastContainer>
  )
}

export default styled(CustomErrorNotification)`
  .check-icon {
    color: #0c5a55;
  }

  .exclamation-circle-icon {
    color: #be0202;
  }

  .exclamation-icon {
    color: #e97e01;
  }

  .toast {
    font-size: 15px !important;
  }
`
