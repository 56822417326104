import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import {
  getLabellingPathsForSpecialRequirementsOrProhibitions,
  transformCheckedElements,
} from '../helper-functions'
import { ITemplate } from '../interface'
import AdditionalRequirementContainer from './additional-requirement-container'
import CustomAlert from './custom-components/custom-alert'
import CustomClickableButton from './custom-components/custom-clickable-button'
import ProhibitedRequirementsPopup from './special-prohibitions/special-prohibitions-popup'
import SpecialRequirementsPopup from './special-requirements/special-requirements-popup'

export interface AdditionalRequirementProps extends React.ComponentProps<any> {
  className?: string
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  template: ITemplate
  setTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
  duplicateTemplate: ITemplate
  setDuplicateTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
}

const AdditionalRequirement: React.FC<AdditionalRequirementProps> = ({
  className,
  setCurrentStep,
  currentStepIndex,
  setCurrentStepIndex,
  template,
  setTemplate,
  duplicateTemplate,
  setDuplicateTemplate,
}) => {
  const [isContractualLiabilityOn, setIsContractualLiabilityOn] =
    useState(false)
  const [isPriorityOfCoverageOn, setIsPriorityOfCoverageOn] = useState(false)
  const [isNoticeOfChangeOn, setIsNoticeOfChangeOn] = useState(false)
  const [isWaiverOfSubrogationOn, setIsWaiverOfSubrogationOn] = useState(false)
  const [isSpecialRequirementsOn, setIsSpecialRequirementsOn] = useState(false)
  const [isSpecialProhibitionsOn, setIsSpecialProhibitionsOn] = useState(false)
  const [showSpecialRequirementsPopup, setShowSpecialRequirementsPopup] =
    useState(false)
  const [showSpecialProhibitionsPopup, setShowSpecialProhibitionsPopup] =
    useState(false)
  const [isNoticeOfChangeSelected, setIsNoticeOfChangeSelected] = useState(
    duplicateTemplate.noticeOfChange
  )

  const [addedSpecialRequirements, setAddedSpecialRequirements] = useState<
    string[]
  >([])
  const [addedSpecialProhibitions, setAddedSpecialProhibitions] = useState<
    string[]
  >([])

  const [
    specialRequirementsOrProhibitionsValidatonError,
    setSpecialRequirementsOrProhibitionsValidatonError,
  ] = useState<boolean>(false)
  const [
    specialRequirementsOrProhibitionsMatchingSelections,
    setSpecialRequirementsOrProhibitionsMatchingSelections,
  ] = useState<string>('')

  const additionalRequirementData = [
    [
      {
        name: 'Contractual Liability',
        value:
          'Policy must have coverage for liability arising out of an insured contract',
        state: isContractualLiabilityOn,
        togglefunc: setIsContractualLiabilityOn,
        key: 'contractualLiability',
      },
      {
        name: 'Priority of Coverage',
        value: 'Policy must contain Primary and Non-Contributory wording',
        state: isPriorityOfCoverageOn,
        togglefunc: setIsPriorityOfCoverageOn,
        key: 'priorityOfCoverage',
      },
    ],
    [
      {
        name: 'Notice of Change',
        value: 'Carrier must provide notice of change or cancellation',
        state: isNoticeOfChangeOn,
        togglefunc: setIsNoticeOfChangeOn,
        key: 'noticeOfChange',
      },
      {
        name: 'Waiver of Subrogation',
        value:
          'Carrier must agree to waive rights of subrogation against additional insureds',
        state: isWaiverOfSubrogationOn,
        togglefunc: setIsWaiverOfSubrogationOn,
        key: 'waiverOfSubrogation',
      },
    ],
    [
      {
        name: 'Special Requirements',
        value: duplicateTemplate?.templateLabelsForSpecialRequirements?.length
          ? duplicateTemplate?.templateLabelsForSpecialRequirements
          : [],
        // value: [],
        state: isSpecialRequirementsOn,
        togglefunc: setIsSpecialRequirementsOn,
        key: 'containsSpecialRequirements',
      },
      {
        name: 'Special Prohibitions',
        value: duplicateTemplate?.templateLabelsForSpecialProhibitions?.length
          ? duplicateTemplate?.templateLabelsForSpecialProhibitions
          : [],
        // value: [],
        state: isSpecialProhibitionsOn,
        togglefunc: setIsSpecialProhibitionsOn,
        key: 'containsSpecialProhibitions',
      },
    ],
  ]

  const handleAdditionalRequirementsNextClick = () => {
    setCurrentStep('Review and Publish')
    setCurrentStepIndex(currentStepIndex + 1)
  }

  const handleOnAdditionalRequirements = (e: any) => {
    const { name, checked } = e.target
    setIsContractualLiabilityOn(!isContractualLiabilityOn)
    setIsPriorityOfCoverageOn(!isPriorityOfCoverageOn)
    setIsNoticeOfChangeOn(!isNoticeOfChangeOn)
    if (name === 'noticeOfChange') {
      setIsNoticeOfChangeSelected(checked)
      setDuplicateTemplate({
        ...duplicateTemplate,
        [e.target.name]: e.target.checked,
      })
    }
    if (name === 'containsSpecialRequirements') {
      setIsSpecialRequirementsOn(checked)
      if (checked) {
        setShowSpecialRequirementsPopup(true)
      } else {
        setDuplicateTemplate({
          ...duplicateTemplate,
          [e.target.name]: e.target.checked,
          templateLabelsForSpecialRequirements: [],
        })
        return
      }
    }
    if (name === 'containsSpecialProhibitions') {
      setIsSpecialProhibitionsOn(checked)
      if (checked) {
        setShowSpecialProhibitionsPopup(true)
      } else {
        setDuplicateTemplate({
          ...duplicateTemplate,
          [e.target.name]: e.target.checked,
          templateLabelsForSpecialProhibitions: [],
        })
        return
      }
    }
    setDuplicateTemplate({
      ...duplicateTemplate,
      [e.target.name]: e.target.checked,
      reviewerEmailAddress: 'changes@resolvedrisk.com',
      reviewerMailingAddress: '285 Fulton St, New York, NY 10007, USA',
    })
  }

  const validateSpecialRequirementsOrProhibitionsSelection = () => {
    let commonLabels =
      addedSpecialRequirements?.filter((addedSpecialRequirement) => {
        return addedSpecialProhibitions?.includes(addedSpecialRequirement)
      }) || []
    if (commonLabels?.length) {
      let transformedCommonLabels = transformCheckedElements(commonLabels)
      let transformedCommonLabelsString = transformedCommonLabels.join(', ')
      setSpecialRequirementsOrProhibitionsValidatonError(true)
      setSpecialRequirementsOrProhibitionsMatchingSelections(
        transformedCommonLabelsString
      )
      return false
    }
    return true
  }

  useEffect(() => {
    let specialRequirementsLabels =
      getLabellingPathsForSpecialRequirementsOrProhibitions(
        duplicateTemplate.templateLabels!,
        'Special Requirements'
      )
    let specialProhibitionsLabels =
      getLabellingPathsForSpecialRequirementsOrProhibitions(
        duplicateTemplate.templateLabels!,
        'Special Prohibitions'
      )
    setAddedSpecialRequirements(specialRequirementsLabels)
    setAddedSpecialProhibitions(specialProhibitionsLabels)
  }, [])

  const clearSpecialRequirementsOrProhibiitonsValidationError = () => {
    setSpecialRequirementsOrProhibitionsValidatonError(false)
    setSpecialRequirementsOrProhibitionsMatchingSelections('')
  }

  return (
    <div className={className}>
      {specialRequirementsOrProhibitionsValidatonError && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() =>
              clearSpecialRequirementsOrProhibiitonsValidationError()
            }
            alertType="error"
            alertMessage={`${specialRequirementsOrProhibitionsMatchingSelections} are added as both Special Requirements and Special Prohibitions. Please ensure that Special Requirements are not also selected as Special prohibitions.`}
          />
        </Row>
      )}
      <Container className={`${className}`}>
        <h3 className="m-4 mb-2 fw-bold text-start">Additional Requirements</h3>
        <Row>
          <Col className="pt-5">
            {additionalRequirementData.map((additionalRequirement, idx) => (
              <Row key={idx}>
                <Col>
                  <AdditionalRequirementContainer
                    additionalRequirement={additionalRequirement[0]}
                    handleOnAdditionalRequirements={
                      handleOnAdditionalRequirements
                    }
                    keyValue={idx}
                    isNoticeOfChangeSelected={isNoticeOfChangeSelected}
                    key={`${idx}`}
                    duplicateTemplate={duplicateTemplate}
                    showSpecialRequirementsPopup={showSpecialRequirementsPopup}
                    setShowSpecialRequirementsPopup={
                      setShowSpecialRequirementsPopup
                    }
                    showSpecialProhibitionsPopup={showSpecialProhibitionsPopup}
                    setShowSpecialProhibitionsPopup={
                      setShowSpecialProhibitionsPopup
                    }
                    className={className}
                  />
                </Col>
                <Col>
                  <AdditionalRequirementContainer
                    additionalRequirement={additionalRequirement[1]}
                    handleOnAdditionalRequirements={
                      handleOnAdditionalRequirements
                    }
                    keyValue={idx}
                    isNoticeOfChangeSelected={isNoticeOfChangeSelected}
                    key={`${idx}`}
                    duplicateTemplate={duplicateTemplate}
                    showSpecialRequirementsPopup={showSpecialRequirementsPopup}
                    setShowSpecialRequirementsPopup={
                      setShowSpecialRequirementsPopup
                    }
                    showSpecialProhibitionsPopup={showSpecialProhibitionsPopup}
                    setShowSpecialProhibitionsPopup={
                      setShowSpecialProhibitionsPopup
                    }
                    className={className}
                  />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row className="text-end">
          <Col sm={10}>
            <CustomClickableButton
              buttonContent="Next"
              handleOnClick={handleAdditionalRequirementsNextClick}
              disable={false}
            />
          </Col>
        </Row>
      </Container>
      {showSpecialRequirementsPopup && (
        <SpecialRequirementsPopup
          show={showSpecialRequirementsPopup}
          onHide={() => setShowSpecialRequirementsPopup(false)}
          template={template}
          setTemplate={setTemplate}
          setShowSpecialRequirementsPopup={setShowSpecialRequirementsPopup}
          editSpecialRequirementsAtTemplateEditLevel={true}
          duplicateTemplate={duplicateTemplate}
          setDuplicateTemplate={setDuplicateTemplate}
          addedSpecialRequirements={addedSpecialRequirements}
          setAddedSpecialRequirements={setAddedSpecialRequirements}
          validateSpecialRequirementsOrProhibitionsSelection={
            validateSpecialRequirementsOrProhibitionsSelection
          }
          setSpecialRequirementsOrProhibitionsMatchingSelections={
            setSpecialRequirementsOrProhibitionsMatchingSelections
          }
        />
      )}
      {showSpecialProhibitionsPopup && (
        <ProhibitedRequirementsPopup
          show={showSpecialProhibitionsPopup}
          onHide={() => setShowSpecialProhibitionsPopup(false)}
          template={template}
          setTemplate={setTemplate}
          setShowSpecialProhibitionsPopup={setShowSpecialProhibitionsPopup}
          editSpecialProhibitionsAtTemplateEditLevel={true}
          duplicateTemplate={duplicateTemplate}
          setDuplicateTemplate={setDuplicateTemplate}
          addedSpecialProhibitions={addedSpecialProhibitions}
          setAddedSpecialProhibitions={setAddedSpecialProhibitions}
          validateSpecialRequirementsOrProhibitionsSelection={
            validateSpecialRequirementsOrProhibitionsSelection
          }
          setSpecialRequirementsOrProhibitionsMatchingSelections={
            setSpecialRequirementsOrProhibitionsMatchingSelections
          }
        />
      )}
    </div>
  )
}

export default styled(AdditionalRequirement)``
