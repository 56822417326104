import { useState } from 'react'
import { IBrokerDetails } from '../../interface'
import { regexEmail } from '../../data'
 
export function useValidationPermissionsSettings(
  brokerDetails: IBrokerDetails[],
  brokerDetailsSavedInDb: IBrokerDetails[]
) {
  const [brokersDetailslnputError, setBrokersDetailsInputError] = useState<any>(
    {
      brokerDetails: brokerDetails?.map(() => ({
        firstName: '',
        lastName: '',
        email: '',
      })),
    }
  )

  const resetBrokersDetailslInputErrors = () => {
    setBrokersDetailsInputError({
      firstName: '',
      lastName: '',
      email: '',
    })
  }

  const validateBrokersDetailsInputForm = () => {
    let brokerDetailsErrorData: any[] = []
    brokerDetails.forEach(() => {
      brokerDetailsErrorData.push({
        firstName: '',
        lastName: '',
        email: '',
      })
    })

    let errors = brokerDetailsErrorData
    errors = brokerDetails?.map((broker: IBrokerDetails) => ({
      firstName: validateFirstName(broker?.firstName),
      lastName: validateLastName(broker?.lastName),
      email: validateEmail(broker?.email),
    }))
    return errors
  }

  const checkBrokersDetailsValid = (brokersDetailsErrorData: any) => {
    let counts: any[] = []
    brokersDetailsErrorData.map((brokerDetailsErrorData: any) => {
      let keys = Object.keys(brokerDetailsErrorData)
      let count = keys.reduce(
        (acc, curr) => (brokerDetailsErrorData[curr] ? acc + 1 : acc),
        0
      )
      counts.push(count === 0)
    })
    return !counts.includes(false)
  }

  const validateFirstName = (name: string) => {
    if (!name) {
      return 'First Name is missing.'
    }
    let regName = /^[A-Za-z]{2,30}$/
    if (!regName.test(name)) {
      return 'Invalid First Name'
    }
    return ''
  }

  const validateLastName = (name: string) => {
    let regName = /^[A-Za-z]{1,30}$/
    if (name && !regName.test(name)) {
      return 'Invalid Last Name'
    }
    return ''
  }

  const validateEmail = (email: string) => {
    
    if (!new RegExp(regexEmail).test(email)) {
      return 'Invalid email address'
    }
    if (!validateEmailDuplicates(email)) {
      return 'Duplicate email found'
    }
    return ''
  }

  const validateEmailDuplicates = (email: string) => {
    let enteredEmailsList = brokerDetails?.map(
      (broker: IBrokerDetails) => broker.email
    )
    let filteredEmailsList = enteredEmailsList?.filter(
      (enteredEmail: string) => enteredEmail == email
    )
    if (filteredEmailsList?.length > 1) {
      return false
    } else {
      return true
    }
  }

  const checkIfBrokersDetailsFormEdited = () => {
    let isFormEdited = false
    let brokerDetailsInDB = brokerDetailsSavedInDb

    let brokersLength = brokerDetails?.length
    if (brokerDetailsInDB?.length !== brokersLength) {
      return true
    } else {
      for (let i = 0; i < brokersLength; i++) {
        let inputBrokerDetails = brokerDetails[i]
        let filteredBrokerInDB = brokerDetailsInDB?.filter(
          (brokerInDb: IBrokerDetails) =>
            brokerInDb.email === inputBrokerDetails.email
        )
        if (filteredBrokerInDB?.length == 0) {
          isFormEdited = true
          break
        } else {
          let matchedBrokerInDB = filteredBrokerInDB[0]
          let doesFieldsMatch =
            inputBrokerDetails.firstName === matchedBrokerInDB.firstName &&
            inputBrokerDetails.lastName === matchedBrokerInDB.lastName &&
            inputBrokerDetails.isActive === matchedBrokerInDB.isActive
          if (!doesFieldsMatch) {
            return true
          }
        }
      }
    }
    return isFormEdited
  }

  return {
    validateBrokersDetailsInputForm,
    checkBrokersDetailsValid,
    brokersDetailslnputError,
    setBrokersDetailsInputError,
    resetBrokersDetailslInputErrors,
    checkIfBrokersDetailsFormEdited,
  }
}
