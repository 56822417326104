import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Toast,
  Badge,
} from 'react-bootstrap'
import { PencilIcon } from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'
import styled from 'styled-components'
import config from '../../core/config'
import FilterDropdown from '../filter-dropdown'
import Pagination from '../Pagination Component/pagination'
import SearchFilter from '../search-filter'
import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import PopupModalForDelete from '../delete-company-popup'
import { IMasterFormDetail } from '../../interface'

interface FormList {
  [key: string]: any
}

export interface MasterFormListComponentProps
  extends React.ComponentProps<any> {
  data: any
  setFormToEditId: React.Dispatch<React.SetStateAction<any>>
  setFilteredMasterFormData: React.Dispatch<React.SetStateAction<any>>
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  setLabelForAll: React.Dispatch<React.SetStateAction<boolean>>
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  labelAllFromCurrentPage: React.Dispatch<React.SetStateAction<any>>
  filteredMasterFormData: any
  searchTerm: string
  count: number
  pageNumber: number
  visibleMasterFormData: any
  unlabelledFormDataList: FormList[]
  filterByStatus: string
  setFilterByStatus: any
  labelForAll: boolean
  labellingStatus: string[]
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>
}

const MasterFormListComponent: React.FC<MasterFormListComponentProps> = ({
  className,
  data,
  setComponent,
  setPageNumber,
  setFormToEditId,
  setLabelForAll,
  setFilteredMasterFormData,
  setSearchTerm,
  filteredMasterFormData,
  searchTerm,
  count,
  pageNumber,
  visibleMasterFormData,
  unlabelledFormDataList,
  filterByStatus,
  setFilterByStatus,
  labelAllFromCurrentPage,
  labellingStatus,
  labelForAll,
  refetch,
}) => {
  const [allFormsLabelledAlert, setAllFormsLabelledAlert] =
    useState<boolean>(false)
  const [searchParam] = useState(['formTitle', 'formNumber'])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [masterFormDetail, setMasterFormDetail] = useState<IMasterFormDetail>({
    id: '',
    formTitle: '',
    formNumber: '',
  })

  const dataPerPage = config.itemPerPage
  const handleSetAllFormsLabelledAlert = () => {
    setAllFormsLabelledAlert(false)
    setLabelForAll(false)
  }

  const handleStartLabelling = () => {
    labelAllFromCurrentPage(labelForAll)
    setLabelForAll(true)
  }

  const handleEditClick = (it: any) => {
    setFormToEditId(it.id)
  }

  const handleDeleteClick = (it: any) => {
    setMasterFormDetail({
      ...masterFormDetail,
      id: it.id,
      formTitle: it.formTitle,
      formNumber: it.formNumber,
    })
    setShowDeleteModal(true)
  }

  const perPage = Math.ceil(count / dataPerPage)

  const handleSearch = (e: any) => {
    setPageNumber(1)
    setSearchTerm(e.target.value)
  }
  useEffect(() => {
    refetch()
  }, [filterByStatus])

  useEffect(() => {
    refetch()
  }, [showDeleteModal]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={className}>
      <Container className="mt-2">
        <Row>
          <Col sm={3}>
            <FilterDropdown
              filterByStatus={filterByStatus}
              setFilterByStatus={setFilterByStatus}
              filteringStatusList={labellingStatus}
              statusText="Labelling Status"
              setSearchTerm={setSearchTerm}
              setPageNumber={setPageNumber}
            />
          </Col>
          <Col>
            {filterByStatus !== 'Labelled' &&
              !(
                filterByStatus === 'Pending' &&
                visibleMasterFormData?.length === 0
              ) && (
                <Button className="float-end" onClick={handleStartLabelling}>
                  Start Labeling
                </Button>
              )}
          </Col>
        </Row>

        <Row>
          <SearchFilter
            handleSearch={handleSearch}
            placeholderText="Search Master Forms (Enter Atleast 3 Characters)"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </Row>

        <Row>
          <Table hover className="bg-white rounded">
            <thead className="text-start">
              <tr>
                <th className="col-sm-3">Form Detail</th>
                <th className="col-sm-1">Pages</th>
                <th className="col-sm-2">Compliance Status</th>
                <th className="col-sm-3">Form Categories</th>
                <th className="col-sm-1">Labelling Status</th>
                <th className="col-sm-1 text-end">Actions</th>
              </tr>
            </thead>
            <tbody className="text-start border-top-0">
              {visibleMasterFormData?.length ? (
                visibleMasterFormData?.map((it: any, index: number) => (
                  <tr key={it.id}>
                    <td className="align-middle">
                      <div className="text-uppercase fw-bold">
                        {it.formTitle}
                      </div>
                      <div className="small text-uppercase">
                        {it.formNumber}
                      </div>
                    </td>
                    <td className="align-middle px-3">{it.numberOfPages}</td>
                    <td className="align-middle">
                      <Badge
                        pill
                        bg={
                          it.isCompliant
                            ? 'primary'
                            : it.isInvalidInput
                            ? 'danger'
                            : 'tertiary'
                        }
                        className="my-2 p-2"
                      >
                        {it.isCompliant === true
                          ? 'Compliant'
                          : it.isInvalidInput === true
                          ? 'Invalid Input'
                          : 'Non-Compliant'}
                      </Badge>
                    </td>
                    <td className="align-middle">
                      {it.containsParserError && (
                        <Badge pill bg="danger" className="my-2 p-2">
                          Contains Parser Error
                        </Badge>
                      )}{' '}
                      {it.isAdditionalInsured && (
                        <Badge pill bg="tertiary" className="my-2 p-2">
                          Additional Insured
                        </Badge>
                      )}{' '}
                      {it.isPrimaryAndNonContributory && (
                        <Badge pill bg="warning" className="my-2 p-2">
                          Primary And Non-Contributory
                        </Badge>
                      )}{' '}
                      {it.isExclusion && (
                        <Badge pill bg="success" className="my-2 p-2">
                          Exclusion
                        </Badge>
                      )}{' '}
                      {it.isDefinition && (
                        <Badge pill bg="info" className="my-2 p-2">
                          Definition
                        </Badge>
                      )}{' '}
                      {it.containsUncapturedScheduledTexts && (
                        <Badge pill bg="secondary" className="my-2 p-2">
                          Uncaptured Scheduled Texts
                        </Badge>
                      )}
                    </td>
                    <td className="align-middle px-3">
                      {it.isLabelled ? 'Labelled' : 'Pending'}
                    </td>
                    <td className="align-middle px-5">
                      <PencilIcon
                        className="react-icon"
                        onClick={() => handleEditClick(it)}
                      />
                    </td>
                    <td className="align-middle px-1">
                      <TrashIcon
                        className="react-icon delete-icon"
                        onClick={() => handleDeleteClick(it)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </Row>
        {visibleMasterFormData?.length > 0 && (
          <div style={{ marginTop: '45px' }}>
            {' '}
            <Pagination
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
              perPage={perPage}
            />
          </div>
        )}

        {visibleMasterFormData?.length < 1 ? (
          <Row className="flex-d justify-content-center mt-3">
            No Forms Found
          </Row>
        ) : (
          ''
        )}
        {showDeleteModal && masterFormDetail?.id && (
          <PopupModalForDelete
            show={showDeleteModal}
            masterFormDetail={masterFormDetail}
            onHide={() => setShowDeleteModal(false)}
            setshowdeletemodal={setShowDeleteModal}
            modalTitle="Delete Master Form"
          />
        )}
      </Container>
    </div>
  )
}

export default styled(MasterFormListComponent)`
  .delete-icon {
    color: #be0202;
    cursor: pointer;
  }
`
