import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'

import { LIST_JOBS_BY_USER_ID_AND_ROLE } from '../graphql/graphql-queries'
import authentication from '../core/authentication'
import Pagination from './Pagination Component/pagination'
import config from './../core/config'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
import CusotmOverlayTooltip from './custom_overlay_tooltip'
export interface JobsListProps extends React.ComponentProps<any> {
  className?: string
  policyId?: string
}
const JobsList: React.FC<JobsListProps> = ({ className, policyId }) => {
  let navigate = useNavigate()
  const [jobs, setJobs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage] = useState(config.itemPerPage)
  const offset = 0
  const [count, setCount] = useState(0)
  let loggedInUser = authentication.getUserData()

  const { refetch, loading, error } = useQuery(LIST_JOBS_BY_USER_ID_AND_ROLE, {
    variables: {
      user: {
        ...loggedInUser,
        nonProcessingPolicyId: policyId,
        offset: currentPage === 1 ? offset : (currentPage - 1) * 25,
        limit: dataPerPage,
      },
    },
    onCompleted: (data) => {
      setJobs(data.getJobsByUserIdAndRole.jobByUserIdData)
      setCount(data.getJobsByUserIdAndRole.count)
    },
  })

  const handleAddTrade = (clickedJob: any) => {
    navigate('/add-trade', { state: clickedJob })
  }

  const handleTradesClick = (job: any) => {
    navigate('/trades-list-for-a-job', {
      state: job,
    })
  }
  useEffect(() => {
    refetch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const jobListArray = jobs
  const perPage = Math.ceil(count / dataPerPage)

  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />

  return (
    <div className={className}>
      <Container className="border text-start bg-white">
        <Row className="fw-bold py-3">
          <Col sm={3}>Job Location</Col>
          <Col sm={1}>Job ID</Col>
          <Col sm={3}>First Named Insured</Col>

          <Col sm={2}>Start Date</Col>
          <Col sm={1}>Claims</Col>
          <Col sm={1}>Trades</Col>
          <Col sm={1}>Actions</Col>
        </Row>
        {!!jobs?.length ? (
          <>
            {jobListArray.map((job: any) => (
              <Row
                className="border-top d-flex align-items-center py-2"
                key={job.id}
              >
                <Col sm={3}>
                  <div>{job?.streetAddress}</div>
                </Col>
                <Col sm={1}>
                  <div>{job?.slug}</div>
                </Col>
                <Col sm={3}>
                  <div>{job?.nonProcessingPolicy?.company?.name}</div>
                </Col>
                <Col sm={2}>
                  <div>{moment(job.startDate).format('MM/DD/YYYY')}</div>
                </Col>
                <Col sm={1}>
                  <CusotmOverlayTooltip tooltipContent="Claims">
                    <span
                      className="span-cursor"
                      onClick={() => {
                        navigate('/claims', {
                          state: {
                            nonProcessingPolicy: '',
                            job: job,
                            contractedCompanyId: '',
                          },
                        })
                      }}
                    >
                      {job?.claims?.length || 0}
                    </span>
                  </CusotmOverlayTooltip>
                </Col>
                <Col sm={1}>
                  <CusotmOverlayTooltip tooltipContent="Trades">
                    <span
                      className="span-cursor"
                      onClick={() => handleTradesClick(job)}
                    >
                      {job.tradeContracts?.length}
                    </span>
                  </CusotmOverlayTooltip>
                </Col>
                <Col sm={1}>
                  <span
                    className="hyperlink-style"
                    onClick={() => handleAddTrade(job)}
                  >
                    Add Trade
                  </span>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <Row className="border-top">
            <p className="text-center m-3">No jobs have been added yet.</p>
          </Row>
        )}
      </Container>
      {jobListArray?.length > 0 && (
        <div style={{ marginTop: '45px' }}>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={perPage}
          />
        </div>
      )}
    </div>
  )
}

export default styled(JobsList)`
  .dots-vertical-icon {
    height: 25px;
    color: #13948b;
    cursor: pointer;
  }

  .span-cursor {
    cursor: pointer;
  }

  .dropdown-toggle::after {
    content: none;
  }
`
