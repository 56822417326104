import { Container, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { ITemplate } from '../../interface'
import IncDecCounter from '../incDecCounter'

export interface CustomRadioDurationProps extends React.ComponentProps<any> {
  className?: string
  list : string[]
  title: string
  caption: string
  name : string
  handleOnChangeScopeAndDuration:(e: any) => void
  // template: ITemplate
  // setTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
  duplicateTemplate: ITemplate
  setDuplicateTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
  selectedValue: string
}


const CustomRadioDuration: React.FC<CustomRadioDurationProps> = ({ className, btnType, list, title, caption, name, duplicateTemplate, setDuplicateTemplate, handleOnChangeScopeAndDuration, selectedValue }) => {
  
  return (
    <div className={`${className} m-4`}>
      <h5 className="fw-bold p-2">{title}</h5>
      <p className="p-2">{caption}</p>
      <div key={`inline-${btnType}`} className="mb-3">
      {list.map((item, i) => {
        return(
                <Container className="border w-50 m-2 p-2 custom-radio-or-checkbox-container" key={`${name}${i}`}>
                  <Form.Check
                      type= {btnType}
                      name={name}
                      id= {`${name}${i}`}
                      key={`${name}${i}`}
                      label={item}
                      onChange={handleOnChangeScopeAndDuration}
                      value={item}
                      defaultChecked={selectedValue===item?true:false}
                  />
                { item === "Ongoing operations and completed operations" &&
                  <IncDecCounter duplicateTemplate={duplicateTemplate} setDuplicateTemplate={setDuplicateTemplate}/>
                }
                </Container>
                )})}
            
                 
      </div>
    </div>
  )
}

export default styled(CustomRadioDuration)`
    .custom-radio-or-checkbox-container {
      border-radius: 5px;
    }
`
