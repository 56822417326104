import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/outline'

export interface NonProcessingPolicyHeaderProps
  extends React.ComponentProps<any> {
  className?: string
  policyStatus: string
  nonProcessingPolicyInfo: any
}

const NonProcessingPolicyHeader: React.FC<NonProcessingPolicyHeaderProps> = ({
  policyStatus,
  nonProcessingPolicyInfo,
}) => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <div>
      <Row>
        <Col className="text-start my-2">
          <span
            className="text-primary-medium fw-bold span-cursor my-2"
            onClick={handleGoBack}
            style={{ cursor: 'pointer' }}
          >
            <ArrowLeftIcon className="arrow-left-icon" />
            Go Back
          </span>
        </Col>
      </Row>
      <Row className="my-4">
        <Col sm={3} className="fw-bold text-start">
          <div>Client Name</div>
          <div>First Named Insured</div>
          <div>CONTACT INFORMATION</div>
        </Col>
        <Col sm={4} className="text-start">
          <div>{nonProcessingPolicyInfo?.clientName}</div>
          <div>{nonProcessingPolicyInfo?.firstNameInsured}</div>
          <div>{nonProcessingPolicyInfo?.clientEmail}</div>
          <div>{nonProcessingPolicyInfo.clientPhoneNumber}</div>
        </Col>
        <Col sm={4} className="text-start">
          <div className="fw-bold">Policy Status</div>
          <div>{policyStatus}</div>
        </Col>
      </Row>
    </div>
  )
}

export default NonProcessingPolicyHeader
