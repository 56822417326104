

import { Card, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { ITemplate } from '../interface'

export interface TemplateRequirementCardProps extends React.ComponentProps<any> {
    template: ITemplate
    editButtonAvailable: boolean
    height?: string
}

const TemplateRequirementCard: React.FC<TemplateRequirementCardProps> = ({ className, template }) => {

    return (
        <div className={className}>
            <Card className="text-start my-4" style={{ height:'20rem' }}>
                <Card.Header className="fw-bold">
                {template.templateName}
                    {/* <Col>
                        <Card.Title as="h7" className="fw-bold">{template.templateName}</Card.Title>
                    </Col> */}
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Card.Title className="fw-bold card-title-text">Limits</Card.Title>
                            <Card.Text>
                                <Row>
                                    <Col>Each Occurence</Col>
                                    <Col className="fw-bold">{template.eachOccurrenceLimit}</Col>
                                </Row>
                            </Card.Text>
                            <Card.Text>
                                <Row>
                                    <Col>Aggregate</Col>
                                    <Col className="fw-bold">{template.aggregateLimit}</Col>
                                </Row>
                            </Card.Text>
                        </Col>
                        <Col>
                            <Card.Title className="fw-bold card-title-text">Scope & Duration</Card.Title>
                            <Card.Text>
                                <span className="fw-bold">Scope: </span><span>{template.scope}</span>
                            </Card.Text>
                            <Card.Text>
                                <span className="fw-bold">Duration:</span>{`${template.duration} years`}<span></span>
                            </Card.Text>
                        </Col>
                        <Col>
                            <Card.Title className="fw-bold card-title-text">Contractual Liability</Card.Title>
                            <Card.Text>
                                {template.contractualLiability && "Trade’s policy must have coverage for it's liability arising out of an insured contract"}
                            </Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}

export default styled(TemplateRequirementCard)`
  .card-title-text {
    font-size: 16px
  }
`
