import { Container, Row, Col } from 'react-bootstrap'

import authentication from '../core/authentication'
import JobsList from './jobs-list'
import CustomButton from './custom-components/custom-button'
import TradeContractorJobsList from './trade-contractor-jobs-list'
import { useParams } from 'react-router-dom'
import BrokerJobsList from './broker-jobs-list'

export interface JobsProps extends React.ComponentProps<any> {}
const Jobs: React.FC<JobsProps> = () => {
  const { policyId } = useParams()

  return (
    <Container>
      <Row className="my-3">
        <Col className="text-primary text-start">
          {authentication.isSuperAdmin() ||
          authentication.isClient() ||
          authentication.isBroker() ? (
            <h3 className="fw-bold">Jobs</h3>
          ) : (
            <h3 className="fw-bold">Choose Job Location</h3>
          )}
        </Col>
        {(authentication.isSuperAdmin() || authentication.isClient()) && (
          <Col className="text-primary text-end">
            {' '}
            <CustomButton
              buttonContent="+ Create New Job"
              onClickPath="/create-job"
            />
          </Col>
        )}
      </Row>
      {/* {authentication.isSuperAdmin() || authentication.isClient() ? (
        <JobsList policyId={policyId} />
      ) : (
        <TradeContractorJobsList />
      )} */}
      {authentication.isSuperAdmin() || authentication.isClient() ? (
        <JobsList policyId={policyId} />
      ) : authentication.isTradeContractor() ? (
        <TradeContractorJobsList />
      ) : authentication.isBroker() ? (
        <BrokerJobsList />
      ) : null}
    </Container>
  )
}

export default Jobs
