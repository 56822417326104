import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import _ from 'lodash'
import { getLabellingPathsForSpecialRequirementsOrProhibitions } from '../../helper-functions'
import { ITemplate } from '../../interface'
import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import SpecialProhibitions from './special-prohibitions'

export interface SpecialProhibitionsPopupProps
  extends React.ComponentProps<any> {
  show: boolean
  onHide: () => void
  duplicateTemplate: ITemplate
  setDuplicateTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
  setShowSpecialProhibitionsPopup: React.Dispatch<React.SetStateAction<boolean>>
  editSpecialProhibitionsAtTemplateEditLevel: boolean
  endorsements: any
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>
  addedSpecialProhibitions: string[]
  setAddedSpecialProhibitions: React.Dispatch<React.SetStateAction<string[]>>
  validateSpecialRequirementsOrProhibitionsSelection: () => boolean
}
const SpecialProhibitionsPopup: React.FC<SpecialProhibitionsPopupProps> = (
  props
) => {
  const [
    checkedElementsForSpecialProhibitions,
    setCheckedElementsForSpecialProhibitions,
  ] = useState([])

  const getSpecialProhibitionsLabelStoredInDB = () => {
    let specialProhibitionsLabelsStoredInDB =
      getLabellingPathsForSpecialRequirementsOrProhibitions(
        props.duplicateTemplate.templateLabels!,
        'Special Prohibitions'
      )
    return specialProhibitionsLabelsStoredInDB
  }

  const handleMarkAsSpecialProhibition = async () => {
    if (!props.validateSpecialRequirementsOrProhibitionsSelection()) {
      let specialProhibiitonsLabelsStoredInDB =
        getSpecialProhibitionsLabelStoredInDB()
      props.setAddedSpecialProhibitions(specialProhibiitonsLabelsStoredInDB)
      props.setShowSpecialProhibitionsPopup(false)
      props.setDuplicateTemplate({
        ...props.duplicateTemplate,
        templateLabelsForSpecialProhibitions:
          specialProhibiitonsLabelsStoredInDB,
      })
      return
    }
    props.setDuplicateTemplate({
      ...props.duplicateTemplate,
      templateLabelsForSpecialProhibitions:
        checkedElementsForSpecialProhibitions.length
          ? checkedElementsForSpecialProhibitions
          : props.addedSpecialProhibitions,
    })
    props.setShowSpecialProhibitionsPopup(false)
  }

  const handleEditSpecialProhibitions = async () => {
    if (!props.validateSpecialRequirementsOrProhibitionsSelection()) {
      let specialProhibiitonsLabelsStoredInDB =
        getSpecialProhibitionsLabelStoredInDB()
      props.setAddedSpecialProhibitions(specialProhibiitonsLabelsStoredInDB)
      props.setShowSpecialProhibitionsPopup(false)
      props.setDuplicateTemplate({
        ...props.duplicateTemplate,
        templateLabelsForSpecialProhibitions:
          specialProhibiitonsLabelsStoredInDB,
      })
      return
    }
    props.setDuplicateTemplate({
      ...props.duplicateTemplate,
      templateLabelsForSpecialProhibitions:
        checkedElementsForSpecialProhibitions.length
          ? checkedElementsForSpecialProhibitions
          : props.addedSpecialProhibitions,
    })
    props.setShowSpecialProhibitionsPopup(false)
  }

  return (
    <div className="popup-modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Special Prohibitions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SpecialProhibitions
            duplicateTemplate={props.duplicateTemplate}
            setDuplicateTemplate={props.setDuplicateTemplate}
            templateId={props.duplicateTemplate.id}
            setCheckedElementsForSpecialProhibitions={
              setCheckedElementsForSpecialProhibitions
            }
            addedSpecialProhibitions={props.addedSpecialProhibitions}
            setAddedSpecialProhibitions={props.setAddedSpecialProhibitions}
            getSpecialProhibitionsLabelStoredInDB={
              getSpecialProhibitionsLabelStoredInDB
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end">
            <span className="m-4">
              {' '}
              {/* <Button
                onClick={handleReset}
                style={{ background: 'orange', border: 'none' }}
              >
                Reset
              </Button> */}
            </span>
            <span className="m-3">
              <Button
                onClick={
                  props.duplicateTemplate?.id
                    ? handleEditSpecialProhibitions
                    : handleMarkAsSpecialProhibition
                }
                style={{ border: 'none' }}
              >
                Done
              </Button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default styled(SpecialProhibitionsPopup)`
  .modal-lg,
  .modal-xl {
    max-width: 75%;
    height: -webkit-fill-available;
  }
  .modal-content {
    height: -webkit-fill-available;
  }
  .modal-header {
    position: sticky;
  }
  .modal-body {
    height: fit-content;
    overflow-y: scroll;
  }
`
