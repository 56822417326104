import { useState, useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { Container, Row } from 'react-bootstrap'
import { LABELLING_LIST } from '../../graphql/graphql-queries'
import MasterFormListComponent from './master-form-list-component'
import LabellingComponent from './labelling-component'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDebounce } from '../custom-hooks/use-debounce'
import CustomAlert from '../custom-components/custom-alert'
import SpinnerLoading from '../spinner-loading'
import ErrorFetchingData from '../error-fetching-data'

type Props = {}

const MasterFormListPageComponent: React.FC<Props> = () => {
  const navigate = useNavigate()
  const location: any = useLocation()
  const [labelForAll, setLabelForAll] = useState<boolean>(false)
  const [filterByStatus, setFilterByStatus] = useState('Pending')
  const [searchTerm, setSearchTerm] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [notification, setNotification] = useState('')
  const [count, setCount] = useState(0)

  const [defaultDataSize, setdefaultDataSize] = useState(25)

  const labellingStatus = ['Labelled', 'Pending', 'All']

  const [masterFormData, setMasterFormData] = useState<any>(null)
  const [visibleMasterFormData, setVisibleMasterFormData] = useState<any>([])
  const [filteredMasterFormData, setFilteredMasterFormData] = useState(
    visibleMasterFormData
  )
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)
  const [formToEditId, setFormToEditId] = useState<any>(null)
  const ref = useRef({ masterData: [], count: 1 })

  const [offset, setOffSet] = useState(0)
  let paginationData = {
    offset: pageNumber === 1 ? offset : (pageNumber - 1) * 25,
    limit: defaultDataSize,
    isLabelled:
      filterByStatus === 'Labelled'
        ? true
        : filterByStatus === 'Pending'
        ? false
        : null,
    searchTerm: searchTerm.length >= 3 ? searchTerm : '',
  }
  useEffect(() => {
    refetch({
      data: paginationData,
    })
  }, [pageNumber])
  useEffect(() => {
    refetch({
      data: paginationData,
    })
    updateMasterFormDataValues()
  }, [filterByStatus])
  const updateMasterFormDataValues = () => {
    let { masterData = [], count = 1 } = ref.current
    setVisibleMasterFormData([...masterData])
    setCount(count)
    if (!location?.state?.flagForPageNumber) {
      setPageNumber(1)
    }
    setOffSet(0)
  }
  useEffect(() => {
    if (searchTerm.length >= 3) {
      setPageNumber(1)
    }
    if (searchTerm === '') {
      refetch()
      updateMasterFormDataValues()
    }
  }, [debouncedSearchTerm, searchTerm])
  const { refetch, loading, error } = useQuery(LABELLING_LIST, {
    variables: {
      data: paginationData,
    },
    onCompleted: (data) => {
      setMasterFormData(data.labels.masterData)
      setVisibleMasterFormData(data.labels.masterData)
      ref.current = {
        masterData: data.labels.masterData,
        count: data.labels.count,
      }
      setCount(data.labels.count)
    },
  })

  useEffect(() => {
    if (location?.state?.refresh) {
      setFilterByStatus(location?.state?.status)
      refetch()
      setPageNumber(location?.state?.pageNumber)
      window.history.replaceState({}, document.title)
    }
  }, [location?.state?.refresh])

  useEffect(() => {
    if (formToEditId) {
      navigate(`/start-labelling/${formToEditId}`, {
        state: {
          unlabelledFormList: [],
          filterByStatus: filterByStatus,
          pageNumber: pageNumber,
        },
      })
    }
  }, [formToEditId])

  const labelAllFromCurrentPage = (labelForAll: boolean) => {
    const unlabelledFormList = masterFormData?.filter(
      (it: any) => !it.isLabelled
    )

    if (unlabelledFormList.length > 0) {
      navigate(`/start-labelling/label-all-form`, {
        state: {
          unlabelledFormList: unlabelledFormList,
          filterByStatus: filterByStatus,
          pageNumber: pageNumber,
        },
      })
    } else {
      setNotification(
        'All forms are labeled for this page. You can edit the labeling.'
      )
    }
  }

  const handleClose = () => {
    setNotification('')
  }

  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />
  return (
    <Container className="m-7 mb-0">
      {notification !== '' && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleClose}
            alertType="success"
            alertMessage={notification}
          />
        </Row>
      )}

      <MasterFormListComponent
        data={masterFormData}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        setFormToEditId={setFormToEditId}
        count={count}
        setSearchTerm={setSearchTerm}
        setFilteredMasterFormData={setFilteredMasterFormData}
        filteredMasterFormData={filteredMasterFormData}
        searchTerm={searchTerm}
        visibleMasterFormData={visibleMasterFormData}
        unlabelledFormDataList={
          masterFormData?.length > 0 &&
          masterFormData?.filter((it: any) => !it.isLabelled)
        }
        filterByStatus={filterByStatus}
        setFilterByStatus={setFilterByStatus}
        labellingStatus={labellingStatus}
        setLabelForAll={setLabelForAll}
        refetch={refetch}
        labelAllFromCurrentPage={labelAllFromCurrentPage}
        labelForAll={labelForAll}
      />
    </Container>
  )
}
export default MasterFormListPageComponent
