import React from 'react'
import { Button, Modal, Row, Col } from 'react-bootstrap'

import { useMutation } from '@apollo/client'
import styled from 'styled-components'

import {
  DELETE_COMPANY,
  DELETE_CLIENT,
  DELETE_MASTER_FORM,
  DELETE_TRADE_CONTRACT_BROKER_RELATIONSHIP,
  LIST_TRADE_CONTRACTOR_BROKERS,
} from '../graphql/graphql-queries'
import { IMasterFormDetail } from '../interface'

export interface PopupModalForDeleteCompanyProps
  extends React.ComponentProps<any> {
  show: boolean
  onHide: () => void
  companyid?: string
  companyname?: string
  className: string
  clientid?: string
  clientname?: string
  masterFormDetail?: IMasterFormDetail
  modalTitle?: string
  setshowdeletemodal: React.Dispatch<React.SetStateAction<boolean>>
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>
}
const PopupModalForDelete: React.FC<PopupModalForDeleteCompanyProps> = (
  props: PopupModalForDeleteCompanyProps
) => {
  const [deleteCompany] = useMutation(DELETE_COMPANY, {
    onCompleted: (data) => {
      props.setshowdeletemodal(false)
      props.onHide()
    },
  })
  const [deleteClient] = useMutation(DELETE_CLIENT, {
    onCompleted: (data) => {
      props.setshowdeletemodal(false)
      props.onHide()
    },
  })

  const [deleteMasterForm] = useMutation(DELETE_MASTER_FORM, {
    onCompleted: (data) => {
      props.setshowdeletemodal(false)
      props.onHide()
    },
  })

  const [deleteTradeContractBrokerRelationship] = useMutation(
    DELETE_TRADE_CONTRACT_BROKER_RELATIONSHIP,
    {
      onCompleted: (data) => {
        props.setshowdeletemodal(false)
        props.onHide()
      },
      onError(err) {
        props?.setErrorMessage &&  props.setErrorMessage('Some error encountered. Please try again!')
        props.setshowdeletemodal(false)
        props.onHide()
      },
      refetchQueries: [
        {
          query: LIST_TRADE_CONTRACTOR_BROKERS,
          variables: {
            tradeContractorId: props?.brokerDetailForDeletion?.tradeContractorId
          },
        },
      ],
    }
  )

  const handleYes = () => {
    if (props.companyid) {
      deleteCompany({
        variables: {
          id: props.companyid,
        },
      })
    }
    if (props.clientid) {
      deleteClient({
        variables: {
          id: props.clientid,
        },
      })
    }
    if (props.masterFormDetail?.id) {
      deleteMasterForm({
        variables: {
          id: props.masterFormDetail?.id,
        },
      })
    }
    if (props.brokerDetailForDeletion?.brokerId) {
      deleteTradeContractBrokerRelationship({
        variables: {
          tradeContractorId: props?.brokerDetailForDeletion?.tradeContractorId,
          brokerId: props.brokerDetailForDeletion?.brokerId,
        },
      })
    }
  }

  const getMasterFormDetail = (masterFormDetail: IMasterFormDetail) => {
    let formTitle = masterFormDetail?.formTitle?.toUpperCase()
    let formNumber = masterFormDetail.formNumber?.toUpperCase()
    let text = ''
    if (formTitle) {
      text = formTitle
    }
    if (formTitle && formNumber) {
      text = `${text} (${formNumber})`
    } else if (!formTitle && formNumber) {
      text = formNumber
    }
    return text ? text : 'Master Form'
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={props.className}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.modalTitle ? props.modalTitle : 'Make Policy Inactive'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete{' '}
          <b>
            {props.companyname
              ? props.companyname
              : props.clientname
              ? props.clientname
              : props.masterFormDetail?.id
              ? getMasterFormDetail(props.masterFormDetail)
              : null}
          </b>
          ?
        </p>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-evenly' }}>
        <Row>
          <Col sm={6} className="text-start">
            <Button
              className="btn btn-warning-medium noButton text-white"
              onClick={props.onHide}
            >
              No
            </Button>
          </Col>
          <Col sm={6} className="text-end">
            <Button
              className="btn btn-primary-medium yesButton btn-sm text-white"
              onClick={() => {
                handleYes()
              }}
            >
              Yes{' '}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default styled(PopupModalForDelete)`
  .yesButton {
    width: 120px;
    height: 35px;
    margin-left: 50px;
    font-size: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .noButton {
    background: orange;
    padding: 7px;
    width: 120px;
    border: none;
    margin-right: 50px;
  }
`
