import React, { useEffect } from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useState } from 'react'
import styled from 'styled-components'
import DropzoneComponent from './dropzone-component'
import { CREATE_NOTICE_FOR_CLAIM } from '../graphql/graphql-queries'
import { useMutation } from '@apollo/client'
import authentication from '../core/authentication'

export interface ClaimNoticeProps extends React.ComponentProps<any> {
  className?: string
  show: boolean
  onHide: () => void
  job: any
  tradeContracts: any
  setErrorNotification: React.Dispatch<React.SetStateAction<any>>
  setShowSuccessNotification: React.Dispatch<React.SetStateAction<any>>
  refetch: any
}
const ClaimNoticeComponent: React.FC<ClaimNoticeProps> = (
  props: ClaimNoticeProps
) => {
  let loggedInUser = authentication.getUserData()

  const [claimNotice, setClaimNotice] = useState({
    date: null,
    time: '',
    jobLocation: props?.job?.streetAddress,
    injuredParty: '',
    incidentDescription: '',
    file: {
      originalName: '',
      assignedName: '',
      path: '',
    },
    tradeContractId: '',
  })
  const [error, setError] = useState({
    date: '',
    jobLocation: '',
    injuredParty: '',
    incidentDescription: '',
  })
  const [isFileUploadedinS3, setIsFileUploadedinS3] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFileUpdatedDetails, setSelectedFileUpdatedDetails] =
    useState<any>(null)
  const [isSubmitClaimProcessing, setIsSubmitClaimProcessing] = useState(false)
  const [isCreateNoticeOfClaimLoading, setIsCreateNoticeOfClaimLoading] =
    useState(false)
  const [showLoding, setShowLoading] = useState(false)
  const handleOnChangeForDate = (date: any) => {
    setClaimNotice({ ...claimNotice, date: date })
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setClaimNotice({ ...claimNotice, [name]: value })
  }

  const handleInjuredPartySelection = (e: any) => {
    const index = e.target.selectedIndex
    const el = e.target.childNodes[index]
    const id = el.getAttribute('id')
    setClaimNotice({
      ...claimNotice,
      injuredParty: e.target.value,
      tradeContractId: id,
    })
  }

  const handleSubmitClaim = async () => {
    let errors = validateForm()

    if (isValid(errors)) {
      if (selectedFile !== null) {
        setIsSubmitClaimProcessing(true)
      }
      setIsCreateNoticeOfClaimLoading(true)
      if (selectedFile === null) {
        submitDataWithoutAttachments()
        setShowLoading(true)
      }
      setError({
        date: '',
        jobLocation: '',
        injuredParty: '',
        incidentDescription: '',
      })
    } else {
      setError(errors)
    }
  }
  const isValid = (errors: any) => {
    let isValid = true
    Object.values(errors).forEach((val: any) => {
      val.length > 0 && (isValid = false)
    })
    return isValid
  }
  const validateForm = () => {
    let errors = {
      date: '',
      jobLocation: '',
      injuredParty: '',
      incidentDescription: '',
    }
    if (!claimNotice.date) {
      errors.date = 'Date is required'
    }
    if (!claimNotice.jobLocation) {
      errors.jobLocation = 'Job Location is required'
    }
    if (!claimNotice.injuredParty) {
      errors.injuredParty = 'Injured Party is required'
    }
    if (!claimNotice.incidentDescription) {
      errors.incidentDescription = 'Incident Description is required'
    }
    return errors
  }
  const submitClaim = async (data: any) => {
    try {
      await createClaimNotice({
        variables: {
          data: {
            ...data,
          },
        },
      })
      props.setShowSuccessNotification('Claim successfully raised.')
      props.onHide()
    } catch (error: any) {
      console.error('Error: ', error)

      setTimeout(() => {
        props.setErrorNotification(
          'Claim can not be raised. It seems your policy is not certified yet!'
        )
        props.onHide()
        setIsCreateNoticeOfClaimLoading(false)
        setShowLoading(false)
      }, 2000)
    }
  }
  const submitDataWithAttachments = async () => {
    let data = {
      dateOfIncident: claimNotice.date,
      address: claimNotice.jobLocation,
      claimNoticeFiledByUserId: loggedInUser?.id,
      jobId: props.job?.id,
      contractedCompanyId: claimNotice.injuredParty,
      descriptionOfIncident: claimNotice.incidentDescription,
      files: {
        originalName: selectedFileUpdatedDetails?.originalFileName,
        assignedName: selectedFileUpdatedDetails?.assignedFileName,
        path: selectedFileUpdatedDetails?.path,
      },
      tradeContractId: claimNotice.tradeContractId,
    }
    submitClaim(data)
  }
  const submitDataWithoutAttachments = async () => {
    let data = {
      dateOfIncident: claimNotice.date,
      address: claimNotice.jobLocation,
      claimNoticeFiledByUserId: loggedInUser?.id,
      jobId: props.job?.id,
      contractedCompanyId: claimNotice.injuredParty,
      descriptionOfIncident: claimNotice.incidentDescription,
      tradeContractId: claimNotice.tradeContractId,
    }

    submitClaim(data)
  }
  const [createClaimNotice] = useMutation(CREATE_NOTICE_FOR_CLAIM, {
    onCompleted: (data) => {
      props.refetch()
      props.onHide()
      setIsCreateNoticeOfClaimLoading(false)
      setShowLoading(false)
    },
  })

  useEffect(() => {
    if (isFileUploadedinS3 && selectedFileUpdatedDetails) {
      submitDataWithAttachments()
    }
  }, [isFileUploadedinS3, selectedFileUpdatedDetails, isSubmitClaimProcessing]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="popup-modal">
      <Modal
        {...props}
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="fs-5 fw-bold">Give Notice of Claim</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mt-4">
              <Row>
                {' '}
                <p className="fs-5 fw-bold">Date of Incident</p>
              </Row>
              <Row>
                <Col className="mt-4">
                  <Form.Group>
                    <Form.Label className="fw-bold">Date</Form.Label>

                    <DatePicker
                      name="startDate"
                      className="datepicker__input"
                      selected={
                        claimNotice.date === '' ? null : claimNotice.date
                      }
                      onChange={(date: Date, e) => {
                        handleOnChangeForDate(date)
                      }}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="MM/dd/yyyy"
                      yearDropdownItemNumber={50}
                      scrollableYearDropdown
                      placeholderText="MM/DD/YYYY"
                      showTimeSelect={true}
                      timeIntervals={30}
                      timeCaption="Time"
                    />
                  </Form.Group>
                  {error.date && (
                    <Row className="mb-0">
                      <span className="m-0 pb-0 text-danger">{error.date}</span>
                    </Row>
                  )}
                </Col>
              </Row>
            </Row>
            <Row className="mt-4">
              <Row>
                {' '}
                <p className="fs-5 fw-bold">Claim Details</p>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className="fw-bold">Job Location</Form.Label>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="Job Location"
                      name="jobLocation"
                      value={props?.job?.streetAddress}
                      readOnly
                    />
                  </Form.Group>
                  {error.jobLocation && (
                    <Row className="mb-0">
                      <span className="m-0 pb-0 text-danger">
                        {error.jobLocation}
                      </span>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="mt-2">
                  <Form.Group>
                    <Form.Label className="fw-bold">
                      Injured Party Employer
                    </Form.Label>
                    <Form.Select
                      className="form-select mt-2"
                      name="injuredParty"
                      aria-label="Default select example"
                      onChange={handleInjuredPartySelection}
                    >
                      <option>Select a Injured Party</option>
                      {props?.tradeContracts?.map((tradeContract: any) => (
                        <option
                          key={tradeContract?.contractedCompany?.id}
                          id={tradeContract?.id}
                          value={tradeContract?.contractedCompany?.id}
                        >
                          {tradeContract?.contractedCompany?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  {error.injuredParty && (
                    <Row className="mb-0">
                      <span className="m-0 pb-0 text-danger">
                        {error.injuredParty}
                      </span>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Form.Group>
                    <Form.Label className="fw-bold">
                      Description of Incident
                    </Form.Label>
                    <Form.Control
                      name="incidentDescription"
                      value={claimNotice.incidentDescription}
                      onChange={(e) => {
                        handleInputChange(e)
                      }}
                      className="mt-2"
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                  {error.incidentDescription && (
                    <Row className="mb-0">
                      <span className="m-0 pb-0 text-danger">
                        {error.incidentDescription}
                      </span>
                    </Row>
                  )}
                </Col>
              </Row>
            </Row>
            <Row className="mt-4">
              <Row>
                <p className="fs-5 fw-bold">
                  Attachments
                  <small style={{ color: '#0c5a55' }}>(optional)</small>
                </p>
                <p className="text-muted">
                  Accident report, photographs, complaint, or other documents
                </p>
                <DropzoneComponent
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  selectedFileUpdatedDetails={selectedFileUpdatedDetails}
                  setSelectedFileUpdatedDetails={setSelectedFileUpdatedDetails}
                  type="claim-notice"
                  setIsFileUploadedinS3={setIsFileUploadedinS3}
                  isFileUploading={isSubmitClaimProcessing}
                />
              </Row>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className="text-end">
            <Col sm={4}>
              <Button
                className="btn btn-warning-medium text-white rounded-pill fw-bold p-2 mx-4"
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </Col>

            <Col sm={8}>
              {isCreateNoticeOfClaimLoading || showLoding ? (
                <Button
                  className="rounded-pill btn btn-primary-medium text-white claim-button fw-bold p-2"
                  disabled={true}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="ps-1">Submitting Claim...</span>
                </Button>
              ) : (
                <Button
                  className="rounded-pill btn btn-primary-medium text-white claim-button fw-bold p-2"
                  onClick={handleSubmitClaim}
                >
                  Submit Claim
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default styled(ClaimNoticeComponent)`
  .modal-lg,
  .modal-xl {
    height: -webkit-fill-available;
  }
  .modal-content {
    height: -webkit-fill-available;
  }
  .modal-header {
    position: sticky;
  }
  .modal-body {
    height: fit-content;
    overflow-y: scroll;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
  .datepicker__input {
    height: 38px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 40%;
  }
  .react-time-picker {
    height: 38px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 70%;
  }

  .btn-warning-medium {
    background-color: orange;
    padding: 9px !important;
    width: 100px;
    border: none;
    marginright: 50px;
  }
`
