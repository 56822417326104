import { Container, Row, Col, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { ITemplate } from '../interface'
import CustomButton from './custom-components/custom-button'
import RangeSlider from './custom-components/range-slider'

export interface LimitsContainerProps extends React.ComponentProps<any> {
  className?: string
  heading: string
  rangeDescription: string
  templateLimitValue: number
  rangeName: string
  duplicateTemplate: ITemplate
  setDuplicateTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
}

const LimitsContainer: React.FC<LimitsContainerProps> = ({
  className,
  heading,
  rangeDescription,
  rangeName,
  currentValue,
  setCurrentValue,
  templateLimitValue,
  duplicateTemplate,
  setDuplicateTemplate,
}) => {
  const radioArray = ['Per Policy', 'Per Location', 'Per Project']
  const handleRadio = (e: any) => {
    setDuplicateTemplate({
      ...duplicateTemplate,
      [e.target.name]: e.target.value,
    })
  }
  return (
    <div className={className}>
      <Container
        className={`${className} align-start mx-0 w-50 border p-4 mb-4`}
      >
        <Row className="mb-4 text-start">
          <Col>
            <h6 className="fw-bold">{heading}</h6>
            <p className="small text-secondary fw-bold">{rangeDescription}</p>
          </Col>
          <Col>
            <CustomButton
              buttonContent={`$ ${templateLimitValue.toLocaleString()}`}
              buttonBg="btn-secondary"
            />
          </Col>
          <Col>
            <div className="custom-pills"></div>
          </Col>
        </Row>
        {rangeName === 'aggregateLimit' && (
          <div className="radioButton">
            {radioArray.map((el: string, index: number) => (
              <Form.Check
                onChange={handleRadio}
                type="radio"
                key={`${el}${index}`}
                name="aggregateType"
                value={el}
                label={el}
                checked={duplicateTemplate.aggregateType === el}
              />
            ))}
          </div>
        )}
        <RangeSlider
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
          duplicateTemplate={duplicateTemplate}
          setDuplicateTemplate={setDuplicateTemplate}
          templateLimitValue={templateLimitValue}
          name={rangeName}
        />
      </Container>
    </div>
  )
}

export default styled(LimitsContainer)`
  .radioButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: 12px;
    margin-bottom: 5px;
  }
`
