import { Container, Row, Col, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { IDocumentInputs, IPolicyDetailsValidationEror } from '../../interface'
import ValidationError from '../validation-error'

export interface DocumentInputsProps extends React.ComponentProps<any> {
  className?: string
  documentInputs: IDocumentInputs
  setDocumentInputs: React.Dispatch<React.SetStateAction<IDocumentInputs>>
  error: IPolicyDetailsValidationEror
}

const DocumentInputs: React.FC<DocumentInputsProps> = ({
  className,
  documentInputs,
  setDocumentInputs,
  error,
}) => {
  const documentTypeDropdownOptions = [
    { id: 1, type: 'TEXT_FILE' },
    { id: 2, type: 'SCANNED_FILE' },
  ]

  const handleDocumentInputsChange = (e: any) => {
    let { name, value, type } = e.target
    if (name === 'documentType') {
      setDocumentInputs({
        ...documentInputs,
        type: type === 'number' ? Number(value) : value,
      })
      return
    }
    setDocumentInputs({
      ...documentInputs,
      [name]: type === 'number' ? Number(value) : value,
    })
  }

  return (
    <div className={className}>
      <Container className="text-start border document-container">
        <p className="fs-5 fw-bold">Document Details</p>
        <Form role="form" className=" ">
          <Form.Group>
            <Row>
              <Col>
                <Form.Label className="required-field">Total Pages</Form.Label>
                <Form.Control
                  name="totalPages"
                  value={documentInputs?.totalPages}
                  type="number"
                  min="0"
                  onChange={handleDocumentInputsChange}
                  autoComplete="off"
                ></Form.Control>
                <ValidationError errorMessage={error?.document?.totalPages} />
              </Col>
              <Col>
                <Form.Label className="required-field">
                  Document Type
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="documentType"
                  onChange={handleDocumentInputsChange}
                  value={documentInputs?.type}
                >
                  <option value="" disabled>
                    Select Document Type
                  </option>
                  {documentTypeDropdownOptions.map(
                    (documentTypeDropdownOption: any) => (
                      <option
                        key={documentTypeDropdownOption?.id}
                        value={documentTypeDropdownOption?.type}
                      >
                        {documentTypeDropdownOption.type}
                      </option>
                    )
                  )}
                </Form.Select>
                <ValidationError errorMessage={error?.document?.type} />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Container>
    </div>
  )
}

export default styled(DocumentInputs)`
  .document-container {
    padding: 40px;
  }
`
