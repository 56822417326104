import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import PolicyInReviewImage from '../assets/images/review-policy.png'
import CustomClickableButton from './custom-components/custom-clickable-button'

export interface PolicyInReviewProps extends React.ComponentProps<any> {
  className?: string
  currentStepIndex?: number
  setCurrentStepIndex?: React.Dispatch<React.SetStateAction<number>>
}

const PolicyInReview: React.FC<PolicyInReviewProps> = ({
  currentStepIndex,
  setCurrentStepIndex,
}) => {
  let navigate = useNavigate()

  const handleOnClickViewJobs = () => {
    window.location.href = '/jobs'
  }

  return (
    <Container>
      <Row className="mt-5">
        <Col className="w-25">
          <img
            src={PolicyInReviewImage}
            height={300}
            width={300}
            alt="Policy In Review"
          />
        </Col>
        <Row className="my-4">
          <h5 className="fw-bold">We are reviewing your policy.</h5>
          <p>Expect an email from us in no more than 48 hours.</p>
          <p>
            If you have any questions in the meantime, please reach out to our
            team at{' '}
            <p className="text-primary fw-bold">
              <a href="mailto:help@resolvedrisk.com" className="email-link">
                help@resolvedrisk.com
              </a>
            </p>
          </p>
        </Row>
      </Row>
      <CustomClickableButton
        buttonContent="View My Jobs"
        handleOnClick={handleOnClickViewJobs}
        disable={false}
      />
    </Container>
  )
}

export default PolicyInReview
