import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";

import FilterContainer from './filter-container'
import CustomClickableButton from './custom-components/custom-clickable-button'
import PolicyHoldersList from './policy-holders-list'


export interface PolicyHoldersComponentProps extends React.ComponentProps<any> {
}
const PolicyHoldersComponent: React.FC<PolicyHoldersComponentProps> = () => {

    let navigate = useNavigate();

    const handleCreatePolicyholder = () => {
        navigate('/create-policyholder');
    }

    return (
        <Container>
            <Row className="my-4">
                <Col className="text-primary text-start"><h4>Policyholders</h4></Col>
                <Col className="text-primary text-end">
                    <CustomClickableButton buttonContent="+ Create Policyholder" handleOnClick={handleCreatePolicyholder} disable={false} />
                </Col>
            </Row>
            <FilterContainer />
            <PolicyHoldersList />
        </Container>
    )
};

export default PolicyHoldersComponent;



