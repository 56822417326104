import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import authentication from '../../core/authentication'
import { appConfig } from '../../core'
import axios from 'axios'
import CheckboxTree from 'react-checkbox-tree'
export interface LabellerComponentProps extends React.ComponentProps<any> {
  className: string
  state: any
  setState: React.Dispatch<any>
}
const LabellingComponentPage: React.FC<LabellerComponentProps> = ({
  className,
  state,
  setState,
}) => {
  const [labellerHierarchyJson, setLabellerHierarchyJson] = useState<any[]>([])

  const handledOnCheck = (checked: any) => {
    setState({ ...state, checked })
  }

  const handledOnClick = (clicked: any) => {
    setState({ ...state, clicked })
  }

  const handledOnExpand = (expanded: any) => {
    setState({ ...state, expanded })
  }

  useEffect(() => {
    const token = authentication.getAccessToken()
    axios
      .get(`${appConfig.apiUrl}/rest/labels-for-checkbox-tree`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLabellerHierarchyJson(response.data)
      })
      .catch((err) => console.error('Error: ', err))
  }, [])

  return (
    <div className={`${className} w-100`}>
      <Container className="border rounded-3 p-2 checkbox-tree-wrapper">
        <CheckboxTree
          nodes={labellerHierarchyJson}
          checked={state.checked}
          expanded={state.expanded}
          onCheck={(checked) => handledOnCheck(checked)}
          onExpand={(expanded) => handledOnExpand(expanded)}
          onClick={(targetNode) => handledOnClick(targetNode)}
          showNodeIcon={true}
        />
      </Container>
    </div>
  )
}
export default styled(LabellingComponentPage)`
  .checkbox-tree-wrapper {
    // height: fit-content;
    max-height: 400px;
    overflow-y: scroll;
  }
`
