import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  GET_CLIENT_COMPANIES,
  GET_CLIENT_DETAIL,
} from '../graphql/graphql-queries'
import CustomClickableButton from './custom-components/custom-clickable-button'
import Pagination from './Pagination Component/pagination'
import PopupModal from './popup-modal'
import config from './../core/config'
import PopupModalForDelete from './delete-company-popup'
import styled from 'styled-components'
import CustomAlert from './custom-components/custom-alert'
import { useParams } from 'react-router-dom'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
import CusotmOverlayTooltip from './custom_overlay_tooltip'
import { PencilIcon } from '@heroicons/react/outline'
type Props = {
  className?: string
}

const ClientCompanyDetail: React.FC<Props> = (props: Props) => {
  const location: any = useLocation()
  const navigate = useNavigate()
  const { clientId } = useParams()
  const [clientCompanyDetail, setClientCompanyDetail] = useState<any>([])
  const [clientDetail, setClientDetail] = useState<any>({})
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage] = useState(config.itemPerPage)
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [companyId, setCompanyId] = useState('')
  const [companyName, setCompanyname] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [count, setCount] = useState(1)
  const [isEdit, setIsEdit] = useState(false)
  let PaginationClientCompanyInputData = {
    offset: currentPage === 1 ? 0 : (currentPage - 1) * 25,
    limit: dataPerPage,
  }
  const { refetch, loading, error } = useQuery(GET_CLIENT_COMPANIES, {
    variables: { clientId: clientId, data: PaginationClientCompanyInputData },
    onCompleted: (data) => {
      setClientCompanyDetail(data?.listCompanies?.companyData)
      setCount(data?.listCompanies?.count)
    },
  })

  useQuery(GET_CLIENT_DETAIL, {
    variables: {
      id: clientId,
    },
    onCompleted: (data) => {
      setClientDetail(data?.getUserDetail)
    },
  })
  useEffect(() => {
    refetch()
  }, [showModal, showDeleteModal]) // eslint-disable-line react-hooks/exhaustive-deps
  const handleDelete = (id: string, name: string) => {
    setCompanyId(id)
    setCompanyname(name)
    setShowDeleteModal(true)
  }

  const perPage = Math.ceil(count / dataPerPage)
  const handleClose = () => {
    setSuccessMessage('')
    setErrorMessage('')
  }
  const handleEditClick = (id: string, name: string) => {
    setCompanyId(id)
    setShowModal(true)
    setCompanyname(name)
    setIsEdit(true)
  }
  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />
  return (
    <Container className={props.className}>
      {successMessage !== '' && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleClose}
            alertType="success"
            alertMessage={successMessage}
          />
        </Row>
      )}
      {errorMessage !== '' && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleClose}
            alertType="error"
            alertMessage={errorMessage}
          />
        </Row>
      )}
      <Row className="my-4 text-start">
        <Col sm={2} className="fw-bold">
          Client Name
        </Col>
        <Col sm={4}>{clientDetail?.fullName}</Col>
        <Col className="text-primary text-end">
          <CustomClickableButton
            buttonContent="Add First Named Insured"
            handleOnClick={() => {
              setShowModal(true)
              setShowDeleteModal(false)
              setIsEdit(false)
            }}
            disable={false}
          />
        </Col>
      </Row>
      <div className="client-list shadow-none p-3 mb-5 bg-white rounded">
        <Row className=" fw-bold border-bottom border-light pb-2">
          <Col sm={2} className="text-start">
            First Named Insured
          </Col>
          <Col sm={3}>Number of Policies</Col>
          <Col sm={3}>Number of Active Policies</Col>
          <Col sm={3}>Number of Inactive Policies</Col>
        </Row>

        {clientCompanyDetail.map((it: any, index: number) => {
          return (
            <Row
              className="border-bottom border-light p-2"
              key={it.name + index}
            >
              <Col className="text-start " sm={2}>
                <div className="d-flex justify-content-between align-items-center">
                  <span
                    onClick={() => {
                      navigate('/policy-listing', {
                        state: {
                          firstNameInsuredId: it.id,
                          firstNameInsured: it.name,
                          clientName: clientDetail.fullName,
                          companyId: it.id,
                          clientEmail: clientDetail.email,
                          clientPhoneNumber: clientDetail.phoneNumber,
                        },
                      })
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {it.name}
                  </span>
                  <span className="ms-4">
                    <CusotmOverlayTooltip tooltipContent="Edit">
                      <PencilIcon
                        className="react-icon"
                        onClick={() => handleEditClick(it.id, it.name)}
                      />
                    </CusotmOverlayTooltip>
                  </span>
                </div>
              </Col>
              <Col sm={3}>{it.numberOfPolicies}</Col>
              <Col sm={3}>{it.numberOfActivePolicies}</Col>
              <Col sm={3}>{it.numberOfNonActivePolicies}</Col>
              {/* <Col>
                <CusotmOverlayTooltip tooltipContent="Delete">
                  <TrashIcon
                    className="trashIcon"
                    onClick={() => {
                      handleDelete(it.id, it.name)
                    }}
                  />
                </CusotmOverlayTooltip>
              </Col> */}
            </Row>
          )
        })}
      </div>
      {clientCompanyDetail.length > 0 && (
        <div>
          {' '}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={perPage}
          />
        </div>
      )}

      {showModal && (
        <PopupModal
          show={showModal}
          onHide={() => setShowModal(false)}
          message={
            !isEdit ? 'Add First Named Insured' : 'Update First Named Insured'
          }
          firstnameinsured="Name"
          page="Client_Company_List"
          clientid={clientId}
          setshowmodal={setShowModal}
          setsuccessmessage={setSuccessMessage}
          seterrormessage={setErrorMessage}
          companyname={companyName}
          companyid={companyId}
        />
      )}
      {showDeleteModal && (
        <PopupModalForDelete
          show={showDeleteModal}
          companyid={companyId}
          companyname={companyName}
          onHide={() => setShowDeleteModal(false)}
          setshowdeletemodal={setShowDeleteModal}
        />
      )}
    </Container>
  )
}
export default styled(ClientCompanyDetail)`
  .trashIcon {
    color: red;
    width: 21px;
    cursor: pointer;
  }
`
