import { Form } from 'react-bootstrap'
import { ITemplate } from '../../interface'

export interface RangeSliderProps extends React.ComponentProps<any> {
  className?: string
  setCurrentValue: React.Dispatch<React.SetStateAction<number>>
  currentValue: number
  duplicateTemplate: ITemplate
  setDuplicateTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
  templateLimitValue: number
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  setTemplate,
  duplicateTemplate,
  setDuplicateTemplate,
  templateLimitValue,
  name,
}) => {
  const handleRangeChange = (e: any) => {
    let value = e.target.value.replace(/,/g, '')
    if (!value.match(/[a-zA-Z\s]|[^a-zA-Z0-9\s]/g)) {
      setDuplicateTemplate({
        ...duplicateTemplate,
        [e.target.name]: Number(value),
      })
    }
  }
  return (
    <>
      <Form.Group>
        <Form.Control
          type="text"
          onChange={handleRangeChange}
          value={templateLimitValue.toLocaleString('en-US')}
          name={name}
        />
      </Form.Group>
    </>
  )
}

export default RangeSlider
