import { useEffect, useState } from 'react'
import { Container, Row, Col, Badge } from 'react-bootstrap'
import moment from 'moment'
import styled from 'styled-components'
import config from '../core/config'
import { useLocation, useNavigate } from 'react-router-dom'

import Pagination from './Pagination Component/pagination'
import {
  formatByInsertingHyphens,
  getLatestUploadedFileFromList,
  getLatestUploadedPolicyCertificationStatusFromList,
  setTadeStatus,
  getLatestUploadedPolicyFromList,
} from '../helper-functions'
import NonProcessingPolicyHeader from './non-processing-policy-header'
import CusotmOverlayTooltip from './custom_overlay_tooltip'
import { GET_TRADE_CONTRACTS_BY_NON_PROCESSING_POLICY_ID } from '../graphql/graphql-queries'
import { useQuery } from '@apollo/client'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
import { DocumentTextIcon, PencilIcon } from '@heroicons/react/solid'
import ShowBadgeForCertificationStatus from './show-badge-for-certification-status'

export interface TradesListProps extends React.ComponentProps<any> {
  className?: string
  job?: any
  handleAddTradeClick: () => void
}

const TradesListingAdmin: React.FC<TradesListProps> = ({ className }) => {
  const {
    state: { policyId, policyStatus, nonProcessingPolicyInfo },
  }: any = useLocation()
  const offset = 0
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const dataPerPage = config.itemPerPage
  const [tradeContracts, setTradeContracts] = useState([])
  const tradeContractArray = tradeContracts
  const perPage = Math.ceil(count / dataPerPage)
  let navigate = useNavigate()

  let getCertifiedPolicyCertificationDate = (tradeContract: any) => {
    let latestUploadedFile = getLatestUploadedFileFromList(tradeContract)
    let latestUploadedPolicy = getLatestUploadedPolicyFromList(
      tradeContract,
      latestUploadedFile
    )
    return latestUploadedPolicy?.createdAt
  }
  let paginationDataForTradeContracts = {
    nonProcessingPolicyId: policyId,
    offset: currentPage === 1 ? offset : (currentPage - 1) * 25,
    limit: dataPerPage,
  }
  const { refetch, loading, error } = useQuery(
    GET_TRADE_CONTRACTS_BY_NON_PROCESSING_POLICY_ID,
    {
      variables: {
        data: paginationDataForTradeContracts,
      },
      onCompleted: (data) => {
        setTradeContracts(
          data.listTradeContractsByNonProcessingPolicyId.TradeContracts
        )
        setCount(data.listTradeContractsByNonProcessingPolicyId.count)
      },
    }
  )

  useEffect(() => {
    refetch({ data: paginationDataForTradeContracts })
  }, [tradeContracts])
  const handleClaimsClick = (trade: any) => {
    navigate('/claims-list', {
      state: {
        job: trade?.job,
        contractedCompanyId: trade?.contractedCompanyId,
      },
    })
  }
  const handleEditClick = (trade: any) => {
    navigate('/update-trade-contractor', {
      state: {
        tradeContractor: trade,
      },
    })
  }
  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />
  return (
    <div className={`${className} m-3`}>
      <NonProcessingPolicyHeader
        policyStatus={policyStatus}
        nonProcessingPolicyInfo={nonProcessingPolicyInfo}
      />
      <hr className="ms-3 small text-secondary" />

      <Container className="border text-start bg-white">
        <Row className="my-3">
          <Col className="text-start">
            <p className="fw-bold fs-5 ms-1">Trades Contracted</p>
          </Col>
        </Row>
        <Row className="fw-bold">
          <Col sm={3}>Company</Col>
          <Col sm={1}>EIN</Col>
          <Col sm={2}>Contact Name</Col>
          <Col sm={1}>Date Certified</Col>
          <Col sm={1}>Claims</Col>
          <Col sm={1}>Trades Status</Col>
        </Row>
        {!!tradeContracts?.length ? (
          tradeContractArray?.map((trade: any) => (
            <Row
              className="border-top d-flex align-items-center py-2"
              key={trade.id}
            >
              <Col sm={3}>
                <div className="d-flex justify-content-between me-5">
                  <span>{trade.contractedCompany.name}</span>
                  <span>
                    <CusotmOverlayTooltip tooltipContent="Edit">
                      <PencilIcon
                        className="react-icon"
                        onClick={() => handleEditClick(trade)}
                      />
                    </CusotmOverlayTooltip>
                  </span>
                </div>
                <div className="text-muted pt-1">
                  <span className="fw-bold">Job Type: </span>
                  {trade?.jobName}
                </div>
              </Col>
              <Col sm={1}>
                <div>
                  {trade?.contractedCompany?.ein
                    ? formatByInsertingHyphens(trade?.contractedCompany?.ein)
                    : 'Pending'}
                </div>
              </Col>
              <Col sm={2}>
                <div>{`${trade?.contractedCompanyUser?.firstName} ${trade?.contractedCompanyUser?.lastName}`}</div>
              </Col>
              <Col sm={1}>
                <div>
                  {getLatestUploadedPolicyCertificationStatusFromList(trade) ===
                  'CERTIFIED'
                    ? moment(getCertifiedPolicyCertificationDate(trade)).format(
                        'MM/DD/YYYY'
                      )
                    : 'Pending'}
                </div>
              </Col>
              <Col sm={1}>
                <CusotmOverlayTooltip tooltipContent="Click to see claims">
                  <span
                    className="clickable-text"
                    onClick={() => {
                      handleClaimsClick(trade)
                    }}
                  >
                    {trade?.contractedCompany?.claims?.length
                      ? trade?.contractedCompany?.claims?.length
                      : 0}
                  </span>
                </CusotmOverlayTooltip>
              </Col>
              <Col sm={1}>
                {
                  <div>
                    <ShowBadgeForCertificationStatus tradeContract={trade} />
                  </div>
                }
              </Col>
              <Col>
                {(getLatestUploadedPolicyCertificationStatusFromList(trade) ===
                  'NOT CERTIFIED' ||
                  getLatestUploadedPolicyCertificationStatusFromList(trade) ===
                    'CERTIFIED') && (
                  <Badge
                    pill
                    bg="primary-light"
                    className="text-dark badge-document badge-view-policy-detail"
                    onClick={() => {
                      navigate(`/review-policy/${trade.id}`, {
                        state: {
                          clickedFile: '',
                        },
                      })
                    }}
                  >
                    <DocumentTextIcon className="check-icon hero-icon-primary" />
                    View Policy Detail
                  </Badge>
                )}
              </Col>
            </Row>
          ))
        ) : (
          <Row className="border-top">
            <p className="text-center m-3">No trades have been added yet.</p>
          </Row>
        )}
      </Container>
      {tradeContractArray?.length > 0 && (
        <div style={{ marginTop: '50px' }}>
          {' '}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={perPage}
          />
        </div>
      )}
    </div>
  )
}

export default styled(TradesListingAdmin)`
  .span-cursor,
  .dots-vertical-icon {
    height: 25px;
    color: #13948b;
    cursor: pointer;
  }
`
