import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  TEMPLATES_LIST,
  PROHIBITED_ENDORSEMENTS,
} from '../graphql/graphql-queries'
import { ITemplate } from '../interface'
import ReviewAndPublishTemplate from './review-and-publish-template'
import { useEffect, useState } from 'react'
import Pagination from './Pagination Component/pagination'
import config from '../core/config'
import SpecialRequirementsPopup from './special-requirements/special-requirements-popup'
import SpecialProhibitionsPopup from './special-prohibitions/special-prohibitions-popup'
import _ from 'lodash'
import TemplateCard from './template-card'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'

export interface RequirementsCardProps extends React.ComponentProps<any> {}

const RequirementsCard: React.FC<RequirementsCardProps> = ({ className }) => {
  const location: any = useLocation()
  const [currentPageShow, setCurrentPageShow] = useState(1)
  const offset = 0
  const dataPerPage = config.itemPerPage
  const PaginationTemplateData = {
    offset: currentPageShow === 1 ? offset : (currentPageShow - 1) * 25,
    limit: dataPerPage,
  }
  const [templateData, setTemplateData] = useState<any>(null)
  const [count, setCount] = useState(0)
  const { refetch, loading, error } = useQuery(TEMPLATES_LIST, {
    variables: {
      data: PaginationTemplateData,
    },
    onCompleted: (data) => {
      setTemplateData(data.listTemplates.templates)
      setCount(data.listTemplates.count)
    },
  })

  let [template, setTemplate] = useState<ITemplate>({
    templateName: '',
    eachOccurrenceLimit: 1000000,
    aggregateLimit: 1000000,
    personalAdvertisingLimit: 50000,
    projectCompletedOperationLimit: 1000000,
    deductible: 10000,
    scope: 'Arising out of the work',
    durationType: 'Ongoing operations',
    duration: 1,
    contractualLiability: false,
    priorityOfCoverage: false,
    noticeOfChange: false,
    waiverOfSubrogation: false,
    reviewerEmailAddress: '',
    reviewerMailingAddress: '',
    isPublished: false,
    id: '',
    aggregateType: 'Per Policy',
    containsSpecialRequirements: false,
    containsSpecialProhibitions: false,
    templateLabels: [],
    templateLabelsForSpecialRequirements: [],
    templateLabelsForSpecialProhibitions: [],
  })

  const obj = { ...template }
  const [duplicateTemplate, setDuplicateTemplate] = useState(obj)

  let [prohibitedEndorsements, setProhibitedEndorsements] = useState<any>([])
  let [isTempleateSelected, setIsTempleateSelected] = useState(false)
  let stepIndex = [0, 1, 2, 3, 4]
  let [isDataSaved, setIsDataSaved] = useState(false)
  let [currentPage, setCurrentPage] = useState('Review and Publish')
  let [templateId, setTemplateId] = useState('')
  let [currentStepIndex, setCurrentStepIndex] = useState(stepIndex[4])
  const [showSpecialRequirementsPopup, setShowSpecialRequirementsPopup] =
    useState(false)
  const [showSpecialProhibitionsPopup, setShowSpecialProhibitionsPopup] =
    useState(false)

  const prohibitedEndorsementsList = useQuery(PROHIBITED_ENDORSEMENTS, {
    variables: { templateId },
  })
  const data1 = prohibitedEndorsementsList.data
  const listOfProhibitedEndorsements: any = []
  useEffect(() => {
    if (data1) {
      setProhibitedEndorsements(data1)
    }
  }, [data1])

  useEffect(() => {
    if (prohibitedEndorsements.listProhibitedEndorsements?.length > 0) {
      setIsTempleateSelected(true)
    }
  }, [prohibitedEndorsements])

  if (prohibitedEndorsements.listProhibitedEndorsements?.length > 0) {
    prohibitedEndorsements.listProhibitedEndorsements.map((it: any) =>
      listOfProhibitedEndorsements.push(it.masterForm)
    )
  }
  const templateArray = templateData
  const perPage = Math.ceil(count / dataPerPage)

  useEffect(() => {
    if (location?.state?.refresh) {
      refetch()
    }
  }, [location?.state?.refresh])

  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />
  return templateData?.length ? (
    <div className={className}>
      {isTempleateSelected ? (
        <ReviewAndPublishTemplate
          setCurrentStep={setCurrentPage}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          template={template}
          setTemplate={setTemplate}
          prohibitedEndorsements={listOfProhibitedEndorsements}
          setIsDataSaved={setIsDataSaved}
          isDataSaved={isDataSaved}
          currentPage={currentPage}
        />
      ) : (
        <>
          <div>
            {templateData?.length ? (
              templateArray.map((it: any) => (
                <TemplateCard template={it} hasEditOption={true} />
              ))
            ) : (
              <></>
            )}
          </div>
          {templateArray.length > 0 && (
            <div className="template-pagination">
              <Pagination
                currentPage={currentPageShow}
                setCurrentPage={setCurrentPageShow}
                perPage={perPage}
              />
            </div>
          )}
          {showSpecialRequirementsPopup && (
            <SpecialRequirementsPopup
              show={showSpecialRequirementsPopup}
              onHide={() => setShowSpecialRequirementsPopup(false)}
              duplicateTemplate={duplicateTemplate}
              setDuplicateTemplate={setDuplicateTemplate}
              setShowSpecialRequirementsPopup={setShowSpecialRequirementsPopup}
              refetch={refetch}
              editSpecialRequirementsAtTemplateEditLevel={false}
            />
          )}
          {showSpecialProhibitionsPopup && (
            <SpecialProhibitionsPopup
              show={showSpecialProhibitionsPopup}
              onHide={() => setShowSpecialProhibitionsPopup(false)}
              duplicateTemplate={duplicateTemplate}
              setDuplicateTemplate={setDuplicateTemplate}
              setShowSpecialProhibitionsPopup={setShowSpecialProhibitionsPopup}
              refetch={refetch}
              editSpecialProhibitionsAtTemplateEditLevel={false}
            />
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  )
}

export default styled(RequirementsCard)`
  .template-pagination {
    margin-top: 45px;
  }
`
