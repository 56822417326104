import { CheckIcon } from '@heroicons/react/solid'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'

export interface MultiStepProgressBarProps extends React.ComponentProps<any> {
  className: string
  progressBarHeading: string
  steps: string[]
  stepIndex: number[]
  currentPage: string
  currentStepIndex: number
}

const MultiStepProgressBar: React.FC<MultiStepProgressBarProps> = ({
  className,
  progressBarHeading,
  steps,
  stepIndex,
  currentPage,
  currentStepIndex,
}) => {
  return (
    <div className={`${className}`}>
      <Container className={`bg-greyish ${className}`}>
        <h6 className="fw-bold text-start px-1">{progressBarHeading}</h6>

        <div className="list-wrapper">
          <div className="red-line"></div>

          {steps.map((it: any, index) => (
            <div className="list-item-wrapper" key={`step-${index}`}>
              {currentPage === it && (
                <div className="list-bullet list-bullet-active"></div>
              )}

              {currentPage !== it && index < currentStepIndex && (
                <div className="list-bullet list-bullet-completed">
                  <CheckIcon style={{ height: '20px' }} />
                </div>
              )}

              {currentPage !== it && !(index < currentStepIndex) && (
                <div className="list-bullet"></div>
              )}

              <div className="list-item">
                <div
                  className={
                    currentPage === it ? 'list-title-active' : 'list-title'
                  }
                >
                  {it}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default styled(MultiStepProgressBar)`
  .list-wrapper {
    position: relative;
    // background:#F7F5F4;
  }
  .list-item-wrapper {
    margin-top: 30px;
    position: relative;
  }
  .list-bullet {
    float: left;
    margin-right: 15px;
    background: #ffffff;
    // background:rgb(163, 86, 86);
    height: 30px;
    width: 30px;
    line-height: 25px;
    border-radius: 100px;
    font-weight: 700;
    color: white;
    text-align: center;
    border: 2px solid #e5e5e5;
  }
  .list-item {
    display: table-row;
    vertical-align: middle;
  }
  .list-title-active {
    font-weight: 700;
  }
  .list-text {
    font-weight: 400;
  }
  .red-line {
    // background:#FF4949;
    background: #e5e5e5;
    // z-index:-1;
    width: 3px;
    height: 100%;
    position: absolute;
    left: 15px;
  }
  .white-line {
    background: #fff;
    z-index: -1;
    top: 0px;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 15px;
  }
  .list-bullet-active {
    background: #ec8046;
  }
  .list-bullet-completed {
    background: #13948b;
  }
  .progress-bar-wrapper {
    height: 100vh;
    background: #f7f5f4;
  }
`
