import { Row, Alert } from 'react-bootstrap'
import styled from 'styled-components'

export interface ValidationErrorProps extends React.ComponentProps<any> {
    className: string
    errorMessage: string
}

const ValidationError: React.FC<ValidationErrorProps> = ({ className, errorMessage }) => {

    return (
            <Row className={`${className} mb-0`}>
                <Alert variant="danger" className="m-0 pb-0 validation-error-alert">
                    <p>{errorMessage}</p>
                </Alert>
            </Row>
    )
}

export default styled(ValidationError)`
    .validation-error-alert {
        background: transparent
    }
    .alert-danger {
        padding-top: 2px;
        padding-left: 12px;
        color: #e51e30
    }
`