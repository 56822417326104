import React, { useState } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'

import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import ValidationError from './validation-error'
import { MAKE_INACTIVE_POLICY } from '../graphql/graphql-queries'

export interface PopupModalForInactivePolicyProps
  extends React.ComponentProps<any> {
  show: boolean
  onHide: () => void
  policyid: string
}
const PopupModalForInactivePolicy: React.FC<
  PopupModalForInactivePolicyProps
> = (props: PopupModalForInactivePolicyProps) => {
  const {
    register,
    handleSubmit: handleCreatePolicyholderNextClick,
    formState,
    watch,
  } = useForm()
  const onError = (errors: any, e: any) => console.log(errors, e)
  const [text, setText] = useState('')
  const handleSaveButtonClick = () => {
    makePolicyInactive(props.policyid)
  }
  const makePolicyInactive = (id: string) => {
    inactivePolicy({
      variables: {
        id: id,
      },
    })
  }
  const [inactivePolicy] = useMutation(MAKE_INACTIVE_POLICY, {
    onCompleted: (data) => {
      props.onHide()
    },
  })
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Make Policy Inactive
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to make this policy inactive? Please type{' '}
          <b>" INACTIVE"</b>
          in the text box below to confirm.
        </p>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              {...register('text', {
                required: {
                  value: true,
                  message: 'You need to type INACTIVE before continue.',
                },
                validate: (val: string) => {
                  if (watch('text') !== 'INACTIVE') {
                    return 'You need to check spell of  INACTIVE before continue.'
                  }
                },
              })}
              type="text"
              placeholder="Type INACTIVE"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            {formState.errors.text && (
              <ValidationError errorMessage={formState.errors.text.message} />
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-evenly' }}>
        <Row>
          <Col sm={6} className="text-start">
            <Button
              className="btn btn-warning-medium text-white"
              onClick={props.onHide}
              style={{
                backgroundColor: 'orange',
                padding: '7px',
                width: '120px',
                border: 'none',
                marginRight: '50px',
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col sm={6} className="text-end">
            <Button
              className="btn btn-primary-medium text-white"
              style={{ width: '120px', marginLeft: '50px', padding: '7px' }}
              onClick={handleCreatePolicyholderNextClick(
                handleSaveButtonClick,
                onError
              )}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default styled(PopupModalForInactivePolicy)``
