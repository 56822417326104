import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-timezone'
import { appConfig } from './core'
import authentication from './core/authentication'
import { IDatum, IJobTypeProhibitionsLabel, ITemplateLabel } from './interface'

export const getLatestUploadedFileFromList = (tradeContract: any) => {
  if (!tradeContract?.fileUploads?.length) {
    return null
  } else if (tradeContract?.fileUploads?.length === 1) {
    return tradeContract.fileUploads[0]
  } else {
    let sortedFileUploadsByLatestDate = tradeContract?.fileUploads
      ?.slice()
      .sort((a: any, b: any) => +new Date(b.createdAt) - +new Date(a.createdAt))
    return sortedFileUploadsByLatestDate[0]
  }
}

export const getLatestUploadedPolicyFromList = (
  tradeContract: any,
  latestUploadedFile: any
) => {
  return tradeContract?.policies?.filter(
    (policy: any) => policy?.documentId === latestUploadedFile?.documentId
  )[0]
}

export const getLatestUploadedPolicyCertificationStatusFromList = (
  tradeContract: any
) => {
  let latestUploadedFile = getLatestUploadedFileFromList(tradeContract)
  let latestUploadedPolicy = getLatestUploadedPolicyFromList(
    tradeContract,
    latestUploadedFile
  )

  if (!latestUploadedFile) {
    return 'NO POLICY UPLOADED'
  } else if (latestUploadedFile && latestUploadedFile?.status === 'FAILED') {
    return "POLICY COULDN'T BE READ"
  } else if (
    !latestUploadedFile?.documentId ||
    (latestUploadedPolicy && !latestUploadedPolicy?.certificationStatus)
  ) {
    return 'REVIEW PENDING'
  } else if (
    latestUploadedPolicy &&
    latestUploadedPolicy?.certificationStatus === 'CERTIFIED'
  ) {
    return 'CERTIFIED'
  } else if (
    latestUploadedPolicy &&
    latestUploadedPolicy?.certificationStatus === 'NOT CERTIFIED'
  ) {
    return 'NOT CERTIFIED'
  } else return 'NO CONDITION MET'
}

export const getLatestUploadedFile = (tradeContract: any) => {
  return tradeContract.fileUploads[0]
}

export const getLatestUploadedPolicy = (tradeContract: any) => {
  return tradeContract.policies[0]
}

export const getLatestUploadedPolicyCertificationStatus = (
  tradeContract: any
) => {
  let latestUploadedFile = getLatestUploadedFileFromList(tradeContract)
  let latestUploadedPolicy = getLatestUploadedPolicyFromList(
    tradeContract,
    latestUploadedFile
  )

  if (!latestUploadedFile) {
    return 'NO POLICY UPLOADED'
  } else if (latestUploadedFile && latestUploadedFile?.status === 'FAILED') {
    return "POLICY COULDN'T BE READ"
  } else if (
    !latestUploadedFile?.documentId ||
    (latestUploadedPolicy &&
      (!latestUploadedPolicy?.certificationStatus ||
        latestUploadedPolicy?.certificationStatus === 'REVIEW PENDING'))
  ) {
    return 'REVIEW PENDING'
  } else if (
    latestUploadedPolicy &&
    latestUploadedPolicy?.certificationStatus === 'CERTIFIED'
  ) {
    return 'CERTIFIED'
  } else if (
    latestUploadedPolicy &&
    latestUploadedPolicy?.certificationStatus === 'NOT CERTIFIED'
  ) {
    return 'NOT CERTIFIED'
  } else return 'NO CONDITION MET'
}

export const convertTimezoneToESTFromUTC = (utcDt: string) => {
  let timezone: string = 'America/Los_Angeles'
  return moment.utc(utcDt).tz(timezone)
}

export const setTadeStatus = (tradeStatus: string) => {
  if (tradeStatus === 'REVIEW PENDING') {
    return 'Pending Review'
  } else if (tradeStatus === 'NOT CERTIFIED') {
    return 'Rejected'
  } else if (tradeStatus === 'CERTIFIED') {
    return 'Accepted'
  } else return 'Pending Upload'
}

export const formatByInsertingHyphens = (str: string) => {
  str = str.replace('-', '')
  return `${str.substring(0, 3)}-${str.substring(3, 5)}-${str.substring(
    str.length - 5
  )}`
}

export const getUniqueListBy = (arr: IDatum[], key: string) => {
  let uniqueList = [
    ...new Map(arr.map((item: any) => [item[key], item])).values(),
  ]
  return uniqueList
}

export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

export const handleViewDocumentClick = (path: string) => {
  const token = authentication.getAccessToken()
  var uploadedFilePath = new URL(path).pathname.slice(1)
  axios({
    url: `${appConfig.apiUrl}/rest/signed-get-url?path=${uploadedFilePath}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf', // declare the file format in s3
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      openInNewTab(res.data.url)
    })
    .catch((err) => console.error('Error: ', err))
}

export const reloadPageAfter2MsDelay = () => {
  setTimeout(() => {
    window.location.reload()
  }, 2000)
}

export const maskEin = (value: string) => {
  if (!value) return value
  const nums = value.replace(/[^\d]/g, '') // only allows 0-9
  if (nums.length === 3) return `${nums}`
  if (nums.length === 5) return `${nums.slice(0, 3)}-${nums.slice(3)}`

  if (nums.length <= 2) return nums
  if (nums.length <= 5) return `${nums.slice(0, 3)}-${nums.slice(3)}`

  return `${nums.slice(0, 3)}-${nums.slice(3, 5)}-${nums.slice(5, 9)}`
}

let aggregateLabelList: string[]

export const getLabelsBasedOnAggregateType = (
  aggregateType: string,
  durationType: string
) => {
  switch (true) {
    case aggregateType === 'Per Project' &&
      durationType === 'Ongoing operations':
      aggregateLabelList = [
        '/ENDORSEMENTS/LIMITS/PERIOD (Group-1)/PER PROJECT',
        '/ENDORSEMENTS/LIMITS/OPERATION STATUS (Group-2)/ONGOING WORK (GENERAL AGG.)',
      ]

      break
    case aggregateType === 'Per Project' &&
      durationType === 'Ongoing operations and completed operations':
      aggregateLabelList = [
        '/ENDORSEMENTS/LIMITS/PERIOD (Group-1)/PER PROJECT',
        '/ENDORSEMENTS/LIMITS/OPERATION STATUS (Group-2)/ONGOING & COMPLETED OPERATIONS',
      ]
      break
    case aggregateType === 'Per Location' &&
      durationType === 'Ongoing operations':
      aggregateLabelList = [
        '/ENDORSEMENTS/LIMITS/PERIOD (Group-1)/PER LOCATION',
        '/ENDORSEMENTS/LIMITS/OPERATION STATUS (Group-2)/ONGOING WORK (GENERAL AGG.)',
      ]
      break
    case aggregateType === 'Per Location' &&
      durationType === 'Ongoing operations and completed operations':
      aggregateLabelList = [
        '/ENDORSEMENTS/LIMITS/PERIOD (Group-1)/PER LOCATION',
        '/ENDORSEMENTS/LIMITS/OPERATION STATUS (Group-2)/ONGOING & COMPLETED OPERATIONS',
      ]
      break
    default:
      break
  }
  return aggregateLabelList
}

let scopeAndDurationList: string[]

export const getLabelsBasedOnScopeAndDurationSelectedinTemplate = (
  scope: string,
  duration: string
) => {
  switch (true) {
    case scope === 'Arising out of the work' &&
      duration === 'Ongoing operations':
      scopeAndDurationList = [
        'ongoing work/arising out of/your work for ai',
        'ongoing & completed operations/arising out of/your work for ai',
      ]
      break
    case scope === 'Arising out of the work' &&
      duration === 'Ongoing operations and completed operations':
      scopeAndDurationList = [
        'Ongoing & Completed Operations/Arising Out Of/Your Work for AI',
        'Ongoing Work/Arising Out Of/Your Work for AI AND Completed Operations/Arising Out Of/Your Work for AI',
      ]
      break
    case scope === 'Caused, in whole or in part by, the work' &&
      duration === 'Ongoing operations':
      scopeAndDurationList = [
        'ongoing work/arising out of/your work for ai',
        'ongoing & completed operations/arising out of/your work for ai',
        'ongoing work/caused by/your work for ai',
        'ongoing & completed operations/caused by/your work for ai',
      ]
      break
    case scope === 'Caused, in whole or in part by, the work' &&
      duration === 'Ongoing operations and completed operations':
      scopeAndDurationList = [
        'Ongoing & Completed Operations/Arising Out Of/Your Work for AI',
        'Ongoing & Completed Operations/Caused By/Your Work for AI',
        '("Ongoing Work/Arising Out Of/Your Work for AI" OR Ongoing Work/Caused By/Your Work for AI") AND ("Completed Operations/Arising Out Of/Your Work for AI" OR "Completed Operations/Caused By/Your Work for AI")',
      ]
      break
    case scope === 'Resulting from the negligence' &&
      duration === 'Ongoing operations':
      scopeAndDurationList = [
        'Ongoing Work/Arising Out Of/Your Work for AI',
        'Ongoing & Completed Operations/Arising Out Of/Your Work for AI',
        'Ongoing Work/Caused By/Your Work for AI',
        'Ongoing & Completed Operations/Caused By/Your Work for AI',
        'Ongoing Work/Negligence Of/Your Work for AI',
        'Ongoing & Completed Operations/Negligence Of/Your Work for AI',
      ]
      break
    case scope === 'Resulting from the negligence' &&
      duration === 'Ongoing operations and completed operations':
      scopeAndDurationList = [
        'Ongoing & Completed Operations/Arising Out Of/Your Work for AI',
        'Ongoing & Completed Operations/Caused By/Your Work for AI',
        'Ongoing & Completed Operations/Negligence Of/Your Work for AI',
        '("Ongoing Work/Arising Out Of/Your Work for AI" OR Ongoing Work/Caused By/Your Work for AI" OR Ongoing Work/Negligence Of/Your Work for AI") AND ("Completed Operations/Arising Out Of/Your Work for AI" OR "Completed Operations/Caused By/Your Work for AI" OR "Completed Operations/Negligence/Your Work for AI")',
      ]
      break
    default:
      break
  }
  return scopeAndDurationList
}

export const maskPhoneNumber = (phone: string) => {
  if (!phone) return phone
  const number = phone.replace(/[^\d]/g, '')
  if (number.length === 3) return `${number}`
  if (number.length === 6) return `(${number.slice(0, 3)}) ${number.slice(3)}`
  if (number.length === 5) return `(${number.slice(0, 3)}) ${number.slice(3)}`
  if (number.length === 4) return `(${number.slice(0, 3)}) ${number.slice(3)}`
  if (number.length <= 2) return number
  if (number.length > 6)
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`

  return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`
  //check if number length equals to 10
  //reformat and return phone number
}

export const addDollarSignInFrontOfAmount = (amount: number) => {
  return `$${amount?.toLocaleString('en-US')}`
}

const transformedListByRemovingCharsAfterStartIndexOfPattern = (
  list: string[],
  pattern: RegExp
) => {
  return list.map((el: string) => {
    if (el.includes('group')) {
      let slicedString = el.slice(0, el.indexOf('group'))
      return slicedString
    }
    return el.replace(pattern, '').replace('//', '/')
  })
}

export const transformCheckedElements = (list: string[]) => {
  list = list.map((el) => el.toLowerCase())

  const pattern = new RegExp('(group-)[/d{0-9}]', 'ig')

  let listAfterRemovingCharsAfterStartIndexOfGroup =
    transformedListByRemovingCharsAfterStartIndexOfPattern(list, pattern)

  listAfterRemovingCharsAfterStartIndexOfGroup = [
    ...new Set(listAfterRemovingCharsAfterStartIndexOfGroup),
  ]
  let listAfterRemovingElementsContainingGroup = list.filter((el: string) => {
    return listAfterRemovingCharsAfterStartIndexOfGroup.some(
      (transformedCheckedElement: string) => {
        return el === transformedCheckedElement
      }
    )
  })

  let finalList = []
  for (
    let i = 0;
    i < listAfterRemovingCharsAfterStartIndexOfGroup.length;
    i++
  ) {
    let string = ''
    for (let j = 0; j < list.length; j++) {
      if (list[j].includes(listAfterRemovingCharsAfterStartIndexOfGroup[i])) {
        string =
          string +
          list[j].replace(listAfterRemovingCharsAfterStartIndexOfGroup[i], '')
      }
      string = string.replace(pattern, '').replace('//', '/')
    }
    string &&
      finalList.push(
        `${listAfterRemovingCharsAfterStartIndexOfGroup[i]}${string}`.replace(
          '//',
          '/'
        )
      )
  }
  finalList = [...listAfterRemovingElementsContainingGroup, ...finalList]
  return finalList
}

export const checkArrayIncludesString = (arr: string[], str: string) => {
  arr = arr?.map((el: string) => el.toLowerCase().trim())
  return arr?.includes(str.toLowerCase().trim())
}

export const validatePolicyPeriodStartAndEndDate = (
  startDate: string,
  endDate: string
) => {
  if (
    new Date(startDate) > new Date(endDate) ||
    new Date(endDate) < new Date(startDate)
  ) {
    return false
  } else {
    return true
  }
}

export const getLabellingPathsForSpecialRequirementsOrProhibitions = (
  templateLabels: ITemplateLabel[] | [],
  templateLabelType: string
) => {
  let labelledPaths = []
  let filteredTemplateLabels: ITemplateLabel[] | [] = []
  if (templateLabelType === 'Special Requirements') {
    filteredTemplateLabels = templateLabels?.filter(
      (templateLabel: any) => templateLabel?.isSpecialRequirement
    )
  } else if (templateLabelType === 'Special Prohibitions') {
    filteredTemplateLabels = templateLabels?.filter(
      (templateLabel: any) => templateLabel?.isSpecialProhibition
    )
  }
  if (filteredTemplateLabels?.length) {
    labelledPaths = filteredTemplateLabels?.map(
      (filteredTemplateLabel: any) => filteredTemplateLabel.labellingPath
    )
  }
  return labelledPaths
}

export const getJobTypeProhibitionsLabellingPaths = (
  jobTypeProhibitionsLabels: IJobTypeProhibitionsLabel[]
) => {
  let jobTypeProhibitionsLabelingPaths = jobTypeProhibitionsLabels?.map(
    (jobTypeProhibitionsLabel: any) => jobTypeProhibitionsLabel?.labellingPath
  )
  return jobTypeProhibitionsLabelingPaths
}
