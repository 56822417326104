import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Row, Col, Form, Spinner } from 'react-bootstrap'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { redirectDelayInMilliseconds } from '../data'
import CustomClickableButton from './custom-components/custom-clickable-button'
import DropzoneComponent from './dropzone-component'
import {
  GET_TRADE_CONTRACTOR_BY_EMAIL,
  JOB_TYPES_LIST_FOR_DROPDOWN,
  CREATE_TRADE,
} from '../graphql/graphql-queries'
import CustomAlert from './custom-components/custom-alert'
import ValidationError from './validation-error'
import CustomUnclickableButton from './custom-components/custom-unclickable-button'
import { useDebounce } from './custom-hooks/use-debounce'

export interface AddTradeProps extends React.ComponentProps<any> {
  className?: string
}

const AddTrade: React.FC<AddTradeProps> = () => {
  const { state: jobForAddingTrade }: any = useLocation()
  const initialTradeInput = {
    job: {
      jobId: jobForAddingTrade?.id,
      contractingParty: jobForAddingTrade?.nonProcessingPolicy?.company?.name,
    },
    tradeCompany: {
      tradeCompanyName: '',
      contactName: '',
      contactEmail: '',
    },
    // contractFile: {
    //   contractFileOriginalName: '',
    //   contractFileAssignedName: '',
    //   contractFilePath: '',
    //   status: 'PENDING',
    // },
    jobContracted: {
      jobName: '',
      jobCode: '',
    },
    // attachmentType: 'TRADE CONTRACT',
    nonProcessingPolicyId: jobForAddingTrade?.nonProcessingPolicy.id,
  }

  const [tradeInput, setTradeInput] = useState<any>(initialTradeInput)

  const [tradeContractor, setTradeContractor] = useState<any>(null)

  let navigate = useNavigate()

  const [jobTypesForDropdown, setJobTypesForDropdown] = useState([])

  // const [selectedFile, setSelectedFile] = useState<any>(null)

  // const [selectedFileUpdatedDetails, setSelectedFileUpdatedDetails] =
  //   useState<any>(null)

  // const [isFileUploadedinS3, setIsFileUploadedinS3] = useState<Boolean>(false)

  const [isAddingTradeContractProcessing, setIsAddingTradeContractProcessing] =
    useState(false)

  const [isTradeAdded, setIsTradeAdded] = useState(false)

  const [tradeCreationError, setTradeCreationError] = useState<any>(false)

  const [tradeCreationErrorMessage, setTradeCreationErrorMessage] =
    useState<any>(null)

  const [isSearching, setIsSearching] = useState(false)

  useQuery(JOB_TYPES_LIST_FOR_DROPDOWN, {
    onCompleted: (data) => {
      setJobTypesForDropdown(data.listJobTypesDropdown)
    },
  })

  const [createTrade] = useMutation(CREATE_TRADE, {
    onCompleted: (data) => {
      setIsAddingTradeContractProcessing(false)
      setIsTradeAdded(true)
      setTimeout(() => {
        navigate('/jobs')
      }, redirectDelayInMilliseconds)
    },
    onError(err) {
      setTradeCreationError(true)
      setTradeCreationErrorMessage(err.message)
      // setIsAddingTradeContractProcessing(false)
    },
  })

  const [
    selectedTradeCompanyNameFromDropdown,
    setSelectedTradeCompanyNameFromDropdown,
  ] = useState('')

  const debouncedSearchTerm = useDebounce(
    tradeInput.tradeCompany.contactEmail,
    400
  )

  const [getTradeContractorByEmail] = useLazyQuery(
    GET_TRADE_CONTRACTOR_BY_EMAIL,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setIsSearching(false)
        setTradeContractor(data.getTradeContractorByEmail)
      },
    }
  )
  useEffect(() => {
    if (tradeCreationError) {
      setTimeout(() => {
        setTradeCreationError(false)
        setTradeCreationErrorMessage(null)
        navigate(-1)
      }, 4000)
    }
  }, [tradeCreationError])

  const handleOnChange = (e: any) => {
    if (e.target.name === 'jobContracted') {
      let selectedJobType: any = jobTypesForDropdown.filter(
        (jobType: any) => e.target.value === jobType.id
      )
      setTradeInput({
        ...tradeInput,
        jobContracted: {
          ...tradeInput.jobContracted,
          jobCode: selectedJobType[0].jobCode,
          jobName: selectedJobType[0].name,
        },
      })
    } else
      setTradeInput({
        ...tradeInput,
        [e.target.name]: e.target.value,
      })
  }

  const handleOnChangeForTradeCompanyDetails = (e: any) => {
    const { name, value } = e.target
    if (name === 'contactEmail') {
      setIsSearching(true)
    }
    setTradeInput({
      ...tradeInput,
      tradeCompany: {
        ...tradeInput.tradeCompany,
        [name]: value,
      },
    })
  }

  const handleOnChangeForTradeCompanyNameSelectionFromDropdown = (e: any) => {
    setSelectedTradeCompanyNameFromDropdown(e.target.value)
    if (e.target.value !== 'Other') {
      setTradeInput({
        ...tradeInput,
        tradeCompany: {
          ...tradeInput.tradeCompany,
          tradeCompanyName: e.target.value,
        },
      })
    } else {
      setTradeInput({
        ...tradeInput,
        tradeCompany: {
          ...tradeInput.tradeCompany,
          tradeCompanyName: '',
        },
      })
    }
  }

  const [error, setError] = useState({
    tradeCompanyName: '',
    contactName: '',
    contactEmail: '',
    jobContracted: '',
    // contractUpload: '',
    tradeCompanyNameDropdown: '',
  })

  const validationForm = () => {
    let errors = {
      tradeCompanyName: '',
      contactName: '',
      contactEmail: '',
      jobContracted: '',
      // contractUpload: '',
      tradeCompanyNameDropdown: '',
    }

    errors.tradeCompanyName = validateTradeCompanyName(
      tradeInput.tradeCompany.tradeCompanyName
    )!
    errors.contactName = validateContactName(
      tradeInput.tradeCompany.contactName
    )!
    errors.contactEmail = validateContactEmail(
      tradeInput.tradeCompany.contactEmail
    )!
    errors.jobContracted = validateJobContracted(
      tradeInput.jobContracted.jobName
    )!
    // errors.contractUpload = validateContractUpload(selectedFile)!
    errors.tradeCompanyNameDropdown = validateTradeCompanyNameFromDropdown(
      selectedTradeCompanyNameFromDropdown
    )!
    return errors
  }

  const isValid = (errors: any) => {
    let keys = Object.keys(errors)
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0)
    return count === 0
  }

  const validateTradeCompanyName = (tradeCompanyName: string) => {
    if (tradeCompanyName.replaceAll(' ', '') === '') {
      return 'Trade Company Name is required'
    }
    return ''
  }

  const validateContactName = (contactName: string) => {
    if (contactName.replaceAll(' ', '') === '') {
      return 'Contact Name is required'
    }
    return ''
  }

  const validateContactEmail = (contactEmail: string) => {
    if (contactEmail === '') {
      return 'Contact Email is required'
    } else if (
      !new RegExp(
        "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
      ).test(contactEmail)
    ) {
      return 'You need to specify a valid email address'
    }
    return ''
  }

  // const validateContractUpload = (uploadedContract: any) => {
  //   if (!uploadedContract) {
  //     return 'You need to upload a contract before moving forward'
  //   }
  //   return ''
  // }

  const validateJobContracted = (jobContracted: string) => {
    if (jobContracted === '') {
      return 'Job Contracted is required'
    }
    return ''
  }

  const validateTradeCompanyNameFromDropdown = (
    tradeCompanyNameFromDropdown: string
  ) => {
    if (tradeContractor !== null && tradeCompanyNameFromDropdown === '') {
      return 'Trade Company Name is required'
    }
    return ''
  }

  const handleAddTradeClick = () => {
    let errorss = validationForm()

    if (isValid(errorss)) {
      setIsAddingTradeContractProcessing(true)
      let data = {
        ...tradeInput,
        // contractFile: {
        //   ...tradeInput.contractFile,
        //   contractFileOriginalName: selectedFileUpdatedDetails.originalFileName,
        //   contractFileAssignedName: selectedFileUpdatedDetails.assignedFileName,
        //   contractFilePath: selectedFileUpdatedDetails.path,
        // },
        clientCompanyName:
          jobForAddingTrade?.nonProcessingPolicy?.company?.name,
      }
      createTrade({
        variables: {
          data: data,
        },
      })
    } else {
      setError(errorss)
    }
  }

  // useEffect(() => {
  //   if (isFileUploadedinS3 && selectedFileUpdatedDetails) {
  //     let data = {
  //       ...tradeInput,
  //       contractFile: {
  //         ...tradeInput.contractFile,
  //         contractFileOriginalName: selectedFileUpdatedDetails.originalFileName,
  //         contractFileAssignedName: selectedFileUpdatedDetails.assignedFileName,
  //         contractFilePath: selectedFileUpdatedDetails.path,
  //       },
  //       clientCompanyName:
  //         jobForAddingTrade?.nonProcessingPolicy?.company?.name,
  //     }
  //     createTrade({
  //       variables: {
  //         data: data,
  //       },
  //     })
  //   }
  // }, [isFileUploadedinS3, selectedFileUpdatedDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetIsTradeAdded = () => setIsTradeAdded(false)

  const handleSetTradeCreationError = () => setTradeCreationError(false)

  useEffect(() => {
    if (!tradeContractor) {
      setTradeInput({
        ...initialTradeInput,
        tradeCompany: {
          ...initialTradeInput.tradeCompany,
          contactEmail: tradeInput.tradeCompany.contactEmail,
        },
        jobContracted: {
          ...initialTradeInput.jobContracted,
          jobName: tradeInput.jobContracted.jobName,
          jobCode: tradeInput.jobContracted.jobCode,
        },
      })
    } else {
      setTradeInput({
        ...tradeInput,
        tradeCompany: {
          ...tradeInput.tradeCompany,
          contactName:
            tradeContractor !== null ? tradeContractor?.fullName : '',
          tradeCompanyName:
            tradeContractor !== null
              ? selectedTradeCompanyNameFromDropdown
              : '',
        },
      })
    }
  }, [tradeContractor]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    ;(async () => {
      if (debouncedSearchTerm) {
        setIsSearching(true)
        await getTradeContractorByEmail({
          variables: { email: tradeInput.tradeCompany.contactEmail },
        })
      } else {
        setTradeContractor(null)
        setIsSearching(false)
      }
    })()
  }, [debouncedSearchTerm])

  return (
    <div className="text-start client-list shadow-none p-5 m-4 bg-white rounded">
      {tradeCreationError &&
      tradeCreationErrorMessage === 'Trade Contract Already Added' ? (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleSetTradeCreationError}
            alertType="error"
            alertMessage={`${tradeInput?.tradeCompany?.tradeCompanyName} already added against this job for the same job type`}
          />
        </Row>
      ) : tradeCreationError ? (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleSetTradeCreationError}
            alertType="error"
            alertMessage={`${tradeCreationErrorMessage}`}
          />
        </Row>
      ) : null}

      {isTradeAdded && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleSetIsTradeAdded}
            alertType="success"
            alertMessage={`${tradeInput?.tradeCompany?.tradeCompanyName} added and requirements sent`}
          />
        </Row>
      )}
      <div className="w-50">
        <Row>
          <p className="fs-4 fw-bold text-primary">Add New Trade</p>
        </Row>
        <Row>
          <Col>
            <p className="fs-5 fw-bold">Trade Details</p>
          </Col>
          <Col></Col>
        </Row>
        <Form autoComplete="new-password">
          <Form.Group className="mb-3" controlId="formBasicContactEmail">
            <Form.Label className="fw-bold"> Contact E-mail</Form.Label>
            <Form.Control
              type="text"
              name="contactEmail"
              value={tradeInput?.tradeCompany?.contactEmail}
              onChange={handleOnChangeForTradeCompanyDetails}
              autoComplete="off"
            />
            {error.contactEmail && (
              <ValidationError errorMessage={error.contactEmail} />
            )}
            {isSearching && (
              <div className="pt-2 text-primary fw-bold">Searching...</div>
            )}
          </Form.Group>
          {tradeContractor?.email && (
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="fw-bold">
                Select a Trade Company Name
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="tradeCompanyNameDropdown"
                onChange={
                  handleOnChangeForTradeCompanyNameSelectionFromDropdown
                }
              >
                <option value="" disabled selected>
                  Select a Trade Company Name
                </option>
                {tradeContractor?.companies?.map((company: any) => (
                  <option key={company.id} value={company.name}>
                    {company.name}
                  </option>
                ))}
                <option>Other</option>
              </Form.Select>
              {error.tradeCompanyNameDropdown && (
                <ValidationError
                  errorMessage={error.tradeCompanyNameDropdown}
                />
              )}
            </Form.Group>
          )}
          {(selectedTradeCompanyNameFromDropdown === 'Other' ||
            !tradeContractor?.email) && (
            <Form.Group className="mb-3" controlId="formBasicTradeCompanyName">
              <Form.Label className="fw-bold">Trade Company Name</Form.Label>
              <Form.Control
                type="text"
                name="tradeCompanyName"
                value={tradeInput?.tradeCompany?.tradeCompanyName}
                onChange={handleOnChangeForTradeCompanyDetails}
                readOnly={!tradeInput?.tradeCompany?.contactEmail}
                autoComplete="off"
              />
              {error.tradeCompanyName && (
                <ValidationError errorMessage={error.tradeCompanyName} />
              )}
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="formBasicContactName">
            <Form.Label className="fw-bold">Contact Name</Form.Label>
            <Form.Control
              type="text"
              name="contactName"
              value={tradeInput?.tradeCompany?.contactName}
              onChange={handleOnChangeForTradeCompanyDetails}
              readOnly={
                !tradeInput?.tradeCompany?.contactEmail ||
                (tradeInput?.tradeCompany?.contactEmail &&
                  tradeContractor?.email &&
                  selectedTradeCompanyNameFromDropdown !== 'Other')
              }
              autoComplete="off"
            />
            {error.contactName && (
              <ValidationError errorMessage={error.contactName} />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="fw-bold">Job Type</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="jobContracted"
              onChange={handleOnChange}
            >
              <option>Select a Job Type</option>
              {jobTypesForDropdown.map((jobType: any) => (
                <option key={jobType.id} value={jobType.id}>
                  {jobType.name}
                </option>
              ))}
            </Form.Select>
            {error.jobContracted && (
              <ValidationError errorMessage={error.jobContracted} />
            )}
          </Form.Group>
          {/* <Row className="mb-5">
            <p className="fs-5 fw-bold">Subcontract</p>
            <p className="text-muted">
              Upload the subcontract for the trade to sign
            </p>
            <DropzoneComponent
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              selectedFileUpdatedDetails={selectedFileUpdatedDetails}
              setSelectedFileUpdatedDetails={setSelectedFileUpdatedDetails}
              type="contract"
              isFileUploadedinS3={isFileUploadedinS3}
              setIsFileUploadedinS3={setIsFileUploadedinS3}
              isFileUploading={isAddingTradeContractProcessing}
              docName="subcontract"
              dropzoneContent="Upload the subcontract for the trade to verify"
            />
            {error.contractUpload && (
              <ValidationError errorMessage={error.contractUpload} />
            )}
          </Row> */}
          <Row className="pt-4">
            <Col sm={12} className="text-center">
              {isAddingTradeContractProcessing ? (
                <CustomUnclickableButton
                  buttonContent="Adding Trade and Sending Requirements..."
                  disabled={true}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </CustomUnclickableButton>
              ) : (
                <CustomClickableButton
                  buttonContent="Add Trade and Send Requirements"
                  handleOnClick={handleAddTradeClick}
                  disable={false}
                />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default AddTrade
