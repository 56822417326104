import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client'
import appConfig from '../core/config'
import { setContext } from '@apollo/client/link/context'
import authentication from '../core/authentication'
import { onError } from '@apollo/client/link/error'

const httpLink = new HttpLink({
  uri: `${appConfig.apiUrl}/graphql`,
})
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (
        message ===
        'Context creation failed: Token is expired, please login again!'
      ) {
        localStorage.removeItem('whistle')
        window.location.href = '/session-expired'
      }
    })
  if (networkError) console.log(`[Network error]: ${networkError}`)
})
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = authentication.getAccessToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: from([errorLink, authLink.concat(httpLink)]),
})
