import { Container, Row, Col } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import authentication from './../core/authentication'
import { useMutation } from '@apollo/client'
import {
  GET_LIST_OF_CLAIMS,
  GET_LIST_OF_CLAIMS_BY_NONPROCESSINGID,
  GET_LIST_OF_CLAIMS_FOR_TRADE_CONTRACTOR,
} from '../graphql/graphql-queries'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
import moment from 'moment'
import ClaimNoticePolicyList from './claim-notice-policy-list'
import Pagination from '../components/Pagination Component/pagination'
import config from '../core/config'

export interface ClaimNoticeListProps extends React.ComponentProps<any> {
  className?: string
}
const ClaimNoticeList: React.FC<ClaimNoticeListProps> = ({ className }) => {
  const navigate = useNavigate()
  const [claimNotices, setClaimNotices] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [count, setCount] = useState(0)
  const dataPerPage = config.itemPerPage

  const handleBackToJobsClick = () => {
    if (authentication.isTradeContractor()) {
      navigate('/')
    } else {
      navigate('/jobs')
    }
  }
  const {
    state: { nonProcessingPolicy, job, contractedCompanyId },
  }: any = useLocation()
  const offset = 0
  let paginationDataForClientAndSuperAdmin = {
    jobId: job?.id,
    offset: pageNumber === 1 ? offset : (pageNumber - 1) * 25,
    limit: dataPerPage,
  }
  const [
    getListOfClaimsForClientAndSuperAdmin,
    {
      loading: getListOfClaimsForClientAndSuperAdminLoading,
      error: getListOfClaimsForClientAndSuperAdminError,
    },
  ] = useMutation(GET_LIST_OF_CLAIMS, {
    onCompleted: (data) => {
      setClaimNotices(data.getListOfClaimsFiled.claimNoticeForClientAdmin)
      setCount(data.getListOfClaimsFiled.count)
    },
  })
  let paginationDataForNonprocessingId = {
    nonPolicyId: nonProcessingPolicy.id,
    offset: pageNumber === 1 ? offset : (pageNumber - 1) * 25,
    limit: dataPerPage,
  }
  const [
    getListOfClaimsByNonprocessingId,
    {
      loading: getListOfClaimsByNonprocessingIdLoading,
      error: getListOfClaimsByNonprocessingIdError,
    },
  ] = useMutation(GET_LIST_OF_CLAIMS_BY_NONPROCESSINGID, {
    onCompleted: (data) => {
      setClaimNotices(
        data.getListOfClaimsByNonprocessingId.claimNoticeForNonProcessingPolicy
      )
      setCount(data.getListOfClaimsByNonprocessingId.count)
    },
  })
  let paginationDataForTradeContractor = {
    contractedCompanyId: contractedCompanyId,
    offset: pageNumber === 1 ? offset : (pageNumber - 1) * 25,
    limit: dataPerPage,
  }
  const [
    getListOfClaimsForIndividualTradeContractor,
    {
      loading: getListOfClaimsForIndividualContractorLoading,
      error: getListOfClaimsForIndividualContractorError,
    },
  ] = useMutation(GET_LIST_OF_CLAIMS_FOR_TRADE_CONTRACTOR, {
    onCompleted: (data) => {
      setClaimNotices(
        data.getListOfClaimsForTradeContractor.claimNoticeForTradeContractors
      )
      setCount(data.getListOfClaimsForTradeContractor.count)
    },
  })
  useEffect(() => {
    if (nonProcessingPolicy) {
      getListOfClaimsByNonprocessingId({
        variables: { data: paginationDataForNonprocessingId },
      })
    }
  }, [pageNumber])
  useEffect(() => {
    if (
      (contractedCompanyId === '' || contractedCompanyId === undefined) &&
      job
    ) {
      getListOfClaimsForClientAndSuperAdmin({
        variables: { data: paginationDataForClientAndSuperAdmin },
      })
    }
    if (contractedCompanyId !== '' && contractedCompanyId !== undefined) {
      getListOfClaimsForIndividualTradeContractor({
        variables: { data: paginationDataForTradeContractor },
      })
    }
  }, [pageNumber])
  const perPage = Math.ceil(count / dataPerPage)
  if (
    getListOfClaimsForClientAndSuperAdminLoading ||
    getListOfClaimsForIndividualContractorLoading ||
    getListOfClaimsByNonprocessingIdLoading
  )
    return <SpinnerLoading />
  if (
    getListOfClaimsForClientAndSuperAdminError ||
    getListOfClaimsForIndividualContractorError ||
    getListOfClaimsByNonprocessingIdError
  )
    return <ErrorFetchingData />

  return (
    <div className={`${className} text-start`}>
      <Container>
        {nonProcessingPolicy && (
          <ClaimNoticePolicyList nonProcessingPolicy={nonProcessingPolicy} />
        )}
        {job && (
          <Row>
            <Col sm={9} className="my-2">
              <span
                className="text-primary-medium fw-bold span-cursor my-2"
                onClick={handleBackToJobsClick}
              >
                <ArrowLeftIcon className="arrow-left-icon" />
                <span className="back-text">Back to Jobs</span>
              </span>
            </Col>
            <Col className="text-end my-2"></Col>
            <Row className="my-1">
              <Col sm={9}>
                <h2 className="fw-bold ">{job?.streetAddress}</h2>
              </Col>
              <Col sm={3} className="text-end"></Col>
            </Row>
            <Col className="text-end my-2"></Col>
            <p className="text-secondary fw-bold">
              <span>{job.nonProcessingPolicy?.template?.templateName}</span>
              <span className="p-2">|</span>
              <span>{`Job ${job.slug}`}</span>
              <span className="p-2">|</span>
              <span>{job.streetAddress}</span>
            </p>
            <hr className="ms-3 small text-secondary" />
          </Row>
        )}

        <Row className="fw-bold py-3 mx-4">
          <Col sm={2}>Company</Col>
          <Col sm={2}>Contact Name</Col>
          <Col sm={3}>Claim Filed Person</Col>
          <Col sm={3}>Claim Date</Col>
          <Col>Attachment</Col>
        </Row>
        {claimNotices.length > 0 ? (
          claimNotices.map((claimNotice: any) => (
            <Row
              className="border-top d-flex align-items-center py-2 mx-4"
              key={claimNotice?.id}
            >
              <Col sm={2}>
                {claimNotice?.injuredPartyEmployer}
                <div className="text-muted pt-1">
                  <span className="fw-bold">Job Type: </span>
                  {claimNotice?.jobName}
                </div>
              </Col>
              <Col sm={2}>{claimNotice?.contactName}</Col>
              <Col sm={3}>{claimNotice?.claimNoticeFiledByUser}</Col>
              <Col sm={3}>
                {moment(claimNotice?.createdAt).format('MM/DD/YYYY')}
              </Col>
              <Col>
                {claimNotice?.attachments?.map((attachment: any) => (
                  <ul key={attachment?.id}>
                    <li>
                      <a href={`${attachment?.path}`}>
                        {attachment?.originalName}
                      </a>
                    </li>
                  </ul>
                ))}
              </Col>
            </Row>
          ))
        ) : (
          <>
            <Row className="border-top">
              <p className="text-center m-3">No Claims have been raised yet.</p>
            </Row>
          </>
        )}
        {claimNotices?.length > 0 && (
          <div style={{ marginTop: '45px' }}>
            {' '}
            <Pagination
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
              perPage={perPage}
            />
          </div>
        )}
      </Container>
    </div>
  )
}
export default styled(ClaimNoticeList)`
  .arrow-left-icon {
    height: 15px;
    color: ##13948Bb;
    cursor: pointer;
    margin-right: 5px;
  }
  .back-text {
    cursor: pointer;
  }
`
