import { SearchIcon } from '@heroicons/react/solid'
import { Row, Col, Form, FormControl, InputGroup } from 'react-bootstrap'

import styled from 'styled-components'

export interface FilterContainersProps extends React.ComponentProps<any> {
  className?: string
  tradesStatus?: String[]
  template?: String[]
  filterSelectList?: []
  filterType?: []
  filterOptions?: []
}

const FilterContainer: React.FC<FilterContainersProps> = ({
  className,
  filterType,
  filterOptions,
}) => {
  return (
    <div className={className}>
      <Form.Group as={Row} className="mb-4 g-0" controlId="formHorizontalEmail">
        <Col sm={7}>
          <InputGroup>
            <InputGroup.Text id="basic-addon1" className="search-icon">
              <SearchIcon className="searchIcon" />
            </InputGroup.Text>
            <FormControl type="text" placeholder="Search Templates..." />
          </InputGroup>
        </Col>
        <Col>
          <Form.Select
            aria-label="Default select example"
            className="fw-bold filter-input-border-radius filter-input-border"
          >
            <option>Occurence</option>
            <option value="1">$2 Million</option>
            <option value="2">$5 Million</option>
            <option value="3">$10 Million</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Select
            aria-label="Default select example"
            className="fw-bold filter-input-border-radius filter-input-border"
          >
            <option>Aggregate</option>
            <option value="1">$2 Million</option>
            <option value="2">$10 Million</option>
          </Form.Select>
        </Col>
        <Col sm={1}>
          <Form.Select
            aria-label="Default select example"
            className="fw-bold filter-input-border-radius filter-input-border"
          >
            <option>Scope</option>
            <option value="1">$2 Million</option>
            <option value="2">$10 Million</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Select
            aria-label="Default select example"
            className="fw-bold filter-input-rounded filter-input-border"
          >
            <option>Duration</option>
            <option value="1">2 years</option>
            <option value="2">Ongoing Operations</option>
          </Form.Select>
        </Col>
      </Form.Group>
    </div>
  )
}

export default styled(FilterContainer)`
  .search-input-rounded {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  .filter-input-rounded {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .filter-input-border-radius {
    border-radius: 0;
  }
  .filter-input-border {
    border-left: 0;
  }
  .search-icon {
    background: white;
    border-right: none;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-right: 0;
    z-index: 9999; /*this will keep the icon appearing all time, even when on input::focus*/
  }
  .searchIcon {
    height: 25px;
    color: #8b9192;
  }
`
