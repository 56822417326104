import { useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import PolicyDetails from './policy-details'
import FormDetails from './form-details'

type Props = {}

const DocumentDetails: React.FC<Props> = () => {
  const { docId } = useParams()

  return (
    <Container>
      <PolicyDetails docId={docId} />
      <FormDetails docId={docId} />
    </Container>
  )
}

export default DocumentDetails
