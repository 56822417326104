import { useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useQuery } from '@apollo/client'
import { TrashIcon } from '@heroicons/react/solid'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { TEMPLATES_LIST } from '../graphql/graphql-queries'
import { IPolicy, IPolicyholder } from '../interface'
import ValidationError from './validation-error'
import CustomClickableButton from './custom-components/custom-clickable-button'

export interface PolicyholderDetailsProps extends React.ComponentProps<any> {
  className: string
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  policyholder: IPolicyholder
  setPolicyHolder: React.Dispatch<React.SetStateAction<IPolicyholder>>
  claimsSpecialistsList: any[]

  selectedClaimsSpecialistsList: any[]
  setSelectedClaimsSpecialistsList: React.Dispatch<React.SetStateAction<any[]>>
  templatesList: any[]
  setTemplatesList: React.Dispatch<React.SetStateAction<any[]>>
  selectedTemplatesList: any[]
  setSelectedTemplatesList: React.Dispatch<React.SetStateAction<any[]>>
}

const PolicyholderDetails: React.FC<PolicyholderDetailsProps> = ({
  className,
  setCurrentStep,
  currentStepIndex,
  setCurrentStepIndex,
  policyholder,
  setPolicyHolder,
  claimsSpecialistsList,

  selectedClaimsSpecialistsList,
  setSelectedClaimsSpecialistsList,
  templatesList,
  setTemplatesList,
  selectedTemplatesList,
  setSelectedTemplatesList,
}) => {
  const {
    register,
    handleSubmit: handleCreatePolicyholderNextClick,
    formState,
  } = useForm()

  const onSubmit = (data: any, e: any) => {
    setCurrentStep('Review and Publish')
    setCurrentStepIndex(currentStepIndex + 1)
  }

  const onError = (errors: any, e: any) => console.log(errors, e)
  const PaginationTemplateData = {
    offset: 0,
    limit: 0,
  }
  useQuery(TEMPLATES_LIST, {
    variables: {
      data: PaginationTemplateData,
    },
    onCompleted: (data) => {
      setTemplatesList(data.listTemplates.templates)
    },
  })

  let formValuesData

  if (policyholder.policies.length) {
    formValuesData = policyholder.policies
  }

  const [formValues, setFormValues] = useState<IPolicy[]>(
    formValuesData || [
      {
        policyNumber: '',
        startDate: null,
        endDate: null,
        templateId: '',
        claimsSpecialistId: '',
      },
    ]
  )

  const addInputFields = () => {
    setFormValues([
      ...formValues,
      {
        policyNumber: '',
        startDate: null,
        endDate: null,
        templateId: '',
        claimsSpecialistId: '',
      },
    ])
  }

  const removeInputFields = (index: number) => {
    const rows = [...formValues]
    rows.splice(index, 1)
    setFormValues(rows)
  }

  const handleOnChange = (e: any) => {
    setPolicyHolder({
      ...policyholder,
      [e.target.name]: e.target.value,
    })
  }

  let values: IPolicy[] = [...formValues]

  const handleOnChangeForPolicy = (index: number, event: any) => {
    if (event.target.name === 'policyNumber') {
      values[index].policyNumber = event.target.value
    } else if (event.target.name === 'startDate') {
      values[index].startDate = event.target.value
    } else if (event.target.name === 'endDate') {
      values[index].endDate = event.target.value
    } else if (event.target.name === 'template') {
      values[index].templateId = event.target.value
      setSelectedTemplatesList([
        ...selectedTemplatesList,
        templatesList?.filter((it) => it?.id === event.target.value),
      ])
    } else if (event.target.name === 'claimSpecialistId') {
      values[index].claimsSpecialistId = event.target.value
      setSelectedClaimsSpecialistsList([
        ...selectedClaimsSpecialistsList,
        claimsSpecialistsList?.filter((it) => it?.id === event.target.value),
      ])
    }
    setFormValues(values)

    setPolicyHolder({
      ...policyholder,
      policies: values,
    })
  }

  const handleOnChangeForDate = (
    e: any,
    date: Date,
    dateInputName: string,
    index: number
  ) => {
    if (dateInputName === 'startDate') {
      values[index].startDate = date
    } else if (dateInputName === 'endDate') {
      values[index].endDate = date
    }
    setFormValues(values)

    setPolicyHolder({
      ...policyholder,
      policies: values,
    })
  }

  return (
    <div className={className}>
      <Container className="m-5 ">
        <h5 className="fw-bold mb-4">Policyholder Details</h5>
        <Form className="text-start">
          <Row className="mb-3">
            <Col sm={6}>
              <Form.Label className="fw-bold">Company Name</Form.Label>
              <Form.Control
                {...register('companyName', {
                  required: {
                    value: true,
                    message:
                      'You must specify a company name before moving forward.',
                  },
                })}
                value={policyholder.companyName}
                onChange={handleOnChange}
              />
              {formState.errors.companyName && (
                <ValidationError
                  errorMessage={formState.errors.companyName.message}
                />
              )}
            </Col>
          </Row>

          <div>
            <Row className="mb-3">
              <h5 className="fw-bold my-4">Contact Information</h5>
              <Col sm={3}>
                <Form.Label className="fw-bold">First Name</Form.Label>
                <Form.Control
                  {...register('userFirstName', {
                    required: {
                      value: true,
                      message:
                        'You must specify a First Name before moving forward.',
                    },
                  })}
                  value={policyholder.userFirstName}
                  onChange={handleOnChange}
                />
                {formState.errors.userFirstName && (
                  <ValidationError
                    errorMessage={formState.errors.userFirstName.message}
                  />
                )}
              </Col>
              <Col sm={3}>
                <Form.Label className="fw-bold">Last Name</Form.Label>
                <Form.Control
                  {...register('userLastName', {
                    required: {
                      value: true,
                      message:
                        'You must specify a Last Name before moving forward.',
                    },
                  })}
                  value={policyholder.userLastName}
                  onChange={handleOnChange}
                />
                {formState.errors.userLastName && (
                  <ValidationError
                    errorMessage={formState.errors.userLastName.message}
                  />
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6}>
                <Form.Label className="fw-bold">Email Address</Form.Label>
                <Form.Control
                  {...register('userEmail', {
                    required: {
                      value: true,
                      message: 'You need to provide an email address.',
                    },
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: 'You need to specify a valid email address.',
                    },
                  })}
                  value={policyholder.userEmail}
                  onChange={handleOnChange}
                />
                {formState.errors.userEmail && (
                  <ValidationError
                    errorMessage={formState.errors.userEmail.message}
                  />
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={3}>
                <Form.Label className="fw-bold">
                  Phone Number (Optional)
                </Form.Label>
                <Form.Control
                  {...register('userPhoneNumber', {
                    required: false,
                    pattern: /^[0-9+-]+$/,
                    minLength: 6,
                    maxLength: 12,
                  })}
                  name="userPhoneNumber"
                  value={policyholder.userPhoneNumber}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
          </div>

          <div>
            <h5 className="fw-bold my-4">Policy Details</h5>

            {formValues.map((element: any, index: number) => {
              return (
                <Row className="mb-3" key={index}>
                  <Col sm={2}>
                    <Form.Label
                      className="fw-bold"
                      key={`${element}-${index}`}
                      id={`${element}-${index}`}
                    >
                      Policy Number
                    </Form.Label>
                    <Form.Control
                      name="policyNumber"
                      value={element.policyNumber}
                      onChange={(e) => handleOnChangeForPolicy(index, e)}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label
                      className="fw-bold"
                      key={`${element}-${index}`}
                      id={`${element}-${index}`}
                    >
                      Policy Start Date
                    </Form.Label>
                    <DatePicker
                      selected={element.startDate}
                      onChange={(date: Date, e) =>
                        handleOnChangeForDate(e, date, 'startDate', index)
                      }
                      className="datepicker"
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label
                      className="fw-bold"
                      key={`${element}-${index}`}
                      id={`${element}-${index}`}
                    >
                      Policy End Date
                    </Form.Label>
                    <DatePicker
                      selected={element.endDate}
                      onChange={(date: Date, e) =>
                        handleOnChangeForDate(e, date, 'endDate', index)
                      }
                      className="datepicker"
                    />
                  </Col>
                  <Col sm={3}>
                    <Form.Label
                      className="fw-bold"
                      key={`${element}-${index}`}
                      id={`${element}-${index}`}
                    >
                      Requirements Template
                    </Form.Label>
                    <Form.Select
                      className="form-select"
                      aria-label="Default select example"
                      name="template"
                      value={element.templateId}
                      onChange={(e) => handleOnChangeForPolicy(index, e)}
                    >
                      <option>Select a template</option>
                      {!!templatesList.length &&
                        templatesList.map((template: any) => (
                          <option key={template.id} value={template.id}>
                            {template.templateName}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Text id="passwordHelpBlock" muted>
                      These requirements are sent to the policyholder and their
                      trades.
                    </Form.Text>
                  </Col>
                  <Col sm={2}>
                    <Form.Label className="fw-bold" key={`${element}-${index}`}>
                      Claims Specialist
                    </Form.Label>
                    <Form.Select
                      className="form-select"
                      aria-label="Default select example"
                      name="claimSpecialistId"
                      value={element.claimsSpecialistId}
                      onChange={(e) => handleOnChangeForPolicy(index, e)}
                    >
                      <option>Select a claims specialist</option>
                      {!!claimsSpecialistsList.length &&
                        claimsSpecialistsList.map((claimsSpecialist: any) => (
                          <option
                            key={claimsSpecialist.id}
                            value={claimsSpecialist.id}
                          >{`${claimsSpecialist.claimsSpecialistFirstName} ${claimsSpecialist.claimsSpecialistLasttName}`}</option>
                        ))}
                    </Form.Select>
                  </Col>
                  {index ? (
                    <Col>
                      <TrashIcon
                        className="add-policyholder-cursor"
                        style={{
                          height: '25px',
                          marginTop: '35px',
                          color: '#0c5a55',
                        }}
                        onClick={() => removeInputFields(index)}
                      />
                    </Col>
                  ) : null}
                </Row>
              )
            })}
          </div>

          <div className="button-section">
            <h5
              className="button add text-primary-medium fw-bold add-policyholder-cursor"
              onClick={() => addInputFields()}
            >
              + Add another policy
            </h5>
          </div>

          <Row className="text-end">
            <Col sm={10}>
              <CustomClickableButton
                buttonContent="Next"
                handleOnClick={handleCreatePolicyholderNextClick(
                  onSubmit,
                  onError
                )}
                disable={false}
              />
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  )
}

export default styled(PolicyholderDetails)`
  .add-policyholder-cursor {
    cursor: pointer;
  }
  .remove-policyholder-cursor {
    cursor: pointer;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
  .datepicker {
    height: 38px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
`
