import { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import { TrashIcon } from '@heroicons/react/solid'
import DatePicker from 'react-datepicker'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import styled from 'styled-components'
import { redirectDelayInMilliseconds } from '../data'
import {
  IAdditionalInsuredInput,
  IIndemnifiedPartyInput,
  IJobInput,
  INonProcessingPolicyForDropdown,
} from '../interface'
import CustomClickableButton from './custom-components/custom-clickable-button'
import {
  CREATE_JOB,
  NON_PROCESSING_POLICIES_LIST_FOR_DROPDOWN,
  GET_JOB_BY_ID,
  UPDATE_JOB,
} from '../graphql/graphql-queries'
import CustomAlert from './custom-components/custom-alert'
import authentication from '../core/authentication'
import ValidationError from './validation-error'

export interface CreateNewJobProps extends React.ComponentProps<any> {
  className: string
}

const CreateNewJob: React.FC<CreateNewJobProps> = ({ className }) => {
  const navigate = useNavigate()
  const location: any = useLocation()
  let initialJobInput = {
    startDate: null,
    streetAddress: '',
    nonProcessingPolicyId: '',
    indemnifiedParties: [
      {
        id: '',
        name: '',
        email: '',
        prefill: false,
        disableHasTrashIcon: false,
      },
    ],
    additionalInsureds: [
      {
        id: '',
        name: '',
        email: '',
        prefill: false,
        disableHasTrashIcon: false,
      },
    ],
  }

  let [jobInput, setJobInput] = useState<IJobInput>(initialJobInput)

  const [templateForSelectedPolicy, setTemplateForSelectedPolicy] = useState('')

  const [indemnifiedPartiesFormValues, setIndemnifiedPartiesFormValues] =
    useState<IIndemnifiedPartyInput[]>([
      {
        id: '',
        name: '',
        email: '',
        prefill: false,
        disableHasTrashIcon: false,
      },
    ])

  const [additionalInsuredFormValues, setAdditionalInsuredFormValues] =
    useState<IAdditionalInsuredInput[]>([
      {
        id: '',
        name: '',
        email: '',
        prefill: false,
        disableHasTrashIcon: false,
      },
    ])

  const [isJobCreated, setIsJobCreated] = useState(false)

  const [
    nonProcessingPoliciesListForDropdown,
    setNonProcessingPoliciesListForDropdown,
  ] = useState<INonProcessingPolicyForDropdown[]>([])

  const [errorJobCreation, setErrorJobCreation] = useState(false)

  const addIndemnifiedPartiesInputFields = () => {
    setIndemnifiedPartiesFormValues([
      ...indemnifiedPartiesFormValues,
      {
        id: '',
        name: '',
        email: '',
        prefill: false,
        disableHasTrashIcon: false,
      },
    ])
  }

  const removeIndemnifiedPartiesInputFields = (index: number) => {
    const rows = [...indemnifiedPartiesFormValues]
    rows.splice(index, 1)
    setIndemnifiedPartiesFormValues(rows)
    setJobInput({
      ...jobInput,
      indemnifiedParties: rows,
    })
  }

  const addAdditionalInsuredInputFields = () => {
    setAdditionalInsuredFormValues([
      ...additionalInsuredFormValues,
      {
        id: '',
        name: '',
        email: '',
        prefill: false,
        disableHasTrashIcon: false,
      },
    ])
  }

  const removeAdditionalInsuredInputFields = (index: number) => {
    const rows = [...additionalInsuredFormValues]
    rows.splice(index, 1)
    setAdditionalInsuredFormValues(rows)
    setJobInput({
      ...jobInput,
      additionalInsureds: rows,
    })
  }

  const handleOnChange = (e: any) => {
    setJobInput({
      ...jobInput,
      [e.target.name]: e.target.value,
    })

    if (e.target.name === 'nonProcessingPolicyId') {
      if (e.target.value === 'Select a Policy') {
        setAdditionalInsuredFormValues([
          {
            id: '',
            name: '',
            email: '',
            prefill: false,
            disableHasTrashIcon: false,
          },
        ])
        setIndemnifiedPartiesFormValues([
          {
            id: '',
            name: '',
            email: '',
            prefill: false,
            disableHasTrashIcon: false,
          },
        ])
      } else {
        let selectedPolicy = nonProcessingPoliciesListForDropdown.filter(
          (nonProcessingPolicy: any) =>
            e.target.value === nonProcessingPolicy.id
        )
        let prefilledDataForIndemnities = selectedPolicy.map((policy: any) => {
          return {
            id: '',
            name: policy.company.name,
            email: policy.company.user.email,
            prefill: true,
            disableHasTrashIcon: true,
          }
        })

        indemnifiedPartiesFormValues.length = 0
        additionalInsuredFormValues.length = 0
        setAdditionalInsuredFormValues(prefilledDataForIndemnities)
        setIndemnifiedPartiesFormValues(prefilledDataForIndemnities)
        setTemplateForSelectedPolicy(selectedPolicy[0]?.template?.templateName)
      }
    }
  }
  useEffect(() => {
    if (location?.state?.job) {
      let data = location?.state.job
      data?.indemnifiedParties?.map((indemnity: any) => {
        delete indemnity?.__typename
        indemnity.prefill = true
        return indemnity
      })
      data?.additionalInsureds?.map((additionalInsured: any) => {
        delete additionalInsured?.__typename
        additionalInsured.prefill = true
        return additionalInsured
      })
      setJobInput({
        ...jobInput,
        startDate: new Date(data.startDate),
        streetAddress: data.streetAddress,
        nonProcessingPolicyId: data.nonProcessingPolicy?.id,
        indemnifiedParties: data?.indemnifiedParties,
        additionalInsureds: data?.additionalInsureds,
      })
      setTemplateForSelectedPolicy(
        data.nonProcessingPolicy?.template?.templateName
      )
      let additionalInsureds = data?.additionalInsureds.map((insured: any) => {
        if (data?.nonProcessingPolicy?.company?.name === insured?.name) {
          insured.disableHasTrashIcon = true
        } else {
          insured.disableHasTrashIcon = false
        }
        return insured
      })
      additionalInsureds = additionalInsureds.sort(
        (a: any, b: any) => +new Date(a.createdAt) - +new Date(b.createdAt)
      )
      let indemnifiedParties = data?.indemnifiedParties.map(
        (indemnity: any) => {
          if (data?.nonProcessingPolicy?.company?.name === indemnity?.name) {
            indemnity.disableHasTrashIcon = true
          } else {
            indemnity.disableHasTrashIcon = false
          }
          return indemnity
        }
      )
      setAdditionalInsuredFormValues(additionalInsureds)
      setIndemnifiedPartiesFormValues(indemnifiedParties)
    }
  }, [location?.state?.job])
  const handleOnChangeForIndemnifiedParty = (index: number, event: any) => {
    if (event.target.name === 'name') {
      indemnifiedPartiesFormValues[index].name = event.target.value
    } else if (event.target.name === 'email') {
      indemnifiedPartiesFormValues[index].email = event.target.value
    }

    setJobInput({
      ...jobInput,
      indemnifiedParties: indemnifiedPartiesFormValues,
    })
  }

  const handleOnChangeForAdditionalInsured = (index: number, event: any) => {
    if (event.target.name === 'name') {
      additionalInsuredFormValues[index].name = event.target.value
    } else if (event.target.name === 'email') {
      additionalInsuredFormValues[index].email = event.target.value
    }

    setJobInput({
      ...jobInput,
      additionalInsureds: additionalInsuredFormValues,
    })
  }

  const handleOnChangeForDate = (e: any, date: Date, dateInputName: string) => {
    if (dateInputName === 'startDate') {
      setJobInput({
        ...jobInput,
        startDate: date,
      })
    }
  }

  let loggedInUser = authentication.getUserData()

  useQuery(NON_PROCESSING_POLICIES_LIST_FOR_DROPDOWN, {
    variables: {
      user: loggedInUser,
    },
    onCompleted: (data) => {
      setNonProcessingPoliciesListForDropdown(
        data.listNonProcessingPoliciesForDropdown
      )
    },
  })

  const clearInputs = () => {
    setJobInput(initialJobInput)
    setIndemnifiedPartiesFormValues([
      {
        id: '',
        name: '',
        email: '',
        prefill: false,
        disableHasTrashIcon: false,
      },
    ])
    setAdditionalInsuredFormValues([
      {
        id: '',
        name: '',
        email: '',
        prefill: false,
        disableHasTrashIcon: false,
      },
    ])
    setTemplateForSelectedPolicy('')
  }

  const [createJob] = useMutation(CREATE_JOB, {
    onCompleted: (data) => {
      setIsJobCreated(true)
      getJobById({ variables: { jobId: data.createJob.id } })
    },
    onError: (err) => {
      clearInputs()
      setErrorJobCreation(true)
    },
  })

  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: (data) => {
      setIsJobCreated(true)
      setTimeout(() => {
        navigate('/jobs')
      }, redirectDelayInMilliseconds)
    },
    onError: (err) => {
      console.log('err', err)
      clearInputs()
      setErrorJobCreation(true)
    },
  })

  const handleSetIsJobCreated = () => setIsJobCreated(false)

  const handleCopyIndemniteesToAdditionaInsuredCheckboxClick = (e: any) => {
    if (e.target.checked) {
      if (
        indemnifiedPartiesFormValues.length > 0 &&
        (indemnifiedPartiesFormValues[0]?.name ===
          additionalInsuredFormValues[0]?.name ||
          additionalInsuredFormValues[0]?.name === '' ||
          indemnifiedPartiesFormValues.length !==
            additionalInsuredFormValues.length)
      ) {
        setAdditionalInsuredFormValues([
          {
            id: '',
            name: '',
            email: '',
            prefill: false,
            disableHasTrashIcon: false,
          },
        ])

        setAdditionalInsuredFormValues([...indemnifiedPartiesFormValues])
        setJobInput({
          ...jobInput,
          additionalInsureds: [...indemnifiedPartiesFormValues],
        })
      }
    } else {
      setAdditionalInsuredFormValues([
        {
          id: '',
          name: '',
          email: '',
          prefill: false,
          disableHasTrashIcon: false,
        },
      ])
    }
  }
  const [error, setError] = useState({
    nonProcessingPolicyId: '',
    startDate: '',
    streetAddress: '',
  })

  const validationForm = () => {
    let errors = {
      nonProcessingPolicyId: '',
      startDate: '',
      streetAddress: '',
    }
    errors.nonProcessingPolicyId = validateNonProcessingPolicyId(
      jobInput.nonProcessingPolicyId
    )!
    errors.startDate = validateDate(jobInput.startDate)!
    errors.streetAddress = validateProjectLocation(jobInput.streetAddress)!
    return errors
  }

  const isValid = (errors: any) => {
    let keys = Object.keys(errors)
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0)
    return count === 0
  }

  const validateNonProcessingPolicyId = (policyNumber: string) => {
    if (policyNumber === '') {
      return 'Policy Number is required'
    }
    return ''
  }

  const validateDate = (date: Date | null) => {
    if (date === null) {
      return 'Date is required'
    }
    return ''
  }

  const validateProjectLocation = (policyNumber: string) => {
    if (policyNumber === '') {
      return 'Project Location is required'
    }
    return ''
  }

  const handleCreateJob = () => {
    let errorss = validationForm()
    if (isValid(errorss)) {
      if (location?.state?.job) {
        updateJob({
          variables: {
            data: {
              ...jobInput,
              indemnifiedParties:
                indemnifiedPartiesFormValues[0]?.name === ''
                  ? []
                  : indemnifiedPartiesFormValues.map((indemnifiedParty) => {
                      return {
                        id: indemnifiedParty.id,
                        name: indemnifiedParty.name,
                        email: indemnifiedParty.email,
                        prefill: indemnifiedParty.prefill,
                      }
                    }),
              additionalInsureds:
                additionalInsuredFormValues[0]?.name === ''
                  ? []
                  : additionalInsuredFormValues.map((additionalInsured) => {
                      return {
                        id: additionalInsured.id,
                        name: additionalInsured.name,
                        email: additionalInsured.email,
                        prefill: additionalInsured.prefill,
                      }
                    }),
              jobId: location?.state?.job?.id,
            },
          },
        })
      } else {
        let updatedIndemnifiedPartiesFormValues =
          indemnifiedPartiesFormValues.map(
            ({ disableHasTrashIcon, ...rest }) => rest
          )
        let updatedAdditionalInsuredFormValues =
          additionalInsuredFormValues.map(
            ({ disableHasTrashIcon, ...rest }) => rest
          )
        createJob({
          variables: {
            data: {
              ...jobInput,
              indemnifiedParties: updatedIndemnifiedPartiesFormValues,
              additionalInsureds: updatedAdditionalInsuredFormValues,
            },
          },
        })
      }
    } else {
      setError(errorss)
    }
  }

  const handleSetErrorJobCreation = () => setErrorJobCreation(false)

  const [getJobById] = useLazyQuery(GET_JOB_BY_ID, {
    fetchPolicy: 'cache-and-network',

    onCompleted: (data) => {
      navigate('/trades-list-for-a-job', { state: data.getJobById })
    },
  })

  return (
    <div className={className}>
      <div className="text-start client-list shadow-none p-5 m-4 bg-white rounded">
        {isJobCreated && (
          <Row className="flex-d justify-content-end pb-3 me-5">
            <CustomAlert
              handleOnClose={handleSetIsJobCreated}
              alertType="success"
              alertMessage={`Job at ${jobInput.streetAddress}  ${
                location?.state?.job ? 'Updated' : 'created'
              }`}
            />
          </Row>
        )}
        {errorJobCreation && (
          <Row className="flex-d justify-content-end pb-3 me-5">
            <CustomAlert
              handleOnClose={handleSetErrorJobCreation}
              alertType="error"
              alertMessage="Some error encountered. Please try again!"
            />
          </Row>
        )}

        <div>
          <Row className="text-start">
            <h4 className="fw-bold text-primary">
              {`${location?.state?.job ? 'Update Job' : 'Create New Job'}`}
            </h4>
          </Row>
        </div>

        <Form autoComplete="new-password">
          <div className="mt-3">
            <h6 className="fw-bold mb-3">Job Details</h6>
            <Row className="mb-3">
              <Col sm={2}>
                <Form.Label className="small fw-bold">Policy</Form.Label>
                <Form.Select
                  className="form-select"
                  aria-label="Default select example"
                  name="nonProcessingPolicyId"
                  value={jobInput.nonProcessingPolicyId}
                  onChange={handleOnChange}
                >
                  <option>Select a Policy</option>
                  {!!nonProcessingPoliciesListForDropdown?.length &&
                    nonProcessingPoliciesListForDropdown.map(
                      (nonProcessingPolicy: any) => (
                        <option
                          key={nonProcessingPolicy.id}
                          value={nonProcessingPolicy.id}
                        >
                          {`${nonProcessingPolicy?.policyNumber} (${nonProcessingPolicy?.company?.name})`}
                        </option>
                      )
                    )}
                </Form.Select>
                {error.nonProcessingPolicyId && (
                  <ValidationError errorMessage={error.nonProcessingPolicyId} />
                )}
              </Col>
              <Col sm={2}>
                <Form.Label className="small fw-bold">Template</Form.Label>
                <Form.Control
                  readOnly
                  name="template"
                  value={templateForSelectedPolicy}
                  className="template-input"
                ></Form.Control>
              </Col>
              <Col sm={2}>
                <Form.Label className="small fw-bold">
                  Job Start Date
                </Form.Label>
                <DatePicker
                  name="startDate"
                  selected={jobInput.startDate}
                  onChange={(date: Date, e) =>
                    handleOnChangeForDate(e, date, 'startDate')
                  }
                  className="datepicker"
                  showYearDropdown
                  showMonthDropdown
                  dateFormatCalendar="MM/dd/yyyy"
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                  autoComplete="off"
                />
                {error.startDate && (
                  <ValidationError errorMessage={error.startDate} />
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Form.Label className="small fw-bold">
                  Project Location
                </Form.Label>
                <Form.Control
                  name="streetAddress"
                  value={jobInput.streetAddress}
                  onChange={handleOnChange}
                  autoComplete="off"
                />
                {error.streetAddress && (
                  <ValidationError errorMessage={error.streetAddress} />
                )}
              </Col>
            </Row>
          </div>

          <div className="mt-5">
            <h6 className="fw-bold">Indemnitee(s)</h6>
            {indemnifiedPartiesFormValues.map((element: any, index: number) => (
              <Row className="mb-3" key={index}>
                <Col sm={4}>
                  <Form.Label className="small fw-bold">
                    Company Name
                  </Form.Label>
                  <Form.Control
                    name="name"
                    value={element?.name}
                    disabled={element?.disableHasTrashIcon ? true : false}
                    onChange={(e) =>
                      handleOnChangeForIndemnifiedParty(index, e)
                    }
                    autoComplete="off"
                  />
                </Col>
                <Col sm={4}>
                  <Form.Label className="small fw-bold">
                    Contact Information
                  </Form.Label>
                  <Form.Control
                    name="email"
                    value={element?.email}
                    disabled={element.disableHasTrashIcon ? true : false}
                    onChange={(e) =>
                      handleOnChangeForIndemnifiedParty(index, e)
                    }
                    autoComplete="off"
                  />
                </Col>
                {!element.disableHasTrashIcon ? (
                  <Col>
                    <TrashIcon
                      className="react-icon"
                      style={{
                        marginTop: '33px',
                      }}
                      onClick={() => removeIndemnifiedPartiesInputFields(index)}
                    />
                  </Col>
                ) : null}
              </Row>
            ))}

            <div>
              <span
                className="button add text-primary-medium fw-bold add-cursor"
                onClick={() => addIndemnifiedPartiesInputFields()}
              >
                <span className="pe-1">+</span>
                <span>Add another company</span>
              </span>
            </div>
          </div>

          <div className="mt-5">
            <Row>
              <Col sm={2}>
                <h6 className="fw-bold">Additional Insured(s)</h6>
              </Col>
              <Col sm={3}>
                <Form.Check
                  type="checkbox"
                  label="Same as Indemnitees"
                  name="copyIndemnitees"
                  key="copyIndemnitees1"
                  onChange={(e: any) =>
                    handleCopyIndemniteesToAdditionaInsuredCheckboxClick(e)
                  }
                  disabled={
                    !!location?.state?.job &&
                    !!additionalInsuredFormValues.length
                  }
                />
              </Col>
            </Row>
            {additionalInsuredFormValues.map((element: any, index: number) => {
              return (
                <Row className="mb-3" key={index}>
                  <Col sm={4}>
                    <Form.Label className="small fw-bold">
                      Company Name
                    </Form.Label>
                    <Form.Control
                      name="name"
                      key={`${element}-${index}`}
                      id={`${element}-${index}`}
                      disabled={element.disableHasTrashIcon ? true : false}
                      onChange={(e) =>
                        handleOnChangeForAdditionalInsured(index, e)
                      }
                      value={element.name}
                      autoComplete="off"
                    />
                  </Col>
                  <Col sm={4}>
                    <Form.Label className="small fw-bold">
                      Contact Information
                    </Form.Label>
                    <Form.Control
                      name="email"
                      value={element.email}
                      key={`${element}-${index}`}
                      id={`${element}-${index}`}
                      disabled={element.disableHasTrashIcon ? true : false}
                      onChange={(e) =>
                        handleOnChangeForAdditionalInsured(index, e)
                      }
                    />
                  </Col>
                  {!element.disableHasTrashIcon ? (
                    <Col>
                      <TrashIcon
                        className="react-icon"
                        style={{
                          marginTop: '33px',
                        }}
                        onClick={() =>
                          removeAdditionalInsuredInputFields(index)
                        }
                      />
                    </Col>
                  ) : null}
                </Row>
              )
            })}

            <div>
              <span
                className="button add text-primary-medium fw-bold add-cursor"
                onClick={() => addAdditionalInsuredInputFields()}
              >
                <span className="pe-1">+</span>
                <span>Add another company</span>
              </span>
            </div>
          </div>

          <Row className="text-end">
            <Col sm={10}>
              <CustomClickableButton
                buttonContent={
                  location?.state?.job ? 'Update Job' : 'Create Job'
                }
                handleOnClick={handleCreateJob}
                disable={false}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default styled(CreateNewJob)`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .datepicker {
    height: 38px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  .job-detail {
    text-decoration: underline;
    text-underline-offset: 0.2em;
  }

  .template-input {
    background: #fff;
  }

  .copy-checkbox {
    margin-top: 5px;
    margin-left: 55px;
  }
`
