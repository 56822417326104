import React, { useEffect } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  CLAIMS_SPECIALISTS_LIST,
  CREATE_CLIENT,
  CREATE_CLIENT_COMPANY,
  CREATE_OR_UPDATE_JOB_TYPE,
  CREATE_POLICY,
  GET_LABELLING_PATH_FOR_PROHIBITED_ENDORSMENT_OF_TRADECONTRACTOR_JOB,
  TEMPLATES_LIST,
  UPDATE_CLIENT,
  UPDATE_FIRST_NAMED_INSURED,
  UPDATE_NPP,
} from '../graphql/graphql-queries'
import { IPolicy, IEditClientData } from '../interface'
import ValidationError from './validation-error'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import clientCompanyDetail from './client-company-detail'
import { maskPhoneNumber } from '../helper-functions'

export interface PopupModalProps extends React.ComponentProps<any> {
  className: string
  clientdatatobeedited?: IEditClientData
  jobtypedatatobeedited?: any
  isedit?: boolean
  show: boolean
  onHide: () => void
  firstname?: string
  lastname?: string
  email?: string
  phone?: string
  message?: string
  firstnameinsured?: string
  policynumber?: string
  policystartdate?: string
  policyenddate?: string
  claimspecialist?: string
  requirementstemplate?: string
  page?: string
  clientid?: string
  companyid?: string
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>
  setshowmodal?: React.Dispatch<React.SetStateAction<boolean>>
  setsuccess?: React.Dispatch<React.SetStateAction<any>>
  setsuccessmessage?: React.Dispatch<React.SetStateAction<any>>
  seterrormessage?: React.Dispatch<React.SetStateAction<any>>
  jobcode?: string
  jobname?: string
  tradecontracrtorid?: string
  companyname?: string
  nonprocessingpolicydatatObeedited?: any
  iseditpolicy?: boolean

  // size?: string
}
const PopupModal: React.FC<PopupModalProps> = (props: PopupModalProps) => {
  const {
    register,
    handleSubmit: handleCreatePolicyholderNextClick,
    formState,
    watch,
  } = useForm()
  const onError = (errors: any, e: any) => console.log(errors, e)

  const [newClient, setNewClient] = useState({
    email: props.isedit ? props.clientdatatobeedited?.email : '',
    firstName: props.isedit ? props.clientdatatobeedited?.firstName : '',
    lastName: props.isedit ? props.clientdatatobeedited?.lastName : '',
    phoneNumber: props.isedit ? props.clientdatatobeedited?.phoneNumber : '',
  })
  const [newCompany, setNewCompany] = useState({
    firstNameInsured: props.companyname ? props.companyname : '',
  })
  const [
    labellingPathForProhibitedEndorsmentForTradeContract,
    setLabellingPathForProhibitedEndorsmentForTradeContract,
  ] = useState([])
  const [templatesList, setTemplatesList] = useState([])

  const [claimsSpecialistsList, setClaimsSpecialistsList] = useState([])
  const [formValues, setFormValues] = useState<IPolicy>({
    policyNumber: props.iseditpolicy
      ? props.nonprocessingpolicydatatObeedited.policyNumber
      : '',
    startDate: props.iseditpolicy
      ? new Date(props.nonprocessingpolicydatatObeedited.startDate)
      : null,
    endDate: props.iseditpolicy
      ? new Date(props.nonprocessingpolicydatatObeedited.endDate)
      : null,
    templateId: props.iseditpolicy
      ? props.nonprocessingpolicydatatObeedited.templateId
      : '',
    claimsSpecialistId: props.iseditpolicy
      ? props.nonprocessingpolicydatatObeedited.claimsSpecialistId
      : '',
  })
  const [error, setError] = useState({
    policyNumber: '',
    startDate: '',
    endDate: '',
    templateId: '',
    claimsSpecialistId: '',
  })

  const [newJob, setNewJob] = useState({
    jobCode: props.isedit ? props.jobtypedatatobeedited?.jobCode : '',
    jobName: props.isedit ? props.jobtypedatatobeedited?.jobName : '',
  })
  const [isExpired, setIsExpired] = useState(
    props.iseditpolicy
      ? props.nonprocessingpolicydatatObeedited.policyStatus
      : true
  )
  const [tradeContractor, setTradeContractor] = useState(false)
  const handleOnChangeCreateNewClient = (e: any) => {
    if (props.page === 'Client_List') {
      if (e.target.name === 'phoneNumber') {
        const maskedPhoneNumber = maskPhoneNumber(e.target.value)
        setNewClient({
          ...newClient,
          [e.target.name]: maskedPhoneNumber!,
        })
      } else {
        setNewClient({
          ...newClient,
          [e.target.name]: e.target.value,
        })
      }
    }
    if (props.page === 'Client_Company_List') {
      setNewCompany({
        ...newCompany,
        [e.target.name]: e.target.value,
      })
    }
    if (props.page === 'Client_Policy_List') {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
      })
    }
    if (props.page === 'Job_Type') {
      setNewJob({
        ...newJob,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleSaveButtonClick = async () => {
    if (
      props.page === 'Client_List' &&
      props.clientdatatobeedited?.id === '' &&
      props.setshowmodal
    ) {
      await createClient({
        variables: {
          data: newClient,
        },
      })
      props.setshowmodal(false)
    }

    if (
      props.page === 'Client_List' &&
      props.clientdatatobeedited?.id !== '' &&
      props.setshowmodal
    ) {
      await updateClient({
        variables: {
          data: newClient,
          id: props.clientdatatobeedited?.id,
        },
      })
      props.setshowmodal(false)
    }

    if (
      props.page === 'Client_Company_List' &&
      !props.companyid &&
      props.setshowmodal
    ) {
      await createCompany({
        variables: {
          data: { name: newCompany.firstNameInsured },
          userId: props.clientid,
        },
      })
      props.setshowmodal(false)
    }
    if (
      props.page === 'Client_Company_List' &&
      props.companyid &&
      props.setshowmodal
    ) {
      await updateFirstNameInsured({
        variables: {
          data: { name: newCompany.firstNameInsured },
          id: props.companyid,
        },
      })
      props.setshowmodal(false)
    }
    if (props.page === 'Client_Policy_List' && props.setshowmodal) {
      let errorss = validationForm()
      if (isValid(errorss)) {
        let data = {
          ...formValues,
          companyId: props.companyid,
          isExpired: !isExpired,
          email: props.email,
        }
        try {
          if (props.iseditpolicy) {
            await updateNonProcessingPolicy(data)
          } else {
            await createNonProcessingPolicy(data)
          }
        } catch (e) {
          if (
            props.setsuccessmessage !== undefined &&
            props.setsuccess !== undefined
          ) {
            props.setsuccessmessage(
              'There is some issue with create new policy! Please try after some time'
            )
            props.setsuccess('failed')
          }
        }

        props.setshowmodal(false)
      } else {
        setError(errorss)
      }
    }
    if (props.page === 'Job_Type' && props.setshowmodal && !props.isedit) {
      let data = { jobCode: newJob.jobCode, name: newJob.jobName }

      try {
        await createJobType({
          variables: {
            data: data,
          },
        })
        if (
          props.setsuccessmessage !== undefined &&
          props.setsuccess !== undefined
        ) {
          props.setsuccessmessage('Job Type has beed created successfully')
          props.setsuccess('success')
        }
      } catch (e) {
        if (
          props.setsuccessmessage !== undefined &&
          props.setsuccess !== undefined
        ) {
          props.setsuccessmessage(
            'There is some issue with job type! Please try after some time'
          )
          props.setsuccess('failed')
        }
      }
      props.setshowmodal(false)
    }
    if (
      props.page === 'Job_Type' &&
      props.jobtypedatatobeedited?.name !== '' &&
      props.setshowmodal &&
      props.isedit
    ) {
      let data = {
        id: props.jobtypedatatobeedited?.id,
        jobCode: newJob.jobCode,
        name: newJob.jobName,
      }
      try {
        await createJobType({
          variables: {
            data: data,
          },
        })
        if (
          props.setsuccessmessage !== undefined &&
          props.setsuccess !== undefined
        ) {
          props.setsuccessmessage('Job Type has beed updated successfully')
          props.setsuccess('success')
        }
      } catch (e) {
        if (
          props.setsuccessmessage !== undefined &&
          props.setsuccess !== undefined
        ) {
          props.setsuccessmessage(
            'There is some issue with job type! Please try after some time'
          )
          props.setsuccess('failed')
        }
      }
      props.setshowmodal(false)
    }
  }
  const createNonProcessingPolicy = async (data: any) => {
    await createPolicy({
      variables: {
        data: data,
      },
    })
    if (
      props.setsuccessmessage !== undefined &&
      props.setsuccess !== undefined
    ) {
      props.setsuccessmessage(
        'Policy is created! Email has sent for signup process.'
      )
      props.setsuccess('success')
    }
  }
  const updateNonProcessingPolicy = async (data: any) => {
    await updatePolicy({
      variables: {
        data: { ...data, id: props.nonprocessingpolicydatatObeedited.id },
      },
    })
    if (
      props.setsuccessmessage !== undefined &&
      props.setsuccess !== undefined
    ) {
      props.setsuccessmessage('Policy is updated successfully!')
      props.setsuccess('success')
    }
  }
  const validationForm = () => {
    let errors = {
      policyNumber: '',
      startDate: '',
      endDate: '',
      templateId: '',
      claimsSpecialistId: '',
      phoneNumber: '',
    }
    errors.policyNumber = validatePolicyNumber(formValues.policyNumber)!
    errors.startDate = validateDate(formValues.startDate)!
    errors.endDate = validateDate(formValues.endDate)!
    errors.templateId = validateTemAndClaim(formValues.templateId, 'Template')!
    errors.claimsSpecialistId = validateTemAndClaim(
      formValues.claimsSpecialistId,
      'Claim'
    )!

    return errors
  }
  const isValid = (errors: any) => {
    let keys = Object.keys(errors)
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0)
    return count === 0
  }
  const validatePolicyNumber = (policyNumber: string) => {
    if (policyNumber === '') {
      return 'Policy Number is required'
    }
    return ''
  }
  const validateDate = (date: any) => {
    if (date === null || date === '') {
      return 'Please select a date'
    }
    return ''
  }
  const validateTemAndClaim = (value: any, string: string) => {
    if (string === 'Template' && value === '') {
      return 'Please select a Template'
    } else if (string === 'Claim' && value === '') {
      return 'Please select a ClaimSpecialist'
    }
    return ''
  }
  const [createClient] = useMutation(CREATE_CLIENT, {
    onCompleted: (data) => {
      if (props.setsuccessmessage) {
        props.setsuccessmessage('Client has been created successfully')
      }
    },

    onError: (error) => {
      if (props.seterrormessage && props.setshowmodal) {
        props.seterrormessage(error.message)
        props.setshowmodal(false)
      }
    },
  })

  useEffect(() => {
    ;(async () => {
      if (props.tradecontracrtorid && props.show) {
        await getProhibitedEndorsmentOfTradeContractorJob({
          variables: { id: props.tradecontracrtorid },
        })
      }
    })()
  }, [props.tradecontracrtorid, props.show])
  const [getProhibitedEndorsmentOfTradeContractorJob] = useMutation(
    GET_LABELLING_PATH_FOR_PROHIBITED_ENDORSMENT_OF_TRADECONTRACTOR_JOB,
    {
      onCompleted: (data) => {
        if (data) {
          setLabellingPathForProhibitedEndorsmentForTradeContract(
            data?.getLabellingPathsForProhibitedEndorsmentsOfTradeContractorJob
          )
        }
      },
    }
  )

  const [updateClient] = useMutation(UPDATE_CLIENT, {
    onCompleted: (data) => {
      if (props.setsuccessmessage) {
        props.setsuccessmessage('Client has been updated successfully')
      }
    },
  })

  const [updateFirstNameInsured] = useMutation(UPDATE_FIRST_NAMED_INSURED, {
    onCompleted: (data) => {
      if (props.setsuccessmessage) {
        props.setsuccessmessage('Company has been updated successfully.')
      }
    },
    onError: (error) => {
      if (props.seterrormessage && props.setshowmodal) {
        props.seterrormessage('Company already registered with same name!')
        props.setshowmodal(false)
      }
    },
  })

  const [createPolicy] = useMutation(CREATE_POLICY, {
    onCompleted: (data) => {},
  })
  const [updatePolicy] = useMutation(UPDATE_NPP, {
    onCompleted: (data) => {},
  })
  const [createJobType] = useMutation(CREATE_OR_UPDATE_JOB_TYPE, {
    onCompleted: (data) => {},
  })

  const handleOnChangeForDropDown = (e: any) => {
    if (e.target.name === 'claimsSpecialist') {
      setFormValues({
        ...formValues,
        claimsSpecialistId: e.target.value,
      })
    } else if (e.target.name === 'template') {
      setFormValues({
        ...formValues,
        templateId: e.target.value,
      })
    }
  }
  const handleOnChangeForDate = (e: any, date: Date, dateInputName: string) => {
    if (dateInputName === 'startDate') {
      setFormValues({
        ...formValues,
        startDate: date,
      })
    } else if (dateInputName === 'endDate') {
      setFormValues({
        ...formValues,
        endDate: date,
      })
    }
  }
  const handleOnChangeForCheckBox = (e: any) => {
    if (e.target.name === 'isExpired') {
      setIsExpired(e.target.checked)
    }
    if (e.target.name === 'tradeContractor') {
      setTradeContractor(e.target.checked)
    }
  }

  useQuery(CLAIMS_SPECIALISTS_LIST, {
    onCompleted: (data) => {
      setClaimsSpecialistsList(data.listClaimsSpecialists)
    },
  })
  const PaginationTemplateData = {
    offset: 0,
    limit: 0,
  }
  useQuery(TEMPLATES_LIST, {
    variables: {
      data: PaginationTemplateData,
    },
    onCompleted: (data) => {
      setTemplatesList(data.listTemplates.templates)
    },
  })

  const [createCompany] = useMutation(CREATE_CLIENT_COMPANY, {
    onCompleted: (data) => {
      if (props.setsuccessmessage) {
        props.setsuccessmessage(
          'Company has been created successfully. Please add a policy for this company.'
        )
      }
    },
    onError: (error) => {
      if (props.seterrormessage && props.setshowmodal) {
        props.seterrormessage(error.message)
        props.setshowmodal(false)
      }
    },
  })
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={props.className}
    >
      <Modal.Header closeButton>
        <strong>{props.message}</strong>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            {/* for Add/edit new client             */}
            {props.tradecontracrtorid &&
              labellingPathForProhibitedEndorsmentForTradeContract.map(
                (item: any) => (
                  <ul key={item.id}>
                    <li className="prohibitedEndorsment">
                      <div>{item.labellingPath}</div>
                    </li>
                  </ul>
                )
              )}
            {props.tradecontracrtorid &&
              labellingPathForProhibitedEndorsmentForTradeContract.length ===
                0 && <h4>No Prohibited Endorsements Specified.</h4>}
            {props.firstname && props.lastname && (
              <Row>
                <Col>
                  <Form.Label>{props.firstname}</Form.Label>
                  <Form.Control
                    {...register('firstName', {
                      required: {
                        value: true,
                        message:
                          'You must specify a First Name before moving forward.',
                      },
                    })}
                    type="text"
                    name="firstName"
                    placeholder={props.firstname}
                    onChange={handleOnChangeCreateNewClient}
                    defaultValue={newClient.firstName}
                    autoComplete="off"
                  />
                  {formState.errors.firstName && (
                    <ValidationError
                      errorMessage={formState.errors.firstName.message}
                    />
                  )}
                </Col>
                <Col>
                  <Form.Label>{props.lastname}</Form.Label>
                  <Form.Control
                    {...register('lastName', {
                      required: {
                        value: true,
                        message:
                          'You must specify a Last Name before moving forward.',
                      },
                    })}
                    type="text"
                    name="lastName"
                    placeholder={props.lastname}
                    onChange={handleOnChangeCreateNewClient}
                    defaultValue={newClient.lastName}
                    autoComplete="off"
                  />
                  {formState.errors.lastName && (
                    <ValidationError
                      errorMessage={formState.errors.lastName.message}
                    />
                  )}
                </Col>
              </Row>
            )}
            {props.email && props.phone && (
              <Row className="mt-3">
                <Col>
                  <Form.Label>{props.email}</Form.Label>
                  {!props.isedit ? (
                    <>
                      {' '}
                      <Form.Control
                        {...register('email', {
                          required: {
                            value: true,
                            message: 'You need to provide an email address.',
                          },
                          pattern: {
                            value: new RegExp(
                              "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
                            ),
                            message:
                              'You need to specify a valid email address.',
                          },
                        })}
                        // type="email"
                        name="email"
                        placeholder={props.email}
                        onChange={handleOnChangeCreateNewClient}
                        defaultValue={newClient.email}
                        disabled={props.isedit ? true : false}
                        autoComplete="off"
                      />
                      {formState.errors.email && (
                        <ValidationError
                          errorMessage={formState.errors.email.message}
                        />
                      )}
                    </>
                  ) : (
                    <Form.Control
                      name="email"
                      placeholder={props.email}
                      onChange={handleOnChangeCreateNewClient}
                      defaultValue={newClient.email}
                      disabled={props.isedit ? true : false}
                      autoComplete="off"
                    />
                  )}
                </Col>
                <Col>
                  <Form.Label>Phone Number(Optional)</Form.Label>
                  <Form.Control
                    {...register('phoneNumber', {
                      validate: (val: string) => {
                        if (
                          watch('phoneNumber').length > 0 &&
                          watch('phoneNumber').length < 14
                        ) {
                          return 'Phone number must be 10 digit long!'
                        }
                      },
                    })}
                    type="text"
                    name="phoneNumber"
                    placeholder={newClient.phoneNumber}
                    onChange={handleOnChangeCreateNewClient}
                    value={newClient.phoneNumber}
                    autoComplete="off"
                    maxLength={14}
                  />
                  {formState.errors.phoneNumber && (
                    <ValidationError
                      errorMessage={formState.errors.phoneNumber.message}
                    />
                  )}
                </Col>
              </Row>
            )}

            {/* for add first named insured */}
            {props.firstnameinsured && (
              <Row>
                <Form.Label>{props.firstnameinsured}</Form.Label>
                <Form.Control
                  {...register('firstNameInsured', {
                    required: {
                      value: true,
                      message:
                        'You must specify a First Name Insured before moving forward.',
                    },
                  })}
                  type="text"
                  name="firstNameInsured"
                  placeholder={props.firstnameinsured}
                  onChange={handleOnChangeCreateNewClient}
                  defaultValue={newCompany.firstNameInsured}
                  autoComplete="off"
                />
                {formState.errors.firstNameInsured && (
                  <ValidationError
                    errorMessage={formState.errors.firstNameInsured.message}
                  />
                )}
              </Row>
            )}

            {/* for add new policy */}
            {props.policynumber && (
              <Row className="text-start mb-2">
                <Col sm={5}>
                  <Form.Label>{props.policynumber}</Form.Label>
                  <Form.Control
                    type="text"
                    name="policyNumber"
                    placeholder={props.policynumber}
                    onChange={handleOnChangeCreateNewClient}
                    defaultValue={formValues.policyNumber}
                    autoComplete="off"
                  />
                </Col>
                {error.policyNumber && (
                  <Row className="mb-0">
                    <span className="m-0 pb-0 text-danger">
                      {error.policyNumber}
                    </span>
                  </Row>
                )}
              </Row>
            )}
            {props.policystartdate && props.policyenddate && (
              <Row className="mb-2">
                <Col>
                  <Form.Label>{props.policystartdate}</Form.Label>

                  <DatePicker
                    name="startDate"
                    className="datepicker"
                    selected={formValues.startDate}
                    onChange={(date: Date, e) => {
                      handleOnChangeForDate(e, date, 'startDate')
                    }}
                    showYearDropdown
                    showMonthDropdown
                    dateFormatCalendar="MM/dd/yyyy"
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                    autoComplete="off"
                  />
                  {error.startDate && (
                    <Row className="mb-0">
                      <span className="m-0 pb-0 text-danger">
                        {error.startDate}
                      </span>
                    </Row>
                  )}
                </Col>
                <Col>
                  <Form.Label>{props.policyenddate}</Form.Label>

                  <DatePicker
                    name="endDate"
                    className="datepicker"
                    selected={formValues.endDate}
                    onChange={(date: Date, e) => {
                      handleOnChangeForDate(e, date, 'endDate')
                    }}
                    showYearDropdown
                    showMonthDropdown
                    dateFormatCalendar="MM/dd/yyyy"
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                    autoComplete="off"
                  />
                  {error.endDate && (
                    <Row className="mb-0">
                      <span className="m-0 pb-0 text-danger">
                        {error.endDate}
                      </span>
                    </Row>
                  )}
                </Col>
              </Row>
            )}
            {props.claimspecialist && props.requirementstemplate && (
              <Row>
                <Col>
                  <Form.Label>{props.claimspecialist}</Form.Label>
                  <Form.Select
                    className="form-select"
                    aria-label="Default select example"
                    name="claimsSpecialist"
                    onChange={(e) => handleOnChangeForDropDown(e)}
                  >
                    <option>Select a claims specialist</option>
                    {!!claimsSpecialistsList.length &&
                      claimsSpecialistsList.map((claimsSpecialist: any) => (
                        <option
                          selected={
                            formValues.claimsSpecialistId ===
                            claimsSpecialist.id
                          }
                          key={claimsSpecialist.id}
                          value={claimsSpecialist.id}
                        >{`${claimsSpecialist.claimsSpecialistFirstName} ${claimsSpecialist.claimsSpecialistLasttName}`}</option>
                      ))}
                  </Form.Select>
                  {error.claimsSpecialistId && (
                    <Row className="mb-0">
                      <span className="m-0 pb-0 text-danger">
                        {error.claimsSpecialistId}
                      </span>
                    </Row>
                  )}
                </Col>
                <Col>
                  <Form.Label>{props.requirementstemplate}</Form.Label>
                  <Form.Select
                    className="form-select"
                    aria-label="Default select example"
                    name="template"
                    onChange={(e) => handleOnChangeForDropDown(e)}
                  >
                    <option>Select a template</option>
                    {!!templatesList.length &&
                      templatesList.map((template: any) => (
                        <option
                          selected={formValues.templateId === template.id}
                          key={template.id}
                          value={template.id}
                        >
                          {template.templateName}
                        </option>
                      ))}
                  </Form.Select>
                  {error.templateId && (
                    <Row className="mb-0">
                      <span className="m-0 pb-0 text-danger">
                        {error.templateId}
                      </span>
                    </Row>
                  )}
                </Col>
              </Row>
            )}
            {props.policynumber && (
              <>
                <Row className="mt-3">
                  <Col sm={6} className="text-end ">
                    <Form.Check
                      type="checkbox"
                      name="isExpired"
                      key="isExpired1"
                      onChange={(e) => handleOnChangeForCheckBox(e)}
                      defaultChecked={isExpired}
                    />
                  </Col>
                  <Col sm={5}>
                    <p>Make this an Active Policy</p>
                  </Col>
                </Row>
                <Row className="">
                  <Col sm={6} className="text-end ">
                    <Form.Check
                      type="checkbox"
                      name="tradeContractor"
                      key="tradeContractor1"
                      onChange={(e) => handleOnChangeForCheckBox(e)}
                    />
                  </Col>
                  <Col sm={5}>
                    <p>Recertify trade contractor policies.</p>
                  </Col>
                </Row>
              </>
            )}
            {/* for add new job type */}
            {props.jobcode && props.jobname && (
              <Row className="mb-2">
                <Col>
                  <Form.Label>{props.jobcode}</Form.Label>
                  {!props.isedit ? (
                    <>
                      <Form.Control
                        {...register('jobCode', {
                          required: {
                            value: true,
                            message:
                              'You must specify a Job Code before moving forward.',
                          },
                        })}
                        type="text"
                        name="jobCode"
                        placeholder={props.jobcode}
                        onChange={handleOnChangeCreateNewClient}
                        defaultValue={newJob.jobCode}
                      />
                      {formState.errors.jobCode && (
                        <ValidationError
                          errorMessage={formState.errors.jobCode.message}
                        />
                      )}
                    </>
                  ) : (
                    <Form.Control
                      name="jobCode"
                      placeholder={props.jobcode}
                      onChange={handleOnChangeCreateNewClient}
                      defaultValue={newJob.jobCode}
                      disabled={props.isedit ? true : false}
                    />
                  )}
                </Col>
                <Col>
                  <Form.Label>{props.jobname}</Form.Label>
                  <Form.Control
                    {...register('jobName', {
                      required: {
                        value: true,
                        message:
                          'You must specify a Job Name before moving forward.',
                      },
                    })}
                    type="text"
                    name="jobName"
                    placeholder={props.jobname}
                    onChange={handleOnChangeCreateNewClient}
                    defaultValue={newJob.jobName}
                  />
                  {formState.errors.jobName && (
                    <ValidationError
                      errorMessage={formState.errors.jobName.message}
                    />
                  )}
                </Col>
              </Row>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-evenly' }}>
        {props?.page === 'Prohibited_Endorsements' ? (
          <Row>
            <Col className="text-end">
              <Button
                className="btn btn-warning-medium cancelButtonEndorsment  text-white"
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={6} className="text-start">
              <Button
                className="btn btn-warning-medium cancelButton text-white"
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </Col>
            <Col sm={6} className="text-end">
              <Button
                className="btn btn-primary-medium submitButton text-white"
                onClick={handleCreatePolicyholderNextClick(
                  handleSaveButtonClick,
                  onError
                )}
              >
                {props.isedit ? 'Update' : 'Save'}
              </Button>
            </Col>
          </Row>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default styled(PopupModal)`
  .submitButton {
    width: 120px;
    height: 35px;
    margin-left: 50px;
    font-size: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .cancelButton {
    background: orange;
    padding: 7px;
    width: 120px;
    border: none;
    margin-right: 50px;
  }
  .cancelButtonEndorsment {
    background: orange;
    padding: 7px;
    width: 120px;
    border: none;
    margin-left: 590px;
  }
  .prohibitedEndorsment {
    font-size: 16px;
    list-style: disc;
  }
`
