import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import styled from 'styled-components'
import authentication from '../../core/authentication'
import { appConfig } from '../../core'
import axios from 'axios'
import SpinnerLoading from '../spinner-loading'

export interface SpecialRequirementsProps extends React.ComponentProps<any> {
  className: string
  templateId: string
  addedTemplateLabels: any[]
  setAddedTemplateLabels: React.Dispatch<React.SetStateAction<any[]>>
  addedSpecialRequirements: any[]
  setAddedSpecialRequirements: React.Dispatch<React.SetStateAction<any[]>>
  setCheckedElementsForSpecialRequirements: React.Dispatch<
    React.SetStateAction<any[]>
  >
  getSpecialRequirementsLabelsStoredInDB: () => any[]
}
const SpecialRequirements: React.FC<SpecialRequirementsProps> = ({
  className,
  addedSpecialRequirements,
  setCheckedElementsForSpecialRequirements,
  setAddedSpecialRequirements,
  getSpecialRequirementsLabelsStoredInDB,
}) => {
  const [state, setState] = useState<any>({
    checked: [],
    expanded: [],
    clicked: {},
  })

  const [labellerHierarchyJson, setLabellerHierarchyJson] = useState<any[]>([])

  const handledOnCheck = (checked: any) => {
    setState({ ...state, checked })
    setCheckedElementsForSpecialRequirements(checked)
    setAddedSpecialRequirements(checked)
  }

  const handledOnClick = (clicked: any) => {
    setState({ ...state, clicked })
  }

  const handledOnExpand = (expanded: any) => {
    setState({ ...state, expanded })
  }

  useEffect(() => {
    const token = authentication.getAccessToken()
    axios
      .get(`${appConfig.apiUrl}/rest/labels-for-checkbox-tree`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLabellerHierarchyJson(response.data)
      })
      .catch((err) => console.error('Error: ', err))
  }, [])
  useEffect(() => {
    let specialRequirementsLabels = getSpecialRequirementsLabelsStoredInDB()
    setState({
      ...state,
      checked: addedSpecialRequirements.length
        ? addedSpecialRequirements
        : specialRequirementsLabels,
    })
  }, [])
  if (!labellerHierarchyJson?.length)
    return (
      <div
        className={`${className} spinner`}
        style={{ position: 'absolute', top: '50%', left: '50%' }}
      >
        <SpinnerLoading />
      </div>
    )
  return (
    <div className={className}>
      <Container>
        <Row>
          <Col sm={1}></Col>
        </Row>
        <Row>
          <Col sm={1}></Col>
          <Col sm={9}>
            <CheckboxTree
              nodes={labellerHierarchyJson}
              checked={state.checked}
              expanded={state.expanded}
              onCheck={(checked) => handledOnCheck(checked)}
              onExpand={(expanded) => handledOnExpand(expanded)}
              onClick={(targetNode) => handledOnClick(targetNode)}
              showNodeIcon={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default styled(SpecialRequirements)`
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
`
