import React from 'react';
import styled from 'styled-components'
import UploadFileToS3 from './upload-file-to-s3';

type Props = {};

const FileUpload: React.FC<Props> = () => {
  return (
    <UploadFileToS3 />
  )

};

export default styled(FileUpload)`
  .file-upload-container {
    border: 2px solid black;
  }
`
