import { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Badge } from 'react-bootstrap'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import {
  LABELLING_FORM,
  LABELLING_LIST,
  MASTER_FORM_DATA,
} from '../../graphql/graphql-queries'
import PdfViewer from '../pdf-viewer'
import appConfig from '../../core/config'
import styled from 'styled-components'
import authentication from '../../core/authentication'
import LabellingComponentPage from './labelling-component-page'
import { IUnlabelledFormData } from '../../interface'

interface FormList {
  [key: string]: any
}

interface FormObject {
  labels: FormList[]
}

export interface LabellerProps extends React.ComponentProps<any> {
  setComponent: React.Dispatch<React.SetStateAction<any>>
  updatedDataFunc: any
  className?: string
}
const LabellingComponent: React.FC<LabellerProps> = ({ className }) => {
  const { formId } = useParams()
  const navigate = useNavigate()
  const location: any = useLocation()
  let data = [
    {
      id: '',
      type: '',
      formNumber: '',
      formTitle: '',
      scheduledText: '',
      numberOfPages: 0,
      filePath: '',
      formStartPageNumber: 0,
      formEndPageNumber: 0,
      isLabelled: false,
      containsParserError: false,
      containsUncapturedScheduledTexts: false,
      isCompliant: false,
      isInvalidInput: false,
    },
  ]
  const [labellingJson, setLabellingJson] = useState({})
  const [uploadedFilePath, setUploadedFilePath] = useState('')
  const [unlabelledFormData, setUnlabelledFormData] =
    useState<IUnlabelledFormData[]>(data)
  const [formIndex, setFormIndex] = useState(0)

  const [pdfPath, setPdfPath] = useState<string>('')

  const [containsParserError, setContainsParserError] = useState<boolean>(
    unlabelledFormData[formIndex]?.containsParserError || false
  )
  const [
    containsUncapturedScheduledTexts,
    setContainsUncapturedScheduledTexts,
  ] = useState<boolean>(
    unlabelledFormData[formIndex]?.containsUncapturedScheduledTexts || false
  )
  const [isCompliant, setIsCompliant] = useState<boolean>(
    unlabelledFormData[formIndex]?.isCompliant || false
  )
  const [isInvalidInput, setIsInvalidInput] = useState<boolean>(
    unlabelledFormData[formIndex]?.isInvalidInput || false
  )
  const [shouldCallApi, setShouldCallApi] = useState<boolean>(false)

  const [labelFormApi] = useMutation(LABELLING_FORM)

  const [state, setState] = useState<any>({
    checked: [],
    expanded: [],
    clicked: {},
  })

  const defaultDataSize = 25

  const [formsListCount, setFormsListCount] = useState(0)
  useEffect(() => {
    const token = authentication.getAccessToken()
    axios
      .get(`${appConfig.apiUrl}/rest/labels`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLabellingJson(response.data)
      })
      .catch((err) => console.error('Error: ', err))
  }, [formId])
  let [initialOffset, setInitialOffset] = useState(4)

  useEffect(() => {
    if (
      location?.state?.unlabelledFormList &&
      location?.state?.unlabelledFormList.length
    ) {
      setUnlabelledFormData(location?.state?.unlabelledFormList)
      updateLabellerData(location?.state?.unlabelledFormList[formIndex])
      let uploadPath = location?.state?.unlabelledFormList[formIndex]?.filePath
      let path = new URL(uploadPath)?.pathname?.slice(1)
      setUploadedFilePath(path)
    }
  }, [])

  useEffect(() => {
    const token = authentication.getAccessToken()
    if (uploadedFilePath) {
      axios({
        url: `${appConfig.apiUrl}/rest/signed-get-url?path=${uploadedFilePath}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf', // declare the file format in s3
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => setPdfPath(res.data))
        .catch((err) => console.error('Error: ', err))
    }
  }, [uploadedFilePath])

  const updateUnlabelledFormsList = (
    id: string,
    unlabelledFormsList: IUnlabelledFormData[]
  ) => {
    let updatedFormList = unlabelledFormsList.map((e: any) => {
      if (e.id === id) {
        e = { ...e, isLabelled: true }
      }
      return e
    })
    setUnlabelledFormData([...updatedFormList])
  }

  const getUnlabelledFormCount = (
    unlabelledFormsList: IUnlabelledFormData[]
  ) => {
    let count = unlabelledFormsList.filter((e: any) => !e.isLabelled).length
    return count
  }
  useEffect(() => {
    if (shouldCallApi) {
      const formData = {
        containsParserError,
        containsUncapturedScheduledTexts,
        isCompliant,
        isInvalidInput,
        labellingPaths: state.checked,
      }
      labelFormApi({
        variables: { id: unlabelledFormData[formIndex].id, formData },
      })
      updateUnlabelledFormsList(
        unlabelledFormData[formIndex].id,
        unlabelledFormData
      )
      refetch()

      if (
        !(unlabelledFormData?.length > 1) &&
        !(formIndex === unlabelledFormData?.length - 1)
      ) {
        navigate('/start-labelling', {
          state: {
            refresh: true,
            status: 'Labelled',
            pageNumber: location?.state?.pageNumber,
            flagForPageNumber: false,
          },
        })
      }

      if (
        unlabelledFormData?.length > 1 &&
        formIndex !== unlabelledFormData?.length - 1
      ) {
        setFormIndex(formIndex + 1)
        let filePath = unlabelledFormData[formIndex]?.filePath
        let path = new URL(filePath)?.pathname?.slice(1)
        updateLabellerData(unlabelledFormData[formIndex])
        state.checked = []
        setUploadedFilePath(path)
        setShouldCallApi(false)
        resetState()
      }
      if (
        unlabelledFormData?.length &&
        formIndex === unlabelledFormData?.length - 1
      ) {
        navigate('/start-labelling', {
          state: {
            refresh: true,
            status: 'Labelled',
            pageNumber: location?.state?.pageNumber,
            flagForPageNumber: false,
          },
        })
      }
    }
  }, [isCompliant, isInvalidInput, shouldCallApi])
  const updateLabellerData = (unlabelledArray: any) => {
    setContainsParserError(unlabelledArray?.containsParserError)
    setContainsUncapturedScheduledTexts(
      unlabelledArray?.containsUncapturedScheduledTexts
    )
  }

  const toggleParserErrorValue = (e: any) => {
    setContainsParserError(!containsParserError)
  }
  const toggleContainsUncapturedScheduledTexts = (e: any) => {
    setContainsUncapturedScheduledTexts(!containsUncapturedScheduledTexts)
  }
  const resetState = () => {
    setContainsParserError(unlabelledFormData[formIndex].containsParserError)
    setContainsUncapturedScheduledTexts(
      unlabelledFormData[formIndex].containsUncapturedScheduledTexts
    )
  }
  useEffect(() => {
    if (
      !location?.state?.unlabelledFormList &&
      !location?.state?.unlabelledFormList.length &&
      !formId
    ) {
      window.location.href = '/start-labelling'
    }
  }, [])

  useEffect(() => {
    if (formId) {
      refetch({
        formId: formId,
      })
    }
  }, [formId])
  useEffect(() => {
    if (getUnlabelledFormCount(unlabelledFormData) === 4) {
      let paginationData = {
        offset: initialOffset,
        limit: defaultDataSize,
        isLabelled: false,
        searchTerm: '',
      }
      labellingFormsList({
        variables: {
          data: paginationData,
        },
      })
    }
  }, [formIndex])

  const [labellingFormsList] = useLazyQuery(LABELLING_LIST, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setUnlabelledFormData([...unlabelledFormData, ...data.labels.masterData])
      setFormsListCount(data.labels.count)
    },
  })

  const { refetch } = useQuery(MASTER_FORM_DATA, {
    variables: {
      formId: formId,
    },
    skip: !formId,
    onCompleted: (data: any) => {
      setUnlabelledFormData([data?.getFormDataUsingFormId])
      let labellingPathArray =
        data?.getFormDataUsingFormId?.labellingPaths?.map(
          (it: any) => it?.labellingPath
        ) || []
      setState({ ...state, checked: labellingPathArray })
      setContainsParserError(data?.getFormDataUsingFormId?.containsParserError)
      setContainsUncapturedScheduledTexts(
        data?.getFormDataUsingFormId?.containsUncapturedScheduledTexts
      )
      let uploadPath = data?.getFormDataUsingFormId?.filePath
      let path = new URL(uploadPath)?.pathname?.slice(1)
      setUploadedFilePath(path)
      updateLabellerData(data?.getFormDataUsingFormId)
    },
  })

  const setIsCompliantToTrue = (e: any) => {
    e.preventDefault()
    setIsCompliant(true)
    setIsInvalidInput(false)
    setShouldCallApi(true)
  }

  const setIsCompliantToFalse = (e: any) => {
    e.preventDefault()
    setIsCompliant(false)
    setIsInvalidInput(false)
    setShouldCallApi(true)
  }

  const setIsInvalidInputToTrue = (e: any) => {
    e.preventDefault()
    setIsInvalidInput(true)
    setIsCompliant(false)
    setShouldCallApi(true)
  }

  const handleGoBackClick = () => {
    navigate('/start-labelling', {
      state: {
        refresh: true,
        status: location?.state?.filterByStatus,
        pageNumber: location?.state?.pageNumber,
        flagForPageNumber: true,
      },
    })
  }

  return (
    <div className={className}>
      <Container>
        <Row className="my-3">
          <Col>
            <div className="label-title justify-content-between">
              <h3 className="text-uppercase fw-bold text-primary me-4 title">
                {unlabelledFormData[formIndex]?.formTitle}
              </h3>
              <Badge
                pill
                bg={
                  unlabelledFormData[0]?.isCompliant
                    ? 'primary'
                    : unlabelledFormData[0]?.isInvalidInput
                    ? 'danger'
                    : 'tertiary'
                }
                className="label-badge me-auto"
              >
                {unlabelledFormData[0].isCompliant === true
                  ? 'Compliant'
                  : unlabelledFormData[0].isInvalidInput === true
                  ? 'Invalid Input'
                  : 'Non-Compliant'}
              </Badge>
              <Button className="float-end go-back" onClick={handleGoBackClick}>
                Go Back
              </Button>
            </div>
          </Col>
          <div className="d-flex flex-column ">
            <div className="label-details">
              <p className="text-secondary fw-bold">
                <span>Form Number : </span>
                <span className="text-uppercase">
                  {unlabelledFormData[formIndex]?.formNumber}
                </span>
                <span className="p-2">|</span>
                <span>Number of pages : </span>
                <span>{unlabelledFormData[formIndex]?.numberOfPages}</span>
              </p>
            </div>
            <p className="text-secondary fw-bold text">
              <span className="me-1">Scheduled Text :</span>
              <span className="scheduled-text">
                {unlabelledFormData[formIndex].scheduledText === ''
                  ? 'N/A'
                  : unlabelledFormData[formIndex]?.scheduledText}
              </span>
            </p>
          </div>
        </Row>
        <Row>
          <Container className="bg-white p-4">
            {unlabelledFormData?.length ? (
              <Row>
                <Col sm={7}>
                  <div>
                    <PdfViewer
                      start={unlabelledFormData[formIndex]?.formStartPageNumber}
                      end={unlabelledFormData[formIndex]?.formEndPageNumber}
                      path={pdfPath}
                      key={formIndex + 1}
                    />
                  </div>
                </Col>
                <Col sm={5} className="text-start">
                  <div className="sticky-top">
                    <div>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          type="checkbox"
                          name="containsParserError"
                          label="Contains Parser Error"
                          key={`parser_error_${unlabelledFormData[formIndex]?.id}`}
                          onChange={toggleParserErrorValue}
                          checked={containsParserError}
                        />

                        <Form.Check
                          type="checkbox"
                          name="containsUncapturedScheduledTexts"
                          label="Contains un-captured scheduled"
                          id="checkbox-3"
                          key={`contains_un-captured_schedule_${unlabelledFormData[formIndex]?.id}`}
                          onChange={toggleContainsUncapturedScheduledTexts}
                          checked={containsUncapturedScheduledTexts}
                        />

                        <div className="d-flex justify-content-start my-3">
                          <LabellingComponentPage
                            labellingJson={labellingJson}
                            className={className}
                            state={state}
                            setState={setState}
                          />
                        </div>

                        <div className="d-grid gap-2 mt-2">
                          <Button
                            name="isCompliant"
                            onClick={setIsCompliantToTrue}
                          >
                            Compliant
                          </Button>
                          <Button
                            name="isNonCompliant"
                            variant="warning"
                            className="btn-non-compliant"
                            onClick={setIsCompliantToFalse}
                          >
                            Non-Compliant
                          </Button>
                          <Button
                            className="btn-invalid-input"
                            name="isInvalidInput"
                            onClick={setIsInvalidInputToTrue}
                          >
                            Invalid Input
                          </Button>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </Container>
        </Row>
      </Container>
    </div>
  )
}

export default styled(LabellingComponent)`
  .btn-non-compliant {
    background: #6c757d;
    border: none;
    color: white;

    &:hover {
      background: #5a6268;
    }
  }

  .btn-invalid-input {
    background: #dc3545;
    border: none;

    &:hover {
      background: #c82333;
    }
  }
  .label-title {
    display: flex;
  }
  .label-title > h3 {
    align-self: end;
  }
  .title {
    max-width: 1000px;
  }
  .go-back {
    max-height: 42.6px;
    align-self: center;
  }
  .scheduled-text {
    text-align: justify;
  }
  .label-details {
    display: flex;
    flex-direction: row;
  }
  .label-badge {
    align-self: center;
  }
  .text > span {
    float: left;
  }
`
