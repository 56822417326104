import { Container, Form } from 'react-bootstrap'
import styled from 'styled-components'

export interface CustomRadioContainerProps extends React.ComponentProps<any> {
  className?: string
  list: string[]
  title: string
  caption: string
  name: string
  handleOnChangeScopeAndDuration: (e: any) => void
  selectedValue: string
}


const CustomRadioOrCheckboxContainer: React.FC<CustomRadioContainerProps> = ({ className, btnType, list, title, caption, name, handleOnChangeScopeAndDuration, selectedValue }) => {
  return (
    <div className={`${className} m-4`}>
      <h4 className="fw-bold p-2">{title}</h4>
      <p className="p-2">{caption}</p>
        <div key={`inline-${btnType}`} className="mb-3">
          {list.map((item, i) => {
            return (
              <Container className="border w-50 m-2 p-2 custom-radio-or-checkbox-container" key={`${name}${i}`}>
                <Form.Check
                  type={btnType}
                  name={name}
                  id={`${name}${i}`}
                  key={`${name}${i}`}
                  label={item}
                  onChange={handleOnChangeScopeAndDuration}
                  defaultValue={item}
                  defaultChecked={selectedValue===item}
                />
              </Container>
            )
          })}
        </div>
    </div>
  )
}

export default styled(CustomRadioOrCheckboxContainer)`
    .custom-radio-or-checkbox-container {
      border-radius: 5px;
    }
`
