import { Button } from 'react-bootstrap'

export interface CustomButtonProps extends React.ComponentProps<any> {
  buttonContent: string;
  buttonBg?: string;
  btnType?: string
  onClickPath?: string
}

const CustomButton: React.FC<CustomButtonProps> = ({ buttonContent, onClickPath }) => {

  return (
    <Button type="submit" variant="primary" className={`btn btn-primary-medium fw-bold text-white rounded-pill`} href={onClickPath}>
      {buttonContent}
    </Button>
  )

}

export default CustomButton;



