import { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import { TrashIcon } from '@heroicons/react/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import CustomClickableButton from '../custom-components/custom-clickable-button'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_USER_BY_EMAIL_AND_ROLE,
  LIST_TRADE_CONTRACTOR_BROKERS,
  UPDATE_BROKER,
} from '../../graphql/graphql-queries'
import { IBrokerDetails } from '../../interface'
import ValidationError from '../validation-error'
import { useDebounce } from '../custom-hooks/use-debounce'
import PopupModalForDelete from '../delete-company-popup'
import SpinnerLoading from '../spinner-loading'
import ErrorFetchingData from '../error-fetching-data'
import { useValidationPermissionsSettings } from './use-validation-permissions-settings'
import CustomUnclickableButton from '../custom-components/custom-unclickable-button'
import styled from 'styled-components'
import CusotmOverlayTooltip from '../custom_overlay_tooltip'

export interface PermissionsSettingsProps extends React.ComponentProps<any> {
  className?: string
  user: any
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  cancelClicked: boolean
  setCancelClicked: React.Dispatch<React.SetStateAction<boolean>>
}

const PermissionsSettings: React.FC<PermissionsSettingsProps> = ({
  className,
  user,
  setSuccessMessage,
  setErrorMessage,
  cancelClicked,
  setCancelClicked,
}) => {
  const brokerDetailsSavedInDb = useRef<IBrokerDetails[]>([])

  const [brokerDetails, setBrokerDetails] = useState<IBrokerDetails[]>([])

  const [isSearching, setIsSearching] = useState(false)

  const [email, setEmail] = useState('')
  const [searchingIndex, setSearchingIndex] = useState(-1)
  const debouncedSearchTerm = useDebounce(email, 400)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [selectedBrokerForDeletion, setSelectedBrokerForDeletion] = useState({
    brokerId: '',
    tradeContractorId: user?.id,
  })

  let {
    validateBrokersDetailsInputForm,
    checkBrokersDetailsValid,
    brokersDetailslnputError,
    setBrokersDetailsInputError,
    resetBrokersDetailslInputErrors,
    checkIfBrokersDetailsFormEdited,
  } = useValidationPermissionsSettings(
    brokerDetails,
    brokerDetailsSavedInDb.current
  )

  const addBroker = () => {
    let newObj = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      isActive: false,
    }
    setBrokerDetails([...brokerDetails, newObj])
  }

  const handleOnChangeForBrokerDetails = (index: number, e: any) => {
    let { name, value } = e.target
    if (name === 'email') {
      setSearchingIndex(index)
      setIsSearching(true)
      setEmail(value)
    }
    let duplicateBrokerDetailsArray: IBrokerDetails[] = [...brokerDetails]
    duplicateBrokerDetailsArray[index] = {
      ...duplicateBrokerDetailsArray[index],
      [name]: value,
    }
    setBrokerDetails(duplicateBrokerDetailsArray)
  }

  const handleDeleteClick = (index: number, brokerId: string) => {
    if (brokerId) {
      let brokerForDeletion = {
        ...selectedBrokerForDeletion,
        brokerId,
        tradeContractorId: user?.id,
      }
      setSelectedBrokerForDeletion(brokerForDeletion)
      setShowDeleteModal(true)
    } else {
      let updatedBrokerDetails = [...brokerDetails]
      updatedBrokerDetails?.splice(index, 1)
      setBrokerDetails(updatedBrokerDetails)
    }
  }

  const handleToggleSwitch = (e: any, index: number) => {
    let { checked } = e.target
    let duplicateBrokerDetailsArray = [...brokerDetails]
    let updatedBrokerDetails = duplicateBrokerDetailsArray.map(
      (brokerDetail, i) => {
        if (i === index && checked) {
          return { ...brokerDetail, isActive: checked }
        }
        return { ...brokerDetail, isActive: false }
      }
    )
    setBrokerDetails(updatedBrokerDetails)
  }

  const [
    updateBroker,
    { loading: updateBrokerLoading, error: updateBrokerError },
  ] = useMutation(UPDATE_BROKER, {
    onCompleted: (data) => {
      setSuccessMessage('Broker details successfully updated')
    },
    onError(err) {
      setErrorMessage('Some error encountered. Please try again!')
    },
    refetchQueries: [
      {
        query: LIST_TRADE_CONTRACTOR_BROKERS,
        variables: {
          tradeContractorId: user?.id,
        },
      },
    ],
  })

  const [
    listTradeContractorBrokers,
    {
      loading: listTradeContractorBrokersLoading,
      error: listTradeContractorBrokersError,
    },
  ] = useLazyQuery(LIST_TRADE_CONTRACTOR_BROKERS, {
    onCompleted: (data) => {
      let brokersList: IBrokerDetails[] = data?.listTradeContractorBrokers?.map(
        (el: any) => ({
          ...el.broker,
          isActive: el.isActive,
        })
      )
      brokerDetailsSavedInDb.current = brokersList
      setBrokerDetails(brokersList)
    },
    onError(err) {
      setErrorMessage('Some error encountered. Please try again!')
    },
  })

  const [getBrokerByEmail] = useLazyQuery(GET_USER_BY_EMAIL_AND_ROLE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setIsSearching(false)
      let matchedBrokerDetails = data?.getUserByEmailAndRole
      if (matchedBrokerDetails) {
        let updatedBrokerDetails = brokerDetails?.map((broker: any) => {
          if (broker.email !== matchedBrokerDetails.email) {
            return broker
          } else {
            return {
              ...broker,
              id: matchedBrokerDetails?.id,
              firstName: matchedBrokerDetails?.firstName,
              lastName: matchedBrokerDetails?.lastName,
            }
          }
        })
        setBrokerDetails(updatedBrokerDetails)
      } else {
        let updatedBrokerDetails = [...brokerDetails]
        updatedBrokerDetails = updatedBrokerDetails?.map((broker) => {
          if (broker.email === email) {
            return { ...broker, id: '' }
          } else {
            return broker
          }
        })
        setBrokerDetails(updatedBrokerDetails)
      }
    },
  })

  const checkEmailExistsAndRetrieveDetails = (email: string) => {
    getBrokerByEmail({
      variables: {
        email,
        role: 'BROKER',
      },
    })
  }

  const handleSaveChangesClick = (e: any) => {
    let errorss = validateBrokersDetailsInputForm()
    if (checkBrokersDetailsValid(errorss)) {
      if (checkIfBrokersDetailsFormEdited()) {
        let data = {
          tradeContractorId: user?.id,
          brokerList: brokerDetails,
        }
        updateBroker({
          variables: {
            data,
          },
        })

        resetBrokersDetailslInputErrors()
      } else {
        setErrorMessage('There are no changes detected in the user profile.')
      }
    } else {
      setBrokersDetailsInputError(errorss)
    }
  }

  useEffect(() => {
    if (cancelClicked) {
      setBrokerDetails(brokerDetailsSavedInDb.current)
      setCancelClicked(false)
    }
  }, [cancelClicked]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (debouncedSearchTerm) {
      checkEmailExistsAndRetrieveDetails(email)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (user?.id) {
      listTradeContractorBrokers({
        variables: {
          tradeContractorId: user.id,
        },
      })
    }
  }, [user])

  if (listTradeContractorBrokersError) return <ErrorFetchingData />

  return (
    <div className={className}>
      <Container className="p-5">
        <p className="text-start text-primary fw-bold fs-5 ">Delegated Permissions
        <CusotmOverlayTooltip tooltipContent="Allow your broker to submit/upload your policy on your behalf">
          <QuestionMarkCircleIcon className="react-icon ps-1 question-mark-icon" />
        </CusotmOverlayTooltip>
        </p>
        <p className="text-start fw-bold">Policy Submission</p>
        {listTradeContractorBrokersLoading && (
          <div className="blank_page">
            <SpinnerLoading />
          </div>
        )}
        {!listTradeContractorBrokersLoading && (
          <Form className="text-start">
            {brokerDetails?.length !== 0 &&
              brokerDetails?.map((brokerDetail: any, index: number) => {
                return (
                  <Row key={`broker${index}`}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="fw-bold">
                          Email Address
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={brokerDetail?.email}
                          onChange={(e) =>
                            handleOnChangeForBrokerDetails(index, e)
                          }
                          autoComplete="off"
                        />
                        {isSearching && searchingIndex === index && (
                          <div className="pt-2 text-primary fw-bold">
                            Searching...
                          </div>
                        )}
                        {brokersDetailslnputError[index]?.email && (
                          <ValidationError
                            errorMessage={
                              brokersDetailslnputError[index]?.email
                            }
                          />
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicFirstName"
                      >
                        <Form.Label className="fw-bold">First Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={brokerDetail?.firstName}
                          onChange={(e) =>
                            handleOnChangeForBrokerDetails(index, e)
                          }
                          autoComplete="off"
                          disabled={!!brokerDetail?.id}
                        />
                        {brokersDetailslnputError[index]?.firstName && (
                          <ValidationError
                            errorMessage={
                              brokersDetailslnputError[index]?.firstName
                            }
                          />
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicLastName"
                      >
                        <Form.Label className="fw-bold">Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={brokerDetail?.lastName}
                          onChange={(e) =>
                            handleOnChangeForBrokerDetails(index, e)
                          }
                          autoComplete="off"
                          disabled={!!brokerDetail?.id}
                        />
                        {brokersDetailslnputError[index]?.lastName && (
                          <ValidationError
                            errorMessage={
                              brokersDetailslnputError[index]?.lastName
                            }
                          />
                        )}
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicActiveInactive"
                      >
                        <Form.Label className="fw-bold">Status</Form.Label>
                        <Form.Check
                          type="switch"
                          name="isActive"
                          id={`$broker{index}`}
                          label={brokerDetail?.isActive ? 'Active' : 'Inactive'}
                          checked={brokerDetail?.isActive}
                          onChange={(e) => handleToggleSwitch(e, index)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="text-start">
                      <div>
                        <p className="fw-bold">Action</p>
                        <TrashIcon
                          className="react-icon trash-icon"
                          onClick={() =>
                            handleDeleteClick(index, brokerDetail?.id)
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                )
              })}

            <div className="text-start">
              <span
                className="button add text-primary-medium fw-bold add-trade-contractor-contact-cursor"
                onClick={addBroker}
              >
                <span className="plus-icon pe-1">+</span>
                <span className="add-cursor">Add Delegate</span>
              </span>
            </div>

            <Row className="mt-4 d-flex d-flex justify-content-center">
              <Col sm={2} className="text-primary me-1">
                {updateBrokerLoading ? (
                  <CustomUnclickableButton
                    buttonContent="Saving..."
                    disabled={true}
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </CustomUnclickableButton>
                ) : (
                  <CustomClickableButton
                    buttonContent="Save Changes"
                    handleOnClick={handleSaveChangesClick}
                    disable={false}
                  />
                )}
              </Col>
              <Col sm={2} className="text-primary">
                <Button
                  variant="outline-primary-medium rounded-pill fw-bold"
                  className="outline-button"
                  onClick={() => setCancelClicked(true)}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        )}
        {showDeleteModal && (
          <PopupModalForDelete
            show={showDeleteModal}
            brokerDetailForDeletion={selectedBrokerForDeletion}
            onHide={() => setShowDeleteModal(false)}
            setshowdeletemodal={setShowDeleteModal}
            modalTitle="Delete Broker"
            setErrorMessage={setErrorMessage}
          />
        )}
      </Container>
    </div>
  )
}

export default styled(PermissionsSettings)`
  .blank_page {
    margin-bottom: 15%;
  }
`
