import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_TRADE_CONTRACT_BY_TRADE_CONTRACT_ID } from '../graphql/graphql-queries'
import {
  getLatestUploadedFile,
  getLatestUploadedPolicy,
} from '../helper-functions'
import PolicyVerificationStatusContent from './policy-verification-status-content'

export interface PolicyVerificationFailedProps
  extends React.ComponentProps<any> {
  className?: string
  tradeContractClickedForPolicyCertificationStatus?: any
  latestUploadedPolicy?: any
}

const PolicyVerificationFailed: React.FC<PolicyVerificationFailedProps> = ({
  tradeContractClickedForPolicyCertificationStatus,
  latestUploadedPolicy,
}) => {
  let navigate = useNavigate()

  const params = new URLSearchParams(window.location.search)
  const tradeContractId = params.get('tradeContract')

  const [
    tradeContractForCertificationStatus,
    setTradeContractForCertificationStatus,
  ] = useState<any>(null)

  const [tradeContract, setTradeContract] = useState({
    contractedCompany: null,
    job: null,
    jobName: '',
    latestUploadedPolicyData: null,
  })

  const [getTradeContractByTradeContractId] = useLazyQuery(
    GET_TRADE_CONTRACT_BY_TRADE_CONTRACT_ID,
    {
      fetchPolicy: 'cache-and-network',

      onCompleted: (data: any) => {
        setTradeContractForCertificationStatus(
          data.getTradeContractByTradeContractId
        )
      },
    }
  )

  useEffect(() => {
    if (tradeContractId) {
      getTradeContractByTradeContractId({
        variables: { tradeContractId: tradeContractId },
      })
    }
  }, [])

  useEffect(() => {
    if (tradeContractForCertificationStatus) {
      setTradeContract({
        contractedCompany:
          tradeContractForCertificationStatus?.contractedCompany,
        job: tradeContractForCertificationStatus?.job,
        jobName: tradeContractForCertificationStatus?.jobName,
        latestUploadedPolicyData: getLatestUploadedPolicy(
          tradeContractForCertificationStatus
        ),
      })
    } else if (
      tradeContractClickedForPolicyCertificationStatus &&
      latestUploadedPolicy
    ) {
      setTradeContract({
        contractedCompany:
          tradeContractClickedForPolicyCertificationStatus?.contractedCompany,
        job: tradeContractClickedForPolicyCertificationStatus?.job,
        jobName: tradeContractClickedForPolicyCertificationStatus?.jobName,
        latestUploadedPolicyData: latestUploadedPolicy,
      })
    }
  }, [
    tradeContractForCertificationStatus,
    tradeContractClickedForPolicyCertificationStatus,
    latestUploadedPolicy,
  ])

  return (
    <PolicyVerificationStatusContent
      tradeContractClickedForPolicyCertificationStatus={
        tradeContractClickedForPolicyCertificationStatus
      }
      latestUploadedPolicy={latestUploadedPolicy}
      alertType="danger"
    />
  )
}

export default PolicyVerificationFailed
