import { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

interface PageObject {
  start: number
  end?: number
  path: string
}

const PdfViewer: React.FC<PageObject> = ({ start, end, path }) => {
  const [numPages, setNumPages] = useState<number | null>(end || null)

  const onDocumentLoadSuccess = (numPages: any) => {
    if (end === null || end === undefined) {
      setNumPages(numPages)
    }
  }

  useEffect(() => {
    if (end) {
      setNumPages(end)
    }
  }, [end])

  return (
    <Document
      file={path}
      onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}
      className="border"
    >
      {numPages &&
        Array.from(new Array(numPages + 1 - start), (el, index) => (
          <Page
            width={750}
            key={`page_${index + 1}`}
            pageNumber={index + start}
            className="d-flex justify-content-center"
          />
        ))}
    </Document>
  )
}

export default PdfViewer
