import { PencilIcon } from '@heroicons/react/solid'
import { Container, Row, Col } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomClickableButton from './custom-components/custom-clickable-button'
import PopupModal from './popup-modal'
import { GET_CLIENTS } from '../graphql/graphql-queries'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { IEditClientData } from '../interface'
import Pagination from './Pagination Component/pagination'
import CusotmOverlayTooltip from './custom_overlay_tooltip'
import PopupModalForDelete from './delete-company-popup'
import config from '../core/config'
import CustomAlert from './custom-components/custom-alert'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
import SearchFilter from './search-filter'
import { useDebounce } from './custom-hooks/use-debounce'
import { useRef } from 'react'
type Props = {}
const ClientListing: React.FC<Props> = () => {
  const navigate = useNavigate()
  const [clients, setClients] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [count, setCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage] = useState(config.itemPerPage)
  const [isEdit, setIsEdit] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [clientId, setClientId] = useState('')
  const [clientName, setClientName] = useState('')
  const ref = useRef({ clientDataArray: [], count: 1 })

  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const [clientDataToBeEdited, setClientDataToBeEdited] =
    useState<IEditClientData>({
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    })
  const [filteredClientsList, setFilteredClientsList] = useState(clients)

  const clientArray = filteredClientsList

  const perPage = Math.ceil(count / dataPerPage)
  let PaginationClientInputData = {
    offset: currentPage === 1 ? 0 : (currentPage - 1) * 25,
    limit: dataPerPage,
    searchTerm: searchTerm.length >= 3 ? searchTerm : '',
  }
  useEffect(() => {
    if (searchTerm.length >= 3) {
      setCurrentPage(1)
    }
    if (searchTerm === '') {
      refetch()
      let { clientDataArray = [], count = 1 } = ref.current
      setClients([...clientDataArray])
      setFilteredClientsList([...clientDataArray])
      setCount(count)
    }
  }, [debouncedSearchTerm, searchTerm])
  const { refetch, loading, error } = useQuery(GET_CLIENTS, {
    variables: {
      data: PaginationClientInputData,
    },
    onCompleted: (data) => {
      setClients(data?.getClients?.clientData)
      setFilteredClientsList(data?.getClients?.clientData)
      ref.current = {
        clientDataArray: data?.getClients?.clientData,
        count: data?.getClients?.count,
      }
      setCount(data?.getClients?.count)
    },
  }) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refetch()
  }, [showModal, showDeleteModal]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditClick = (client: any) => {
    setClientDataToBeEdited({
      id: client.id,
      email: client.email,
      firstName: client.firstName,
      lastName: client.lastName,
      phoneNumber: client.phoneNumber,
    })
    setIsEdit(true)
    setShowModal(true)
    setShowDeleteModal(false)
  }
  const handleDelete = (id: string, name: string) => {
    setClientId(id)
    setClientName(name)
    setShowDeleteModal(true)
  }

  const handleClose = () => {
    setSuccessMessage('')
    setErrorMessage('')
  }

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  if (loading && searchTerm === '') return <SpinnerLoading />
  if (error) return <ErrorFetchingData />
  return (
    <Container className="mt-3">
      {successMessage !== '' && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleClose}
            alertType="success"
            alertMessage={successMessage}
          />
        </Row>
      )}
      {errorMessage !== '' && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleClose}
            alertType="error"
            alertMessage={errorMessage}
          />
        </Row>
      )}
      <Row className="my-4">
        <Col>
          <SearchFilter
            handleSearch={handleSearch}
            placeholderText="Search Clients (Enter Atleast 3 Characters)"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </Col>
        <Col className="text-primary text-end">
          <CustomClickableButton
            buttonContent="Create Client"
            handleOnClick={() => {
              setShowModal(true)
              setIsEdit(false)
              setShowDeleteModal(false)
            }}
            disable={false}
          />
        </Col>
      </Row>
      <div className="client-list shadow-none p-3 mb-5 bg-white rounded">
        <Row className=" fw-bold border-bottom border-light pb-2">
          <Col sm={2} className="text-start">
            Client
          </Col>
          <Col sm={2}>First Named Insured</Col>
          <Col sm={3}>Number of Policies</Col>
          <Col sm={3}>Number of Active Policies</Col>
          <Col></Col>
        </Row>

        {clientArray?.length &&
          clientArray.map((client: any, index: number) => {
            return (
              <Row
                className="border-bottom border-light p-2"
                key={client.name + index}
              >
                <Col className="text-start" sm={2}>
                  <div
                    onClick={() => {
                      navigate(`/client-details/${client.id}`)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {client.name}
                  </div>
                  <div>{client.email}</div>
                </Col>
                <Col sm={2}>{client.firstNameInsuredCount}</Col>
                <Col sm={3}>{client.numberOfPolicies}</Col>
                <Col sm={3}>{client.numberOfActivePolicies}</Col>
                <Col>
                  <CusotmOverlayTooltip tooltipContent="Edit">
                    <PencilIcon
                      className="react-icon"
                      onClick={() => handleEditClick(client)}
                    />
                  </CusotmOverlayTooltip>
                  {/* <CusotmOverlayTooltip tooltipContent="Delete">
                  <TrashIcon
                    className="trashIcon"
                    style={{
                      color: 'red',
                      width: '21px',
                      cursor: 'pointer',
                      marginLeft: '20px',
                    }}
                    onClick={() => {
                      handleDelete(client.id, client.name)
                    }}
                  />
                </CusotmOverlayTooltip> */}
                </Col>
              </Row>
            )
          })}
      </div>
      {clientArray?.length > 0 && (
        <div>
          {' '}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={perPage}
          />
        </div>
      )}

      {showModal && (
        <PopupModal
          show={showModal}
          onHide={() => setShowModal(false)}
          clientdatatobeedited={clientDataToBeEdited}
          isedit={isEdit}
          firstname="First Name"
          lastname="Last Name"
          email="Email Address"
          phone="Phone"
          message={!isEdit ? 'Add New Client' : 'Update Client'}
          page="Client_List"
          setshowmodal={setShowModal}
          setsuccessmessage={setSuccessMessage}
          seterrormessage={setErrorMessage}
        />
      )}
      {showDeleteModal && (
        <PopupModalForDelete
          show={showDeleteModal}
          clientid={clientId}
          clientname={clientName}
          onHide={() => setShowDeleteModal(false)}
          setshowdeletemodal={setShowDeleteModal}
        />
      )}
    </Container>
  )
}
export default styled(ClientListing)`
  .client-list {
    border: 1px solid #13948b;
  }
`
