import { Container, Row, Col, Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import styled from 'styled-components'
import CertifiedImage from '../assets/images/certified.png'
import PolicyReviewErrorImage from '../assets/images/policy-review-failed.png'
import CusotmOverlayTooltip from './custom_overlay_tooltip'
import CustomClickableButton from './custom-components/custom-clickable-button'
import { useEffect, useState } from 'react'
import { GET_TRADE_CONTRACT_BY_TRADE_CONTRACT_ID } from '../graphql/graphql-queries'
import {
  getLabellingPathsForSpecialRequirementsOrProhibitions,
  getLabelsBasedOnAggregateType,
  getLabelsBasedOnScopeAndDurationSelectedinTemplate,
  getLatestUploadedPolicy,
} from '../helper-functions'
import ScrollableProhibitedEndorsements from './prohibited-endorsment/scrollable-prohibited-endorsements'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
import ScrollableList from './scrollable-list'
import { OtherTemplateDetails } from './template-details'
import authentication from '../core/authentication'
import { labelNotMatched } from '../data'

export interface PolicyVerificatioStatusContentProps
  extends React.ComponentProps<any> {
  className?: string
  tradeContractClickedForPolicyCertificationStatus?: any
  latestUploadedPolicy?: any
  alertType?: string
  verificationStatus?: string
}

const PolicyVerificationStatusContent: React.FC<
  PolicyVerificatioStatusContentProps
> = ({
  className,
  tradeContractClickedForPolicyCertificationStatus,
  latestUploadedPolicy,
  alertType,
  verificationStatus,
}) => {
  let navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)
  const tradeContractId = params.get('tradeContract')

  const [
    tradeContractForCertificationStatus,
    setTradeContractForCertificationStatus,
  ] = useState<any>(null)

  const [tradeContract, setTradeContract] = useState<any>({
    contractedCompany: null,
    job: null,
    jobName: '',
    latestUploadedPolicyData: null,
    prohibitedEndorsementsByJobType: [],
  })

  const [
    getTradeContractByTradeContractId,
    {
      loading: getTradeContractByTradeContractIdLoading,
      error: getTradeContractByTradeContractIdError,
    },
  ] = useLazyQuery(GET_TRADE_CONTRACT_BY_TRADE_CONTRACT_ID, {
    fetchPolicy: 'cache-and-network',

    onCompleted: (data: any) => {
      setTradeContractForCertificationStatus(
        data.getTradeContractByTradeContractId
      )
    },
  })

  useEffect(() => {
    if (tradeContractId) {
      getTradeContractByTradeContractId({
        variables: { tradeContractId: tradeContractId },
      })
    }
  }, [])

  useEffect(() => {
    if (tradeContractForCertificationStatus) {
      setTradeContract({
        contractedCompany:
          tradeContractForCertificationStatus?.contractedCompany,
        job: tradeContractForCertificationStatus?.job,
        jobName: tradeContractForCertificationStatus?.jobName,
        latestUploadedPolicyData: getLatestUploadedPolicy(
          tradeContractForCertificationStatus
        ),
        prohibitedEndorsementsByJobType:
          tradeContractForCertificationStatus?.prohibitedEndorsementsByJobType,
      })
    } else if (
      tradeContractClickedForPolicyCertificationStatus &&
      latestUploadedPolicy
    ) {
      setTradeContract({
        contractedCompany:
          tradeContractClickedForPolicyCertificationStatus?.contractedCompany,
        job: tradeContractClickedForPolicyCertificationStatus?.job,
        jobName: tradeContractClickedForPolicyCertificationStatus?.jobName,
        latestUploadedPolicyData: latestUploadedPolicy,
        prohibitedEndorsementsByJobType:
          tradeContractClickedForPolicyCertificationStatus?.prohibitedEndorsementsByJobType,
      })
    } else if (
      tradeContractClickedForPolicyCertificationStatus &&
      !latestUploadedPolicy
    ) {
      setTradeContract({
        contractedCompany:
          tradeContractClickedForPolicyCertificationStatus?.contractedCompany,
        job: tradeContractClickedForPolicyCertificationStatus?.job,
        jobName: tradeContractClickedForPolicyCertificationStatus?.jobName,
        latestUploadedPolicyData: latestUploadedPolicy,
        prohibitedEndorsementsByJobType:
          tradeContractClickedForPolicyCertificationStatus?.prohibitedEndorsementsByJobType,
      })
    }
  }, [
    tradeContractForCertificationStatus,
    tradeContractClickedForPolicyCertificationStatus,
    latestUploadedPolicy,
  ])

  const {
    contractedCompany,
    job,
    jobName,
    latestUploadedPolicyData,
    prohibitedEndorsementsByJobType,
  }: {
    contractedCompany: any
    job: any
    jobName: string
    latestUploadedPolicyData: any
    prohibitedEndorsementsByJobType: any
  } = tradeContract
  const handleContactOurTeamClick = (e: any) => {
    e.preventDefault()
    window.open(
      'mailto:appeals@resolvedrisk.com?subject=Need help with certifying policy&body=Message',
      '_blank'
    )
  }

  const handleUploadNewPolicyClick = () => {
    if (tradeContractId) {
      navigate('/certify-insurance?upload-new-policy=true', {
        state: tradeContractId,
      })
    } else
      navigate('/certify-insurance?upload-new-policy=true', {
        state: tradeContractClickedForPolicyCertificationStatus?.id,
      })
  }

  if (getTradeContractByTradeContractIdLoading) return <SpinnerLoading />
  if (getTradeContractByTradeContractIdError) return <ErrorFetchingData />

  return (
    <>
      {(latestUploadedPolicyData?.matchingResponse?.unmatchedInputs ||
        !latestUploadedPolicyData) && (
        <Container className={`${className}`}>
          <Row>
            <Col>
              <Container className="text-start mx-5">
                <div>
                  <h6 className="text-primary fw-bold">Job Details</h6>
                  <h5 className="fw-bold">{job?.streetAddress}</h5>
                  <p className="text-secondary fw-bold">
                    <span>{contractedCompany?.name}</span>
                    <span className="p-2">|</span>
                    <span>{`Job ${job?.slug}`}</span>
                    <span className="p-2">|</span>
                    <span>{jobName}</span>
                  </p>
                  <hr />
                </div>

                <div>
                  {alertType === 'success' ? (
                    <>
                      <Alert
                        key={alertType}
                        variant={alertType}
                        className="w-75 text-black"
                      >
                        <div className="d-flex">
                          <span className="pe-2">
                            <CheckCircleIcon className="check-icon hero-icon-primary" />
                          </span>
                          <span className="fs-5 fw-bold mb-2">
                            Hooray! Your insurance policy has been certified for
                            this job.
                          </span>
                        </div>
                        <div className="ps-4 ms-2 line-height">
                          <div className="">
                            After review we have determined that your policy
                            meets our requirements. And a certificate has been
                            sent to your email so that you can save it to your
                            device.
                          </div>
                        </div>
                      </Alert>
                      <div className="certification-success-image">
                        <span className="">
                          <img
                            src={CertifiedImage}
                            height={300}
                            width={300}
                            alt="Policy Certified"
                          />
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <Alert
                        key="danger"
                        variant="danger"
                        className="w-75 text-black alert-danger-policy"
                      >
                        <span className="ps-4 pe-2">
                          <InformationCircleIcon className="info-circle info-circle-error" />
                        </span>
                        {verificationStatus ? (
                          <span>
                            We couldn't read your policy. We will manually
                            upload the policy.
                          </span>
                        ) : (
                          <span>
                            Your policy failed our verification process. Scroll
                            down to get in touch with our team.
                          </span>
                        )}
                      </Alert>
                    </>
                  )}
                </div>

                <div className="my-5">
                  <h5 className="fw-bold">
                    Insurance Requirements
                    <span className="px-1">
                      <InformationCircleIcon className="info-circle" />
                    </span>
                  </h5>
                  <p>
                    {`These requirements must be met to work at ${job?.streetAddress}.`}
                  </p>
                </div>

                <div>
                  <Row className="my-5">
                    <Col>
                      <div className="fw-bold mb-2">Limits</div>
                      <div>
                        <span>
                          {alertType === 'danger' &&
                            !!latestUploadedPolicyData?.matchingResponse
                              ?.unmatchedInputs?.unmatchedOccurrenceLimit && (
                              <CusotmOverlayTooltip
                                tooltipContent={
                                  latestUploadedPolicyData?.matchingResponse
                                    ?.unmatchedInputs?.unmatchedOccurrenceLimit
                                }
                              >
                                <span>
                                  <InformationCircleIcon className="info-circle info-circle-error" />
                                </span>
                              </CusotmOverlayTooltip>
                            )}
                        </span>
                        <span>
                          The required{' '}
                          <strong className="text-underline-dashed">
                            Each Occurrence{' '}
                          </strong>{' '}
                          is{' '}
                          <strong>
                            $
                            {job?.nonProcessingPolicy?.template?.eachOccurrenceLimit?.toLocaleString()}
                          </strong>
                          . We found{' '}
                          <strong>
                            $
                            {tradeContract?.latestUploadedPolicyData
                              ?.limitEachOccurance === null
                              ? 0
                              : tradeContract?.latestUploadedPolicyData?.limitEachOccurance?.toLocaleString()}{' '}
                          </strong>
                          in policy.
                        </span>
                      </div>
                      <div>
                        <span>
                          {!!latestUploadedPolicyData?.matchingResponse
                            ?.unmatchedInputs?.unmatchedAggregateLimit &&
                            latestUploadedPolicyData?.matchingResponse
                              ?.unmatchedInputs?.unmatchedAggregateLimit !==
                              'The label or labels do not match' && (
                              <CusotmOverlayTooltip
                                tooltipContent={
                                  latestUploadedPolicyData?.matchingResponse
                                    ?.unmatchedInputs?.unmatchedAggregateLimit
                                }
                              >
                                <span>
                                  <InformationCircleIcon className="info-circle info-circle-error" />
                                </span>
                              </CusotmOverlayTooltip>
                            )}
                        </span>
                        <span>
                          The required{' '}
                          <strong className="text-underline-dashed">
                            Aggregate (
                            {job?.nonProcessingPolicy?.template?.aggregateType}){' '}
                          </strong>{' '}
                          is{' '}
                          <strong>
                            $
                            {job?.nonProcessingPolicy?.template?.aggregateLimit?.toLocaleString()}
                          </strong>
                          . We found{' '}
                          <strong>
                            $
                            {tradeContract?.latestUploadedPolicyData
                              ?.generalAggregateLimit === null
                              ? 0
                              : tradeContract?.latestUploadedPolicyData?.generalAggregateLimit?.toLocaleString()}{' '}
                            {'('}
                            {
                              tradeContract?.latestUploadedPolicyData
                                ?.matchingResponse?.policyAggregateType
                            }
                            {') '}
                          </strong>
                          in policy.
                        </span>
                      </div>
                      <div className="limit-scroll">
                        {latestUploadedPolicyData?.matchingResponse
                          ?.unmatchedInputs?.unmatchedAggregateLimit ==
                          labelNotMatched && (
                          <CusotmOverlayTooltip tooltipContent=" Aggregate label not present">
                            <span>
                              <InformationCircleIcon className="info-circle info-circle-error" />
                            </span>
                          </CusotmOverlayTooltip>
                        )}
                      </div>
                      <div>
                        {latestUploadedPolicyData?.matchingResponse
                          ?.unmatchedInputs?.unmatchedAggregateLimit ==
                          labelNotMatched && (
                          <ScrollableList
                            labelsDefinedInRequirement={getLabelsBasedOnAggregateType(
                              job?.nonProcessingPolicy?.template?.aggregateType,
                              job?.nonProcessingPolicy?.template?.durationType
                            )}
                            className="mb-2"
                            typeOfLabels="Labels Combinations"
                            title="Unmatched Aggregate Labels"
                            labelsInMatchingResponseJson={getLabelsBasedOnAggregateType(
                              job?.nonProcessingPolicy?.template?.aggregateType,
                              job?.nonProcessingPolicy?.template?.durationType
                            )}
                            width="w-75"
                            commonwidth={false}
                          />
                        )}
                      </div>
                      <div>
                        <span>
                          {!!latestUploadedPolicyData?.matchingResponse
                            ?.unmatchedInputs?.unmatchedDeductible && (
                            <CusotmOverlayTooltip
                              tooltipContent={latestUploadedPolicyData?.matchingResponse?.unmatchedInputs?.unmatchedDeductible?.toLocaleString()}
                            >
                              <span>
                                <InformationCircleIcon className="info-circle info-circle-error" />
                              </span>
                            </CusotmOverlayTooltip>
                          )}
                        </span>
                        <span>
                          The required{' '}
                          <strong className="text-underline-dashed">
                            Deductible/Retention
                          </strong>{' '}
                          is{' '}
                          <strong>
                            $
                            {job?.nonProcessingPolicy?.template?.deductible?.toLocaleString()}
                          </strong>
                          . We found{' '}
                          <strong>
                            $
                            {tradeContract?.latestUploadedPolicyData
                              ?.deductible === null
                              ? tradeContract?.latestUploadedPolicyData
                                  ?.selfInsuredRetention === null
                                ? 0
                                : tradeContract?.latestUploadedPolicyData?.selfInsuredRetention.toLocaleString()
                              : tradeContract?.latestUploadedPolicyData?.deductible?.toLocaleString()}{' '}
                          </strong>
                          in policy.
                        </span>
                      </div>
                      <div>
                        <span>
                          {!!latestUploadedPolicyData?.matchingResponse
                            ?.unmatchedInputs
                            ?.unmatchedProjectCompletedOperationLimit && (
                            <CusotmOverlayTooltip
                              tooltipContent={latestUploadedPolicyData?.matchingResponse?.unmatchedInputs?.unmatchedProjectCompletedOperationLimit?.toLocaleString()}
                            >
                              <span>
                                <InformationCircleIcon className="info-circle info-circle-error" />
                              </span>
                            </CusotmOverlayTooltip>
                          )}
                        </span>
                        <span>
                          The required{' '}
                          <strong className="text-underline-dashed">
                            Products-Completed Operations Limit
                          </strong>{' '}
                          is{' '}
                          <strong>
                            $
                            {job?.nonProcessingPolicy?.template?.projectCompletedOperationLimit?.toLocaleString()}
                          </strong>
                          . We found{' '}
                          <strong>
                            $
                            {tradeContract?.latestUploadedPolicyData
                              ?.projectCompletedOperationLimit === null
                              ? 0
                              : tradeContract?.latestUploadedPolicyData?.projectCompletedOperationLimit?.toLocaleString()}{' '}
                          </strong>
                          in policy.
                        </span>
                      </div>
                      <div>
                        <span>
                          {!!latestUploadedPolicyData?.matchingResponse
                            ?.unmatchedInputs
                            ?.unmatchedPersonalAdvertisingLimit && (
                            <CusotmOverlayTooltip
                              tooltipContent={
                                latestUploadedPolicyData?.matchingResponse
                                  ?.unmatchedInputs
                                  ?.unmatchedPersonalAdvertisingLimit
                              }
                            >
                              <span>
                                <InformationCircleIcon className="info-circle info-circle-error" />
                              </span>
                            </CusotmOverlayTooltip>
                          )}
                        </span>
                        <span>
                          The required{' '}
                          <strong className="text-underline-dashed">
                            Personal & Advertising Injury Limit
                          </strong>{' '}
                          is{' '}
                          <strong>
                            $
                            {job?.nonProcessingPolicy?.template?.personalAdvertisingLimit?.toLocaleString()}
                          </strong>
                          . We found{' '}
                          <strong>
                            $
                            {tradeContract?.latestUploadedPolicyData
                              ?.personalAdvertisingLimit === null
                              ? 0
                              : tradeContract?.latestUploadedPolicyData?.personalAdvertisingLimit?.toLocaleString()}{' '}
                          </strong>
                          in policy.
                        </span>
                      </div>
                    </Col>
                    <Col className="column">
                      <div className="fw-bold mb-2">Scope and Duration</div>
                      <div>
                        <span className="text-underline-dashed">Scope</span>
                        <span className="fw-bold p-3">
                          {job?.nonProcessingPolicy?.template?.scope}
                        </span>
                      </div>
                      <div>
                        <span className="text-underline-dashed">Duration</span>
                        <span className="fw-bold p-3">
                          {job?.nonProcessingPolicy?.template?.durationType !==
                          'ongoing operations'
                            ? `${job?.nonProcessingPolicy?.template?.durationType} for ${job?.nonProcessingPolicy?.template?.duration} years`
                            : job?.nonProcessingPolicy?.template?.durationType}
                        </span>
                      </div>
                      <div className="d-flex pb-4 ">
                        <div>
                          {!!latestUploadedPolicyData?.matchingResponse
                            ?.unmatchedInputs?.scopeAndDurationRequirements && (
                            <CusotmOverlayTooltip tooltipContent=" Scope And Duration Present">
                              <span>
                                <InformationCircleIcon className="info-circle info-circle-error" />
                              </span>
                            </CusotmOverlayTooltip>
                          )}
                        </div>
                        <div>
                          {latestUploadedPolicyData?.matchingResponse
                            ?.unmatchedInputs?.scopeAndDurationRequirements && (
                            <ScrollableList
                              labelsDefinedInRequirement={getLabelsBasedOnScopeAndDurationSelectedinTemplate(
                                job?.nonProcessingPolicy?.template?.scope,
                                job?.nonProcessingPolicy?.template?.durationType
                              )}
                              typeOfLabels="Labels Combinations"
                              title="Unmatched Scope And Duration Labels"
                              labelsInMatchingResponseJson={getLabelsBasedOnScopeAndDurationSelectedinTemplate(
                                job?.nonProcessingPolicy?.template?.scope,
                                job?.nonProcessingPolicy?.template?.durationType
                              )}
                              width="w-125"
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row className="my-5">
                  <Col>
                    <div className="fw-bold mb-2">
                      Named Insured(s) and Additional Insured(s)
                    </div>
                    <div className="pb-2">
                      <p className="mb-1">
                        <span>
                          {!!latestUploadedPolicyData?.matchingResponse
                            ?.unmatchedInputs?.unmatchedInsuredNames
                            ?.length && (
                            <CusotmOverlayTooltip tooltipContent="Named Insured doesn't meet the requirement">
                              <span>
                                <InformationCircleIcon className="info-circle info-circle-error" />
                              </span>
                            </CusotmOverlayTooltip>
                          )}
                        </span>
                        <span className="text-underline-dashed">
                          Named Insured(s)
                        </span>
                      </p>
                      <p className="fw-bold">{contractedCompany?.name}</p>
                    </div>
                    <div>
                      <div>
                        <p className="text-underline-dashed mb-2">
                          Additional Insured(s)
                        </p>
                        {job?.additionalInsureds &&
                        job?.additionalInsureds?.length ? (
                          <div className="">
                            {job?.additionalInsureds?.map(
                              (additionalInsured: any) => (
                                <div
                                  className="d-flex"
                                  key={additionalInsured?.id}
                                >
                                  {!!latestUploadedPolicyData?.matchingResponse
                                    ?.unmatchedInputs
                                    ?.unmatchedAdditionalInsureds?.length && (
                                    <CusotmOverlayTooltip tooltipContent="Additional Insured doesn't meet the requirement">
                                      <span>
                                        <InformationCircleIcon className="info-circle info-circle-error" />
                                      </span>
                                    </CusotmOverlayTooltip>
                                  )}
                                  <p
                                    className="my-0 fw-bold"
                                    key={additionalInsured.id}
                                  >
                                    {additionalInsured.name}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <Col className="column-project">
                    <div className="fw-bold mb-2">
                      Project Start Date and Address
                    </div>
                    <div>
                      <span>
                        {!!latestUploadedPolicyData?.matchingResponse
                          ?.unmatchedInputs?.unmatchedPolicyPeriod && (
                          <CusotmOverlayTooltip tooltipContent="Policy is either not in effect or has expired">
                            <span>
                              <InformationCircleIcon className="info-circle info-circle-error" />
                            </span>
                          </CusotmOverlayTooltip>
                        )}
                      </span>
                      <span className="text-underline-dashed">
                        Project Start Date
                      </span>
                      <span className="fw-bold p-3">
                        {moment(job?.startDate).format('MM/DD/YYYY')}
                      </span>
                    </div>
                    <div>
                      <span>
                        {!!latestUploadedPolicyData?.matchingResponse
                          ?.unmatchedInputs?.unmatchedProjectAddresse
                          ?.length && (
                          <CusotmOverlayTooltip tooltipContent="Project Address doesn't meet the requirement">
                            <span>
                              <InformationCircleIcon className="info-circle info-circle-error" />
                            </span>
                          </CusotmOverlayTooltip>
                        )}
                      </span>
                      <span className="text-underline-dashed">
                        Project Address
                      </span>
                      <span className="fw-bold p-3">{job?.streetAddress}</span>
                    </div>
                    <Col sm={4}>
                      <div className="d-flex pb-4 pt-4 mt-1">
                        <div>
                          {!!latestUploadedPolicyData?.matchingResponse
                            ?.unmatchedInputs?.nonCompliantFormArray
                            ?.length && (
                            <CusotmOverlayTooltip tooltipContent="Non Compliant Forms Present">
                              <span>
                                <InformationCircleIcon className="info-circle info-circle-error" />
                              </span>
                            </CusotmOverlayTooltip>
                          )}
                        </div>
                        <div>
                          <ScrollableList
                            labelsDefinedInRequirement={
                              latestUploadedPolicyData?.matchingResponse
                                ?.unmatchedInputs?.nonCompliantFormArray
                            }
                            title={
                              latestUploadedPolicyData?.matchingResponse
                                ?.unmatchedInputs?.nonCompliantFormArray?.length
                                ? 'Non Compliant forms found in Policy'
                                : 'Non Compliants'
                            }
                            labelsInMatchingResponseJson={
                              latestUploadedPolicyData?.matchingResponse
                                ?.unmatchedInputs?.nonCompliantFormArray
                            }
                            width="w-125"
                            commonwidth={false}
                          />
                        </div>
                      </div>
                    </Col>
                  </Col>
                </Row>
                {!!latestUploadedPolicyData?.matchingResponse?.unmatchedInputs
                  ?.addressForSpecifiedTitle ? (
                  <Row className="mb-2">
                    <Col sm={3}>
                      <span>
                        <InformationCircleIcon className="info-circle info-circle-error" />
                      </span>
                      <span>
                        Neither project address nor the specified text value
                        were found in the scheduled text of the special
                        endorsement titles.
                      </span>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}

                <Row className="my-4">
                  <Col>
                    <div className="fw-bold mb-2 ">
                      <span>
                        {!!latestUploadedPolicyData?.matchingResponse
                          ?.unmatchedInputs
                          ?.contractualLiabilityRequirement && (
                          <CusotmOverlayTooltip tooltipContent="Includes Contractual Liability Label does not match!">
                            <span>
                              <InformationCircleIcon className="info-circle info-circle-error" />
                            </span>
                          </CusotmOverlayTooltip>
                        )}
                      </span>
                      <span>Contract Liability</span>
                    </div>
                    <div>
                      {job?.nonProcessingPolicy?.template?.contractualLiability
                        ? "Trade’s policy must have coverage for it's liability arising out of an insured contract."
                        : 'Not Specified'}
                    </div>
                  </Col>
                  <Col>
                    <div className="fw-bold mb-2 ">
                      <span>
                        {!!latestUploadedPolicyData?.matchingResponse
                          ?.unmatchedInputs?.priorityOfCoverageRequirement && (
                          <CusotmOverlayTooltip tooltipContent="Priority of Coverage label does not match!">
                            <span>
                              <InformationCircleIcon className="info-circle info-circle-error" />
                            </span>
                          </CusotmOverlayTooltip>
                        )}
                      </span>
                      <span>Priority of Coverage</span>
                    </div>
                    <div>
                      {job?.nonProcessingPolicy?.template?.priorityOfCoverage
                        ? 'Coverage afforded to an additional insured must be primary and non-contributory.'
                        : 'Not Specified'}
                    </div>
                  </Col>
                  <Col>
                    <div className="fw-bold mb-2">
                      <span>
                        {!!latestUploadedPolicyData?.matchingResponse
                          ?.unmatchedInputs?.wavierOfSubrogationRequirementArray
                          ?.length && (
                          <CusotmOverlayTooltip tooltipContent=" Waiver of Subrogation Present">
                            <span>
                              <InformationCircleIcon className="info-circle info-circle-error" />
                            </span>
                          </CusotmOverlayTooltip>
                        )}
                      </span>
                      <span>Waiver of Subrogation</span>
                    </div>
                    <div>
                      {job?.nonProcessingPolicy?.template?.waiverOfSubrogation
                        ? 'Carrier must agree to waive rights of subrogation against additional insureds.'
                        : 'Not Specified'}
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="d-flex pb-4 ">
                      <div>
                        {!!latestUploadedPolicyData?.matchingResponse
                          ?.unmatchedInputs?.noticeOfChangeRequirementArray
                          ?.length && (
                          <CusotmOverlayTooltip tooltipContent="Notice Of Change Present">
                            <span>
                              <InformationCircleIcon className="info-circle info-circle-error" />
                            </span>
                          </CusotmOverlayTooltip>
                        )}
                      </div>
                      <div>
                        <ScrollableList
                          labelsDefinedInRequirement={
                            latestUploadedPolicyData?.matchingResponse
                              ?.unmatchedInputs?.noticeOfChangeRequirementArray
                          }
                          title=" Notice of Change"
                          labelsInMatchingResponseJson={
                            latestUploadedPolicyData?.matchingResponse
                              ?.unmatchedInputs?.noticeOfChangeRequirementArray
                          }
                          width="w-75"
                          commonwidth={true}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="my-4">
                  <Col sm={4}>
                    <div className="d-flex pb-4">
                      <div>
                        {!!latestUploadedPolicyData?.matchingResponse
                          ?.unmatchedInputs?.prohibitedEndorsementsByJobType
                          ?.length && (
                          <CusotmOverlayTooltip tooltipContent="Endorsements prohibited by Job Type present">
                            <span>
                              <InformationCircleIcon className="info-circle info-circle-error" />
                            </span>
                          </CusotmOverlayTooltip>
                        )}
                      </div>
                      <div>
                        <ScrollableList
                          labelsDefinedInRequirement={prohibitedEndorsementsByJobType?.map(
                            (prohibitedEndorsementByJobType: any) =>
                              prohibitedEndorsementByJobType?.labellingPath
                          )}
                          title="Endorsements Prohibited by Job Type"
                          labelsInMatchingResponseJson={
                            latestUploadedPolicyData?.matchingResponse
                              ?.unmatchedInputs?.prohibitedEndorsementsByJobType
                          }
                          width="w-75"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col sm={4}>
                    <div className="d-flex pb-4">
                      {!!latestUploadedPolicyData?.matchingResponse
                        ?.unmatchedInputs?.specialRequirements?.length && (
                        <div>
                          <CusotmOverlayTooltip tooltipContent="Required endorsements not present">
                            <span>
                              <InformationCircleIcon className="info-circle info-circle-error" />
                            </span>
                          </CusotmOverlayTooltip>
                        </div>
                      )}
                      <div>
                        <ScrollableList
                          labelsDefinedInRequirement={getLabellingPathsForSpecialRequirementsOrProhibitions(
                            job?.nonProcessingPolicy?.template?.templateLabels,
                            'Special Requirements'
                          )}
                          title="Special Requirements"
                          labelsInMatchingResponseJson={
                            latestUploadedPolicyData?.matchingResponse
                              ?.unmatchedInputs?.specialRequirements
                          }
                          width="w-75"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col sm={4}>
                    <div className="d-flex">
                      {!!latestUploadedPolicyData?.matchingResponse
                        ?.unmatchedInputs?.specialProhibitions?.length && (
                        <div>
                          <CusotmOverlayTooltip tooltipContent="Prohibited endorsements present">
                            <span>
                              <InformationCircleIcon className="info-circle info-circle-error" />
                            </span>
                          </CusotmOverlayTooltip>
                        </div>
                      )}
                      <div>
                        <ScrollableList
                          labelsDefinedInRequirement={getLabellingPathsForSpecialRequirementsOrProhibitions(
                            job?.nonProcessingPolicy?.template?.templateLabels,
                            'Special Prohibitions'
                          )}
                          title="Specially Prohibited Endorsements"
                          labelsInMatchingResponseJson={
                            latestUploadedPolicyData?.matchingResponse
                              ?.unmatchedInputs?.specialProhibitions
                          }
                          width="w-75"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="mb-4">
                  <h6 className="fw-bold my-2">Things to know</h6>
                  <p>
                    If your policy is changed or cancelled for any reason, you
                    must notify us immediately by sending an email to{' '}
                    <a
                      href="mailto:changes@resolvedrisk.com"
                      className="email-link"
                    >
                      changes@resolvedrisk.com
                    </a>
                    .
                  </p>
                </div>

                {alertType === 'danger' && (
                  <Row className="mb-4">
                    <Col sm={8}>
                      <h5 className="fw-bold">What can you do now?</h5>
                      <p>
                        If you feel like this is an error please contact our
                        team below and we will review the policy. You may also
                        upload a new document.
                      </p>
                      <div className="d-flex">
                        {authentication.isTradeContractor() && (
                          <span className="me-3">
                            <CustomClickableButton
                              buttonContent="Upload new policy"
                              handleOnClick={handleUploadNewPolicyClick}
                            />
                          </span>
                        )}
                        <span>
                          <CustomClickableButton
                            buttonContent="Contact our Team"
                            handleOnClick={(e: any) =>
                              handleContactOurTeamClick(e)
                            }
                          />
                        </span>
                      </div>
                    </Col>
                    <Col sm={4}>
                      {
                        <img
                          className="policy-verification-failed flaot-top"
                          src={PolicyReviewErrorImage}
                          height={300}
                          width={300}
                          alt="Policy Verification Failed"
                        />
                      }
                    </Col>
                  </Row>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

export default styled(PolicyVerificationStatusContent)`
  .text-underline-dashed {
    text-decoration: underline dashed #13948b;
    text-underline-offset: 4px;
  }

  .info-circle {
    height: 20px;
    color: #8b9192;
  }

  .info-circle-error {
    color: #de0202;
    margin-left: -25px;
    margin-right: 5px;
  }

  .policy-verification-failed {
    margin-top: -60px;
  }

  .certification-success-image {
    position: absolute;
    top: 30%;
    right: 3%;
  }
  .alert-danger-policy {
    background-color: #f8d7da;
  }

  .column-project {
    margin-right: 5rem !important;
  }
  .column {
    margin-right: 5rem !important;
  }
  .limit-scroll {
    height: 0px;
  }
`
