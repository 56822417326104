import { Button } from 'react-bootstrap'

export interface CustomClickableNavigateButtonProps extends React.ComponentProps<any> {
  buttonContent: string;
  buttonBg?: string;
  handleOnClick: () => void
}

const CustomClickableNavigateButton: React.FC<CustomClickableNavigateButtonProps> = ({ buttonContent, handleOnClick }) => {

  return (
    <Button type="submit" variant="primary" className={`btn btn-primary-medium fw-bold text-white rounded-pill`} onClick={handleOnClick}>
      {buttonContent}
    </Button>
  )

}

export default CustomClickableNavigateButton;

