import { useEffect, useState } from 'react'
import { Container, Row, Button, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { InformationCircleIcon } from '@heroicons/react/solid'
import authentication from '../core/authentication'
import { appConfig } from '../core'
import CustomClickableButton from './custom-components/custom-clickable-button'
import DropzoneComponent from './dropzone-component'
import CustomUnclickableButton from './custom-components/custom-unclickable-button'
import styled from 'styled-components'

export interface IFile {
  selectedFiles: any
  currentFile: string
  progress: number
  message: string
  fileInfos: []
}

export interface UploadPolicyProps extends React.ComponentProps<any> {
  className?: string
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  tradeContractForCertifyInsurance: any
  companyFeatureFlags: any
}

const UploadPolicy: React.FC<UploadPolicyProps> = ({
  className,
  setCurrentStep,
  currentStepIndex,
  setCurrentStepIndex,
  tradeContractForCertifyInsurance,
  companyFeatureFlags,
}) => {
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(window.location.search)

  const [selectedFile, setSelectedFile] = useState<any>(null)

  const [selectedFileUpdatedDetails, setSelectedFileUpdatedDetails] =
    useState<any>(null)

  const [isPolicyUploadProcessing, setIsPolicyUploadProcessing] =
    useState(false)

  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true)

  const [isFileUploadedinS3, setIsFileUploadedinS3] = useState<boolean>(false)

  const loggedInUser = authentication.getUserData()

  const handleUpload = async () => {
    setIsUploadButtonDisabled(false)
    setIsPolicyUploadProcessing(true)
    setIsFileUploadedinS3(true)
  }

  useEffect(() => {
    if (isFileUploadedinS3 && selectedFileUpdatedDetails) {
      ;(async () => {
        await saveFile(selectedFileUpdatedDetails)
        setCurrentStep('Certify')
        setCurrentStepIndex(currentStepIndex + 1)
      })()
    }
  }, [isFileUploadedinS3, selectedFileUpdatedDetails]) // eslint-disable-line react-hooks/exhaustive-deps
  const saveFile = async (file: any) => {
    const token = authentication.getAccessToken()
    await axios({
      method: 'POST',
      url: `${appConfig.apiUrl}/rest/file-upload`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({
        originalName: file.originalFileName,
        assignedName: file.assignedFileName,
        path: file.path,
        jobId: tradeContractForCertifyInsurance.job.id,
        loggedInUser: loggedInUser,
        tradeContractId: tradeContractForCertifyInsurance.id,
      }),
    })
  }

  const handleSignDocumentsBackClick = () => {
    if (queryParams.get('upload-new-policy')) {
      queryParams.delete('upload-new-policy')
      navigate({
        search: queryParams.toString(),
      })
    }
    // if (
    //   companyFeatureFlags !== null &&
    //   companyFeatureFlags?.isDocusignEnabled
    // ) {
    //   setCurrentStep('Accept Trade Contract')
    //   setCurrentStepIndex(currentStepIndex - 2)
    // } else {
    //   setCurrentStep('Sign Addendum')
    //   setCurrentStepIndex(currentStepIndex - 1)
    // }

    //Remove this and uncomment above when need for Accept/Sign Addendum

    setCurrentStep('Review Requirements')
    setCurrentStepIndex(currentStepIndex - 1)
  }
  return (
    <Container className={`${className} text-start my-5`}>
      <Row>
        <h6 className="text-primary fw-bold">Job Details</h6>
        <h5 className="fw-bold">
          {tradeContractForCertifyInsurance?.job.streetAddress}
        </h5>
        <p className="text-secondary fw-bold">
          <span>
            {tradeContractForCertifyInsurance?.contractedCompany?.name}
          </span>
          <span className="p-2">|</span>
          <span>{`Job ${tradeContractForCertifyInsurance?.job.slug}`}</span>
          <span className="p-2">|</span>
          <span>{tradeContractForCertifyInsurance.jobName}</span>
        </p>
        <hr className="ms-3 small text-secondary" />
      </Row>

      <Row>
        <h5 className="fw-bold my-3">Upload your insurance policy</h5>
      </Row>

      <Row>
        <DropzoneComponent
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          selectedFileUpdatedDetails={selectedFileUpdatedDetails}
          setSelectedFileUpdatedDetails={setSelectedFileUpdatedDetails}
          type=""
          isFileUploadedinS3={isFileUploadedinS3}
          setIsFileUploadedinS3={setIsFileUploadedinS3}
          setIsUploadButtonDisabled={setIsUploadButtonDisabled}
          isFileUploading={isPolicyUploadProcessing}
          docName="policy"
        />
      </Row>

      <div className="footer">
        <div className="left-side">
          <Button
            className={`btn btn-primary-medium rounded-pill fw-bold back-button`}
            variant="outline-success"
            onClick={handleSignDocumentsBackClick}
          >
            Back
          </Button>
        </div>
        <div className="right-side">
          {isPolicyUploadProcessing ? (
            <CustomUnclickableButton
              buttonContent="Uploading..."
              disabled={true}
            >
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </CustomUnclickableButton>
          ) : (
            <CustomClickableButton
              buttonContent="Submit"
              handleOnClick={() => handleUpload()}
              disable={isUploadButtonDisabled}
            />
          )}
        </div>
      </div>
      <div className="d-flex mt-5">
        <p className="pe-2">
          <InformationCircleIcon className="info-circle" />
        </p>
        <div className="text-muted">We are not supporting scanned files.</div>
      </div>
    </Container>
  )
}

export default styled(UploadPolicy)`
  .back-button {
    border-radius: 100px !important;
    color: black;
    background-color: #fff;
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100vw;
    border-top: 1px solid #e6e6e6;
    height: 60px;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  .left-side {
    margin-left: 40px;
  }
  .right-side {
    margin-right: 40px;
  }
  .info-circle {
    height: 20px;
    color: #8b9192;
  }
`
