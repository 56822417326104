import { Container, Row, Col, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { PencilIcon } from '@heroicons/react/solid'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { redirectDelayInMilliseconds } from '../data'
import {
  ISpecialRequirementOrSpecialProhibiition,
  ITemplate,
} from '../interface'
import CustomClickableButton from './custom-components/custom-clickable-button'
import {
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  EDIT_TEMPLATE,
} from '../graphql/graphql-queries'
import { useState } from 'react'
import CustomAlert from './custom-components/custom-alert'
import CustomUnclickableButton from './custom-components/custom-unclickable-button'
import ScrollableList from './scrollable-list'

export interface ReviewAndPublishProps extends React.ComponentProps<any> {
  className?: string
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  duplicateTemplate: ITemplate
  setIsDataSaved: React.Dispatch<React.SetStateAction<boolean>>
  isDataSaved?: boolean
  currentPage?: string
  edit?: boolean
}

const ReviewAndPublish: React.FC<ReviewAndPublishProps> = ({
  className,
  setCurrentStep,
  currentStepIndex,
  setCurrentStepIndex,
  duplicateTemplate,
  setIsDataSaved,
  edit,
}) => {
  const navigate = useNavigate()

  let [returnedId, setReturnedId] = useState('')

  const [templateCreatedSuccessAlert, setTemplateCreatedSuccessAlert] =
    useState(false)

  const [templatePublishedSuccessAlert, setTemplatePublishedSuccessAlert] =
    useState(false)

  const [isTemplateSaved, setIsTemplateSaved] = useState(false)

  const [isSavingTemplateProcessing, setIsSavingTemplateProcessing] =
    useState(false)

  const [isTemplatePublished, setIsTemplatePublished] = useState(false)

  const [createTemplate] = useMutation(CREATE_TEMPLATE, {
    onCompleted: (data) => {
      setIsSavingTemplateProcessing(false)
      setTemplateCreatedSuccessAlert(true)
      setIsTemplateSaved(true)
      setCurrentStepIndex(currentStepIndex + 1)
      setReturnedId(data.createTemplate.id)
      setTimeout(() => {
        navigate('/templates', {
          state: {
            refresh: true,
          },
        })
      }, redirectDelayInMilliseconds)
    },
  })

  const handlePublishButtonClick = () => {
    updateIsPublished({
      variables: {
        id: returnedId,
      },
    })
  }
  const handleSaveButtonClick = async () => {
    setIsSavingTemplateProcessing(true)
    if (edit) {
      editTemplate({
        variables: {
          data: duplicateTemplate,
        },
      })
    } else {
      createTemplate({
        variables: {
          data: duplicateTemplate,
        },
      })
    }
  }

  const [updateIsPublished] = useMutation(UPDATE_TEMPLATE, {
    onCompleted: (id) => {
      setIsDataSaved(true)
      setTemplatePublishedSuccessAlert(true)
      setIsTemplatePublished(true)
      setTimeout(() => {
        navigate('/templates', {
          state: {
            refresh: true,
          },
        })
      }, 3000)
    },
  })
  const [editTemplate] = useMutation(EDIT_TEMPLATE, {
    onCompleted: (data) => {
      setIsSavingTemplateProcessing(false)
      setTemplateCreatedSuccessAlert(true)
      setIsTemplateSaved(true)
      setCurrentStepIndex(currentStepIndex + 1)
      setReturnedId(data.updateTemplate.id)
      setTimeout(() => {
        navigate('/templates', {
          state: {
            refresh: true,
          },
        })
      }, 3000)
    },
  })
  const handleSetTemplateCreatedSuccessAlert = () =>
    setTemplateCreatedSuccessAlert(false)

  const handleSetTemplatePublishedSuccessAlert = () =>
    setTemplatePublishedSuccessAlert(false)

  const handleTemplateNameEdit = () => {
    setCurrentStep('Name and Limits')
  }

  const handleScopeDurationEdit = () => {
    setCurrentStep('Scope and Duration')
  }
  return (
    <div className={className}>
      <Container className={`${className} `}>
        {templateCreatedSuccessAlert && (
          <Row className="flex-d justify-content-center pb-3 me-5 ">
            <CustomAlert
              handleOnClose={handleSetTemplateCreatedSuccessAlert}
              alertType="success"
              alertMessage={`${duplicateTemplate?.templateName} ${
                duplicateTemplate?.id ? 'edited' : 'created'
              }`}
            />
          </Row>
        )}
        {templatePublishedSuccessAlert && (
          <Row className="flex-d justify-content-center pb-3 me-5 ">
            <CustomAlert
              alertType="success"
              handleOnClose={handleSetTemplatePublishedSuccessAlert}
              alertMessage={`${duplicateTemplate?.templateName} published`}
            />
          </Row>
        )}
        <Row>
          <Col>
            <Container className="text-start mx-5">
              <div className="my-5">
                <h4 className="fw-bold mb-4 text-start ">Review and Publish</h4>
                <h6 className="fw-bold mb-2">Template Name</h6>

                <div className="d-flex text-center py-2 mb-5 justify-content-start">
                  <PencilIcon
                    className="pencil-icon-margin pe-2"
                    style={{ height: '25px', color: '#13948B' }}
                    onClick={handleTemplateNameEdit}
                  />
                  <h4 className="fw-bold">{duplicateTemplate.templateName}</h4>
                </div>
                <div className="mb-5">
                  <div className="d-flex text-end justify-content-start">
                    <h4 className="fw-bold">Limits</h4>
                  </div>
                  <Row>
                    <Col>
                      <Row>
                        <Col sm={6} className="mb-1">
                          Each Occurence
                        </Col>
                        <Col sm={1} className="fw-bold text-start">
                          $
                          {duplicateTemplate.eachOccurrenceLimit.toLocaleString()}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} className="mb-1 ">
                          General Aggregate ({duplicateTemplate?.aggregateType})
                        </Col>
                        <Col sm={1} className="fw-bold text-start">
                          ${duplicateTemplate.aggregateLimit.toLocaleString()}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} className="mb-1">
                          Deductible/Retention
                        </Col>
                        <Col sm={1} className="fw-bold text-start">
                          ${duplicateTemplate.deductible.toLocaleString()}
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col sm={6} className="mb-1">
                          Personal & Advertising Injury Limit
                        </Col>
                        <Col sm={1} className="fw-bold text-start">
                          $
                          {duplicateTemplate.personalAdvertisingLimit.toLocaleString()}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} className="mb-1">
                          Products-Completed Operations Limit
                        </Col>
                        <Col sm={1} className="fw-bold text-start">
                          $
                          {duplicateTemplate.projectCompletedOperationLimit.toLocaleString()}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div className="mb-5">
                  <div className="d-flex text-end justify-content-start">
                    <PencilIcon
                      className="pencil-icon-margin pe-2"
                      style={{ height: '25px', color: '#13948B' }}
                      onClick={handleScopeDurationEdit}
                    />
                    <h4 className="fw-bold">Scope and Duration</h4>
                  </div>
                  <Row>
                    <Col sm={1} className="mb-1">
                      Scope
                    </Col>
                    <Col sm={4} className="fw-bold text-start">
                      {' '}
                      {duplicateTemplate.scope}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={1} className="mb-1">
                      Duration
                    </Col>
                    {duplicateTemplate.durationType ===
                    'Ongoing operations and completed operations' ? (
                      <Col sm={4} className="fw-bold text-start">
                        {' '}
                        {duplicateTemplate.durationType} for{' '}
                        {duplicateTemplate.duration}
                        {duplicateTemplate.duration > 1 ? ' years' : ' year'}
                      </Col>
                    ) : (
                      <Col sm={4} className="fw-bold text-start">
                        {' '}
                        {duplicateTemplate.durationType}
                      </Col>
                    )}
                  </Row>
                </div>

                {/* <Row className="mb-5">
                  <Col>
                    <h5 className="fw-bold">Special Requirements</h5>
                    {/* {duplicateTemplate?.templateLabelsForSpecialRequirements
                      .length ? (
                      <ScrollableList
                        listOfElements={duplicateTemplate?.templateLabelsForSpecialRequirements?.map(
                          (specialRequirement: any) => {
                            return specialRequirement
                          }
                        )}
                        width="w-75"
                      />
                    ) : (
                      'N/A'
                    )} */}
                {/* </Col> */}
                {/* <Col>
                    <h5 className="fw-bold">Special Prohibitions</h5>
                    {duplicateTemplate?.templateLabelsForSpecialProhibitions
                      .length ? (
                      <ScrollableList
                        listOfElements={duplicateTemplate?.templateLabelsForSpecialProhibitions?.map(
                          (specialProhibition: any) => {
                            return specialProhibition
                          }
                        )}
                        width="w-75"
                      />
                    ) : (
                      'N/A'
                    )}
                  </Col>
                </Row>  */}

                <h5 className="fw-bold mb-4">Additional Requirements</h5>
                <Row className="mb-3">
                  <div className="d-flex">
                    <div className="fw-bold pe-4">Contract Liability</div>
                    {duplicateTemplate.contractualLiability ? (
                      <p className="fw-bold text-success">Yes</p>
                    ) : (
                      <p className="fw-bold text-danger">No</p>
                    )}
                  </div>
                  <div>
                    Policy must have coverage for liability arising out of an
                    insured contract.
                  </div>
                </Row>
                <Row className="mb-3">
                  <div className="d-flex">
                    <div className="fw-bold pe-4">Priority of Coverage</div>
                    {duplicateTemplate.priorityOfCoverage ? (
                      <p className="fw-bold text-success">Yes</p>
                    ) : (
                      <p className="fw-bold text-danger">No</p>
                    )}
                  </div>
                  <div>
                    Policy must contain Primary & Non-Contributory wording.
                  </div>
                </Row>
                <Row className="mb-3">
                  <div className="d-flex">
                    <div className="fw-bold pe-4">Notice of Change</div>
                    {duplicateTemplate.noticeOfChange ? (
                      <p className="fw-bold text-success">Yes</p>
                    ) : (
                      <p className="fw-bold text-danger">No</p>
                    )}
                  </div>
                  <div className="d-flex">
                    <div className="fw-bold pe-4">Special Requirements</div>
                    {duplicateTemplate.containsSpecialRequirements ? (
                      <p className="fw-bold text-success">Yes</p>
                    ) : (
                      <p className="fw-bold text-danger">No</p>
                    )}
                  </div>
                  {duplicateTemplate?.containsSpecialRequirements && (
                    <div className="d-flex">
                      <ul>
                        {duplicateTemplate?.templateLabelsForSpecialRequirements?.map(
                          (e: string) => (
                            <li>{e}</li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  <div className="d-flex">
                    <div className="fw-bold pe-4">Special Prohibitions</div>
                    {duplicateTemplate?.containsSpecialProhibitions ? (
                      <p className="fw-bold text-success">Yes</p>
                    ) : (
                      <p className="fw-bold text-danger">No</p>
                    )}
                  </div>
                  {duplicateTemplate?.containsSpecialProhibitions && (
                    <div className="d-flex">
                      <ul>
                        {duplicateTemplate?.templateLabelsForSpecialProhibitions?.map(
                          (e: string) => (
                            <li>{e}</li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  <div>
                    Carrier must provide notice of change or cancellation:
                  </div>
                  <div>
                    <span className="fw-bold">Email: </span>
                    changes@resolvedrisk.com
                  </div>
                  <div>
                    <span className="fw-bold">Mailing Address: </span>285 Fulton
                    St, New York, NY 10007, USA
                  </div>
                </Row>
              </div>
              <Row>
                <Col className="text-end p-1">
                  {/* <span className="p-1">
                    <CustomClickableButton
                      buttonContent="Save Template"
                      handleOnClick={handleSaveButtonClick}
                      disable={isTemplateSaved}
                    />
                  </span> */}
                  {isSavingTemplateProcessing ? (
                    <CustomUnclickableButton
                      buttonContent="Saving Template..."
                      disabled={true}
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </CustomUnclickableButton>
                  ) : (
                    <CustomClickableButton
                      buttonContent="Save Template"
                      handleOnClick={handleSaveButtonClick}
                      disable={isTemplateSaved}
                    />
                  )}
                  {!isTemplatePublished && (
                    <span className="p-1">
                      <CustomClickableButton
                        buttonContent="Publish Template"
                        handleOnClick={handlePublishButtonClick}
                        disable={!returnedId ? true : false}
                      />
                    </span>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default styled(ReviewAndPublish)`
  .text-underline-dashed {
    text-decoration: underline dashed #13948b;
    text-underline-offset: 4px;
  }
  .pencil-icon-margin {
    margin-left: -35px;

    &:hover {
      cursor: pointer;
    }
  }
`
