import { TrashIcon } from '@heroicons/react/solid'
import { Row, Col, Form } from 'react-bootstrap'
import ValidationError from '../validation-error'
import {
  IFormInputs,
  IFormsAndScheduledTextInputs,
  IPolicyDetailsValidationEror,
  IScheduledTextInputs,
} from '../../interface'
import { scheduledTextNamesForDropdown } from '../../data'

export interface ScheduledTextInputsProps extends React.ComponentProps<any> {
  className?: string
  formAndScheduledTextInputs: IFormsAndScheduledTextInputs
  setFormAndScheduledTextInputs: React.Dispatch<
    React.SetStateAction<IFormsAndScheduledTextInputs>
  >
  scheduledTextFields: IScheduledTextInputs
  formIndex: number
  scheduledTextInputValues: IScheduledTextInputs[]
  setScheduledTextInputValues: React.Dispatch<
    React.SetStateAction<IScheduledTextInputs[]>
  >
  formInputValues: IFormInputs[]
  scheduledTextIndex: number
  keyValue: string
  error: IPolicyDetailsValidationEror
  tradeContracrtorId?: string
}

const ScheduledTextInputs: React.FC<ScheduledTextInputsProps> = ({
  formAndScheduledTextInputs,
  setFormAndScheduledTextInputs,
  scheduledTextInputValues,
  setScheduledTextInputValues,
  scheduledTextIndex,
  keyValue,
  error,
  formIndex,
  formInputValues,
  tradeContracrtorId,
}) => {
  const handleScheduledTextInputsChange = (event: any, i: number) => {
    let { name, value } = event.target
    let newScheduledTextValues: any[] = [...scheduledTextInputValues]
    newScheduledTextValues[i][name] = value
    newScheduledTextValues[i].documentId =
      newScheduledTextValues[i].formId !== ''
        ? formInputValues[formIndex]?.documentId
        : 1
    setScheduledTextInputValues(newScheduledTextValues)
    setFormAndScheduledTextInputs({
      ...formAndScheduledTextInputs,
      scheduledTexts: newScheduledTextValues,
    })
  }

  const removeScheduledTextInputFields = (index: number) => {
    const rows = [...scheduledTextInputValues]
    rows.splice(index, 1)
    setScheduledTextInputValues(rows)
    setFormAndScheduledTextInputs({
      ...formAndScheduledTextInputs,
      scheduledTexts: rows,
    })
  }

  return (
    <div key={keyValue}>
      <Form role="form">
        <Form.Group>
          <Row className="mb-3">
            <Col sm={3}>
              <Form.Label>Text Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="textName"
                onChange={(e) =>
                  handleScheduledTextInputsChange(e, scheduledTextIndex)
                }
                value={scheduledTextInputValues?.[scheduledTextIndex]?.textName}
              >
                <option value="" disabled>
                  Select a Text Name
                </option>
                {scheduledTextNamesForDropdown.map(
                  (scheduledTextNameForDropdown: any) => (
                    <option
                      key={scheduledTextNameForDropdown?.id}
                      value={scheduledTextNameForDropdown?.textName}
                    >
                      {scheduledTextNameForDropdown?.textName}
                    </option>
                  )
                )}
              </Form.Select>

              {error?.scheduledTexts?.[scheduledTextIndex]?.textName && (
                <ValidationError
                  errorMessage={
                    error?.scheduledTexts?.[scheduledTextIndex]?.textName
                  }
                />
              )}
            </Col>
            <Col sm={8}>
              <Form.Label>Text Value</Form.Label>
              <Form.Control
                name="textValue"
                as="textarea"
                rows={1}
                type="text"
                value={
                  scheduledTextInputValues?.[scheduledTextIndex]?.textValue
                }
                onChange={(e) =>
                  handleScheduledTextInputsChange(e, scheduledTextIndex)
                }
                autoComplete="off"
              ></Form.Control>

              {error?.scheduledTexts?.[scheduledTextIndex]?.textValue && (
                <ValidationError
                  errorMessage={
                    error?.scheduledTexts?.[scheduledTextIndex]?.textValue
                  }
                />
              )}
            </Col>
            <Col>
              {!tradeContracrtorId && (
                <TrashIcon
                  className="react-icon"
                  style={{
                    marginTop: '33px',
                  }}
                  onClick={() =>
                    removeScheduledTextInputFields(scheduledTextIndex)
                  }
                />
              )}
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </div>
  )
}

export default ScheduledTextInputs
