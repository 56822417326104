import http from './http'

export interface LoginCredentials {
  username: string
  password: string
}

class Authentication {
  private _tk: string = 'whistle'

  getAccessToken() {
    return localStorage.getItem(this._tk)
  }

  setAccessToken(value: string) {
    localStorage.setItem(this._tk, value)
  }

 setUserData(value: any) {
    localStorage.setItem('user', value)
  }

  getUserData() {
    let s = localStorage.getItem('user')
    if (s) {
      return JSON.parse(s)
    } else {
      return {}
    }
  }

  isUserLoggedIn() {
    return !!this.getAccessToken()
  }

  isSuperAdmin() {
    return this.getUserData().role === 'SUPER ADMIN'
  }

  isTradeContractor() {
    return this.getUserData().role === 'TRADE CONTRACTOR'
  }

  isClient() {
    return this.getUserData().role === 'CLIENT'
  }

  isBroker() {
    return this.getUserData().role === 'BROKER'
  }

  async clear() {
    localStorage.clear()
  }

  loadState() {
    const accessToken = this.getAccessToken()
    return {
      isAuthenticated: !!accessToken,
      accessToken,
      user: this.getUserData()
    }
  }

  async login(credentials: LoginCredentials) {
    const { data } = await http.request({
      method: 'POST',
      url: '/rest/v1/token/',
      data: credentials,
    })

    if (!data.token) {
      return false
    }
    this.setAccessToken(data.token)
    this.setUserData(JSON.stringify(data.user))
    this.loadState()
  }
}

export default new Authentication()
