import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import _ from 'lodash'
import SpecialRequirements from './special-requirements'
import { ITemplate } from '../../interface'
import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import { getLabellingPathsForSpecialRequirementsOrProhibitions } from '../../helper-functions'

export interface PopupModalForSpecialRequirementsProps
  extends React.ComponentProps<any> {
  show: boolean
  onHide: () => void
  className: string
  template: ITemplate
  setTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
  setShowSpecialRequirementsPopup: React.Dispatch<React.SetStateAction<boolean>>
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>
  editSpecialRequirementsAtTemplateEditLevel: boolean
  endorsements: any[]
  showSpecialRequirementsPopup: boolean
  savedDataAfterDone: any[]
  setSavedDateAfterDone: React.Dispatch<React.SetStateAction<any[]>>
  duplicateTemplate: ITemplate
  setDuplicateTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
  addedSpecialRequirements: string[]
  setAddedSpecialRequirements: React.Dispatch<React.SetStateAction<string[]>>
  validateSpecialRequirementsOrProhibitionsSelection: () => boolean
}
const PopupModalForSpecialRequirements: React.FC<
  PopupModalForSpecialRequirementsProps
> = (props) => {
  const [
    checkedElementsForSpecialRequirements,
    setCheckedElementsForSpecialRequirements,
  ] = useState<any>([])

  const getSpecialRequirementsLabelsStoredInDB = () => {
    let specialRequirementsLabelsStoredInDB =
      getLabellingPathsForSpecialRequirementsOrProhibitions(
        props.duplicateTemplate.templateLabels!,
        'Special Requirements'
      )
    return specialRequirementsLabelsStoredInDB
  }

  const handleMarkAsSpecialRequirement = async () => {
    if (!props.validateSpecialRequirementsOrProhibitionsSelection()) {
      let specialRequirementsLabelsStoredInDB =
        getSpecialRequirementsLabelsStoredInDB()

      props.setAddedSpecialRequirements(specialRequirementsLabelsStoredInDB)
      props.setShowSpecialRequirementsPopup(false)
      props.setDuplicateTemplate({
        ...props.duplicateTemplate,
        templateLabelsForSpecialRequirements:
          specialRequirementsLabelsStoredInDB,
      })
      return
    }
    props.setDuplicateTemplate({
      ...props.duplicateTemplate,
      templateLabelsForSpecialRequirements:
        checkedElementsForSpecialRequirements.length
          ? checkedElementsForSpecialRequirements
          : props.addedSpecialRequirements,
    })
    props.setAddedSpecialRequirements(props.addedSpecialRequirements)

    props.setShowSpecialRequirementsPopup(false)
  }

  const handleEditSpecialRequirements = async () => {
    if (!props.validateSpecialRequirementsOrProhibitionsSelection()) {
      let specialRequirementsLabelsStoredInDB =
        getSpecialRequirementsLabelsStoredInDB()
      props.setAddedSpecialRequirements(specialRequirementsLabelsStoredInDB)
      props.setShowSpecialRequirementsPopup(false)
      props.setDuplicateTemplate({
        ...props.duplicateTemplate,
        templateLabelsForSpecialRequirements:
          specialRequirementsLabelsStoredInDB,
      })
      return
    }
    props.setDuplicateTemplate({
      ...props.duplicateTemplate,
      templateLabelsForSpecialRequirements:
        checkedElementsForSpecialRequirements.length
          ? checkedElementsForSpecialRequirements
          : props.addedSpecialRequirements,
    })

    props.setShowSpecialRequirementsPopup(false)
  }
  return (
    <div className="popup-modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Special Requirements
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SpecialRequirements
            duplicateTemplate={props.duplicateTemplate}
            setDuplicateTemplate={props.setDuplicateTemplate}
            templateId={props.duplicateTemplate.id}
            setCheckedElementsForSpecialRequirements={
              setCheckedElementsForSpecialRequirements
            }
            addedSpecialRequirements={props.addedSpecialRequirements}
            setAddedSpecialRequirements={props.setAddedSpecialRequirements}
            getSpecialRequirementsLabelsStoredInDB={
              getSpecialRequirementsLabelsStoredInDB
            }
            className={props.className}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="text-end">
            <span className="m-4">
              {' '}
              {/* <Button
                onClick={handleReset}
                style={{ background: 'orange', border: 'none' }}
              >
                Reset
              </Button> */}
            </span>
            <span className="m-3">
              {/* <Button
                onClick={handleMarkAsSpecialRequirement}
                style={{ border: 'none' }}
              >
                Done
              </Button> */}
              <Button
                onClick={
                  props.duplicateTemplate?.id
                    ? handleEditSpecialRequirements
                    : handleMarkAsSpecialRequirement
                }
                style={{ border: 'none' }}
              >
                Done
              </Button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default styled(PopupModalForSpecialRequirements)`
  .modal-lg,
  .modal-xl {
    max-width: 75%;
    height: -webkit-fill-available;
  }
  .modal-content {
    height: -webkit-fill-available;
  }
  .modal-header {
    position: sticky;
  }
  .modal-body {
    height: fit-content;
    overflow-y: scroll;
  }
`
