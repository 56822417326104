import { useEffect } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import styled from 'styled-components'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { IPolicyDetailsValidationEror, IPolicyInputs } from '../../interface'
import ValidationError from '../validation-error'
import moment from 'moment'

export interface PolicyInputsProps extends React.ComponentProps<any> {
  className?: string
  policyInputs: IPolicyInputs
  setPolicyInputs: React.Dispatch<React.SetStateAction<IPolicyInputs>>
  error: IPolicyDetailsValidationEror
  policyPeriodStartDate: string
  setPolicyPeriodStartDate: React.Dispatch<React.SetStateAction<string>>
  policyPeriodEndDate: string
  setPolicyPeriodEndDate: React.Dispatch<React.SetStateAction<string>>
}

const PolicyInputs: React.FC<PolicyInputsProps> = ({
  className,
  policyInputs,
  setPolicyInputs,
  error,
  policyPeriodStartDate,
  setPolicyPeriodStartDate,
  policyPeriodEndDate,
  setPolicyPeriodEndDate,
}) => {
  const converDateToSpecifiedFormat = (date: string) => {
    return moment(date).format('MM/DD/YYYY')
  }

  useEffect(() => {
    if (
      policyInputs?.policyPeriodStartDate ||
      policyInputs?.policyPeriodEndDate
    ) {
      let startDate = policyInputs?.policyPeriodStartDate
        ? converDateToSpecifiedFormat(policyInputs?.policyPeriodStartDate)
        : ''
      let endDate = policyInputs?.policyPeriodEndDate
        ? converDateToSpecifiedFormat(policyInputs?.policyPeriodEndDate)
        : ''

      setPolicyPeriodStartDate(startDate)
      setPolicyPeriodEndDate(endDate)
    }
  }, [policyInputs?.policyPeriodStartDate, policyInputs?.policyPeriodEndDate])

  const handlePolicyInputsChange = (e: any) => {
    let { name, value, type } = e.target
    value = e.target.value.replace(/,/g, '')
    if (name === 'minimumPremium') {
      setPolicyInputs({
        ...policyInputs,
        [name]: String(value),
      })
    } else {
      if (!value.match(/[a-zA-Z\s]|[^a-zA-Z0-9\s]/g)) {
        setPolicyInputs({
          ...policyInputs,
          [name]: Number(value),
        })
      }
    }
  }

  const handleOnChangeForStartDate = (e: any) => {
    const { value } = e.target
    setPolicyPeriodStartDate(value)
  }

  const handleOnChangeForEndDate = (e: any) => {
    const { value } = e.target
    setPolicyPeriodEndDate(value)
  }
  const handlePolicyDeclarationChange = (e: any) => {
    let { name, value } = e.target

    setPolicyInputs({
      ...policyInputs,
      [name]: value,
    })
  }

  return (
    <Container className={`${className} text-start mb-5 border p-5 my-4`}>
      <p className="fs-5 fw-bold">Policy Details</p>
      <Form role="form">
        <Form.Group>
          <Row className="pb-2">
            <Col sm={5}>
              <Form.Label className="required-field">
                Claim Notification Address
              </Form.Label>
              <Form.Control
                name="claimNotificationAddress"
                type="text"
                value={policyInputs?.claimNotificationAddress}
                onChange={handlePolicyDeclarationChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError
                errorMessage={error?.policy?.claimNotificationAddress}
              />
            </Col>
            <Col>
              <Form.Label className="required-field">
                Claim Notification Person Name
              </Form.Label>
              <Form.Control
                name="claimNotificationPersonName"
                type="text"
                value={policyInputs?.claimNotificationPersonName}
                onChange={handlePolicyDeclarationChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError
                errorMessage={error?.policy?.claimNotificationPersonName}
              />
            </Col>
            <Col>
              <Form.Label className="required-field">
                Claim Notification Person Designation
              </Form.Label>
              <Form.Control
                name="claimNotificationPersonDesignation"
                type="text"
                value={policyInputs?.claimNotificationPersonDesignation}
                onChange={handlePolicyDeclarationChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError
                errorMessage={error?.policy?.claimNotificationPersonDesignation}
              />
            </Col>
          </Row>

          <Row>
            <Col className="d-flex flex-column">
              <Form.Label>Claim Notification Phone</Form.Label>
              <InputMask
                className="input-field"
                type="text"
                mask="(999) 999-9999"
                name="claimNotificationPhone"
                value={policyInputs?.claimNotificationPhone}
                onChange={handlePolicyDeclarationChange}
                defaultValue=""
                autoComplete="off"
              />
            </Col>
            <Col>
              <Form.Label>Claim Notification Fax</Form.Label>
              <Form.Control
                name="claimNotificationFax"
                type="text"
                value={policyInputs?.claimNotificationFax}
                onChange={handlePolicyDeclarationChange}
                autoComplete="off"
              ></Form.Control>
            </Col>
            <Col>
              <Form.Label className="required-field">
                Claim Notification Email
              </Form.Label>
              <Form.Control
                name="claimNotificationEmail"
                type="text"
                value={policyInputs?.claimNotificationEmail}
                onChange={handlePolicyDeclarationChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError
                errorMessage={error?.policy?.claimNotificationEmail}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={3}>
              <Form.Label className="required-field">
                Insurer Company
              </Form.Label>
              <Form.Control
                name="insurerCompany"
                type="text"
                value={policyInputs?.insurerCompany}
                onChange={handlePolicyDeclarationChange}
                autoComplete="off"
              ></Form.Control>
              <ValidationError errorMessage={error?.policy?.insurerCompany} />
            </Col>
            <Col sm={3}>
              <Form.Label className="required-field">Insured Name</Form.Label>
              <Form.Control
                name="insuredName"
                type="text"
                value={policyInputs?.insuredName}
                onChange={handlePolicyDeclarationChange}
                autoComplete="off"
              ></Form.Control>
              <ValidationError errorMessage={error?.policy?.insuredName} />
            </Col>
            <Col className="text-center">
              <Form.Label className="required-field">
                Policy Period <span className="text-muted">(MM/DD/YYYY)</span>
              </Form.Label>
              <div className="d-flex justify-content-center align-items-center">
                <span>
                  <Form.Control
                    name="startDate"
                    type="text"
                    value={policyPeriodStartDate}
                    onChange={handleOnChangeForStartDate}
                    autoComplete="off"
                  ></Form.Control>
                </span>

                <span className="px-3">to</span>
                <span>
                  <Form.Control
                    name="endDate"
                    type="text"
                    value={policyPeriodEndDate}
                    onChange={handleOnChangeForEndDate}
                    autoComplete="off"
                  ></Form.Control>
                </span>
              </div>
              <ValidationError
                errorMessage={`${error?.policy?.policyPeriod} ${error?.policy?.policyPeriodStartDate} ${error?.policy?.policyPeriodEndDate}`}
              />
            </Col>
            <Col>
              <Form.Label className="required-field">Policy Number</Form.Label>
              <Form.Control
                name="policyNumber"
                type="text"
                value={policyInputs?.policyNumber}
                onChange={handlePolicyDeclarationChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError errorMessage={error?.policy?.policyNumber} />
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label className="required-field">Carrier Name</Form.Label>
              <Form.Control
                name="carrierName"
                type="text"
                value={policyInputs?.carrierName}
                onChange={handlePolicyDeclarationChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError errorMessage={error?.policy?.carrierName} />
            </Col>
            <Col>
              <Form.Label className="required-field">
                Limit Each Occurance
              </Form.Label>
              <Form.Control
                name="limitEachOccurance"
                type="text"
                value={policyInputs?.limitEachOccurance?.toLocaleString(
                  'en-US'
                )}
                onChange={handlePolicyInputsChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError
                errorMessage={error?.policy?.limitEachOccurance}
              />
            </Col>
            <Col>
              <Form.Label className="required-field">
                General Aggregate Limit
              </Form.Label>
              <Form.Control
                name="generalAggregateLimit"
                type="text"
                value={policyInputs?.generalAggregateLimit?.toLocaleString(
                  'en-US'
                )}
                onChange={handlePolicyInputsChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError
                errorMessage={error?.policy?.generalAggregateLimit}
              />
            </Col>
            <Col>
              <Form.Label className="required-field">Deductible</Form.Label>
              <Form.Control
                name="deductible"
                type="text"
                value={policyInputs?.deductible?.toLocaleString('en-US')}
                onChange={handlePolicyInputsChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError errorMessage={error?.policy?.deductible} />
            </Col>
            <Col>
              <Form.Label className="required-field">
                Products/Completed Operations
              </Form.Label>
              <Form.Control
                name="projectCompletedOperationLimit"
                type="text"
                value={policyInputs?.projectCompletedOperationLimit?.toLocaleString(
                  'en-US'
                )}
                onChange={handlePolicyInputsChange}
                autoComplete="off"
              ></Form.Control>
              <ValidationError
                errorMessage={error?.policy?.projectCompletedOperationLimit}
              />
            </Col>
            <Col>
              <Form.Label className="required-field">
                Personal & Advertising
              </Form.Label>
              <Form.Control
                name="personalAdvertisingLimit"
                type="text"
                value={policyInputs?.personalAdvertisingLimit?.toLocaleString(
                  'en-US'
                )}
                onChange={handlePolicyInputsChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError
                errorMessage={error?.policy?.personalAdvertisingLimit}
              />
            </Col>
            <Col>
              <Form.Label className="required-field">
                Self Insured Retention
              </Form.Label>
              <Form.Control
                name="selfInsuredRetention"
                type="text"
                value={policyInputs?.selfInsuredRetention?.toLocaleString(
                  'en-US'
                )}
                onChange={handlePolicyInputsChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError
                errorMessage={error?.policy?.selfInsuredRetention}
              />
            </Col>
            <Col>
              <Form.Label className="required-field">
                Minimum Premium
              </Form.Label>
              <Form.Control
                name="minimumPremium"
                type="text"
                value={policyInputs?.minimumPremium?.toLocaleString('en-US')}
                onChange={handlePolicyInputsChange}
                autoComplete="off"
              ></Form.Control>

              <ValidationError errorMessage={error?.policy?.minimumPremium} />
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </Container>
  )
}

export default styled(PolicyInputs)`
  .datepicker__input {
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 115px;
    padding: 3px;
  }
  .input-field {
    border: 1px solid #ced4da;
    border-radius: 4px;
    height: 34px;
  }
`
