import { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useQuery } from '@apollo/client'

import MultiStepProgressBar from './custom-components/multi-step-progress-bar'
import PolicyholderDetails from './policy-holder-details'
import ReviewAndPublish from './review-and-publish'
import {
  CLAIMS_SPECIALISTS_LIST,
  TEMPLATES_LIST,
} from '../graphql/graphql-queries'

export interface CreatePolicyholderProps extends React.ComponentProps<any> {}

const CreatePolicyholder: React.FC<CreatePolicyholderProps> = () => {
  const [templatesList, setTemplatesList] = useState([])

  const [claimsSpecialistsList, setClaimsSpecialistsList] = useState([])

  const [selectedTemplatesList, setSelectedTemplatesList] = useState([])

  const [selectedClaimsSpecialistsList, setSelectedClaimsSpecialistsList] =
    useState([])

  let [isDataSaved, setIsDataSaved] = useState(false)

  useQuery(CLAIMS_SPECIALISTS_LIST, {
    onCompleted: (data) => {
      setClaimsSpecialistsList(data.listClaimsSpecialists)
    },
  })
  const PaginationTemplateData = {
    offset: 0,
    limit: 0,
  }
  useQuery(TEMPLATES_LIST, {
    variables: {
      data: PaginationTemplateData,
    },
    onCompleted: (data) => {
      setTemplatesList(data.listTemplates.templates)
    },
  })

  let [policyholder, setPolicyHolder] = useState({
    companyName: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userPhoneNumber: '',
    policies: [
      {
        policyNumber: '',
        startDate: null,
        endDate: null,
        templateId: '',
        claimsSpecialistId: '',
      },
    ],
  })

  let steps = ['Policyholder Details', 'Review and Publish']

  let stepIndex = [0, 1]

  let [currentPage, setCurrentPage] = useState('Policyholder Details')

  let [currentStepIndex, setCurrentStepIndex] = useState(stepIndex[0])

  return (
    <Container className="border">
      <Row>
        <Col
          sm={2}
          className="min-vh-100 py-5 d-flex justify-content-center progress-bar-wrapper "
        >
          <MultiStepProgressBar
            progressBarHeading="Create Policyholder"
            steps={steps}
            currentPage={currentPage}
            stepIndex={stepIndex}
            currentStepIndex={currentStepIndex}
            isDataSaved={isDataSaved}
            setIsDataSaved={setIsDataSaved}
          />
        </Col>
        <Col sm={10}>
          <Container className="text-start mx-5">
            {currentPage === 'Policyholder Details' ? (
              <PolicyholderDetails
                setCurrentStep={setCurrentPage}
                currentStepIndex={currentStepIndex}
                setCurrentStepIndex={setCurrentStepIndex}
                policyholder={policyholder}
                setPolicyHolder={setPolicyHolder}
                claimsSpecialistsList={claimsSpecialistsList}
                setClaimsSpecialistsList={setClaimsSpecialistsList}
                selectedClaimsSpecialistsList={selectedClaimsSpecialistsList}
                setSelectedClaimsSpecialistsList={
                  setSelectedClaimsSpecialistsList
                }
                templatesList={templatesList}
                setTemplatesList={setTemplatesList}
                selectedTemplatesList={selectedTemplatesList}
                setSelectedTemplatesList={setSelectedTemplatesList}
              />
            ) : (
              <ReviewAndPublish
                policyholder={policyholder}
                currentStepIndex={currentStepIndex}
                setCurrentStepIndex={setCurrentStepIndex}
                claimsSpecialistsList={claimsSpecialistsList}
                selectedClaimsSpecialistsList={selectedClaimsSpecialistsList}
                templatesList={templatesList}
                setTemplatesList={setTemplatesList}
                selectedTemplatesList={selectedTemplatesList}
                setSelectedTemplatesList={setSelectedTemplatesList}
                isDataSaved={isDataSaved}
                setIsDataSaved={setIsDataSaved}
                setCurrentStep={setCurrentPage}
              />
            )}
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default CreatePolicyholder
