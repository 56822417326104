import { Row, Col, Card } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { POLICY_DETAILS } from '../../graphql/graphql-queries'
import { addDollarSignInFrontOfAmount } from '../../helper-functions'

export interface PolicyDetailsProps extends React.ComponentProps<any> {
  docId: string | undefined
}

const PolicyDetails: React.FC<PolicyDetailsProps> = ({ docId }) => {
  const { data, loading, error } = useQuery(POLICY_DETAILS, {
    variables: { docId },
  })

  return (
    <>
      <Card className="my-5 bg-gray-light3">
        <Card.Body>
          <h5 className="card-title fw-bold text-start text-primary">
            Policy Details
          </h5>

          {loading && <p>Loading...</p>}
          {error && (
            <p>
              Oops! Something went wrong while fetching policy details. Please
              refresh the page and try again.
            </p>
          )}

          {!loading && !error && data?.getPolicy && (
            <Card.Text className="text-start">
              <Row className="pt-3">
                <Col>
                  <label className="fw-bold text-tertiary">Insured Name:</label>
                  <span className="p-1">
                    {data?.getPolicy?.insuredName || 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">
                    Policy Number:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.policyNumber || 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">Period:</label>
                  <span className="p-1">
                    {data?.getPolicy?.policyPeriod || 'N/A'}
                  </span>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col>
                  <label className="fw-bold text-tertiary">Insurer:</label>
                  <span className="p-1">
                    {data?.getPolicy?.insurerCompany || 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">Carrier:</label>
                  <span className="p-1">
                    {data?.getPolicy?.carrierName || 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">
                    Minimum Premium:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.minimumPremium || 'N/A'}
                  </span>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col>
                  <label className="fw-bold text-tertiary">
                    Notification Person Name:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.claimNotificationPersonName || 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">
                    Notification Person Designation:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.claimNotificationPersonDesignation ||
                      'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">
                    Notification Address:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.claimNotificationAddress || 'N/A'}
                  </span>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col>
                  <label className="fw-bold text-tertiary">
                    Notification Phone:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.claimNotificationPhone || 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">
                    Notification Fax:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.claimNotificationFax || 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">
                    Notification Email:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.claimNotificationEmail || 'N/A'}
                  </span>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col>
                  <label className="fw-bold text-tertiary">
                    Limit Each Occurance:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.limitEachOccurance
                      ? addDollarSignInFrontOfAmount(
                          data?.getPolicy?.limitEachOccurance
                        )
                      : 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">
                    General Aggregate Limit:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.generalAggregateLimit
                      ? addDollarSignInFrontOfAmount(
                          data?.getPolicy?.generalAggregateLimit
                        )
                      : 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">Deductible:</label>
                  <span className="p-1">
                    {data?.getPolicy?.deductible || 'N/A'}
                  </span>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col>
                  <label className="fw-bold text-tertiary">
                    Products-Completed Operations Aggregate Limit:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.projectCompletedOperationLimit
                      ? addDollarSignInFrontOfAmount(
                          data?.getPolicy?.projectCompletedOperationLimit
                        )
                      : 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">
                    Personal Advertising Limit:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.personalAdvertisingLimit
                      ? addDollarSignInFrontOfAmount(
                          data?.getPolicy?.personalAdvertisingLimit
                        )
                      : 'N/A'}
                  </span>
                </Col>
                <Col>
                  <label className="fw-bold text-tertiary">
                    Self Insured Retention:
                  </label>
                  <span className="p-1">
                    {data?.getPolicy?.selfInsuredRetention || 'N/A'}
                  </span>
                </Col>
              </Row>
            </Card.Text>
          )}
          {!loading && !error && !data?.getPolicy && (
            <Card.Text>No data found</Card.Text>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default PolicyDetails
