import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import styled from 'styled-components'

import TradesList from './trades-list'
import { useEffect, useState } from 'react'
import ClaimNoticeComponent from './claim-notice'
import CustomAlert from './custom-components/custom-alert'

import NoTradesAdded from './no-trades-added'
import { useQuery } from '@apollo/client'
import { GET_TRADE_CONTRACTS_BY_JOB_ID } from '../graphql/graphql-queries'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'
import config from '../core/config'

export interface TradesProps extends React.ComponentProps<any> {
  className?: string
}

const Trades: React.FC<TradesProps> = ({ className }) => {
  const { state: job }: any = useLocation()
  const [tradeContracts, setTradeContracts] = useState([])
  const [showModel, setShowModel] = useState(false)
  const [errorNotification, setErrorNotification] = useState('')
  const [showSuccessNotification, setShowSuccessNotification] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const offset = 0
  const dataPerPage = config.itemPerPage
  let navigate = useNavigate()
  const handleBackToJobsClick = () => {
    navigate('/jobs')
  }

  const handleNoticeClaimClick = () => {
    setShowModel(true)
  }
  const handleCloseClaimNotification = () => {
    setErrorNotification('')
  }

  const handleAddTradeClick = () => {
    navigate('/add-trade', { state: job })
  }
  let paginationDataForTradeContracts = {
    jobId: job?.id,
    offset: currentPage === 1 ? offset : (currentPage - 1) * 25,
    limit: dataPerPage,
  }
  const perPage = Math.ceil(tradeContracts?.length / dataPerPage)
  const { refetch, loading, error } = useQuery(GET_TRADE_CONTRACTS_BY_JOB_ID, {
    variables: {
      data: paginationDataForTradeContracts,
    },
    onCompleted: (data) => {
      setTradeContracts(data.listTradeContractsByJobId.TradeContracts)
    },
  })
  useEffect(() => {
    refetch({ data: paginationDataForTradeContracts })
  }, [job?.tradeContracts?.length])

  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />

  return (
    <Container className={`${className} text-start`}>
      {errorNotification && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleCloseClaimNotification}
            alertType="error"
            alertMessage={errorNotification}
          />
        </Row>
      )}
      {showSuccessNotification && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setShowSuccessNotification('')}
            alertType="success"
            alertMessage={showSuccessNotification}
          />
        </Row>
      )}
      <Row>
        <Col sm={9} className="my-2">
          <span
            className="text-primary-medium fw-bold span-cursor my-2"
            onClick={handleBackToJobsClick}
          >
            <ArrowLeftIcon className="arrow-left-icon" />
            Back to Jobs
          </span>
        </Col>
        <Col className="text-end my-2"></Col>
        <Row className="my-1">
          <Col sm={9}>
            <h2 className="fw-bold ">{job.streetAddress}</h2>
          </Col>
          <Col sm={3} className="text-end">
            <Button
              className="btn btn-primary-medium rounded-pill claim-button fw-bold p-2 mx-4"
              variant="outline-success"
              onClick={() => {
                navigate('/update-job', {
                  state: {
                    job: job,
                  },
                })
              }}
            >
              Edit Job
            </Button>
            <Button
              className="btn btn-primary-medium rounded-pill claim-button fw-bold p-2"
              variant="outline-success"
              onClick={handleNoticeClaimClick}
            >
              Give Notice of Claim
            </Button>
          </Col>
        </Row>
        <p className="text-secondary fw-bold">
          <span>{job.nonProcessingPolicy?.template?.templateName}</span>
          <span className="p-2">|</span>
          <span>{`Job ${job.slug}`}</span>
          <span className="p-2">|</span>
          <span>{job.streetAddress}</span>
        </p>
        <hr className="ms-3 small text-secondary" />
      </Row>
      {!!job?.tradeContracts?.length ? (
        <TradesList
          tradeContracts={tradeContracts}
          handleAddTradeClick={handleAddTradeClick}
          job={job}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
        />
      ) : (
        <NoTradesAdded handleAddTradeClick={handleAddTradeClick} />
      )}
      {showModel && (
        <ClaimNoticeComponent
          show={showModel}
          className="-notice-popup"
          onHide={() => setShowModel(false)}
          job={job}
          tradeContracts={tradeContracts}
          setErrorNotification={setErrorNotification}
          setShowSuccessNotification={setShowSuccessNotification}
          refetch={refetch}
        />
      )}
    </Container>
  )
}
export default styled(Trades)`
  .span-cursor {
    cursor: pointer;
  }

  .arrow-left-icon {
    height: 15px;
    color: ##13948Bb;
    cursor: pointer;
    margin-right: 5px;
  }

  .claim-button {
    border-radius: 100px !important;
    border: 5px solid #13948Bb !important;
    color: black;
    background-color: #fff;
  }
`
