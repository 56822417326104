import { useNavigate } from 'react-router-dom'
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/solid'
import styled from 'styled-components'
import {
  getLatestUploadedFileFromList,
  getLatestUploadedPolicy,
  getLatestUploadedPolicyCertificationStatus,
  getLatestUploadedPolicyFromList,
} from '../helper-functions'
import CertificationStatusBadge from './certification-status-badge'

export interface ShowBadgeForCertificationStatusProps
  extends React.ComponentProps<any> {
  className?: string
  tradeContract: any
}

const ShowBadgeForCertificationStatus: React.FC<
  ShowBadgeForCertificationStatusProps
> = ({ className, tradeContract }) => {
  let navigate = useNavigate()

  let clickCertificationStatus = (tradeContract: any) => {
    let latestUploadedFile = getLatestUploadedFileFromList(tradeContract)

    handleReviewPolicyCertificationStatusClick(
      tradeContract,
      getLatestUploadedPolicyCertificationStatus(tradeContract),
      getLatestUploadedPolicyFromList(tradeContract, latestUploadedFile)
    )
  }

  const handleReviewPolicyCertificationStatusClick = (
    clickedTradeContract: any,
    latestUploadPolicyCertificationStatus: any,
    latestUploadedPolicy: any
  ) => {
    navigate('/certify-policy', {
      state: {
        clickedTradeContract,
        latestUploadPolicyCertificationStatus,
        latestUploadedPolicy,
      },
    })
  }
  return (
    <div className={className}>
      {getLatestUploadedPolicyCertificationStatus(tradeContract) ===
      'NO POLICY UPLOADED' ? (
        <CertificationStatusBadge
          certificationStatus="Pending Upload"
          bg="warning-light"
          className="text-primary2-med"
        >
          <InformationCircleIcon className="icon-inside-badge information-circle-icon" />
        </CertificationStatusBadge>
      ) : getLatestUploadedPolicyCertificationStatus(tradeContract) ===
        "POLICY COULDN'T BE READ" ? (
        <CertificationStatusBadge
          certificationStatus="Policy couldn't be read"
          bg="error-light"
          className="text-error-dark"
          clickCertificationStatusFunction={() =>
            clickCertificationStatus(tradeContract)
          }
        >
          <ExclamationCircleIcon className="icon-inside-badge exclamation-icon" />
        </CertificationStatusBadge>
      ) : getLatestUploadedPolicyCertificationStatus(tradeContract) ===
        'REVIEW PENDING' ? (
        <CertificationStatusBadge
          certificationStatus="Pending Review"
          bg="warning-light"
          className="text-primary2-med"
          clickCertificationStatusFunction={() =>
            clickCertificationStatus(tradeContract)
          }
        >
          <InformationCircleIcon className="icon-inside-badge information-circle-icon" />
        </CertificationStatusBadge>
      ) : getLatestUploadedPolicyCertificationStatus(tradeContract) ===
        'CERTIFIED' ? (
        <CertificationStatusBadge
          certificationStatus="Accepted"
          bg="success-light"
          className="text-primary"
          clickCertificationStatusFunction={() =>
            clickCertificationStatus(tradeContract)
          }
        >
          <ShieldExclamationIcon className="icon-inside-badge shield-exclamation-icon" />
        </CertificationStatusBadge>
      ) : getLatestUploadedPolicyCertificationStatus(tradeContract) ===
        'NOT CERTIFIED' ? (
        <CertificationStatusBadge
          certificationStatus="Rejected"
          bg="error-light"
          className="text-error-dark"
          clickCertificationStatusFunction={() =>
            clickCertificationStatus(tradeContract)
          }
        >
          <ExclamationCircleIcon className="icon-inside-badge exclamation-icon" />
        </CertificationStatusBadge>
      ) : null}
    </div>
  )
}

export default styled(ShowBadgeForCertificationStatus)`
  .icon-inside-badge {
    height: 15px;
    margin-left: -20px;
  }

  .information-circle-icon {
    color: #ec8046;
  }

  .exclamation-icon {
    color: #be0202;
  }

  .shield-excalamtion-icon {
    color: #0c5a55;
    margin-left: -20px;
  }
  }
`
