import { Badge } from 'react-bootstrap'
import styled from 'styled-components'

export interface CertificationStatusBadgeProps
  extends React.ComponentProps<any> {
  className?: string
  bg: string
  certificationStatus: string
  children: any
  clickCertificationStatusFunction: any
}

const CertificationStatusBadge: React.FC<CertificationStatusBadgeProps> = ({
  bg,
  className,
  certificationStatus,
  children,
  clickCertificationStatusFunction,
}) => {
  return (
    <div className={className}>
      <Badge
        pill
        bg={bg}
        className={`${className} certification-status ${
          clickCertificationStatusFunction ? 'clickable-certification-status' : ''
        }`}
        onClick={
          clickCertificationStatusFunction
            ? clickCertificationStatusFunction
            : null
        }
      >
        {children && <span className="ps-4 pe-2">{children}</span>}
        {certificationStatus}
      </Badge>
    </div>
  )
}

export default styled(CertificationStatusBadge)`
  .certification-status {
    padding: 10px 15px;
  }
  .clickable-certification-status {
    cursor: pointer;
  }
`
