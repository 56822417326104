import { ArrowLeftIcon } from '@heroicons/react/outline'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export interface NoticesProps extends React.ComponentProps<any> {
  nonProcessingPolicy: any
}
const Notices: React.FC<NoticesProps> = ({ nonProcessingPolicy }) => {
  const navigate = useNavigate()
  const handleBackToPolicyClick = () => {
    navigate(-1)
  }
  return (
    <Container>
      <Row className="mt-3">
        <Col sm={9} className="my-2">
          <span
            className="text-primary-medium fw-bold span-cursor my-2"
            onClick={handleBackToPolicyClick}
            style={{ cursor: 'pointer' }}
          >
            <ArrowLeftIcon className="arrow-left-icon" />
            Back to Policies
          </span>
        </Col>
        <Row className="mt-2 mx-2">
          <Col className="text-start">
            <p className="fw-bold fs-5 my-0 ">
              List Of All Claims For Non Processing Policy
            </p>
          </Col>
        </Row>
        <Row className="mx-2">
          <Col className="text-start">
            <p className="fw-bold my-0">{nonProcessingPolicy.policyNumber}</p>
          </Col>
        </Row>
      </Row>
      <hr></hr>
    </Container>
  )
}

export default Notices
