class AppConfig {
  readonly baseURL: string =
    process.env.REACT_APP_BASE_URL ?? 'http://localhost:3000'
  readonly title = process.env.REACT_APP_PAGE_TITLE ?? 'Resolved Risk'
  readonly isProduction = process.env.REACT_APP_NODE_ENV === 'production'
  readonly isDevelopment = process.env.REACT_APP_NODE_ENV !== 'production'
  readonly version = process.env.REACT_APP_VERSION ?? 'development'
  readonly logEvents = process.env.REACT_APP_LOG_EVENTS ?? false
  readonly apiUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:3000'
  readonly pdfparserUrl =
    process.env.REACT_APP_PDF_PARSER_URL ?? 'http://localhost:5000'
  readonly s3DirName = process.env.REACT_APP_DIR_NAME ?? 'production/'
  itemPerPage = 25
}

export default new AppConfig()
