import { useEffect, useState, useRef } from 'react'
import { Badge, Container, Table, Row, Col } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import Moment from 'moment'
import { DocumentTextIcon, PlusCircleIcon } from '@heroicons/react/solid'
import styled from 'styled-components'
import { handleViewDocumentClick } from '../helper-functions'
import { LIST_FILE_UPLOADS_BY_STATUS_COMPLETED_OR_FAILED } from '../graphql/graphql-queries'
import Pagination from './Pagination Component/pagination'
import config from './../core/config'
import FilterDropdown from './filter-dropdown'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'

type Props = {
  className?: string
}

const FileListing: React.FC<Props> = ({ className }) => {
  let navigate = useNavigate()
  const ref = useRef({ fileUploads: [], count: 1 })
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage] = useState(config.itemPerPage)
  const [count, setCount] = useState(1)
  const [documentsList, setDocumentsList] = useState([])
  const [filteredDocumentsList, setFilteredDocumentsList] = useState([])
  const [filterByStatus, setFilterByStatus] = useState('All')
  const filterBasedOnStatus = ['Complete', 'Pending', 'Failed', 'All']
  const offset = 0
  let paginationDocumentsData = {
    offset: currentPage === 1 ? offset : (currentPage - 1) * 25,
    limit: dataPerPage,
    status:
      filterByStatus === 'All'
        ? null
        : filterByStatus === 'Complete'
        ? 'COMPLETE'
        : filterByStatus === 'Pending'
        ? 'PENDING'
        : 'Failed',
  }
  const { refetch, loading, error } = useQuery(
    LIST_FILE_UPLOADS_BY_STATUS_COMPLETED_OR_FAILED,
    {
      variables: {
        data: paginationDocumentsData,
      },
      onCompleted: (data) => {
        setDocumentsList(
          data?.listFileUploadsByStatusCompleteOrFailed.fileUploads
        )
        ref.current = {
          fileUploads:
            data?.listFileUploadsByStatusCompleteOrFailed.fileUploads,
          count: data?.listFileUploadsByStatusCompleteOrFailed?.count,
        }
        setFilteredDocumentsList(
          data?.listFileUploadsByStatusCompleteOrFailed.fileUploads
        )
        setCount(data?.listFileUploadsByStatusCompleteOrFailed?.count)
      },
    }
  )

  useEffect(() => {
    setCurrentPage(1)
    refetch({
      data: paginationDocumentsData,
    })
    const updatedData = ref.current.fileUploads || []
    setDocumentsList([...updatedData])
    setCount(ref.current.count)
  }, [filterByStatus])
  const perPage = Math.ceil(count / dataPerPage)

  const handleAddPolicyClick = (fileUpload: any) => {
    navigate('/policy-details-form', {
      state: {
        clickedFile: fileUpload,
      },
    })
  }

  if (loading) return <SpinnerLoading />
  if (error) return <ErrorFetchingData />

  return (
    <div className={className}>
      <Container className="mt-3">
        <Row>
          <Col sm={4}>
            <FilterDropdown
              filterByStatus={filterByStatus}
              setFilterByStatus={setFilterByStatus}
              filteringStatusList={filterBasedOnStatus}
              statusText="Parsing Status"
            />
          </Col>
        </Row>
        <Table hover className="bg-white rounded">
          <thead className="text-start">
            <tr className="text-start">
              <th scope="col">S.No.</th>
              <th scope="col">File Name</th>
              <th scope="col">Status</th>
              <th scope="col">Created At</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody className="text-start border-top-0">
            {documentsList?.length ? (
              documentsList.map((fileUpload: any, index: number) => (
                <tr key={fileUpload.id}>
                  <th className="fw-normal">{index + 1}</th>
                  <td>
                    <a
                      className="fw-bold text-decoration-none"
                      href={fileUpload?.documentId}
                    >
                      {fileUpload?.originalName}
                    </a>
                  </td>
                  <td>{fileUpload?.status}</td>
                  <td>{Moment(fileUpload?.createdAt).format('YYYY-MM-DD')}</td>
                  <td>
                    <Badge
                      pill
                      bg="primary-light"
                      className="text-primary-medium badge-document"
                      onClick={() => handleViewDocumentClick(fileUpload?.path)}
                    >
                      <DocumentTextIcon className="check-icon hero-icon-primary pe-1" />
                      View Document
                    </Badge>
                  </td>
                  <td>
                    {fileUpload?.status === 'FAILED' && (
                      <Badge
                        pill
                        bg="primary-light"
                        className="text-primary-medium badge-document"
                        onClick={() => handleAddPolicyClick(fileUpload)}
                      >
                        <PlusCircleIcon className="check-icon hero-icon-primary pe-1" />
                        Add Policy Details
                      </Badge>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td align="center" colSpan={6} className="p-2">
                  No documents found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {documentsList?.length > 0 && (
          <div style={{ marginTop: '45px' }}>
            {' '}
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPage={perPage}
            />
          </div>
        )}
      </Container>
    </div>
  )
}

export default styled(FileListing)``
