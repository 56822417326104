import { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Container, Row, Col, Form } from 'react-bootstrap'
import styled from 'styled-components'
import {
  GET_JOBS_LIST_FOR_DROPDOWN_FOR_BROKER,
  LIST_TRADE_CONTRACTS_ADDED_AGAINST_A_JOB_FOR_SPECIFIED_BROKER,
} from '../graphql/graphql-queries'
import authentication from '../core/authentication'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import ShowBadgeForCertificationStatus from './show-badge-for-certification-status'
import CertificationStatusBadge from './certification-status-badge'
import { getLatestUploadedPolicyCertificationStatus } from '../helper-functions'
import config from '../core/config'
import Pagination from './Pagination Component/pagination'
import SpinnerLoading from './spinner-loading'
import ErrorFetchingData from './error-fetching-data'

export interface BrokerJobsListProps extends React.ComponentProps<any> {
  className?: string
}

const BrokerJobsList: React.FC<BrokerJobsListProps> = ({ className }) => {
  let navigate = useNavigate()

  let loggedInUser = authentication.getUserData()
  let brokerId = loggedInUser?.id

  const [jobsList, setJobsList] = useState([])
  const [selectedJobLocation, setSelectedJobLocation] = useState('')
  const [tradeContractsForSelectedJob, setTradeContractsForSelectedJob] =
    useState<any>([])

  const [count, setCount] = useState(1)
  const dataPerPage = config.itemPerPage
  const [pageNumber, setPageNumber] = useState(1)
  const perPage = Math.ceil(count / dataPerPage)
  const offset = 0

  let paginationDataForTradeContracts = {
    jobId: selectedJobLocation,
    offset: pageNumber === 1 ? offset : (pageNumber - 1) * 25,
    limit: dataPerPage,
  }

  const {
    loading: getJobsListForDropdownLoading,
    error: getJobsListForDropdownError,
  } = useQuery(GET_JOBS_LIST_FOR_DROPDOWN_FOR_BROKER, {
    variables: {
      brokerId: brokerId,
    },
    onCompleted: (data) => {
      setJobsList(data?.listJobsByBrokerId)
    },
  })

  const [
    getTradeContractsForSelectedJob,
    {
      loading: getTradeContractsForSelectedJobLoading,
      error: getTradeContractsForSelectedJobError,
    },
  ] = useLazyQuery(
    LIST_TRADE_CONTRACTS_ADDED_AGAINST_A_JOB_FOR_SPECIFIED_BROKER,
    {
      fetchPolicy: 'cache-and-network',

      onCompleted: (data) => {
        setTradeContractsForSelectedJob(
          data.listTradeContractsForABroker?.TradeContracts
        )
        setCount(data?.listTradeContractsForABroker?.count)
      },
    }
  )

  const handleJobChooseJobLocationClick = (e: any) => {
    setSelectedJobLocation(e.target.value)
  }

  useEffect(() => {
    if (selectedJobLocation) {
      getTradeContractsForSelectedJob({
        variables: { data: paginationDataForTradeContracts },
      })
    }
  }, [selectedJobLocation])

  const handleCertifyInsuranceClick = (clickedTradeContractId: any) => {
    navigate('/certify-insurance', { state: clickedTradeContractId })
  }

  let setCertifyInsuranceAction = (tradeContract: any) => {
    if (
      getLatestUploadedPolicyCertificationStatus(tradeContract) === 'CERTIFIED'
    ) {
      return 'Update Policy'
    } else if (
      getLatestUploadedPolicyCertificationStatus(tradeContract) ===
      'REVIEW PENDING'
    ) {
      return null
    } else {
      return 'Start'
    }
  }

  if (getJobsListForDropdownLoading || getTradeContractsForSelectedJobLoading)
    return <SpinnerLoading />
  if (getJobsListForDropdownError || getTradeContractsForSelectedJobError)
    return <ErrorFetchingData />

  return (
    <div className={className}>
      <Row className="text-start">
        <Col sm={4}>
          <Form>
            <Form.Group>
              <Form.Select
                aria-label="Default select example"
                name="jobTypeForTrade"
                onChange={handleJobChooseJobLocationClick}
                value={selectedJobLocation}
              >
                <option value="">Select a Job</option>
                {jobsList?.map((job: any) => (
                  <option key={job?.id} value={job.id}>
                    {job?.streetAddress}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Col>
      </Row>

      {selectedJobLocation && tradeContractsForSelectedJob?.length > 0 && (
        <Container className="border text-start bg-white my-4">
          <Row className="border-bottom fw-bold py-2">
            <Col sm={2}>Job</Col>
            <Col sm={3}>Company</Col>
            <Col sm={1}>Start Date</Col>
            <Col sm={1}>Claims</Col>
            <Col sm={2}>Status</Col>
            <Col sm={3}>Certify Insurance</Col>
          </Row>
          {!!tradeContractsForSelectedJob &&
            tradeContractsForSelectedJob?.map(
              (tradeContractForSelectedJob: any) => {
                return (
                  <Row
                    className="border-top d-flex align-items-center py-2"
                    key={tradeContractForSelectedJob?.id}
                  >
                    <Col sm={2}>
                      <div>
                        {tradeContractForSelectedJob?.job.streetAddress}
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div>
                        {tradeContractForSelectedJob?.contractedCompany?.name}
                        <div className="text-muted pt-1">
                          <span className="fw-bold">Job Type: </span>
                          {tradeContractForSelectedJob?.jobName}
                        </div>
                      </div>
                      <div>
                        {tradeContractForSelectedJob?.job?.user?.fullName}
                      </div>
                    </Col>
                    <Col sm={1}>
                      <div>
                        {moment(
                          tradeContractForSelectedJob?.job?.startDate
                        ).format('MM/DD/YYYY')}
                      </div>
                    </Col>
                    <Col sm={1}>
                      <span
                        className="span-cursor"
                        onClick={() => {
                          navigate('/claims', {
                            state: {
                              nonProcessingPolicy: '',
                              job: tradeContractForSelectedJob?.job,
                              contractedCompanyId:
                                tradeContractForSelectedJob?.contractedCompany
                                  ?.id,
                            },
                          })
                        }}
                      >
                        {tradeContractForSelectedJob?.contractedCompany?.claims
                          ?.length
                          ? tradeContractForSelectedJob?.contractedCompany
                              ?.claims?.length
                          : 0}
                      </span>
                    </Col>
                    <Col>
                      <ShowBadgeForCertificationStatus
                        tradeContract={tradeContractForSelectedJob}
                      />
                    </Col>
                    {setCertifyInsuranceAction(tradeContractForSelectedJob) !==
                      null && (
                      <Col sm={3}>
                        <CertificationStatusBadge
                          certificationStatus={setCertifyInsuranceAction(
                            tradeContractForSelectedJob
                          )}
                          bg="warning-light"
                          className="text-primary2-med"
                          clickCertificationStatusFunction={() =>
                            handleCertifyInsuranceClick(
                              tradeContractForSelectedJob.id
                            )
                          }
                        />
                      </Col>
                    )}
                  </Row>
                )
              }
            )}
        </Container>
      )}
      {selectedJobLocation && tradeContractsForSelectedJob?.length > 0 && (
        <div style={{ marginTop: '45px' }}>
          <Pagination
            currentPage={pageNumber}
            setCurrentPage={setPageNumber}
            perPage={perPage}
          />
        </div>
      )}
    </div>
  )
}

export default styled(BrokerJobsList)`
  .span-cursor {
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: #0e6c66 !important;
    }
  }

  .claim-button {
    border-radius: 100px !important;
    border: 5px solid #13948Bb !important;
    color: black;
    background-color: #fff;
  }
`
