import { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { PencilIcon } from '@heroicons/react/solid'

import { IClaimsSpecialist, IPolicyholder, ITemplate } from '../interface'
import { CREATE_POLICYHOLDER } from '../graphql/graphql-queries'
import CustomClickableButton from './custom-components/custom-clickable-button'
import InputPolicyReviewAndPublish from './input-policy-review-and-publish'
import CustomAlert from './custom-components/custom-alert'
import styled from 'styled-components'

export interface ReviewAndPublishProps extends React.ComponentProps<any> {
  className: string
  policyholder: IPolicyholder
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  claimsSpecialistsList: IClaimsSpecialist[]
  selectedClaimsSpecialistsList: IClaimsSpecialist[]
  templatesList: ITemplate[]
  selectedTemplatesList: ITemplate[]
  isDataSaved: boolean
  setIsDataSaved: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
}
const ReviewAndPublish: React.FC<ReviewAndPublishProps> = ({
  className,
  policyholder,
  currentStepIndex,
  setCurrentStepIndex,
  templatesList,
  selectedTemplatesList,
  claimsSpecialistsList,
  selectedClaimsSpecialistsList,
  isDataSaved,
  setIsDataSaved,
  setCurrentStep,
}) => {
  let inputPolicies = policyholder.policies

  let updatedPolicies = inputPolicies.map((i) => ({
    ...i,
    claimsSpecialists: claimsSpecialistsList.filter(
      (claimsSpecialists) => claimsSpecialists.id === i.claimsSpecialistId
    ),
    templates: templatesList.filter((template) => template.id === i.templateId),
  }))

  const [policyholderCreatedSuccessAlert, setPolicyholderCreatedSuccessAlert] =
    useState(false)

  const [createPolicyholder] = useMutation(CREATE_POLICYHOLDER, {
    onCompleted: (data) => {
      setPolicyholderCreatedSuccessAlert(true)
      setIsDataSaved(true)
      setCurrentStepIndex(currentStepIndex + 1)
    },
  })

  const handlePublishPolicyholder = () => {
    createPolicyholder({
      variables: {
        data: policyholder,
      },
    })
  }

  const handleSetPolicyholderCreatedSuccessAlert = () =>
    setPolicyholderCreatedSuccessAlert(false)

  const handleEdit = () => {
    setCurrentStep('Policyholder Details')
  }

  return (
    <Container className={`${className} mt-5`}>
      {policyholderCreatedSuccessAlert && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={handleSetPolicyholderCreatedSuccessAlert}
            alertType="success"
            alertMessage="Yay! Policyholder has been successfully published."
          />
        </Row>
      )}
      <div>
        <h4 className="fw-bold mb-4">Review and Publish</h4>

        <div className="d-flex justify-content-start policyholder-details">
          <p className="ms-0 policyholder-details">
            <PencilIcon onClick={handleEdit} className="pencil-icon" />
          </p>
          <h5 className="fw-bold mb-4 ms-2">Policyholder Details</h5>
        </div>

        <div className="mb-4">
          <h6 className="fw-bold">Company Name</h6>
          <p>{policyholder.companyName}</p>
        </div>

        <div className="mb-4">
          <h6 className="fw-bold">Contact Information</h6>
          <p className="m-0">
            {policyholder.userFirstName} {policyholder.userLastName}
          </p>
          <p className="m-0">{policyholder.userEmail}</p>
          <p>{policyholder.userPhoneNumber}</p>
        </div>

        <div className="d-flex justify-content-start">
          <h5 className="fw-bold ms-2">Policy Information</h5>
        </div>
        <InputPolicyReviewAndPublish updatedPolicies={updatedPolicies} />

        <div className="text-end my-4 me-4">
          <CustomClickableButton
            buttonContent="Save"
            handleOnClick={handlePublishPolicyholder}
            disable={false}
          />
        </div>
      </div>
    </Container>
  )
}

export default styled(ReviewAndPublish)`
  .pencil-icon {
    height: 25px;
    color: #13948b;
    cursor: pointer;
  }

  .policyholder-details {
    margin-left: -32px;
  }
`
