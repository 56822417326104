import { Container, Row, Col, Form } from 'react-bootstrap'
import LimitsContainer from './LimitsContainer'
import CustomClickableButton from './custom-components/custom-clickable-button'
import { ITemplate } from '../interface'
import { useForm } from 'react-hook-form'
import ValidationError from './validation-error'

export interface NamesAndLimitsProps extends React.ComponentProps<any> {
  className?: string
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  duplicateTemplate: ITemplate
  setDuplicateTemplate: React.Dispatch<React.SetStateAction<ITemplate>>
}

const NamesAndLimits: React.FC<NamesAndLimitsProps> = ({
  className,
  setCurrentStep,
  currentStepIndex,
  setCurrentStepIndex,
  duplicateTemplate,
  setDuplicateTemplate,
}) => {
  const {
    register,
    handleSubmit: handleNameAndLimitsNextClick,
    formState,
  } = useForm()

  const handleOnChange = (e: any) => {
    setDuplicateTemplate({
      ...duplicateTemplate,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = (data: any) => {
    setCurrentStep('Scope and Duration')
    setCurrentStepIndex(currentStepIndex + 1)
  }

  return (
    <Container className="m-4">
      <Row>
        <Col>
          <Container>
            <Row>
              <h3 className="fw-bold mb-4 text-start pt-3 mb-5">
                Name and Limits
              </h3>
            </Row>
            <Row className="text-start">
              <Form className="text-start">
                <Row className="text-start mb-5">
                  <Col className="text-start" sm={3}>
                    <h4>
                      <Form.Label className="fw-bold">Template Name</Form.Label>
                    </h4>
                    <Form.Control
                      {...register('templateName', {
                        required: {
                          value: duplicateTemplate?.templateName ? false : true,
                          message:
                            'You must specify a template name before moving forward.',
                        },
                      })}
                      onChange={handleOnChange}
                      placeholder="Template Name"
                      value={duplicateTemplate?.templateName}
                      autoComplete="off"
                    />
                    {formState.errors.templateName && (
                      <ValidationError
                        errorMessage={formState.errors.templateName.message}
                      />
                    )}
                  </Col>
                </Row>

                <h4 className="fw-bold">Limits</h4>

                <Row className="mb-3 align-start">
                  <Col className="align-start">
                    <LimitsContainer
                      heading="Each occurence (BI/PD)"
                      rangeName="eachOccurrenceLimit"
                      templateLimitValue={duplicateTemplate.eachOccurrenceLimit}
                      duplicateTemplate={duplicateTemplate}
                      setDuplicateTemplate={setDuplicateTemplate}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col sm={12}>
                    <LimitsContainer
                      heading="General Aggregate"
                      rangeName="aggregateLimit"
                      templateLimitValue={duplicateTemplate.aggregateLimit}
                      duplicateTemplate={duplicateTemplate}
                      setDuplicateTemplate={setDuplicateTemplate}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={12}>
                    <LimitsContainer
                      heading="Personal & Advertising"
                      rangeName="personalAdvertisingLimit"
                      templateLimitValue={
                        duplicateTemplate.personalAdvertisingLimit
                      }
                      duplicateTemplate={duplicateTemplate}
                      setDuplicateTemplate={setDuplicateTemplate}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={12}>
                    <LimitsContainer
                      heading="Products/Completed Operations"
                      rangeName="projectCompletedOperationLimit"
                      templateLimitValue={
                        duplicateTemplate.projectCompletedOperationLimit
                      }
                      duplicateTemplate={duplicateTemplate}
                      setDuplicateTemplate={setDuplicateTemplate}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={12}>
                    <LimitsContainer
                      heading="Deductible/Retention"
                      rangeName="deductible"
                      templateLimitValue={duplicateTemplate.deductible}
                      duplicateTemplate={duplicateTemplate}
                      setDuplicateTemplate={setDuplicateTemplate}
                    />
                  </Col>
                </Row>
                <Row className="text-end">
                  <Col sm={10}>
                    <CustomClickableButton
                      buttonContent="Next"
                      handleOnClick={handleNameAndLimitsNextClick(onSubmit)}
                      disable={false}
                    />
                  </Col>
                </Row>
              </Form>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default NamesAndLimits
