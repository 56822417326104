import { useState } from 'react'
import { Container, Row, Tabs, Tab } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import authentication from '../../core/authentication'
import CompanyDetailsSettings from './company-details-settings'
import SecurityInfoSettings from './security-info-settings'
import UserProfileSettings from './user-profile-settings'
import PermissionsSettings from './permissions-settings'
import { GET_USER_BY_ID } from '../../graphql/graphql-queries'
import CustomAlert from '../custom-components/custom-alert'
import styled from 'styled-components'
import BrokerPermissionsSettings from './broker-permissions-settings'

export interface SettingsProps extends React.ComponentProps<any> {
  className?: string
}

const Settings: React.FC<SettingsProps> = ({ className }) => {
  const [user, setUser] = useState(null)

  const [errorMessage, setErrorMessage] = useState('')

  const [successMessage, setSuccessMessage] = useState('')

  const [cancelClicked, setCancelClicked] = useState(false)

  let loggedInUserData = authentication.getUserData()

  useQuery(GET_USER_BY_ID, {
    variables: {
      userId: loggedInUserData.id,
    },
    onCompleted: (data) => {
      setUser(data.getUserById)
    },
  })

  return (
    <div className={className}>
      <Container className="w-75 bg-white">
        {successMessage && (
          <Row className="flex-d justify-content-end pb-3 me-5">
            <CustomAlert
              handleOnClose={() => setSuccessMessage('')}
              alertType="success"
              alertMessage={successMessage}
            />
          </Row>
        )}
        {errorMessage && (
          <Row className="flex-d justify-content-end pb-3 me-5">
            <CustomAlert
              handleOnClose={() => setErrorMessage('')}
              alertType="error"
              alertMessage={errorMessage}
            />
          </Row>
        )}
        <h5 className="p-4 fw-bold text-primary">Account Settings</h5>
        <Tabs
          defaultActiveKey="userProfileSettings"
          id="uncontrolled-tab-example"
          className="mb-3 d-flex justify-content-center"
        >
          <Tab eventKey="userProfileSettings" title="User Profile">
            <UserProfileSettings
              user={user}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              cancelClicked={cancelClicked}
              setCancelClicked={setCancelClicked}
            />
          </Tab>
          <Tab eventKey="companyDetailsSettings" title="Company Details">
            <CompanyDetailsSettings
              user={user}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              cancelClicked={cancelClicked}
              setCancelClicked={setCancelClicked}
            />
          </Tab>
          <Tab
            eventKey="securityInformationSettings"
            title="Security Information"
          >
            <SecurityInfoSettings
              user={user}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              cancelClicked={cancelClicked}
              setCancelClicked={setCancelClicked}
            />
          </Tab>
          {authentication.isTradeContractor() ? (
            <Tab eventKey="permissionsSettings" title="Permissions">
              <PermissionsSettings
                user={user}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                cancelClicked={cancelClicked}
                setCancelClicked={setCancelClicked}
              />
            </Tab>
          ) : authentication.isBroker() ? (
            <Tab eventKey="permissionsSettings" title="Permissions">
              <BrokerPermissionsSettings user={user} />
            </Tab>
          ) : null}
        </Tabs>
      </Container>
    </div>
  )
}

export default styled(Settings)`
  .nav-tabs .nav-link.active {
    color: #0c5a55;
    font-weight: bolder;
  }
`
