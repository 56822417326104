import { useEffect, useState } from 'react'
import { Row, Col, Spinner, Alert } from 'react-bootstrap'
import axios from 'axios'
import appConfig from '../core/config'
import authentication from '../core/authentication'
import DropzoneComponent from './dropzone-component'
import CustomUnclickableButton from './custom-components/custom-unclickable-button'
import CustomClickableButton from './custom-components/custom-clickable-button'
import { reloadPageAfter2MsDelay } from '../helper-functions'

interface UploadFileToS3Object {
  className?: string
}

const UploadFileToS3: React.FC<UploadFileToS3Object> = ({ className }) => {
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [selectedFileUpdatedDetails, setSelectedFileUpdatedDetails] =
    useState<any>(null)
  const [isFileUploadedinS3, setIsFileUploadedinS3] = useState<boolean>(false)
  const [isPolicyUploadProcessing, setIsPolicyUploadProcessing] =
    useState(false)
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const loggedInUser = authentication.getUserData()

  const handleUpload = async () => {
    setIsUploadButtonDisabled(false)
    setIsPolicyUploadProcessing(true)
    setIsFileUploadedinS3(true)
  }

  const saveFile = async (file: any) => {
    try {
      const token = authentication.getAccessToken()
      await axios({
        method: 'POST',
        url: `${appConfig.apiUrl}/rest/file-upload`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({
          originalName: file.originalFileName,
          assignedName: file.assignedFileName,
          path: file.path,
          loggedInUser: loggedInUser,
          jobId: null,
          tradeContractId: null
        }),
      })
      setIsPolicyUploadProcessing(false)
      setSuccessMessage('File Uploaded Successfully!')
      reloadPageAfter2MsDelay()
    } catch (err) {
      console.error('Error: ', err)
      setIsPolicyUploadProcessing(false)
      setErrorMessage('Some error encountered. Please try again!')
    }
  }

  useEffect(() => {
    if (isFileUploadedinS3 && selectedFileUpdatedDetails) {
      ;(async () => {
        await saveFile(selectedFileUpdatedDetails)
      })()
    }
  }, [isFileUploadedinS3, selectedFileUpdatedDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorMessage) {
      setIsPolicyUploadProcessing(false)
      reloadPageAfter2MsDelay()
    }
  }, [errorMessage])

  return (
    <div>
      <h4 className="p-4">Upload a File</h4>
      <Row className="w-50 m-auto border rounded p-3 d-flex justify-content-center align-items-center">
        <Col sm={9}>
          <DropzoneComponent
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFileUpdatedDetails={selectedFileUpdatedDetails}
            setSelectedFileUpdatedDetails={setSelectedFileUpdatedDetails}
            type=""
            isFileUploadedinS3={isFileUploadedinS3}
            setIsFileUploadedinS3={setIsFileUploadedinS3}
            setIsUploadButtonDisabled={setIsUploadButtonDisabled}
            isFileUploading={isPolicyUploadProcessing}
            docName="policy"
            setErrorMessage={setErrorMessage}
          />
        </Col>
        <Col sm={3}>
          <div className="right-side">
            {isPolicyUploadProcessing ? (
              <CustomUnclickableButton
                buttonContent="Uploading..."
                disabled={true}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </CustomUnclickableButton>
            ) : (
              <CustomClickableButton
                buttonContent="Submit"
                handleOnClick={handleUpload}
                disable={isUploadButtonDisabled}
              />
            )}
          </div>
        </Col>
        {successMessage && (
          <Row className="mt-4">
            <Alert variant="success fw-bold">{successMessage}</Alert>
          </Row>
        )}
        {errorMessage && (
          <Row className="mt-4">
            <Alert variant="danger">{errorMessage}</Alert>
          </Row>
        )}
      </Row>
    </div>
  )
}

export default UploadFileToS3
