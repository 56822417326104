import { TrashIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { formTypesForDropdown } from '../../data'
import {
  IFormInputs,
  IFormsAndScheduledTextInputs,
  IPolicyDetailsValidationEror,
  IScheduledTextInputs,
} from '../../interface'
import ValidationError from '../validation-error'
import ScheduledTextInputs from './scheduled-text-inputs'

export interface FormInputsProps extends React.ComponentProps<any> {
  className?: string
  formAndScheduledTextInputs: IFormsAndScheduledTextInputs
  setFormAndScheduledTextInputs: React.Dispatch<
    React.SetStateAction<IFormsAndScheduledTextInputs>
  >
  tradeContracrtorId?: string
  scheduledTextFields: IScheduledTextInputs
  formInputValues: IFormInputs[]
  setFormInputValues: React.Dispatch<React.SetStateAction<IFormInputs[]>>
  error: IPolicyDetailsValidationEror
}

const FormInputs: React.FC<FormInputsProps> = ({
  className,
  formAndScheduledTextInputs,
  setFormAndScheduledTextInputs,
  scheduledTextFields,
  formInputValues,
  tradeContracrtorId,
  setFormInputValues,
  error,
}) => {
  const [scheduledTextInputValues, setScheduledTextInputValues] = useState<
    IScheduledTextInputs[]
  >([])

  let numTypeInputs = ['pageNumber', 'endorsementNumber']

  const handleFormInputsChange = (event: any, i: number) => {
    let { name, value } = event.target
    let newFormValues: any[] = [...formInputValues]
    if (name === 'formTitle') {
      newFormValues[i]['normalizedFormTitle'] = value.toUpperCase()
    }
    newFormValues[i][name] = numTypeInputs.includes(name)
      ? Number(value)
      : value
    newFormValues[i].documentId = formInputValues[0]?.documentId
    setFormInputValues(newFormValues)
    setFormAndScheduledTextInputs({
      ...formAndScheduledTextInputs,
      forms: newFormValues,
    })
  }
  useEffect(() => {
    if (formAndScheduledTextInputs !== undefined) {
      setFormInputValues(formAndScheduledTextInputs?.forms)
      setScheduledTextInputValues(formAndScheduledTextInputs?.scheduledTexts)
    }
  }, [formAndScheduledTextInputs])
  const handleAddScheduledTextInputFields = (formId: number) => {
    setScheduledTextInputValues([
      ...scheduledTextInputValues,
      { ...scheduledTextFields, formId },
    ])
    setFormAndScheduledTextInputs({
      ...formAndScheduledTextInputs,
      scheduledTexts: [
        ...formAndScheduledTextInputs?.scheduledTexts,
        { ...scheduledTextFields, formId },
      ],
    })
  }

  const removeFormInputFields = (
    formInputIndex: number,
    formInputId: number
  ) => {
    const formInputRows = [...formInputValues]
    formInputRows.splice(formInputIndex, 1)
    setFormInputValues(formInputRows)
    const scheduledTextInputRows = [...scheduledTextInputValues]
    let scheduledTextInputRowsAfterFormInputRowRemoval =
      scheduledTextInputRows.filter(
        (scheduledTextInputRow) => scheduledTextInputRow.formId !== formInputId
      )
    setScheduledTextInputValues(scheduledTextInputRowsAfterFormInputRowRemoval)
    setFormAndScheduledTextInputs({
      ...formAndScheduledTextInputs,
      forms: formInputRows,
      scheduledTexts: scheduledTextInputRowsAfterFormInputRowRemoval,
    })
  }
  return (
    <div className={className}>
      {formInputValues?.map((formInput: any, index: number) => {
        return (
          <div
            className="formScheduledTextContainer mt-5 p-3"
            key={`formInput${formInput?.id}${index}`}
          >
            <p className="fs-5 fw-bold">Form Details</p>
            <Form.Group controlId={`formBasic${formInput?.id}${index}`}>
              <Row className="mb-3">
                <Col sm={2}>
                  <Form.Label>Page Number</Form.Label>
                  <Form.Control
                    name="pageNumber"
                    type="number"
                    min="0"
                    value={formInput?.pageNumber}
                    onChange={(e) => handleFormInputsChange(e, index)}
                    autoComplete="off"
                  ></Form.Control>

                  {error?.forms?.[index]?.pageNumber && (
                    <ValidationError
                      errorMessage={error?.forms?.[index]?.pageNumber}
                    />
                  )}
                </Col>
                <Col>
                  <Form.Label>Form Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="type"
                    onChange={(e) => handleFormInputsChange(e, index)}
                    value={formInput?.type}
                  >
                    <option value="" disabled>
                      Select Form Type
                    </option>
                    {formTypesForDropdown.map((formTypeForDropdown: any) => (
                      <option
                        key={formTypeForDropdown?.id}
                        value={formTypeForDropdown?.type}
                      >
                        {formTypeForDropdown.type}
                      </option>
                    ))}
                  </Form.Select>

                  {error?.forms?.[index]?.type && (
                    <ValidationError
                      errorMessage={error?.forms?.[index]?.type}
                    />
                  )}
                </Col>
                <Col sm={2}>
                  <Form.Label>Form Number</Form.Label>
                  <Form.Control
                    name="formNumber"
                    type="text"
                    value={formInput?.formNumber}
                    onChange={(e) => handleFormInputsChange(e, index)}
                    autoComplete="off"
                  ></Form.Control>

                  {error?.forms?.[index]?.formNumber && (
                    <ValidationError
                      errorMessage={error?.forms?.[index]?.formNumber}
                    />
                  )}
                </Col>
                <Col sm={2}>
                  <Form.Label>Endorsement Number</Form.Label>
                  <Form.Control
                    name="endorsementNumber"
                    type="number"
                    value={formInput?.endorsementNumber}
                    onChange={(e) => handleFormInputsChange(e, index)}
                    autoComplete="off"
                  ></Form.Control>

                  {error?.forms?.[index]?.endorsementNumber && (
                    <ValidationError
                      errorMessage={error?.forms?.[index]?.endorsementNumber}
                    />
                  )}
                </Col>
                <Col>
                  <Form.Label>Form Title</Form.Label>
                  <Form.Control
                    name="formTitle"
                    type="text"
                    value={formInput?.formTitle}
                    onChange={(e) => handleFormInputsChange(e, index)}
                    autoComplete="off"
                  ></Form.Control>

                  {error?.forms?.[index]?.formTitle && (
                    <ValidationError
                      errorMessage={error?.forms?.[index]?.formTitle}
                    />
                  )}
                </Col>
                <Col>
                  {!tradeContracrtorId && (
                    <TrashIcon
                      className="react-icon"
                      style={{
                        marginTop: '33px',
                      }}
                      onClick={() =>
                        removeFormInputFields(index, formInput?.id)
                      }
                    />
                  )}
                </Col>
              </Row>
            </Form.Group>
            {scheduledTextInputValues.find(
              (el: any) => el.formId === formInput?.id
            ) && <p className="fs-5 fw-bold text-primary">Scheduled Texts</p>}
            {scheduledTextInputValues?.map(
              (scheduledText: any, scheduledTextIndex: number) => {
                if (formInput?.id === scheduledText?.formId) {
                  return (
                    <ScheduledTextInputs
                      key={`scheduledTextInput${scheduledText?.id}${scheduledTextIndex}`}
                      formAndScheduledTextInputs={formAndScheduledTextInputs}
                      setFormAndScheduledTextInputs={
                        setFormAndScheduledTextInputs
                      }
                      scheduledTextFields={scheduledTextFields}
                      formIndex={index}
                      formInputValues={formInputValues}
                      scheduledTextInputValues={scheduledTextInputValues}
                      setScheduledTextInputValues={setScheduledTextInputValues}
                      scheduledTextIndex={scheduledTextIndex}
                      keyValue={`${formInput?.id}${scheduledTextIndex}`}
                      error={error}
                      tradeContracrtorId={tradeContracrtorId}
                    />
                  )
                }
              }
            )}
            <div className="mb-3">
              {!tradeContracrtorId && (
                <span
                  className="button add text-primary-medium fw-bold add-cursor"
                  onClick={() =>
                    handleAddScheduledTextInputFields(formInput?.id)
                  }
                >
                  <span className="pe-1">+</span>
                  <span>Add scheduled text for this form</span>
                </span>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default styled(FormInputs)`
  .formScheduledTextContainer {
    background: #f7f5f4;
  }
`
