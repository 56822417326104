import { Container } from 'react-bootstrap'
import AddTradesImage from '../assets/images/add-trades.png'
import CustomClickableButton from './custom-components/custom-clickable-button'

export interface NoTradesAddedProps extends React.ComponentProps<any> {
  className?: string
  handleAddTradeClick: () => void
}

const NoTradesAdded: React.FC<NoTradesAddedProps> = ({
  handleAddTradeClick,
}) => {
  return (
    <Container className="text-center bg-white">
      <div className="py-5">
        {
          <img
            className="policy-verification-failed"
            src={AddTradesImage}
            height={300}
            width={400}
            alt="Add Trade"
          />
        }
        <p className="fs-5 fw-bold">You haven’t added trades yet</p>
        <CustomClickableButton
          buttonContent="Add Trade"
          handleOnClick={handleAddTradeClick}
          disable={false}
        />
      </div>
    </Container>
  )
}

export default NoTradesAdded
