import React from 'react'
import {
  Routes,
  Route,
  Outlet,
  BrowserRouter,
  Navigate,
} from 'react-router-dom'
import { client } from './ApolloClient/client'
import { ApolloProvider } from '@apollo/client'

import './App.css'
import {
  NavigationBar,
  FileListing,
  DocumentDetails,
  FileUpload,
} from './components'
import MasterFormListPageComponent from './components/master-form/master-form-list-page-component'
import Templates from './components/templates'
import PolicyHoldersComponent from './components/policy-holders-component'
import CertifyInsurance from './components/certify-insurance'
import CreatePolicyholder from './components/createPolicyholder'
import CreateTemplate from './components/create-new-template'
import CreateNewJob from './components/create-new-job'

import ClientListing from './components/client-list'
import ClientCompanyDetail from './components/client-company-detail'
import ClientCompanyPolicyDetail from './components/client-policy-listing'
import ClientPolicyDetails from './components/client-policy-details'
import Jobs from './components/jobs'
import CertifyPolicy from './components/certify-policy'
import JobTypeListing from './components/job-type-listing'
import AddTrade from './components/add-trade'
import Trades from './components/trades'
import SignIn from './components/sign-in'
import SignUp from './components/sign-up'
import TradeContractorForm from './components/trade-contractor-form'
import styled from 'styled-components'
import '../src/index.css'
import ClaimAttachments from './components/claim-attachments'
import Settings from './components/settings-component/settings'
import PolicyVerificationFailed from './components/policy-verification-failed'
import PrivateRoutes from './components/private-routes'
import PolicyDetailsForm from './components/policy-details-form/policy-details-form'
import PolicyVerificationSuccessful from './components/policy-verification-successful'
import ClaimNoticeList from './components/claim-notice-list'
import NotFound from './components/not-found'
import TradesListingAdmin from './components/trades-listing-admin.tsx'
import authentication from './core/authentication'
import Login from './components/login/login'
import ClaimNoticeListingAdmin from './components/claims-notice-listing-admin'
import LabellingComponent from './components/master-form/labelling-component'
import ReviewPolicyDetail from './components/review-policy-detail'
import CustomErrorNotification from './components/custom-components/custom-error-notification'

const NavbarLayout = () => (
  <>
    <NavigationBar />
    <Outlet />
  </>
)

const App: React.FC = () => {
  let auth = authentication.getAccessToken()

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <div className="App font-face-proxima">
          <Routes>
            <Route
              path="/login"
              element={auth ? <Navigate to="/" /> : <SignIn />}
            />
            <Route
              path="/session-expired"
              element={<CustomErrorNotification />}
            />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/sign-up-trade-contractor" element={<SignUp />} />
            <Route path="/sign-up-broker" element={<SignUp />} />
            <Route path="/sign-up-client" element={<SignUp />} />
            <Route path="/claim-attachments" element={<ClaimAttachments />} />
            <Route path="/reset-password" element={<SignUp />} />
            <Route element={<NavbarLayout />}>
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/"
                  element={
                    authentication.isSuperAdmin() ? (
                      <MasterFormListPageComponent />
                    ) : (
                      <Jobs />
                    )
                  }
                />
                <Route path="/jobs" element={<Jobs />} />
                <Route
                  path="/start-labelling/:formId"
                  element={<LabellingComponent />}
                />
                <Route
                  path="/start-labelling/label-all-form"
                  element={<LabellingComponent />}
                />
                <Route
                  path="/create-policyholder"
                  element={<CreatePolicyholder />}
                />
                <Route
                  path="/start-labelling"
                  element={
                    authentication.isSuperAdmin() ? (
                      <MasterFormListPageComponent />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/document/list"
                  element={
                    authentication.isSuperAdmin() ? (
                      <FileListing />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/job-type-listing"
                  element={
                    authentication.isSuperAdmin() ? (
                      <JobTypeListing />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/document/:docId"
                  element={
                    authentication.isSuperAdmin() ? (
                      <DocumentDetails />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/start-labelling"
                  element={
                    authentication.isSuperAdmin() ? (
                      <MasterFormListPageComponent />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/client-listing"
                  element={
                    authentication.isSuperAdmin() ? (
                      <ClientListing />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/client-details/:clientId"
                  element={
                    authentication.isSuperAdmin() ? (
                      <ClientCompanyDetail />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/policy-listing"
                  element={
                    authentication.isSuperAdmin() ? (
                      <ClientCompanyPolicyDetail />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/client-policy-details"
                  element={
                    authentication.isSuperAdmin() ? (
                      <ClientPolicyDetails />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/templates"
                  element={
                    authentication.isSuperAdmin() ? <Templates /> : <NotFound />
                  }
                />

                <Route
                  path="/policies"
                  element={
                    authentication.isSuperAdmin() ? (
                      <PolicyHoldersComponent />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/create-policyholder"
                  element={
                    authentication.isSuperAdmin() ? (
                      <CreatePolicyholder />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/certify-insurance"
                  element={
                    authentication.isTradeContractor() ||
                    authentication.isBroker() ? (
                      <CertifyInsurance />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/create-template"
                  element={
                    authentication.isSuperAdmin() ? (
                      <CreateTemplate />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/edit-template"
                  element={
                    authentication.isSuperAdmin() ? (
                      <CreateTemplate />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/create-job"
                  element={
                    authentication.isSuperAdmin() ||
                    authentication.isClient() ? (
                      <CreateNewJob />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="/update-job"
                  element={
                    authentication.isSuperAdmin() ||
                    authentication.isClient() ? (
                      <CreateNewJob />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route path="/certify-policy" element={<CertifyPolicy />} />

                <Route
                  path="/jobs"
                  element={
                    (authentication.isSuperAdmin() ||
                      authentication.isClient() ||
                      authentication.isTradeContractor()) && <Jobs />
                  }
                />
                <Route path="/claims" element={<ClaimNoticeList />} />
                <Route
                  path="/claims-list"
                  element={<ClaimNoticeListingAdmin />}
                />

                <Route
                  path="/jobs/:policyId"
                  element={
                    (authentication.isSuperAdmin() ||
                      authentication.isClient() ||
                      authentication.isTradeContractor()) && <Jobs />
                  }
                />

                <Route
                  path="/add-trade"
                  element={
                    authentication.isSuperAdmin() ||
                    authentication.isClient() ? (
                      <AddTrade />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/trades-list-for-a-job"
                  element={
                    authentication.isSuperAdmin() ||
                    authentication.isClient() ? (
                      <Trades />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/trades-list-for-all-jobs"
                  element={
                    authentication.isSuperAdmin() ||
                    authentication.isClient() ? (
                      <TradesListingAdmin />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/trade-contractor-details"
                  element={
                    authentication.isTradeContractor() ? (
                      <TradeContractorForm />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="/update-trade-contractor"
                  element={
                    authentication.isClient() ||
                    authentication.isSuperAdmin() ? (
                      <TradeContractorForm />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route path="/settings" element={<Settings />} />

                <Route
                  path="/issue-report"
                  element={
                    (authentication.isTradeContractor() ||
                      authentication.isSuperAdmin() ||
                      authentication.isBroker()) && <PolicyVerificationFailed />
                  }
                />

                {/* <Route
                  path="/policy-details-form"
                  element={
                    (authentication.isSuperAdmin() || authentication.isClient()) && (
                      <PolicyDetailsForm />
                    )
                  }
                /> */}

                <Route
                  path="/policy-details-form"
                  element={<PolicyDetailsForm />}
                />
                <Route
                  path="/review-policy/:id"
                  element={<ReviewPolicyDetail />}
                />

                <Route
                  path="/certificate"
                  element={
                    (authentication.isTradeContractor() ||
                      authentication.isSuperAdmin() ||
                      authentication.isBroker()) && (
                      <PolicyVerificationSuccessful />
                    )
                  }
                />
              </Route>

              <Route
                path="/file-upload"
                element={
                  authentication.isSuperAdmin() ? <FileUpload /> : <NotFound />
                }
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default styled(App)``
