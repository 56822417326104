import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { GET_ATTACHMENT_DATA } from '../graphql/graphql-queries'
interface ClaimAttachmentsProps extends React.ComponentProps<any> {
  className?: string
}
const ClaimAttachments: React.FC<ClaimAttachmentsProps> = ({ className }) => {
  const params = new URLSearchParams(window.location.search)
  const claim_id = params.get('claim_id')
  const [attachmentData, setAttachmentData] = useState([])

  useQuery(GET_ATTACHMENT_DATA, {
    variables: { claimId: claim_id },
    onCompleted: (data) => {
      setAttachmentData(data.getAttachments)
    },
  })

  return (
    <div className={`${className} mt-5`}>
      <Container className="mt-5">
        {attachmentData?.length ? (
          <div className="client-list shadow-none p-3 mb-5 bg-white rounded">
            {attachmentData.map((attachment: any, index: number) => {
              return (
                <Row
                  key={index + attachment.id}
                  className=" fw-bold  border-light pb-2"
                >
                  <Col sm={6} className="">
                    {attachment.originalName}
                  </Col>
                  <Col sm={4}>
                    <a href={attachment.path} download>
                      Download
                    </a>
                  </Col>
                </Row>
              )
            })}
          </div>
        ) : (
          <div className="text-center">No Attachments Added for this claim</div>
        )}
      </Container>
    </div>
  )
}
export default styled(ClaimAttachments)`
  .client-list {
    border: 1px solid;
  }
`
