import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { UPDATE_USER_PASSWORD } from '../../graphql/graphql-queries'
import CustomClickableButton from '../custom-components/custom-clickable-button'

import ValidationError from '../validation-error'

export interface SecurityInfoSettingsProps extends React.ComponentProps<any> {
  className?: string
  user: any
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  cancelClicked: boolean
  setCancelClicked: React.Dispatch<React.SetStateAction<boolean>>
}

const SecurityInfoSettings: React.FC<SecurityInfoSettingsProps> = ({
  className,
  user,
  setSuccessMessage,
  setErrorMessage,
  cancelClicked,
  setCancelClicked,
}) => {
  const {
    register,
    handleSubmit: securityInfoUpdationHandler,
    formState,
    watch,
  } = useForm()
  const onError = (errors: any, e: any) => console.log(errors, e)

  const [userPasswordDetails, setUserPasswordDetails] = useState<any>({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  const handleOnChangePassword = (e: any) => {
    setUserPasswordDetails({
      ...userPasswordDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleSaveChangesClick = (e: any) => {
    updateUserPassword({
      variables: {
        userId: user?.id,
        data: {
          currentPassword: userPasswordDetails?.currentPassword,
          newPassword: userPasswordDetails?.newPassword,
        },
      },
    })
  }

  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD, {
    onCompleted: (data) => {
      setSuccessMessage('Password successfully updated')
    },
    onError(err) {
      if (
        err.message ===
          'Your current password does not the match the password you provided.' ||
        err.message ===
          'Your new password cannot be the same as your current password.'
      ) {
        setErrorMessage(err.message)
      } else {
        setErrorMessage('Some error encountered. Please try again!')
      }
    },
  })

  useEffect(() => {
    setUserPasswordDetails({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    })
    setCancelClicked(false)
  }, [cancelClicked]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className={`${className} p-5`}>
      <Form className="text-start">
        <p className="fs-5 fw-bold text-primary">Change Password</p>
        <p className="text-muted">
          Your password must be atleast 8 characters long.
        </p>
        <Row className="w-50">
          <Form.Group className="mb-3" controlId="formBasicCurrentPassword">
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              {...register('currentPassword', {
                required: {
                  value: true,
                  message: 'You must specify a Password before moving forward.',
                },
              })}
              type="password"
              value={userPasswordDetails.currentPassword}
              onChange={handleOnChangePassword}
            />
            {formState.errors.currentPassword && (
              <ValidationError
                errorMessage={formState.errors.currentPassword.message}
              />
            )}
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicNewPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                {...register('newPassword', {
                  required: {
                    value: true,
                    message:
                      'You must specify a New Password before moving forward.',
                  },
                  validate: (val: string) => {
                    if (watch('newPassword').length < 8) {
                      return 'Password must be at least 8 characters long!'
                    }
                  },
                })}
                type="password"
                value={userPasswordDetails.newPassword}
                onChange={handleOnChangePassword}
              />
              {formState.errors.newPassword && (
                <ValidationError
                  errorMessage={formState.errors.newPassword.message}
                />
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className="mb-3"
              controlId="formBasicConfirmNewPassword"
            >
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                {...register('confirmNewPassword', {
                  required: {
                    value: true,
                    message: 'You must confirm password before moving forward.',
                  },
                  validate: (val: string) => {
                    if (watch('newPassword') !== val) {
                      return 'Your passwords do no match'
                    }
                  },
                })}
                type="password"
                value={userPasswordDetails.confirmNewPassword}
                onChange={handleOnChangePassword}
              />
              {formState.errors.confirmNewPassword && (
                <ValidationError
                  errorMessage={formState.errors.confirmNewPassword.message}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4 d-flex d-flex justify-content-center">
          <Col sm="auto" className="text-primary me-2">
            <CustomClickableButton
              buttonContent="Change Password"
              handleOnClick={securityInfoUpdationHandler(
                handleSaveChangesClick,
                onError
              )}
              disable={false}
            />
          </Col>
          <Col sm={2}>
            <Button
              variant="outline-primary-medium rounded-pill fw-bold"
              className="outline-button"
              onClick={() => setCancelClicked(true)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default styled(SecurityInfoSettings)`
`
