import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Row, Col, Badge, Spinner, Container } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { DocumentTextIcon } from '@heroicons/react/solid'
import styled from 'styled-components'
import { redirectDelayInMilliseconds } from '../../data'
import {
  CREATE_POLICY_FROM_JSON,
  LIST_FILE_UPLOADS_BY_STATUS_COMPLETED_OR_FAILED,
  UPDATE_NOTIFICATIONS,
  NOTIFICATIONS_LIST,
  UPDATE_POLICY_FROM_JSON,
  POLICY_DETAILS_BY_TRADE_CONTRACT_ID,
  DOCUMENT_BY_DOC_ID,
  FORM_DETAILS,
} from '../../graphql/graphql-queries'
import {
  IDocumentInputs,
  IFileUploadInputs,
  IFormInputs,
  IFormsAndScheduledTextInputs,
  IPolicyDetailsValidationEror,
  IPolicyInputs,
  IScheduledTextInputs,
} from '../../interface'
import CustomClickableButton from '../custom-components/custom-clickable-button'
import DocumentInputs from './document-inputs'
import FormsAndScheduledTextsInputs from './forms-and-scheduled-texts-inputs'
import PolicyInputs from './policy-inputs'
import {
  handleViewDocumentClick,
  validatePolicyPeriodStartAndEndDate,
} from '../../helper-functions'
import CustomAlert from '../custom-components/custom-alert'
import CustomUnclickableButton from '../custom-components/custom-unclickable-button'

export interface PolicyDetailsFormProps extends React.ComponentProps<any> {
  className?: string
  setCurrentStep?: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex?: number
  setCurrentStepIndex?: React.Dispatch<React.SetStateAction<number>>
  tradeContractForCertifyInsurance?: any
  policyDetails?: any
  documentDetails?: any
  formsAndScheduledTextsForMannualEdit?: IFormsAndScheduledTextInputs
  tradeContracrtorId?: string
}

const PolicyDetailsForm: React.FC<PolicyDetailsFormProps> = ({
  className,
  policyDetails,
  documentDetails,
  formsAndScheduledTextsForMannualEdit,
  tradeContracrtorId,
}) => {
  const {
    state: { clickedFile },
  }: any = useLocation()
  let navigate = useNavigate()

  const [isPolicyUploadProcessing, setIsPolicyUploadProcessing] =
    useState(false)
  const [documentName, setDocumentName] = useState<string>('')
  const [isCreatePolicySuccess, setIsCreatePolicySuccess] =
    useState<boolean>(false)
  const [errorNotification, setErrorNotification] = useState('')
  const [isCreatePolicyError, setIsCreatePolicyError] = useState<boolean>(false)

  const fileUpload: IFileUploadInputs = {
    id: '1',
    documentId: null,
    originalName: '',
    assignedName: '',
    path: '',
    status: 'PENDING',
  }

  let document: IDocumentInputs = {
    id: null, // parserId
    name: '',
    totalPages: '',
    type: '',
  }

  let policy: IPolicyInputs = {
    claimNotificationAddress: '',
    claimNotificationPersonName: '',
    claimNotificationPersonDesignation: '',
    claimNotificationPhone: '',
    claimNotificationFax: '',
    claimNotificationEmail: '',
    insurerCompany: '',
    insuredName: '',
    policyPeriod: '',
    policyNumber: '',
    carrierName: '',
    limitEachOccurance: '',
    generalAggregateLimit: '',
    deductible: '',
    projectCompletedOperationLimit: '',
    personalAdvertisingLimit: '',
    selfInsuredRetention: '',
    minimumPremium: '',
    documentId: null,
    policyPeriodStartDate: '',
    policyPeriodEndDate: '',
  }

  const formFields: IFormInputs = {
    id: '1',
    documentId: null,
    pageNumber: '',
    type: '',
    formNumber: '',
    endorsementNumber: '',
    isDeleted: false,
    deletedBy: 1,
    formTitle: '',
    normalizedFormTitle: '',
  }

  const scheduledTextFields: IScheduledTextInputs = {
    documentId: null,
    formId: '',
    textName: '',
    textValue: '',
    id: '',
  }
  const formsAndScheduledTexts: IFormsAndScheduledTextInputs = {
    forms: [formFields],
    scheduledTexts: [],
  }
  const [fileUploadInputs, setFileUploadInputs] = useState(fileUpload)
  const [documentInputs, setDocumentInputs] = useState(document)
  const [policyInputs, setPolicyInputs] = useState(policy)
  const [formAndScheduledTextInputs, setFormAndScheduledTextInputs] = useState(
    formsAndScheduledTexts
  )
  const [policyPeriodStartDate, setPolicyPeriodStartDate] = useState<string>(
    policyInputs?.policyPeriodStartDate || ''
  )
  const [policyPeriodEndDate, setPolicyPeriodEndDate] = useState<string>(
    policyInputs?.policyPeriodEndDate || ''
  )

  useEffect(() => {
    if (formsAndScheduledTextsForMannualEdit) {
      setFormAndScheduledTextInputs({ ...formsAndScheduledTextsForMannualEdit })
    }
    if (policyDetails) {
      let policy = {
        id: policyDetails.id,
        claimNotificationAddress: policyDetails.claimNotificationAddress,
        claimNotificationPersonName: policyDetails.claimNotificationPersonName,
        claimNotificationPersonDesignation:
          policyDetails.claimNotificationPersonDesignation,
        claimNotificationPhone: policyDetails.claimNotificationPhone,
        claimNotificationFax: policyDetails.claimNotificationFax,
        claimNotificationEmail: policyDetails.claimNotificationEmail,
        insurerCompany: policyDetails.insurerCompany,
        insuredName: policyDetails.insuredName,
        policyPeriod: policyDetails.policyPeriod,
        policyNumber: policyDetails.policyNumber,
        carrierName: policyDetails.carrierName,
        limitEachOccurance: policyDetails.limitEachOccurance,
        generalAggregateLimit: policyDetails.generalAggregateLimit,
        deductible: policyDetails.deductible,
        projectCompletedOperationLimit:
          policyDetails.projectCompletedOperationLimit,
        personalAdvertisingLimit: policyDetails.personalAdvertisingLimit,
        selfInsuredRetention: policyDetails.selfInsuredRetention,
        minimumPremium: policyDetails.minimumPremium,
        documentId: policyDetails.documentId,
        policyPeriodStartDate: policyDetails.policyPeriodStartDate,
        policyPeriodEndDate: policyDetails.policyPeriodEndDate,
      }
      setPolicyInputs({ ...policy })
      let document = {
        id: documentDetails?.id,
        name: documentDetails?.name,
        totalPages: documentDetails?.totalPages,
        type: documentDetails?.type,
      }
      setDocumentInputs({ ...document })
    }
  }, [formsAndScheduledTextsForMannualEdit])

  const [error, setError] = useState<IPolicyDetailsValidationEror>({
    document: {
      totalPages: '',
      type: '',
    },
    policy: {
      claimNotificationAddress: '',
      claimNotificationPersonName: '',
      claimNotificationPersonDesignation: '',
      claimNotificationEmail: '',
      insurerCompany: '',
      insuredName: '',
      policyPeriod: '',
      policyPeriodStartDate: '',
      policyPeriodEndDate: '',
      policyNumber: '',
      carrierName: '',
      limitEachOccurance: '',
      generalAggregateLimit: '',
      deductible: '',
      projectCompletedOperationLimit: '',
      personalAdvertisingLimit: '',
      selfInsuredRetention: '',
      minimumPremium: '',
    },
    forms: formAndScheduledTextInputs?.forms?.map((form: any) => ({
      pageNumber: '',
      type: '',
      formNumber: '',
      endorsementNumber: '',
      formTitle: '',
    })),
    scheduledTexts: formAndScheduledTextInputs?.scheduledTexts?.map(
      (scheduledText: any) => ({
        textName: '',
        textValue: '',
      })
    ),
  })

  let policyDetailsInputs = {
    fileUpload: tradeContracrtorId
      ? documentDetails?.fileUpload
      : fileUploadInputs,
    document: documentInputs,
    policy: policyInputs,
    formsAndScheduledTexts: formAndScheduledTextInputs,
  }

  useEffect(() => {
    setDocumentInputs({
      ...documentInputs,
      name: documentName,
    })
  }, [documentName])

  const markPolicyUploadNotificationIsReadTrueAndRedirect = async (
    notificationType: string,
    redirectPath: string,
    id?: string
  ) => {
    await updateNotifications({
      variables: {
        notificationType,
        id,
      },
    })
    setTimeout(() => navigate(redirectPath), redirectDelayInMilliseconds)
  }

  const [updateNotifications] = useMutation(UPDATE_NOTIFICATIONS, {
    refetchQueries: [
      { query: NOTIFICATIONS_LIST },
      { query: LIST_FILE_UPLOADS_BY_STATUS_COMPLETED_OR_FAILED },
    ],
  })

  const [createPolicyFromJson, { loading }] = useMutation(
    CREATE_POLICY_FROM_JSON,
    {
      onCompleted: (data) => {
        setIsCreatePolicySuccess(true)
        markPolicyUploadNotificationIsReadTrueAndRedirect(
          'Policy Upload',
          '/document/list',
          clickedFile?.id
        )
      },
      onError: (err) => {
        console.error('Error: ', err)
        setIsCreatePolicyError(true)
      },
    }
  )
  const [updatePolicyFromJson] = useMutation(UPDATE_POLICY_FROM_JSON, {
    onCompleted: (data) => {
      setIsCreatePolicySuccess(true)
      setTimeout(() => {
        navigate(-1)
      }, redirectDelayInMilliseconds)
    },
    refetchQueries: [
      { query: DOCUMENT_BY_DOC_ID, variables: { docId: documentDetails?.id } },
      { query: FORM_DETAILS, variables: { docId: documentDetails?.id } },
    ],
    onError: (err) => {
      console.error('Error: ', err)
      setIsCreatePolicyError(true)
    },
  })
  const validateForm = () => {
    let formsErrorData: any[] = []
    formAndScheduledTextInputs?.forms?.forEach((form: any) => {
      formsErrorData.push({
        pageNumber: '',
        type: '',
        formNumber: '',
        endorsementNumber: '',
        formTitle: '',
      })
    })
    let scheduledTextsErrorData: any[] = []
    formAndScheduledTextInputs?.scheduledTexts?.forEach(
      (scheduledText: any) => {
        scheduledTextsErrorData.push({
          textName: '',
          textValue: '',
        })
      }
    )

    let errors = {
      document: {
        totalPages: '',
        type: '',
      },
      policy: {
        claimNotificationAddress: '',
        claimNotificationPersonName: '',
        claimNotificationPersonDesignation: '',
        claimNotificationEmail: '',
        insurerCompany: '',
        insuredName: '',
        policyPeriod: '',
        policyPeriodStartDate: '',
        policyPeriodEndDate: '',
        policyNumber: '',
        carrierName: '',
        limitEachOccurance: '',
        generalAggregateLimit: '',
        deductible: '',
        projectCompletedOperationLimit: '',
        personalAdvertisingLimit: '',
        selfInsuredRetention: '',
        minimumPremium: '',
      },
      forms: formsErrorData,
      scheduledTexts: scheduledTextsErrorData,
    }

    errors = {
      ...errors,
      document: {
        totalPages: validateDocument(
          policyDetailsInputs?.document,
          'totalPages'
        ),
        type: validateDocument(policyDetailsInputs?.document, 'type'),
      },
      policy: {
        claimNotificationAddress: validatePolicy(
          policyDetailsInputs?.policy,
          'claimNotificationAddress'
        ),
        claimNotificationPersonName: validatePolicy(
          policyDetailsInputs?.policy,
          'claimNotificationPersonName'
        ),
        claimNotificationPersonDesignation: validatePolicy(
          policyDetailsInputs?.policy,
          'claimNotificationPersonDesignation'
        ),
        claimNotificationEmail: validatePolicy(
          policyDetailsInputs?.policy,
          'claimNotificationEmail'
        ),
        insurerCompany: validatePolicy(
          policyDetailsInputs?.policy,
          'insurerCompany'
        ),
        insuredName: validatePolicy(policyDetailsInputs?.policy, 'insuredName'),
        policyPeriod: validatePolicy(
          policyDetailsInputs?.policy,
          'policyPeriod'
        ),
        policyPeriodStartDate: validatePolicyPeriodStartOrEndDate(
          policyPeriodStartDate,
          'policyPeriodStartDate'
        ),
        policyPeriodEndDate: validatePolicyPeriodStartOrEndDate(
          policyPeriodEndDate,
          'policyPeriodEndDate'
        ),
        policyNumber: validatePolicy(
          policyDetailsInputs?.policy,
          'policyNumber'
        ),
        carrierName: validatePolicy(policyDetailsInputs?.policy, 'carrierName'),
        limitEachOccurance: validatePolicy(
          policyDetailsInputs?.policy,
          'carrierName'
        ),
        generalAggregateLimit: validatePolicy(
          policyDetailsInputs?.policy,
          'generalAggregateLimit'
        ),
        deductible: validatePolicy(policyDetailsInputs?.policy, 'deductible'),
        projectCompletedOperationLimit: validatePolicy(
          policyDetailsInputs?.policy,
          'projectCompletedOperationLimit'
        ),
        personalAdvertisingLimit: validatePolicy(
          policyDetailsInputs?.policy,
          'personalAdvertisingLimit'
        ),
        selfInsuredRetention: validatePolicy(
          policyDetailsInputs?.policy,
          'selfInsuredRetention'
        ),
        minimumPremium: validatePolicy(
          policyDetailsInputs?.policy,
          'minimumPremium'
        ),
      },
      forms: formAndScheduledTextInputs?.forms?.map((form: any) => ({
        pageNumber: validateFormInput(form, 'pageNumber'),
        type: validateFormInput(form, 'type'),
        formNumber: validateFormInput(form, 'formNumber'),
        endorsementNumber: validateFormInput(form, 'endorsementNumber'),
        formTitle: validateFormInput(form, 'formTitle'),
      })),
      scheduledTexts: formAndScheduledTextInputs?.scheduledTexts?.map(
        (scheduledText: any) => ({
          textName: validateScheduledTextInput(scheduledText, 'textName'),
          textValue: validateScheduledTextInput(scheduledText, 'textValue'),
        })
      ),
    }
    return errors
  }

  const validateDocument = (document: any, keyName: string) => {
    let validateKeyCheck =
      document[keyName] === '' || document[keyName] === null
    if (keyName === 'totalPages' && validateKeyCheck) {
      return 'Total Pages is required'
    } else if (keyName === 'type' && validateKeyCheck) {
      return 'Type is required'
    }
    return ''
  }

  const validatePolicy = (policy: any, keyName: string) => {
    let validateKeyCheck = policy[keyName] === '' || policy[keyName] === null
    if (keyName === 'claimNotificationAddress' && validateKeyCheck) {
      return 'Claim Notification Address is required'
    } else if (keyName === 'claimNotificationPersonName' && validateKeyCheck) {
      return 'Claim Notification Person Name is required'
    } else if (
      keyName === 'claimNotificationPersonDesignation' &&
      validateKeyCheck
    ) {
      return 'Claim Notification Person Degination is required'
    } else if (keyName === 'claimNotificationEmail') {
      if (policy.claimNotificationEmail === '') {
        return 'Claim Notification Email is required'
      } else {
        let regex = new RegExp(
          "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
        )
        if (!regex.test(policy.claimNotificationEmail)) {
          return 'You need to specify a valid email address.'
        }
      }
    } else if (keyName === 'insurerCompany' && validateKeyCheck) {
      return 'Insurer Company is required'
    } else if (keyName === 'insuredName' && validateKeyCheck) {
      return 'Insured Name is required'
    } else if (keyName === 'policyNumber' && validateKeyCheck) {
      return 'Policy Number is required'
    } else if (keyName === 'carrierName' && validateKeyCheck) {
      return 'Carrier Name is required'
    } else if (keyName === 'limitEachOccurance' && validateKeyCheck) {
      return 'Limit Each Occurance is required'
    } else if (keyName === 'generalAggregateLimit' && validateKeyCheck) {
      return 'General Aggregate Limit is required'
    } else if (
      keyName === 'projectCompletedOperationLimit' &&
      validateKeyCheck
    ) {
      return 'projectCompletedOperationLimit is required'
    } else if (keyName === 'personalAdvertisingLimit' && validateKeyCheck) {
      return 'personalAdvertisingLimit is required'
    } else if (keyName === 'deductible' && validateKeyCheck) {
      return 'Deductible is required'
    } else if (keyName === 'selfInsuredRetention' && validateKeyCheck) {
      return 'Self Insured Retention is required'
    } else if (keyName === 'minimumPremium' && policy.minimumPremium === '') {
      return 'Minimum Premium is required'
    }
    return ''
  }

  const validatePolicyPeriodStartOrEndDate = (
    policyPeriodStartOrEndDate: any,
    keyName: string
  ) => {
    if (keyName === 'policyPeriodStartDate') {
      if (policyPeriodStartOrEndDate === '') {
        return 'Policy Period Start Date is required'
      } else {
        let regex = new RegExp(
          '^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)?[0-9]{2})*$'
        )
        if (!regex.test(policyPeriodStartDate)) {
          return 'Date format should be MM/DD/YYYY'
        }
      }
    } else if (keyName === 'policyPeriodEndDate') {
      if (policyPeriodStartOrEndDate === '') {
        return 'Policy Period End Date is required'
      } else {
        let regex = new RegExp(
          '^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)?[0-9]{2})*$'
        )
        if (!regex.test(policyPeriodEndDate)) {
          return 'Date format should be MM/DD/YYYY'
        }
        if (policyPeriodStartDate && policyPeriodEndDate) {
          let isPolicyPeriodValid = validatePolicyPeriodStartAndEndDate(
            policyPeriodStartDate,
            policyPeriodEndDate
          )
          if (!isPolicyPeriodValid) {
            return 'Start Date must be earlier than End Date'
          }
        }
      }
    }
    return ''
  }

  const validateFormInput = (form: any, keyName: string) => {
    let validateKeyCheck = form[keyName] === '' || form[keyName] === null
    if (keyName === 'pageNumber' && validateKeyCheck) {
      return 'Page Number is required'
    } else if (keyName === 'type' && validateKeyCheck) {
      return 'Type is required'
    } else if (keyName === 'formNumber' && validateKeyCheck) {
      return 'Form Number is required'
    } else if (keyName === 'endorsementNumber' && validateKeyCheck) {
      return 'Endorsement Number is required'
    } else if (keyName === 'formTitle' && validateKeyCheck) {
      return 'Form Title is required'
    }
    return ''
  }

  const validateScheduledTextInput = (form: any, keyName: string) => {
    let validateKeyCheck = form[keyName] === '' || form[keyName] === null
    if (keyName === 'textName' && validateKeyCheck) {
      return 'Text Name is required'
    } else if (keyName === 'textValue' && validateKeyCheck) {
      return 'Text Value is required'
    }
    return ''
  }

  const isValid = (errors: any) => {
    let keys = Object.keys(errors)
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0)
    return count === 0
  }

  const isFormsOrScheduledTextsValid = (
    formsOrScheduledTextsErrorData: any
  ) => {
    let counts: any[] = []
    formsOrScheduledTextsErrorData.map((formOrScheduledTextErrorData: any) => {
      let keys = Object.keys(formOrScheduledTextErrorData)
      let count = keys.reduce(
        (acc, curr) => (formOrScheduledTextErrorData[curr] ? acc + 1 : acc),
        0
      )
      counts.push(count === 0)
    })
    return !counts.includes(false)
  }

  const checkIfFormDataValid = (errorsData: any) => {
    let documentAndPolicyErrorsDataForValidation = {
      ...errorsData.document,
      ...errorsData.policy,
    }

    let formsErrorsDataForValidation = errorsData.forms
    let scheduledTextsErrorsDataForValidation = errorsData.scheduledTexts

    let isDocumentAndPolicyValidData = isValid(
      documentAndPolicyErrorsDataForValidation
    )
    let isFormsValidData = isFormsOrScheduledTextsValid(
      formsErrorsDataForValidation
    )
    let isScheduledTextsValidData = isFormsOrScheduledTextsValid(
      scheduledTextsErrorsDataForValidation
    )

    return (
      isDocumentAndPolicyValidData &&
      isFormsValidData &&
      isScheduledTextsValidData
    )
  }

  const handleAddPolicyDetailsSubmitClick = () => {
    //Remove validation for manual policy review
    // let errorsData = validateForm()
    // if (checkIfFormDataValid(errorsData)) {
    let policyDetails = {
      ...policyDetailsInputs?.policy,
      policyPeriodStartDate: policyPeriodStartDate,
      policyPeriodEndDate: policyPeriodEndDate,
      policyPeriod: `${policyPeriodStartDate} to ${policyPeriodEndDate}`,
    }
    setIsPolicyUploadProcessing(true)
    const JsonForPolicyCreation = {
      fileUpload: policyDetailsInputs?.fileUpload,
      document: policyDetailsInputs?.document,
      policy: policyDetails,
      forms: policyDetailsInputs?.formsAndScheduledTexts?.forms,
      scheduledTexts:
        policyDetailsInputs?.formsAndScheduledTexts?.scheduledTexts,
    }
    if (tradeContracrtorId) {
      updatePolicyFromJson({
        variables: {
          data: JsonForPolicyCreation,
        },
      })
    } else {
      createPolicyFromJson({
        variables: {
          data: JsonForPolicyCreation,
        },
      })
    }
    // } else {
    //   setError(errorsData)
    //   setErrorNotification('Please fill all the required details.')
    // }
  }

  useEffect(() => {
    setFileUploadInputs({
      ...fileUploadInputs,
      originalName: clickedFile?.originalName,
      assignedName: clickedFile?.assignedName,
      path: clickedFile?.path,
      status: 'PENDING',
    })
  }, [])

  useEffect(() => {
    setPolicyInputs(policy)
    setDocumentInputs(document)
  }, [policyDetails?.id, documentDetails?.id])

  return (
    <div
      className={`${className} text-start client-list shadow-none p-4 m-4 bg-white rounded fs-5`}
    >
      {isCreatePolicySuccess && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setIsCreatePolicySuccess(false)}
            alertType="success"
            alertMessage="Policy successfully uploaded"
          />
        </Row>
      )}
      {isCreatePolicyError && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setIsCreatePolicyError(false)}
            alertType="error"
            alertMessage="Some error encountered"
          />
        </Row>
      )}
      {errorNotification && (
        <Row className="flex-d justify-content-end pb-3 me-5">
          <CustomAlert
            handleOnClose={() => setErrorNotification('')}
            alertType="error"
            alertMessage={errorNotification}
          />
        </Row>
      )}
      <div>
        <p className="fs-4 fw-bold mb-4 ps-2 text-primary">
          Add Policy Details
        </p>
      </div>
      <Row className="mb-5 d-flex justify-content-center align-items-center">
        <Col sm={4}>
          <Container className="d-flex justify-content-center align-items-center text-start border view-document-container">
            <Badge
              pill
              bg="primary-light"
              className="text-primary-medium badge-document"
              onClick={() =>
                handleViewDocumentClick(
                  documentDetails !== null
                    ? documentDetails?.fileUpload?.path
                    : clickedFile?.path
                )
              }
            >
              <DocumentTextIcon className="check-icon hero-icon-primary pe-1" />
              View Document
            </Badge>
          </Container>
        </Col>
        <Col>
          <DocumentInputs
            documentInputs={documentInputs}
            setDocumentInputs={setDocumentInputs}
            documentName={documentName}
            error={error}
          />
        </Col>
      </Row>
      <PolicyInputs
        policyInputs={policyInputs}
        setPolicyInputs={setPolicyInputs}
        error={error}
        policyPeriodStartDate={policyPeriodStartDate}
        setPolicyPeriodStartDate={setPolicyPeriodStartDate}
        policyPeriodEndDate={policyPeriodEndDate}
        setPolicyPeriodEndDate={setPolicyPeriodEndDate}
      />
      <FormsAndScheduledTextsInputs
        formAndScheduledTextInputs={formAndScheduledTextInputs}
        setFormAndScheduledTextInputs={setFormAndScheduledTextInputs}
        formFields={formFields}
        scheduledTextFields={scheduledTextFields}
        error={error}
        tradeContracrtorId={tradeContracrtorId}
      />
      <div className="mt-5 text-center">
        {loading ? (
          <CustomUnclickableButton
            buttonContent="Uploading Policy..."
            disabled={true}
          >
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </CustomUnclickableButton>
        ) : (
          <CustomClickableButton
            buttonContent="Submit"
            handleOnClick={handleAddPolicyDetailsSubmitClick}
            disable={false}
          />
        )}
      </div>
    </div>
  )
}

export default styled(PolicyDetailsForm)`
  .badge-document {
    padding: 25px;
    cursor: pointer;
  }

  .view-document-container {
    padding: 69px;
  }
`
