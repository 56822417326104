import axios from 'axios'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import CheckboxTree from 'react-checkbox-tree'
import styled from 'styled-components'
import { appConfig } from '../../core'
import authentication from '../../core/authentication'
import { ITemplate } from '../../interface'
import SpinnerLoading from '../spinner-loading'

export interface SpecialProhibitionsProps extends React.ComponentProps<any> {
  duplicateTemplate: ITemplate
  templateId: string
  addedSpecialProhibitions: any[]
  setCheckedElementsForSpecialProhibitions: React.Dispatch<
    React.SetStateAction<any[]>
  >
  setAddedSpecialProhibitions: React.Dispatch<React.SetStateAction<any[]>>
  getSpecialProhibitionsLabelStoredInDB: () => any[]
}
const SpecialProhibitions: React.FC<SpecialProhibitionsProps> = ({
  className,
  addedSpecialProhibitions,
  setCheckedElementsForSpecialProhibitions,
  duplicateTemplate,
  setAddedSpecialProhibitions,
  getSpecialProhibitionsLabelStoredInDB,
}) => {
  const [state, setState] = useState<any>({
    checked: [],
    expanded: [],
    clicked: {},
  })

  const [labellerHierarchyJson, setLabellerHierarchyJson] = useState<any[]>([])

  const handledOnCheck = (checked: any) => {
    setState({ ...state, checked })
    setCheckedElementsForSpecialProhibitions(checked)
    setAddedSpecialProhibitions(checked)
  }

  const handledOnClick = (clicked: any) => {
    setState({ ...state, clicked })
  }

  const handledOnExpand = (expanded: any) => {
    setState({ ...state, expanded })
  }
  useEffect(() => {
    const token = authentication.getAccessToken()
    axios
      .get(`${appConfig.apiUrl}/rest/labels-for-checkbox-tree`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLabellerHierarchyJson(response.data)
      })
      .catch((err) => console.error('Error: ', err))
  }, [])

  useEffect(() => {
    let specialProhibitionsLabels = getSpecialProhibitionsLabelStoredInDB()
    setState({
      ...state,
      checked: addedSpecialProhibitions.length
        ? addedSpecialProhibitions
        : specialProhibitionsLabels,
    })
  }, [])
  if (!labellerHierarchyJson?.length)
    return (
      <div
        className={`${className} spinner`}
        style={{ position: 'absolute', top: '50%', left: '50%' }}
      >
        <SpinnerLoading />
      </div>
    )
  return (
    <div className={className}>
      <Container>
        <Row>
          <Col sm={1}></Col>
        </Row>
        <Row>
          <Col sm={1}></Col>
          <Col sm={9}>
            <CheckboxTree
              nodes={labellerHierarchyJson}
              checked={state.checked}
              expanded={state.expanded}
              onCheck={(checked) => handledOnCheck(checked)}
              onExpand={(expanded) => handledOnExpand(expanded)}
              onClick={(targetNode) => handledOnClick(targetNode)}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default styled(SpecialProhibitions)`
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
`
