import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { UPDATE_CONTRACT_ACCEPTANCE_DATE_TIME_IN_TRADE_CONTRACT } from '../graphql/graphql-queries'
import CustomClickableButton from './custom-components/custom-clickable-button'
import PdfViewer from './pdf-viewer'

export interface AcceptTradeContractProps extends React.ComponentProps<any> {
  className?: string
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>
  currentStepIndex: number
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
  companyFeatureFlags: any
}

const AcceptTradeContract: React.FC<AcceptTradeContractProps> = ({
  className,
  setCurrentStep,
  currentStepIndex,
  setCurrentStepIndex,
  tradeContractForCertifyInsurance,
  companyFeatureFlags,
}) => {
  const [acceptedContract, setAcceptedContract] = useState<boolean>(
    !!tradeContractForCertifyInsurance?.contractAcceptanceDateTime || false
  )

  const [
    isAcceptContractNextButtonDisabled,
    setIsAcceptContractNextButtonDisabled,
  ] = useState(true)

  const handleAcceptContractNextClick = async () => {
    try {
      if (!tradeContractForCertifyInsurance?.contractAcceptanceDateTime) {
        await updateAcceptedContractInTradeContract({
          variables: {
            tradeContractId: tradeContractForCertifyInsurance?.id,
          },
        })
      } else {
        if (
          companyFeatureFlags !== null &&
          companyFeatureFlags?.isDocusignEnabled
        ) {
          setCurrentStep('Upload Policy')
          setCurrentStepIndex(currentStepIndex + 2)
        }
        if (
          companyFeatureFlags == null &&
          !companyFeatureFlags?.isDocusignEnabled
        ) {
          setCurrentStep('Sign Addendum')
          setCurrentStepIndex(currentStepIndex + 1)
        }
      }
    } catch (err) {
      console.error('Error: ', err)
    }
  }

  const handleAcceptContractBackClick = () => {
    setCurrentStep('Review Requirements')
    setCurrentStepIndex(currentStepIndex - 1)
  }

  const handleAcceptContractCheckboxClick = (e: any) => {
    if (e.target.checked) {
      setAcceptedContract(true)
      setIsAcceptContractNextButtonDisabled(false)
    } else {
      setAcceptedContract(false)
      setIsAcceptContractNextButtonDisabled(true)
    }
  }

  const [updateAcceptedContractInTradeContract] = useMutation(
    UPDATE_CONTRACT_ACCEPTANCE_DATE_TIME_IN_TRADE_CONTRACT,
    {
      onCompleted: (data) => {
        if (companyFeatureFlags?.isDocusignEnabled) {
          setCurrentStep('Upload Policy')
          setCurrentStepIndex(currentStepIndex + 2)
        } else {
          setCurrentStep('Sign Addendum')
          setCurrentStepIndex(currentStepIndex + 1)
        }
      },
    }
  )

  useEffect(() => {
    if (acceptedContract) {
      setIsAcceptContractNextButtonDisabled(false)
    }
  }, [acceptedContract])

  return (
    <Container className={`${className} bg-white p-4`}>
      <Row>
        <Col>
          <PdfViewer
            start={1}
            path={tradeContractForCertifyInsurance?.contractFilePath}
          />
        </Col>
        <Col sm={2}>
          <div className="d-flex accept-checkbox">
            <Form.Check
              type="checkbox"
              name="acceptContract"
              key="acceptContract"
              onChange={handleAcceptContractCheckboxClick}
              checked={acceptedContract}
              disabled={
                tradeContractForCertifyInsurance?.contractAcceptanceDateTime
              }
            />
            <Form.Check.Label className="ps-1 ">
              Accept Trade Contract
            </Form.Check.Label>
          </div>
        </Col>
      </Row>

      <div className="footer">
        <div className="left-side">
          <Button
            className={`btn btn-primary-medium rounded-pill fw-bold back-button`}
            variant="outline-success"
            onClick={handleAcceptContractBackClick}
          >
            Back
          </Button>
        </div>
        <div className="right-side">
          <CustomClickableButton
            buttonContent="Next"
            handleOnClick={handleAcceptContractNextClick}
            disable={isAcceptContractNextButtonDisabled}
          />
        </div>
      </div>
    </Container>
  )
}

export default styled(AcceptTradeContract)`
  .back-button {
    border-radius: 100px !important;
    color: black;
    background-color: #fff;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100vw;
    border-top: 1px solid #e6e6e6;
    height: 60px;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  .left-side {
    margin-left: 40px;
  }
  .right-side {
    margin-right: 40px;
  }

  .accept-checkbox {
    position: absolute;
    top: 10%;
    right: 5%;
  }
`
