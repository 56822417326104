import { SearchIcon } from '@heroicons/react/solid'
import { Row, Col, Form, FormControl, InputGroup } from 'react-bootstrap'
import styled from 'styled-components'

export interface SearchFilterProps extends React.ComponentProps<any> {
  className?: string
  handleSearch: (e: any) => any
  placeholderText?: string
  searchTerm?: string
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  className,
  handleSearch,
  placeholderText,
  searchTerm,
}) => {
  return (
    <div className={className}>
      <Form.Group
        as={Row}
        className="mb-4 g-0 d-flex"
        controlId="formHorizontalEmail"
      >
        <Col sm={6}>
          <InputGroup>
            <InputGroup.Text id="basic-addon1" className="input-group-wrapper">
              <SearchIcon className="search-icon" />
            </InputGroup.Text>
            <FormControl
              type="text"
              placeholder={placeholderText ? placeholderText : 'Search...'}
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
              className="form-control-wrapper"
              autoComplete='off'
            />
          </InputGroup>
        </Col>
      </Form.Group>
    </div>
  )
}

export default styled(SearchFilter)`
  .input-group-wrapper {
    background: white;
    border-right: none;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-right: 0;
    z-index: 9999; /*this will keep the icon appearing all time, even when on input::focus*/
  }

  .search-icon {
    height: 25px;
    color: #8b9192;
  }

  .form-control-wrapper {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    &:focus {
      box-shadow: none;
      border-top: 1px solid #ced4da;
      border-right: 1px solid #ced4da;
      border-bottom: 1px solid #ced4da;
    }
  }
`
