import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export interface CusotmOverlayTooltipProps extends React.ComponentProps<any> {
  className?: string
  children?: any
  tooltipContent: string
}

const CusotmOverlayTooltip: React.FC<CusotmOverlayTooltipProps> = ({
  children,
  tooltipContent,
}) => {
  const entering = (e: any) => {
    e.children[0].style.borderTopColor = '#13948B'
    e.children[1].style.backgroundColor = '#000000'
  }

  return (
    <OverlayTrigger
      placement="bottom-start"
      onEntering={entering}
      overlay={
        <Tooltip>
          <div className="tc-text w-100">{tooltipContent}</div>
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default CusotmOverlayTooltip
