import { Container, Row, Col, Card } from 'react-bootstrap'
import styled from 'styled-components'

import TemplateRequirementCard from './template-requirement-card'

export interface InputPolicyReviewAndPublishProps
  extends React.ComponentProps<any> {
  className?: string
  updatedPolicies: any
  // updatedPolicies: IUpdatedPolicy[]
}

const InputPolicyReviewAndPublish: React.FC<
  InputPolicyReviewAndPublishProps
> = ({ className, updatedPolicies }) => {
  return (
    <Container className={`${className} border`}>
      <Row className="fw-bold mt-3 text-center">
        <Col>Policy Information</Col>
        <Col>Template Requirements</Col>
      </Row>

      {updatedPolicies.map((updatedPolicy: any, i: number) => (
        <Row
          key={`${updatedPolicy.policyNumber}${i}`}
          className="d-flex align-items-center"
        >
          <Col>
            <Card
              className="text-start card-border-radius"
              style={{ height: '20rem' }}
            >
              <Card.Header className="fw-bold">Policy Details</Card.Header>
              <Card.Body>
                <Card.Text>
                  <h6 className="fw-bold">Policy Number & Duration</h6>
                  <p className="m-0">{updatedPolicy?.policyNumber}</p>
                  <p className="m-0">{`${String(
                    updatedPolicy?.startDate
                  )} - ${String(updatedPolicy?.endDate)}`}</p>
                </Card.Text>

                <Card.Text>
                  <h6 className="fw-bold">Claims Specialist Information</h6>
                  <p className="m-0">
                    {
                      updatedPolicy?.claimsSpecialists[0]
                        ?.claimsSpecialistFirstName
                    }{' '}
                    {
                      updatedPolicy?.claimsSpecialists[0]
                        ?.claimsSpecialistLastName
                    }
                  </p>
                  <p className="m-0">
                    {updatedPolicy?.claimsSpecialists[0]?.claimsSpecialistTitle}
                  </p>
                  <p className="m-0">
                    {
                      updatedPolicy?.claimsSpecialists[0]
                        ?.claimsSpecialistEmailAddress
                    }
                  </p>
                  <p className="m-0">
                    {
                      updatedPolicy?.claimsSpecialists[0]
                        ?.claimsSpecialistPhoneNumber
                    }
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <TemplateRequirementCard
              template={updatedPolicy.templates[0]}
              cardHeaderBg={true}
            />
          </Col>
        </Row>
      ))}
    </Container>
  )
}

export default styled(InputPolicyReviewAndPublish)`
  .card-border-radius {
    border-radius: 12px;
  }
`
